import { useContext } from 'react';
import './WabaTemplateComponent.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { chatTemplatesContext } from '../../ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesFillContext } from '../../ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import { chatTemplatesMobileContext } from '../../ChatTemplatesMobileProvider/ChatTemplatesMobileProvider';
import { useMobile } from '../../../../../../components/utils/hooks';
import { isSendToChatButton } from '../ChatTemplateWaba/components/utils';
import { useSubmitChatWabaTemplate } from '../../api';
import { ReactComponent as ArrowBack } from '../../../../../../assets/arrow-left.svg';
import { ReactComponent as SmallLoader } from '../../../../../../assets/small-loader.svg';
import Button from '../../../../../../components/Button';
import ChatTemplateWaba from '../ChatTemplateWaba';


const WabaTemplateComponent = () => {
  const { t } = useTranslation();
  const {
    wabaTemplate, setWabaTemplate,
    setCustomTemplate, onClose
  } = useContext(chatTemplatesContext);
  const { fillTemplate, setFillTemplate, setFile } = useContext(chatTemplatesFillContext);
  const { setCurrentStep, currentStep } = useContext(chatTemplatesMobileContext);
  const isMobile = useMobile();
  const isParams = wabaTemplate && isSendToChatButton(wabaTemplate);
  const onSubmitTemplate = useSubmitChatWabaTemplate();
  const loader = useSelector((state: RootState) => state.chats.wabaLoader);

  const onSendTemplate = async () => {
    await onSubmitTemplate();
    onClose();
  };

  const onBack = () => {
    if (currentStep === 'finish') {
      setCurrentStep('fill');
    } else {
      setCustomTemplate(undefined);
      setWabaTemplate(undefined);
      setFile(undefined);
      setFillTemplate(false);
      setCurrentStep('selected');
    }
  };

  const gotoFill = () => {
    setFillTemplate(true);
    setCurrentStep('fill');
  };

  return (
    <div className='wabaTemplateComponent'>
      <div className="wabaTemplateComponent__header">
        {isMobile && <ArrowBack className='arrowBack' onClick={onBack} />}
        {isMobile ? <h3>{t('chats.templates.preview.title')}</h3> : <h4>{t('chats.templates.preview.title')}</h4>}
      </div>
      {wabaTemplate && <ChatTemplateWaba
        body={wabaTemplate.components.body}
        header={wabaTemplate.components.header}
        buttons={wabaTemplate.components.buttons}
        footer={wabaTemplate.components.footer}
        carousel={wabaTemplate.components.carousel}
      />}
      {!fillTemplate && <Button
        text={isParams ? t('chats.templates.preview.open_template') : t('chats.templates.preview.send_in_chat')}
        color={isParams ? (isMobile ? 'orange' : 'white') : 'orange'}
        textType={isParams ? (isMobile ? 'regular' : 'small') : (isMobile ? 'regular' : 'small')}
        onClick={isParams ? gotoFill : onSendTemplate}
      />}
      {isMobile && currentStep === 'finish' && (
        <Button image={loader ? <SmallLoader /> : <></>}
          text={t('chats.templates.preview.send_in_chat')} disabled={loader}
          color="orange" textType="regular" onClick={onSendTemplate}
        />
      )}
    </div>
  );
};

export default WabaTemplateComponent;
