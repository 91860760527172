import React, { createContext, useState } from 'react';
import { ChatTemplateMobileSteps } from '../utils';


type ChatTemplatesMobileProviderProps = {
  children: React.ReactNode;
};

export type ChatTemplatesMobileContext = {
  currentStep: ChatTemplateMobileSteps;
  setCurrentStep: React.Dispatch<React.SetStateAction<ChatTemplateMobileSteps>>;
};

export const chatTemplatesMobileContext = createContext<ChatTemplatesMobileContext>({} as ChatTemplatesMobileContext);

const ChatTemplatesMobileProvider = ({ children }: ChatTemplatesMobileProviderProps) => {
  const [currentStep, setCurrentStep] = useState<ChatTemplateMobileSteps>('selected');

  const valueContext = {
    currentStep, setCurrentStep,
  };

  return <chatTemplatesMobileContext.Provider value={valueContext}>{children}</chatTemplatesMobileContext.Provider>;
};

export default ChatTemplatesMobileProvider;
