import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UsersType } from "../../../../api/CompanyAPI";
import { currentCompanySelector, getRolesCompanySelector } from "../../../Chat/companiesSelector";
import { getNameRole } from "../UserEdit/utlity";


export function useRole(user: UsersType) {
  const { t } = useTranslation();
  const company = useSelector(currentCompanySelector);
  const roles = useSelector(getRolesCompanySelector);
  const [roleName, setRoleName] = useState<string>('');
  const [roleType, setRoleType] = useState<string>('');
  const isOwner = user.userId === company.ownerId;

  useEffect(() => {
    const userRole = roles.find(r => r.id === user.roleId)
    if (userRole) {
      setRoleName(getNameRole(userRole.name, isOwner, t));
      if (isOwner) {
        setRoleType('owner');
      } else {
        setRoleType(['ADMINISTRATOR', 'MANAGER'].includes(userRole.name) ? userRole?.name.toLowerCase() : 'member');
      }
    }
  }, [roles, user]);

  return { isOwner, roleType, roleName };
}
