import { Trans, useTranslation } from 'react-i18next';
import ListItems from '../../../../../../../components/ListItems';
import './Instruction.scss';


function Instruction() {
  const { t } = useTranslation();

  return (
    <div className='instruction'>
      <h3>{t('connections.telegram_bot.register.title')}</h3>
      <ol>
        <li>
          <Trans i18nKey='connections.telegram_bot.register.redirectBotFather'>
            text<a target='_blank' rel='noreferrer' href='https://t.me/BotFather'>link</a>text
          </Trans>
        </li>
      </ol>
      <ListItems typeList='ol' items={t('connections.telegram_bot.register.instructions').split('\n')} />
    </div>
  );
}

export default Instruction;
