import './RemindTestPayment.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { setOnboardingSwitchModal } from '../../../../views/Chat/companiesSlice';
import { onboardingSwitchModalSelector } from '../../../../views/Chat/companiesSelector';
import { updateIsOnbourdingModal } from "../../utils/onboarding";
import { YandexMetrikaCall } from "../../../../utils/utils";
import { bdAnchorsSupport } from '../../../../pages/Home/utils/consts';
import Button from '../../../../components/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';


const RemindTestPayment = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const isSwitchModal = useSelector(onboardingSwitchModalSelector);

  const onSwitchModal = () => {
    dispatch(setOnboardingSwitchModal(!isSwitchModal));
    updateIsOnbourdingModal('isOnbourdingModal', false);
  };

  const onPayWithDiscount = () => {
    history.push(`/companies/${url?.params.companyId}/settings/subscriptions`, true);
    onSwitchModal();
  };

  const onHelp = () => YandexMetrikaCall('reachGoal', 'onb-need-help-btn-clck');

  return (<>
    <div className="remindTestPayment">
      <CloseIcon className='closeIcon' onClick={onSwitchModal} />
      <h2>{t('company_onboarding.remindTestPayment.title')}</h2>
      <p className="regularText">{t('company_onboarding.remindTestPayment.description')}</p>
      <div className="remindTestPayment__footer">
        <Button color="orange" textType="small" text={t('company_onboarding.remindTestPayment.pay')}
          onClick={onPayWithDiscount} />
        <a href={bdAnchorsSupport[i18n.language]} target="_blank" rel="noreferrer">
          <Button color="white" textType="small" text={t('company_onboarding.remindTestPayment.support')}
            onClick={onHelp} />
        </a>
      </div>
    </div>
  </>);
};

export default RemindTestPayment;
