import './AuthCode.scss';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Button from '../../../../../../../components/Button';
import PhoneField from '../../../../../../../components/PhoneInput/PhoneField';
import { ReactComponent as NotificationIcon } from '../../../../../../../assets/info.svg';
import { TelegramActions, TelegramState } from '../utils';
import Password from '../Password/Password';
import { TelegramData } from '../../../shemas';
import TgCode from '../TgCode';

type AuthCodeProps = {
  init: (data?: TelegramData) => Promise<void>;
  update: Dispatch<TelegramActions>;
  state: TelegramState;
};

function AuthCode({ state, update, init }: AuthCodeProps) {
  const { t } = useTranslation();
  const { handleSubmit, formState: { isSubmitting, isDirty } } = useFormContext<TelegramData>();
  const onClickHandler = () => update({ type: 'authType', payload: { authType: 'qr' } });

  const onInitHandler = async (data: TelegramData) => await init(data);
  return (
    <div className="authCode">
      <div className='authCode__phone'>
        <p>{t('connections.telegram.auth.phone')}</p>
        <span className='info__icon'><NotificationIcon /></span>
        <div className='info__message'>{t('connections.telegram.auth.code_info')}<div />
        </div>
      </div>
      <PhoneField name='phone' readOnly={!!state.phone} />
      {state.status === null && <Button type='button' color='white' textType='small'
        text={t('connections.telegram.auth.auth_qr')}
        onClick={(onClickHandler)} />}
      {state.status === 'waiting_for_code' && <TgCode state={state} update={update} init={init} />}
      {state.status === 'waiting_for_password' && <Password state={state} update={update} />}
      {state.status === null && <Button type='submit' color='orange' textType='regular' text={t('continue')}
        onClick={handleSubmit(onInitHandler)}
        disabled={!isDirty || isSubmitting} />}
    </div>
  );
}

export default AuthCode;
