import { FC } from 'react';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { fetchInitialData, joinMemberToCompany, setCurrentCompany } from '../../views/Chat/companiesSlice';
import Button from '../../components/Button';


type Props = {
  invitationCompanyName: string | undefined;
  companies: Array<{ id: number, name: string, ownerId: number; }> | null;
};

const Invitation: FC<Props> = ({ invitationCompanyName, companies }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const invitationCode = useRouteMatch<{ invitationCode: string; }>('/registration/invitation/:invitationCode');

  const confirmHandler = () => {
    if (invitationCode && invitationCode.params.invitationCode) {
      dispatch(joinMemberToCompany(invitationCode.params.invitationCode)).then(async res => {
        if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
          const { companyId } = res.payload;
          if (companyId) {
            await dispatch(fetchInitialData(companyId));
            dispatch(setCurrentCompany(companyId));
            dispatch(push(`/companies/${companyId}`));
          }
        }
      });
    }
  };

  const cancelHandler = () => {
    if (companies && companies?.length > 0) {
      dispatch(fetchInitialData(undefined));
      dispatch(push('/'));
    } else {
      dispatch(push('/registration/cancel'));
    }
  };

  return (
    <div className="registrationRightContent_invitation">
      <p className="regularText">
        {t('registration.invite.text')}
        <span className="regularText bold">{invitationCompanyName}</span>
      </p>
      <div className="registrationRightContent_invitation_button">
        <Button type="button" color='white' textType='regular' text={t('cancel')} onClick={cancelHandler} />
        <Button type="button" color='orange' textType='regular' text={t('registration.invite.right')}
          onClick={confirmHandler} />
      </div>
    </div>
  );
};

export default Invitation;
