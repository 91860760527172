import './InvoiceCreate.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PartnerCompaniesSelector } from '../../../PartnerCabinetSelector';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { subscriptionServiceMap } from '../../types';
import { PartnerInvoiceSchema, useInvoice } from '../../../api/invoices';
import {
  getPartnerCompanyRequisites, getPartnerCompany, getPartnerCompanySubscriptions, getPartnerRequisites
} from '../../../PartnerCabinetAPI';
import { CompanySubscription, PartnerCompany } from '../../../PartnerCabinetTypes';
import { TariffType, tarifName } from '../../../../../utils/dialogs';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import Dropdown from '../../../../Settings/Dropdown/Dropdown';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import PeriodSelector from '../../../components/PeriodSelector';
import DropdownWithCheckbox from '../../../../../components/DropdownWithCheckbox';
import PromoCode from '../../../components/PromoCode/PromoCode';
import PaymentMethod from '../../../components/PaymentMethod';


type ClientsInvoice = PartnerInvoiceSchema & {
  company: number;
};

type Named = {
  id: number;
  name: string;
};

type InvoiceCreateProps = {
  setOnPay: React.Dispatch<React.SetStateAction<boolean>>;
};

const DIALOGS_LIMITS = { min: 1, max: 25000 };

function InvoiceCreate({ setOnPay }: InvoiceCreateProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useSelector(PartnerCompaniesSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const [subscriptions, setSubscriptions] = useState<CompanySubscription[]>([]);
  const [requisites, setRequisites] = useState<Named[]>([]);
  const [client, setClient] = useState<PartnerCompany>({} as PartnerCompany);
  const [hasDialogs, setHasDialogs] = useState<boolean>(false);
  const [isVisiblePromoCode, setIsVisiblePromoCode] = useState(false);
  const [promoCodeTest, setPromoCodeTest] = useState<boolean>(false);
  const getInvoice = useInvoice();

  useEffect( () => {
    let isFetchData = true;
    let listRequisites = [];
    const fetchData = async () => {
      if (client.paymentsLocked) {
        listRequisites = await getPartnerRequisites({ company_id: currentCompany.id, only_approved: true });
      } else {
        listRequisites = await getPartnerCompanyRequisites({
          company_id: currentCompany.id, partner_company_id: client.id, only_approved: true });
      }
      if (isFetchData) setRequisites(listRequisites.map(item => ({ id: item.id, name: item.name })));
    };

    if (client.id) fetchData();

    return () => { isFetchData = false; };

  }, [currentCompany, client]);

  const schema = yup.object().shape({
    company: yup.number().transform((current, original) =>  original === '' ? null : current)
      .nullable().required(t('error.form.empty_field')),
    period_months: yup.number(),
    subscriptions: yup.array().when('dialogs', {
      is: (value: number | null) => !hasDialogs || !value,
      then: yup.array().min(1, t('error.form.empty_field')).of(
        yup.number().transform((current, original) =>  !original ? null : current).required(t('error.form.empty_field'))
    ) }),
    company_requisite_id: yup.number().when('payment_method', {
      is: 'BANK_TRANSFER',
      then: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      otherwise: yup.number().transform((current, original) =>  original === '' ? null : current).nullable(),
    }),
    payment_method: yup.string(),
    partner_email: yup.string().when('payment_method', {
      is: (value: string) => value === 'CARD_RU' && client.paymentsLocked,
      then: yup.string().email(t('error.form.email_format')).required(t('error.form.empty_field')),
      otherwise: yup.string().nullable()
    }),
    dialogs: yup.number().integer().transform((current, original) =>  original === '' ? null : current)
      .nullable(true).min(DIALOGS_LIMITS.min, t('error.form.min_value', { limit: DIALOGS_LIMITS.min }))
      .max(DIALOGS_LIMITS.max, t('error.form.max_value', { limit: DIALOGS_LIMITS.max }))
  });

  const methods = useForm<ClientsInvoice>({ mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: { period_months: 1, payment_method: 'CARD_RU' } });

  const company = useWatch({ control: methods.control, name: 'company' });
  const paymentMethod = useWatch({ control: methods.control, name: 'payment_method' });
  const promo = useWatch({ control: methods.control, name: 'promo_code' });

  useEffect( () => {
    let isFetchData = true;

    const fetchData = async () => {
      if (company) {
        const listSubscriptions = await getPartnerCompanySubscriptions(
          { company_id: currentCompany.id, partner_company_id: company });

        const companyInfo = await getPartnerCompany({ company_id: currentCompany.id, partner_company_id: company });

        const requisitesList = await getPartnerCompanyRequisites({
          company_id: currentCompany.id, partner_company_id: company, only_approved: true });

        if (isFetchData) {
          const clientSubscriptions = listSubscriptions.filter(
            (item) => item.pausedAt === null && item.connections.length > 0);
          setSubscriptions(clientSubscriptions);
          setHasDialogs(clientSubscriptions.some((item) => item.subscriptionType === 'CONNECTION_WABA') &&
            tarifName(companyInfo.waba360DialogPartnerId as TariffType) === 'russia');
          setClient(companyInfo);

          if (!companyInfo.paymentsLocked) {
            setRequisites(requisitesList.map((item) => ({ id: item.id, name: item.name })));
          }
        }
      } else {
        setSubscriptions([]);
      }
    };

    fetchData();

    return () => {
      methods.resetField('company_requisite_id', { defaultValue: null });
      methods.resetField('partner_email', { defaultValue: '' });
      methods.setValue('subscriptions', [] );
      methods.resetField('promo_code', { defaultValue: '' });
      methods.resetField('dialogs', { defaultValue: null });
      isFetchData = false;
    };

  }, [company]);

  useEffect(() => {
    if (promo) checkPromo();
  }, [promo]);

  const onClickPromoCode = (promoCodeValue: string) => {
    methods.setValue('promo_code', promoCodeValue);
  };

  const showPromoInput = () => {
    setIsVisiblePromoCode(true);
  };

  const checkPromo = async () => {
    const invoices = await getInvoice(methods.getValues(), client.id, true);
    setPromoCodeTest(!!invoices);
    if (!invoices) methods.setValue('promo_code', '');
  };

  const onSubmit = async (data: ClientsInvoice) => {
    const invoices = await getInvoice(data, data.company, false);
    if (invoices?.previewUrl) {
      dispatch(addAlertWithCustomText({
        message: t('partner_cabinet.payments.create.submit_fulfilled'), type: 'info' }));
      setOnPay(false);
      window.open(invoices.previewUrl, '_blank');
    }
  };

  return (
    <div className='invoiceCreate'>
      <h1>{t('partner_cabinet.payments.create.title')}</h1>
      <FormProvider {...methods}>
        <Dropdown label={t('partner_cabinet.payments.create.company')} name='company'
          items={companies} labelKey='name' valueKey='id' placeholder={t('select')} />
        <PeriodSelector label={t('partner_cabinet.payments.create.period')} name='period_months' />
        <DropdownWithCheckbox label={t('partner_cabinet.payments.create.subscriptions')} name='subscriptions'
          disabled={!client.id} listArray={subscriptions.map((item) => (
            { id: item.id, name: t(`subscriptions.types.${subscriptionServiceMap[item.subscriptionType]}`) }))} />
        {hasDialogs &&
          <Input name='dialogs' label={t('partner_cabinet.payments.create.dialogs.label')}
            placeholder={t('partner_cabinet.payments.create.dialogs.placeholder')}
            inputType='number' min={0} step={100} />
        }
        <PaymentMethod label={t('partner_cabinet.payments.create.payment.label')} name='payment_method'
          clientPaymentsLocked={client.paymentsLocked} />
        {paymentMethod === 'BANK_TRANSFER' &&
          <Dropdown label={t('partner_cabinet.payments.create.requisites')} name='company_requisite_id'
            items={requisites} labelKey='name' valueKey='id' placeholder={t('select')} />
        }
        {paymentMethod === 'CARD_RU' && client && client.paymentsLocked &&
          <Input name='partner_email' label={t('partner_cabinet.payments.create.email.label')}
            placeholder={t('partner_cabinet.payments.create.email.placeholder')} autoComplete='on' />
        }
        {client.id && <div className='invoiceCreate__promoCode'>
          {isVisiblePromoCode
            ? <>
                <PromoCode label={t('partner_cabinet.payments.create.promo_code.label')}
                  setPromo={onClickPromoCode} />
                {promoCodeTest && <span>{t('partner_cabinet.payments.create.promo_code.success')}</span>}
              </>
            : <p className='smallText'  onClick={showPromoInput}>
              {t('partner_cabinet.payments.create.promo_code.caption')}
            </p>}
        </div>}
        <Button type='submit' color='orange' textType='regular' text={t('create')}
          onClick={methods.handleSubmit(onSubmit)} disabled={!methods.formState.isDirty} />
      </FormProvider>
    </div>
  )
}

export default InvoiceCreate;
