import { useTranslation } from 'react-i18next';
import './InvoiceInfo.scss';
import { dateTimeFormat } from '../../../../../Broadcasts/utils';
import { ReactComponent as ClientIcon } from '../../../../../../assets/id.svg';
import { ReactComponent as IdIcon } from '../../../../../../assets/id-num.svg';


type InvoiceInfoProps = {
  number: number | null;
  date: string;
  status: string;
  companyName?: string;
  companyId?: number;
};

function InvoiceInfo({ companyId, companyName, number, date, status }: InvoiceInfoProps) {
  const { i18n } = useTranslation();

  return (
    <div className={`invoiceInfo ${status}`}>
      <p className={`monospaceRegText ${companyId ?'semibold' : ''}`}>
        {i18n.language === 'ru' ? '№' : '#'}&nbsp;{number}, {dateTimeFormat(date, 'shortDate', i18n.language)}
      </p>
      {companyName && <p className='smallText'><ClientIcon />&nbsp;{companyName}</p>}
      {companyId &&<p className='smallText'><IdIcon />&nbsp;{companyId}</p>}
  </div>
  )
}

export default InvoiceInfo;
