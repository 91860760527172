import './ChatTemplateWabaButtons.scss';
import { WabaTemplateButtons } from '../../../../../../../Templates/templatesTypes';
import { configWabaTemplateButton } from "../utils";


type Props = {
  buttons: WabaTemplateButtons;
};

const ChatTemplateWabaButtons = ({ buttons }: Props) => {
  return (
    <div className="chatTemplateWabaButtons">
      {buttons.text && <p className="smallText">{buttons.text}</p>}
      {buttons.buttons.map((button, i) => (
        <button key={button.type + String(i)}>
          {configWabaTemplateButton[button.type] && configWabaTemplateButton[button.type].icon}
          <p className="smallText middle">{button.text}</p>
        </button>
      ))}
    </div>
  );
};

export default ChatTemplateWabaButtons;
