import { useEffect } from 'react';
import './WithdrawRequisites.scss';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyRequisite } from '../../../../PartnerCabinetTypes';
import { Account } from '../utils';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';


const TIN_LENGTH_LEGAL = 10;

type WithdrawRequisitesProps = {
  account: CompanyRequisite;
  onSubmit: (isReady: boolean, data: Account) => Promise<void>;
};

function WithdrawRequisites({ account, onSubmit }: WithdrawRequisitesProps) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    current_account: yup.string().required(t('error.form.empty_field'))
    .matches(/^\d+$/, t('error.form.digits_only'))
    .min(20, ({ min }) => t('error.form.min_length', { limit: min }))
    .max(20, ({ max }) => t('error.form.max_length', { limit: max })),
    bic: yup.string().required(t('error.form.empty_field'))
    .matches(/^\d+$/, t('error.form.digits_only'))
    .min(9, ({ min }) => t('error.form.min_length', { limit: min }))
    .max(9, ({ max }) => t('error.form.max_length', { limit: max })),
    kpp: yup.string().when({
      is: () => account.tin.length === TIN_LENGTH_LEGAL,
      then: yup.string().required(t('error.form.empty_field'))
      .matches(/^\d+$/, t('error.form.digits_only'))
      .min(9, ({ min }) => t('error.form.min_length', { limit: min }))
      .max(9, ({ max }) => t('error.form.max_length', { limit: max })),
      otherwise: yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
      .matches(/^\d+$/, t('error.form.digits_only'))
      .min(9, ({ min }) => t('error.form.min_length', { limit: min }))
      .max(9, ({ max }) => t('error.form.max_length', { limit: max }))
    }),
  });

  const methods = useForm<Account>({ mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: { current_account: '', bic: '', kpp: '' } });

  useEffect(() => {
    methods.reset({ current_account: account.currentAccount ?? '', bic: account.bic ?? '', kpp: account.kpp ?? '' });
  }, [account]);

  const onSubmitHandler = async (data: Account) => {
    await onSubmit(Object.entries(methods.formState.dirtyFields).length === 0, data);
  };

  return (
    <div className="withdrawRequisites">
      <p className="regularText">{t('partner_cabinet.requisites.tin')}&nbsp;{account.tin}</p>
      <FormProvider {...methods}>
        <Input name='current_account' label={t('partner_cabinet.requisites.current_account')} />
        <Input name='bic' label={t('partner_cabinet.requisites.bic')}/>
        {account.type === 'company' && <Input name='kpp' label={t('partner_cabinet.requisites.kpp')}/>}
        <Button type="submit" color='orange' textType='regular' text={t('create')}
          disabled={!methods.formState.isValid || methods.formState.isSubmitting}
          onClick={methods.handleSubmit(onSubmitHandler) }/>
      </FormProvider>
    </div>
  );
}

export default WithdrawRequisites;
