import { createContext } from 'react';
import { IndexedParams, ParamsType } from './utils';


type WabaModerationContext = {
  onAttachmentFile: File | undefined;
  setOnAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  arrayHeaderParams: ParamsType[];
  setArrayHeaderParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayBodyParams: ParamsType[];
  setArrayBodyParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayButtonParams: ParamsType[];
  setArrayButtonParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayCardParams: IndexedParams[];
  setArrayCardParams: React.Dispatch<React.SetStateAction<IndexedParams[]>>;
  isError: boolean;
};

export const wabaModeration = createContext<WabaModerationContext>({} as WabaModerationContext);
