import React, { FunctionComponent, SVGProps } from 'react';
import './SwitchTabs.scss';

export type Tabs = Array<{ tabName: string; tabId: string; tabIcon?: FunctionComponent<SVGProps<SVGSVGElement>> }>;

type Props = {
  tabs: Tabs;
  onSelectHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedTab: string;
};

const SwitchTabs: React.FC<Props> = ({ tabs, onSelectHandler, selectedTab }) => (
  <div className="switchTabs">
    {tabs.map((tab) => {
      const TabIcon = tab.tabIcon;
      return (
        <button
          key={tab.tabId}
          type="button"
          id={tab.tabId}
          className={`${selectedTab === tab.tabId && 'active'}`}
          onClick={onSelectHandler}
        >
          {TabIcon && <TabIcon />}
          {tab.tabName}
        </button>
      );
    })}
  </div>
);

export default SwitchTabs;
