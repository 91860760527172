import './ClientItem.scss';
import { useHistory, useRouteMatch } from "react-router-dom";
import { PartnerCompany } from "../../../PartnerCabinetTypes";
import ClientName from "../ClientItemComponents/ClientName";
import ClientPercent from "../ClientItemComponents/ClientPercent";
import ClientStatus from "../ClientItemComponents/ClientStatus";
import ClientPaymentLocked from "../ClientItemComponents/ClientPaymentLocked";


type Props = {
  client: PartnerCompany;
}

const ClientItem = ({ client }: Props) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const onClient = () => {
    history.push(`${url}/${client.id}`);
  }

  return (
    <div className='clientsList__list_item' onClick={onClient}>
      <span><ClientName name={client.name} id={client.id} /></span>
      <span><ClientPaymentLocked paymentsLocked={client.paymentsLocked} /></span>
      <span><p className='monospaceRegText'>{client.wabaDialogsBalance}</p></span>
      <span><ClientPercent percent={client.partnerPercent} /></span>
      <span><ClientStatus client={client} /></span>
    </div>
  );
};

export default ClientItem;
