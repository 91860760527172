import { useEffect } from 'react';
import './RequisitesList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPartnerRequisites } from '../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { PartnerRequisites } from '../../PartnerCabinetSelector';
import RequisiteItem from '../RequisiteItem';


const RequisitesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const partnerRequisites = useSelector(PartnerRequisites)

  useEffect(() => {
    dispatch(fetchPartnerRequisites({ company_id: currentCompany.id }));
  }, []);

  if (!partnerRequisites) {
    return <></>;
  }

  return (
    <div className="requisitesList">
      <div className="requisitesList__header smallText semibold">
        <span>{t('partner_cabinet.requisites.name')}</span>
        <span>{t('partner_cabinet.requisites.tin')}</span>
        <span>{t('partner_cabinet.requisites.status')}</span>
      </div>
      <div className="requisitesList__content">
        {partnerRequisites.map((requisite) => (
          <RequisiteItem key={requisite.id} requisite={requisite}  />
        ))}
      </div>
    </div>
  );
};

export default RequisitesList;
