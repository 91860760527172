import { useDispatch } from "react-redux";
import { removePartnerNotificationChannel, updatePartnerNotificationChannel } from "../../PartnerCabinetSlice";
import { PartnerNotificationChannelRequest, PartnerNotificationChannelUpdate } from "../../PartnerCabinetTypes";


export function usePartnerNotificationChannel() {
  const dispatch = useDispatch();

  const deleteChannel = (params: PartnerNotificationChannelRequest) => {
    dispatch(removePartnerNotificationChannel(params));
  };

  const updateChannel = (params: PartnerNotificationChannelUpdate) => {
    dispatch(updatePartnerNotificationChannel(params));
  };

  return { deleteChannel, updateChannel };
}
