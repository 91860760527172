import './WabaTemplatePlug.scss';


type WabaTemplatePlugProps = {
  header: string;
  text: string;
};

function WabaTemplatePlug({ header, text }: WabaTemplatePlugProps) {
  return (
    <div className='wabaTemplatePlug'>
      <div className='wabaTemplatePlug__header'>
        <h3>{header}</h3>
      </div>
      <textarea className='regularText' autoComplete='off' readOnly value={text} />
    </div>
  );
}

export default WabaTemplatePlug;
