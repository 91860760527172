import { useTranslation } from "react-i18next";
import {
  connectionsMessengerOnboarding,
  ConnectionsMessengerOnboarding,
  connectionsOnboarding,
  ConnectionsOnboarding,
} from '../../../api/types';
import { settingConnections } from "../../../views/Settings/Connections/utils/connections";


const crm = ['amocrm', 'bitrix', 'no_crm', 'other'];

type Named = {
  id: string;
  name: string;
};

export const getTranslationCRM = () => {
  const { t } = useTranslation();
  const selectCRM: Named[] = [];

  for (let i = 0; i < crm.length; i++) {
    selectCRM.push({ id: crm[i], name: t(`company_onboarding.chooseCrm.crm.${crm[i]}`) });
  }

  return selectCRM;
};

export type SelectConnections = {
  id: ConnectionsOnboarding | ConnectionsMessengerOnboarding;
  name: string;
};

export const selectConnection = () => {
  const { t, i18n } = useTranslation();
  const arrayConnections: SelectConnections[] = [];
  const connections = i18n.language === 'ru' ? connectionsOnboarding : connectionsMessengerOnboarding;

  for (let i = 0; i < connections.length; i++) {
    arrayConnections.push({
      id: connections[i],
      name: t(settingConnections[connections[i]]?.name ?? 'unkown'),
    });
  }
  return arrayConnections;
};
