import { WabaTemplateButtons } from '../../../../ChatTypes';
import './WabaButtons.scss';


type WabaButtonsProps = {
  buttons: WabaTemplateButtons;
};

function WabaButtons({ buttons }: WabaButtonsProps) {
  return (<>
    {buttons.map((button) => (
      <button type="button" key={button.text}>
        <span className='smallText'>{button.text}</span>
      </button>
    ))}
  </>);
}

export default WabaButtons;
