import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allConnectionsForChatSelector, currentCompanySelector } from "../companiesSelector";
import { getChatAvailability } from "../ChatAPI";
import { ChatAvailability } from "../ChatTypes";
import { addAlertWithCustomText, setAlertWithClosing } from "../../../components/Alert/alertSlice";
import { getDateTime } from "../../../utils/getDateTime/getDateTime";
import { currentChatSelector } from "../chatsSelector";


export type ChatStatus = {
  blocked: boolean;
  message?: string;
  waba?: {
    shouldPay: boolean;
    wabaExpired: boolean;
  };
};

export function useChatStatus() {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const allConnections = useSelector(allConnectionsForChatSelector);
  const currentChat = useSelector(currentChatSelector);
  const [hasStatus, setHasStatus] = useState<boolean>(false);
  const [availability, setAvailability] = useState<ChatAvailability>();
  const [isPaid, setIsPaid] = useState<boolean | '' | null>(false);
  const [chatStatus, setChatStatus] = useState<ChatStatus>();
  const [wabaTimerElapsed, setWabaTimerElapsed] = useState<boolean>(false);
  const currentConnection = allConnections.find((connection) => currentChat.connectionId === connection.connectionId);

  useEffect(() => {
    let isFetchData = true;
    if (currentCompany && currentConnection && currentChat && allConnections.length > 0) {
      setIsPaid(Boolean(currentConnection.subscription.paidUntil
        && !getDateTime().isPast(currentConnection.subscription.paidUntil)));

      if (currentConnection.type === 'waba') {
        const fetchChatAvailability = async () => {
          const chatAvailability = await getChatAvailability({
            chatId: currentChat.chatId,
            connectionId: currentChat.connectionId,
            companyId: currentCompany.id
          });

          if (isFetchData) setAvailability(chatAvailability);
        };

        fetchChatAvailability().catch(e => {
          if (e.response) {
            dispatch(addAlertWithCustomText({ code: e.response.data.error_code, type: 'alarm' }));
          } else {
            dispatch(setAlertWithClosing({ payload: 'error.unknown', type: 'alarm' }));
          }
        }).finally(() => setHasStatus(true));

      } else {
        setHasStatus(true);
      }
    }

    return () => {
      setHasStatus(false);
      setAvailability(undefined);
      isFetchData = false;
    };

  }, [currentCompany, currentChat, allConnections, currentConnection]);

  useEffect(() => {
    if (hasStatus) {
      let message: string = '';
      let shouldPay: boolean = false;
      let wabaExpired: boolean = false;

      if (availability && !availability.isAvailable && isPaid) {
        shouldPay = !availability.waba.dialogsAvailable;
        wabaExpired = !shouldPay;
        message = shouldPay ? 'chats.chat.no_waba_dialogs' : 'chats.chat.block_message';
      } else if (!isPaid) {
        const statusName = currentConnection?.subscription?.paidUntil ? 'unpaid' : 'unknown';
        message = `subscriptions.connection_status.${statusName}.chat`;
      }

      setChatStatus({
        blocked: !isPaid || (availability !== undefined ? !availability.isAvailable : false),
        ...(availability && !availability.isAvailable && isPaid && { waba: { shouldPay, wabaExpired } }),
        message
      });

      setHasStatus(false);
    }
  }, [hasStatus]);

  useEffect(() => {
    setChatStatus((prev) => {
      if (prev && prev.waba) return { ...prev, waba: {
        wabaExpired: wabaTimerElapsed && !prev.waba.shouldPay,
        shouldPay: prev.waba.shouldPay
       } };
      return prev;
    });
  }, [wabaTimerElapsed]);

  return { availability, chatStatus, setWabaTimerElapsed };
}
