import { useEffect, useState } from 'react';
import './NewMessageNotification.scss';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import { notificationDataSelector } from '../../views/Chat/chatsSelector';
import { selectChat, updateNotificationData } from '../../views/Chat/chatsSlice';
import { setCurrentConnectionId } from '../../views/Chat/companiesSlice';
import { getDateTime } from '../../utils/getDateTime/getDateTime';
import { ReactComponent as CloseIcon } from '../../assets/cross.svg';


function NewMessageNotification() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const notificationData = useSelector(notificationDataSelector);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (notificationData) {
      const id = setTimeout(() => {
        dispatch(updateNotificationData(null));
      }, 9000);
      setTimerId(id);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [notificationData]);

  const onCloseNotification = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(updateNotificationData(null));
  };

  const onRedirectToChat = () => {
    if (notificationData?.chat) {
      dispatch(selectChat(notificationData.chat));
      dispatch(setCurrentConnectionId(notificationData.chat.connectionId));
      history.push(
        `/companies/${params?.params.companyId}/chats/${notificationData.contactId}/${notificationData.chat.chatId}`);
    }
  };

  if (notificationData === null) return null;

  return (
    <div className="messageNotification" onClick={onRedirectToChat}>
      <div className="messageNotification__avatar">
        <p>{notificationData.chat?.name.charAt(0)}</p>
      </div>
      <div className="messageNotification__description">
        <div className="messageNotification__userInfo">
          <p className="messageNotification__userInfo_name">{notificationData.chat?.name}</p>
          <p className="messageNotification__userInfo_text">{notificationData.message?.text?.text}</p>
        </div>
        <div className="messageNotification__description_time">
          <p>
            {notificationData.message && getDateTime().format(notificationData.message.createdAt, 'hourMinutes')}
          </p>
        </div>
      </div>
      <div className="messageNotification__closeIcon" onClick={onCloseNotification}>
        <CloseIcon />
      </div>
    </div>
  );
}

export default NewMessageNotification;
