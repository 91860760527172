import { useEffect } from 'react';
import './ChooseCrm.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { getTranslationCRM } from '../../utils/arrays';
import { bdAnchorsSupport } from '../../../../pages/Home/utils/consts';
import { CompanyOnboardingUpdate } from '../../../../api/types';
import { useUpdateStep } from '../../utils/onboarding';
import { onboardingSelector } from '../../../../views/Chat/companiesSelector';
import { YandexMetrikaCall } from "../../../../utils/utils";
import Dropdown from '../../../../views/Settings/Dropdown/Dropdown';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import MenuButtons from '../MenuButtons';


const ChooseCrm = () => {
  const { t, i18n } = useTranslation();
  const onboarding = useSelector(onboardingSelector);
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const onSubmitOnboardingStep = useUpdateStep();
  const selectCrm = getTranslationCRM();

  const schema = yup.object().shape({
    value: yup.string().typeError(t('empty_field')),
    custom_value: yup.string().max(256, t('error.form.max_length', { limit: 256 }))
      .when('value', (value, customValue) =>
        value === 'other' ? customValue.typeError(t('empty_field')).min(1, t('empty_field')) : customValue.nullable()
      )
  });

  const methods = useForm({
    mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: {
      value: '',
      custom_value: ''
    },
  });

  useEffect(() => {
    if (onboarding) {
      const findStep = onboarding.steps.find((step) => step.type === 'choose_crm');
      if (findStep) {
        methods.reset({ value: findStep.result?.value, custom_value: findStep.result?.customValue });
      }
    }
  }, []);

  const onHelp = () => YandexMetrikaCall('reachGoal', 'onb-need-help-btn-clck');

  const onNextStep = (result: { value: string; custom_value: string }) => {
    const data: CompanyOnboardingUpdate = {
      company_id: Number(url?.params.companyId),
      steps: [
        {
          is_finished: true,
          type: 'choose_crm',
          result: { value: result.value, ...(result.value === 'other' ? { custom_value: result.custom_value } : {}) },
        },
        {
          ...(result.value === 'no_crm' || result.value === 'other'
            ? { is_finished: true }
            : { is_finished: false }),
          type: 'add_integration',
        },
      ],
    };
    YandexMetrikaCall('reachGoal', 'onb-step-choose-crm');
    onSubmitOnboardingStep(data);
  };

  const onPrevStep = () => {
    if (onboarding) {
      const prevResult = onboarding.steps.find((step) => step.type === 'choose_niche')?.result;

      if (prevResult) {
        const data: CompanyOnboardingUpdate = {
          company_id: Number(url?.params.companyId),
          steps: [
            {
              is_finished: false,
              type: 'choose_niche',
              result: {
                value: prevResult.value,
                ...(prevResult.value === 'other' ? { custom_value: prevResult.customValue } : {}),
              },
            },
          ],
        };
        onSubmitOnboardingStep(data);
      }
    }
  };

  return (
    <div className='chooseCrm'>
      <MenuButtons />
      <FormProvider {...methods}>
        <Dropdown label={t('company_onboarding.chooseCrm.title')} items={selectCrm} labelKey='name' valueKey='id'
          name='value' placeholder={t('select')} />
        {methods.watch('value') === 'other' &&
          <Input name="custom_value" label={t('company_onboarding.chooseCrm.otherCRM')} />}
        <div className='chooseCrm__buttons'>
          <Button color="white" textType="small" text={t('back')} onClick={onPrevStep} />
          <div className='chooseCrm__buttons_right'>
            <a href={bdAnchorsSupport[i18n.language]} target="_blank" rel="noreferrer">
              <Button color="white" textType="small" text={t('help')} onClick={onHelp} />
            </a>
            <Button color="orange" textType="small" text={t('next')} onClick={methods.handleSubmit(onNextStep)} />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ChooseCrm;

