import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICatalog, ICatalogList,
  ICatalogCreateRequest, ICatalogRequest, ICatalogListRequest, ICatalogEditRequest, ICatalogProductsRequest,
  ProductsList, Product, ProductRequest, ProductCreateRequest, ProductsListRequest,
  createCatalog, deleteCatalog, updateCatalog, addProductsCatalog, deleteProductsCatalog, getCatalogList,
  getProductsList, createProduct, deleteProduct, updateProduct,
  ProductEditRequest, updateProductToCatalogs, ProductToCatalogsRequest
} from "./SalesAPI";
import { getPageLimit } from "../../components/Paginator/utils";


export type SalesSlice = {
  catalogs: ICatalog[];
  catalogsProduct: ICatalog[];
  products: ProductsList | null;
  product: Product | null;
  load: boolean;
  loadProduct: boolean,
  checkProducts: boolean;
  catalogIdRemove: number | null;
  productIdRemove: number | null;
  hasMore: boolean;
};

const initialState: SalesSlice = {
  catalogs: [],
  catalogsProduct: [],
  products: null,
  product: null,
  load: false,
  loadProduct: false,
  checkProducts: false,
  catalogIdRemove: null,
  productIdRemove: null,
  hasMore: true,
};

export const fetchCatalogs = createAsyncThunk<ICatalogList, ICatalogListRequest, { rejectValue: number }>(
  'sales/fetchCatalogs', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getCatalogList(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const newCatalog = createAsyncThunk<ICatalog, ICatalogCreateRequest, { rejectValue: number }>(
  'sales/newCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      return await createCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const editCatalog = createAsyncThunk<ICatalog, ICatalogEditRequest, { rejectValue: number }>(
  'sales/editCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removeCatatlog = createAsyncThunk<unknown, ICatalogRequest, { rejectValue: number }>(
  'sales/removeCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      await deleteCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removeProductsCatalog = createAsyncThunk<ICatalog, ICatalogProductsRequest, { rejectValue: number}>(
  'sales/removeProductsCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      return await deleteProductsCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const insertProductsCatalog = createAsyncThunk<ICatalog, ICatalogProductsRequest, { rejectValue: number }>(
  'sales/insertProductsCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      return await addProductsCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchProducts = createAsyncThunk<ProductsList, ProductsListRequest, { rejectValue: number }>(
  'sales/fetchProducts', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getProductsList(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const checkProductsCompany = createAsyncThunk<boolean, {companyId: number}, { rejectValue: number }>(
  'sales/checkProductsCompany', async (requestOptions, { dispatch, rejectWithValue }) => {
    dispatch(setSalesLoad(true));
    const productsCheckNumber = 1;
    try {
      const products =  await getProductsList({ companyId: requestOptions.companyId, limit: productsCheckNumber });
      return products.count === productsCheckNumber;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const postProducts = createAsyncThunk<Product, ProductCreateRequest, {rejectValue:number}>(
  'sales/postProducts',
  async (payload, { rejectWithValue }) => {
    try {
      return await createProduct(payload);
    }
    catch (e) {
      return rejectWithValue(e.response.data.detail.error);
    }
  }
);

export const removeProduct = createAsyncThunk<unknown, ProductRequest, { rejectValue: number }>(
  'sales/removeProduct', async (requestOptions, { rejectWithValue }) => {
    try {
      await deleteProduct(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const editProduct = createAsyncThunk<Product, ProductEditRequest, { rejectValue: number }>(
  'sales/editProduct', async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateProduct(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const productUpdateCatalogs = createAsyncThunk<Product, ProductToCatalogsRequest, { rejectValue: number }>(
  'sales/productUpdateCatalogs', async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateProductToCatalogs(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    clearProductsList: (state) => {state.products = null;},
    setSalesLoad: (state, action: PayloadAction<boolean>) => {state.load = action.payload;},
    setCatalogIdRemove: (state, action: PayloadAction<number>) => {state.catalogIdRemove = action.payload;},
    setProductIdRemove: (state, action: PayloadAction<number>) => {state.productIdRemove = action.payload;},
    clearSales: (state) => {state = { ...initialState };},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogs.fulfilled, (state, action) => {
        if(action.meta.arg.productId){
          state.catalogsProduct = action.payload.items;
        } else if (action.meta.arg.offset && state.catalogs.length > 0 ) {
            const catalogIds = state.catalogs.map(catalog => catalog.id);
            state.catalogs.push(...action.payload.items.filter((item) => !catalogIds.includes(item.id)));
            if (action.meta.arg.limit) {
              state.hasMore = action.meta.arg.limit === action.payload.count;
            }
        } else {
          state.catalogs = action.payload.items;
        }
        state.load = false;
      })
      .addCase(fetchCatalogs.rejected, (state) => {
        state.load = false;
      })
      .addCase(newCatalog.fulfilled, (state, action: PayloadAction<ICatalog>) => {
        state.catalogs.push(action.payload);
      })
      .addCase(removeCatatlog.fulfilled, (state) => {
        if (state.catalogIdRemove) {
          state.catalogs = state.catalogs.filter(catalog => catalog.id !== state.catalogIdRemove);
          state.catalogIdRemove = null;
        }
      })
      .addCase(editCatalog.fulfilled, (state, action: PayloadAction<ICatalog>) => {
        const catalogIndex = state.catalogs.findIndex(catalog => catalog.id === action.payload.id);
        if (catalogIndex !== -1) state.catalogs[catalogIndex] = action.payload;
      })
      .addCase(removeProductsCatalog.fulfilled, (state, action: PayloadAction<ICatalog>) => {
        const catalogIndex = state.catalogs.findIndex(catalog => catalog.id === action.payload.id);
        if (catalogIndex !== -1) state.catalogs[catalogIndex] = action.payload;
        if (state.productIdRemove) {
          if (state.products) {
            state.products.items = state.products.items.filter(item => item.id !== state.productIdRemove);
          }
          state.productIdRemove = null;
        }
      })
      .addCase(insertProductsCatalog.fulfilled, (state, action: PayloadAction<ICatalog>) => {
        const catalogIndex = state.catalogs.findIndex(catalog => catalog.id === action.payload.id);
        if (catalogIndex !== -1 ) state.catalogs[catalogIndex] = action.payload;
      })
      .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<ProductsList>) => {
        state.products = action.payload;
        state.loadProduct = false;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.loadProduct = true;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loadProduct = false;
      })
      .addCase(postProducts.fulfilled, (state, action: PayloadAction<Product>) => {
        if (state.products) {
          if(state.products.count < getPageLimit('productListPagesLimit')){
            state.products.items.push(action.payload);
            state.products.totalCount += 1;
          } else state.products.totalCount += 1;
        }
      })
      .addCase(checkProductsCompany.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.checkProducts = action.payload;
        state.load = false;
      })
      .addCase(editProduct.fulfilled, (state, action: PayloadAction<Product>) => {
        if(state.products) {
          const productIndex = state.products.items.findIndex(product => product.id === action.payload.id);
          if (productIndex !== -1) state.products.items[productIndex] = action.payload;
        }
      })
      .addCase(editProduct.pending, (state) => {
        state.load = true;
      })
      .addCase(editProduct.rejected, (state) => {
        state.load = false;
      })
      .addCase(productUpdateCatalogs.fulfilled, (state, action: PayloadAction<Product>) => {
        if(state.products) {
          const productIndex = state.products.items.findIndex(product => product.id === action.payload.id);
          if (productIndex !== -1) state.products.items[productIndex] = action.payload;
        }
      })
      .addCase(removeProduct.fulfilled, (state) => {
        if (state.products && state.productIdRemove) {
          state.products.items = state.products.items.filter(product => product.id !== state.productIdRemove);
          state.productIdRemove = null;
        }
      });
  },
});

export const {
  clearProductsList, setCatalogIdRemove, setSalesLoad, setProductIdRemove, clearSales
} = salesSlice.actions;
export default salesSlice.reducer;
