import { RootState  } from "../../store/store";


export const broadcastsItemSelector = (state: RootState) => state.broadcasts.broadcast;
export const broadcastsListSelector = (state: RootState) => state.broadcasts.broadcasts;
export const broadcastsPlanSelector = (state: RootState) => state.broadcasts.broadcastsPlan;
export const broadcastsLogSelector = (state: RootState) => state.broadcasts.broadcastsLog;
export const broadcastsLogTotalSelector = (state: RootState) => state.broadcasts.broadcastsLogTotal;
export const broadcastsSourceSelector = (state: RootState) => state.broadcasts.sources;
export const broadcastsTagsSelector = (state: RootState) => state.broadcasts.tags;
export const broadcastsTagsStashSelector = (state: RootState) => state.broadcasts.stashTags;
export const broadcastsTagsHasMoreSelector = (state: RootState) => state.broadcasts.hasMoreTags;
export const broadcastsTagsOffsetSelector = (state: RootState) => state.broadcasts.offsetTags;
export const broadcastsStatusSelector = (state: RootState) => state.broadcasts.status;
export const broadcastsStatusLogSelector = (state: RootState) => state.broadcasts.statusLog;
export const broadcastsWabaTemplatesSelector = (state: RootState) => state.broadcasts.wabaTemplates;
