import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { WabaConnection } from "../../../../../api/CompanyAPI";
import { useAppSelector } from "../../../../../store/store";
import { currentCompanySelector } from "../../../../Chat/companiesSelector";
import { getWabaCatalogStatus } from "../../connectionsSlice";
import { FacebookCatalogStatus, verticales, WabaCatalogStatus, WabaProfileVertical } from "../../ConnectionsTypes";
import { connectionItemContext } from "../../utils/connections";
import { ReactComponent as DisconnectedIcon } from '../../../../../assets/folder.svg';
import { ReactComponent as AuthorizateIcon } from '../../../../../assets/person-run.svg';


export function useWabaCatalogStatus() {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const catalogStatuses = useAppSelector((state) => state.connections.wabaCatalogsStatus);
  const { connection } = useContext(connectionItemContext);
  const [catalogStatus, setCatalogStatus] = useState<WabaCatalogStatus>();

  useEffect(() => {
    if (company && connection && (connection as WabaConnection).params.facebookCatalogEnabled) {
      dispatch(getWabaCatalogStatus({ company_id: company.id, waba_connection_id: connection.id }));
    }
  }, [company, connection]);

  useEffect(() => {
    if (catalogStatuses) {
      setCatalogStatus(catalogStatuses[connection.id]);
    }
  }, [catalogStatuses])

  return catalogStatus;
}

type StatusIcons = Partial<Record<FacebookCatalogStatus, React.FC<React.SVGProps<SVGSVGElement>>>>;

export const wabaCatalogStausIcons: StatusIcons = {
  disconnected: DisconnectedIcon,
  expired: AuthorizateIcon,
};

export type AuthData = {
  id: string;
  secret: string;
};

export const LinksWabaCatalogInstruction: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/whatsapp-business-api/dobavlenie-prilozheniya-dlya-katalogov-v-meta-for-developers',
  en: 'https://docs.radist.online/docs/our-products/whatsapp-business-api/adding-a-catalog-application-to-meta-for-developers',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp-business-api/anadir-una-aplicacion-de-catalogo-a-meta-for-developers'
};

export const LinksWabaWorkRules: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/whatsapp-business-api/pravila-raboty-s-waba',
  en: 'https://docs.radist.online/docs/our-products/whatsapp-business-api/rules-for-working-with-waba',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp-business-api/normas-para-trabajar-con-waba'
}

export const LinksWabaTariff: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-uslugi/tarify#1.-tarif-s-oplatoi-v-radist.online',
  en: 'https://docs.radist.online/docs/our-services/tariffs',
  es: 'https://docs.radist.online/docs/our-services/tariffs'
}

export type EditData = {
  name: string;
  waba_auto_templates_enabled: boolean;
};

export function useWabaEditSchema() {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    waba_auto_templates_enabled: yup.boolean(),
  });
  return schema;
}

export type WabaProfileData = {
  about: string;
  description: string;
  vertical: WabaProfileVertical;
  email: string;
  website_main: string;
  website_additional: string;
  address: string;
  profile_picture_url: string | null;
};

export function useWabaProfileSchema() {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    about: yup.string().max(139, t('error.form.max_length', { limit: 139 })).required(t('error.form.empty_field')),
    description: yup.string().max(512, t('error.form.max_length', { limit: 512 })),
    vertical: yup.string().oneOf([...verticales]).required(t('error.form.empty_field')),
    email: yup.string().email(t('error.form.email_format')).required(t('error.form.empty_field')).max(128,
      t('error.form.max_length', { limit: 128 })),
    website_main: yup.string().url(t('error.form.url')),
    website_additional: yup.string().url(t('error.form.url')),
    address: yup.string().max(256, t('error.form.max_length', { limit: 256 })),
    profile_picture_url: yup.string().required(t('error.form.empty_field')).transform((_, val) =>
      (val !== "" ? val : null))
  })
  return schema;
}
