import React, { useEffect, useState } from 'react';
import './ConversationChatsList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router';
import { allConnectionsForChatSelector } from '../../companiesSelector';
import { selectChat } from '../../chatsSlice';
import { ChatType } from '../../ChatTypes';
import { getLastMessageDate } from '../Conversation/helpers';
import { ReactComponent as WhatsAppBusinessLogo } from '../../../../assets/logo-whatsapp-buiness.svg';
import { ReactComponent as WhatsAppLogo } from '../../../../assets/logo-whatsapp.svg';
import { ReactComponent as TelegramLogo } from '../../../../assets/logo-telegram.svg';
import { ReactComponent as TelegramBotLogo } from '../../../../assets/logo-telegram-bot.svg';


type Props = {
  chats: ChatType[];
  contactId: number;
  openDropdown: (a: never) => void;
};

const chatsIconsTypes = {
  waba: <WhatsAppBusinessLogo className="conversationChatsList__header_wabaIcon" />,
  whatsapp: <WhatsAppLogo className="conversationChatsList__header_whatsAppIcon" />,
  telegram: <TelegramLogo className="conversationChatsList__header_telegramIcon" />,
  telegram_bot: <TelegramBotLogo className="conversationChatsList__header_telegramBotIcon" />
};

const ConversationChatsList: React.FC<Props> = ({ chats,contactId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useRouteMatch('/companies/:companyId/');
  const params = useRouteMatch<{ contactId: string; chatId: string }>('/companies/:companyId/chats/:contactId/:chatId');
  const connections = useSelector(allConnectionsForChatSelector);
  const [chatsIcons, setChatsIcon] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    const chatsIconsConnections = chats.map(
      (chat) => connections.find((connection) => connection.connectionId === chat.connectionId)?.type
    );
    setChatsIcon(chatsIconsConnections);
  }, [chats]);


  const onSelectChat = (chat: ChatType, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(selectChat(chat));
    dispatch(push(`${history?.url}/chats/${contactId}/${chat.chatId}`));
  };

  return (
    <>
      {chats.map((chat, idx) => (
        <div
          className={`conversationChatsList ${
            params && params.params.chatId && Number(params.params.chatId) === chat.chatId && 'active'
          }`}
          key={chat.chatId}
          onClick={(e) => onSelectChat(chat, e)}
        >
          <div className="conversationChatsList__header">
            {/* @ts-ignore */}
            {chatsIcons[idx] && chatsIconsTypes[chatsIcons[idx]]}
            <div className="conversationChatsList__header_info">
              <p className="conversationChatsList__header_info__name">
                {connections.find((connection) => connection.connectionId === chat.connectionId)?.name || ''}
              </p>
              <p className="conversationChatsList___header_info__phone">{chat.phone}</p>
            </div>
            <div className="conversationChatsList__header_info__lastMessageInfo">
              <p className="conversationChatsList__header_info__lastMessageInfo_time">
                {chat.lastMessage ? getLastMessageDate(chat.lastMessage.createdAt, t) : ''}
              </p>
              {chat.unansweredCount > 0 && (
                <div className="conversationChatsList__header_info__lastMessageInfo_unansweredCountPoint" />
              )}
            </div>
          </div>
          <div className="conversationChatsList__tags">
            {/* {chatsTags && */}
            {/*  chatsTags[chat.chatId] && */}
            {/*  chatsTags[chat.chatId].map((tag) => ( */}
            {/*    <div */}
            {/*      className="conversationChatsList__tags_tag" */}
            {/*      style={{ backgroundColor: tag.backgroundColor }} */}
            {/*      key={tag.id} */}
            {/*    > */}
            {/*      {tag.name} */}
            {/*    </div> */}
            {/*  ))} */}
          </div>
        </div>
      ))}
    </>
  );
};

export default ConversationChatsList;
