import './WithdrawPanel.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../store/store';
import { PartnerRequisites } from '../../../PartnerCabinetSelector';
import {
  createPartnerPayout, fetchPartnerBalance, fetchPartnerRequisites, updatePartnerRequisite
} from '../../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { CompanyRequisite } from '../../../PartnerCabinetTypes';
import { Account } from './utils';
import Select from '../../../../../components/Select';
import WithdrawRequisites from './WithdrawRequisites';


type WithdrawPanelProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const WithdrawPanel = ({ setOpen }: WithdrawPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const balance = useSelector((state: RootState) => state.partners.partnerBalance);
  const requisites = useSelector(PartnerRequisites);
  const [account, setAccount] = useState<CompanyRequisite>();

  useEffect(() => {
    dispatch(fetchPartnerRequisites({ company_id: companyId }));
  }, []);

  useEffect(() => {
    if (requisites.length > 0) {
      const defaultAccount = requisites.find((item) => item.default);
      if (defaultAccount) setAccount(defaultAccount);
    }
  }, [requisites]);

  const onSelectHandler = (item: CompanyRequisite) => {
    setAccount(item);
  };

  const onSubmit = async (isReady: boolean, data: Account) => {
    if (account) {
      if (!isReady) {
        await dispatch(updatePartnerRequisite({
          company_id: companyId, name: account.name, requisite_id: account.id, tin: account.tin,
          type: account.type,
          default: account.default, ...data }))
          .then((res) => {isReady = res.meta.requestStatus === 'fulfilled'});
      }
      if (isReady) {
        dispatch(createPartnerPayout({ company_id: companyId, requisite_id: account.id }))
        .then((res) => {if (res.meta.requestStatus === 'fulfilled') {
          dispatch(fetchPartnerBalance({ companyId }));
          setOpen(false);
        }});
      }
    }
  };

  return (
    <div className="withdrawPanel">
      <h1>{t('partner_cabinet.finances.fee.title')}</h1>
      <p className="regularText">{t('partner_cabinet.finances.fee.amount')}:&nbsp;
        <span className="regularText semibold">{balance}&nbsp;₽</span></p>
      <p className="regularText middle">{t('partner_cabinet.finances.fee.requisites')}</p>
      <p className="regularText">{t('partner_cabinet.finances.fee.info')}</p>
      {requisites.length > 0 &&
        <Select items={requisites} labelKey="name" placeholder={t('select')} initValue={account?.name}
          onChoose={onSelectHandler} />
      }
      {account && <WithdrawRequisites account={account} onSubmit={onSubmit} />}
    </div>
  );
};

export default WithdrawPanel;
