import React, { useEffect, useState } from 'react';
import './Input.scss';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { getError } from '../utils/forms';
import { ReactComponent as CloseIcon } from '../../assets/eye-close.svg';
import { ReactComponent as OpenIcon } from '../../assets/eye-open.svg';
import { ReactComponent as PercentIcon } from '../../assets/percent.svg';


type InputType = 'password' | 'text' | 'percent' | 'number';

type Props = {
  label?: string;
  name: string;
  rightText?: string;
  inputType?: InputType;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = ({ label, name, inputType = 'text', rightText, ...props }) => {
  const { register, formState: { errors } } = useFormContext();
  const [isContentHidden, setIsContentHidden] = useState(false);
  const isError = getError(errors, name);

  useEffect(() => {
    if (inputType && inputType === 'password') {
      setIsContentHidden(true);
    }
  }, [inputType]);

  const triggerContentVisibility = () => {
    setIsContentHidden(!isContentHidden);
  };

  const getPasswordIcon = () => {
    if (inputType) {
      if (inputType === 'password') {
        return isContentHidden ? (
          <CloseIcon onClick={triggerContentVisibility} className="inputContainer__passwordIcon" />
        ) : (
          <OpenIcon onClick={triggerContentVisibility} className="inputContainer__passwordIcon" />
        );
      }
      if (inputType === 'percent') {
        return <PercentIcon className="inputContainer__percentIcon" />;
      }
    }
    return null;
  };

  const getInputType = () => {
    if (inputType) {
      if (inputType === 'password') {
        return isContentHidden ? 'password' : 'text';
      }
      return inputType;
    }
    return 'text';
  };

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    if (inputType === 'number') e.currentTarget.blur();
  };

  return (
    <div className="inputContainer">
      {(label || rightText) &&
        <div className={`inputContainer__label ${props.disabled ? 'diabled' : ''}`}>
          {label && <p>{label}</p>}
          {rightText && <p>{rightText}</p>}
        </div>}
      <div className="inputContainer__wrapper">
        <input
          className={`inputContainer__input ${isError ? 'error' : ''} ${inputType !== 'text' ? 'noText' : ''}`}
          {...props}
          {...register(name)}
          type={getInputType()}
          onWheel={(e) => onWheel(e)}
          autoComplete={props.autoComplete ?? 'off'}
        />
        {getPasswordIcon()}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="inputContainer__errorMessage">{message}</p>}
      />
    </div>
  );
};

export default Input;
