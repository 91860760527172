import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonRunIcon } from '../../../../assets/person-run.svg';
import { useOutsideClick } from '../../../../components/utils/hooks';


type PropsType = {
  toggleProfile: () => void;
  toggleVisible: () => void;
  isGroup: boolean;
};

const ChatHeaderModal: FC<PropsType> = ({ toggleVisible, toggleProfile, isGroup }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);

  useOutsideClick(contentRef, toggleVisible);

  return (
    <div className="chatHeader__wrapperModal__modal" ref={contentRef}>
      <div className="chatHeader__wrapperModal__modal__icon" onClick={toggleProfile}>
        <PersonRunIcon />
        <p className='regularText'>{isGroup
          ? t('contacts.detailContact.group')
          : t('contacts.detailContact.profile')
        }</p>
      </div>
    </div>
  );
};

export default ChatHeaderModal;
