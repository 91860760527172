import { useContext } from 'react';
import './NotificationConnect.scss';
import { useTranslation } from 'react-i18next';
import { Channels } from '../../../PartnerCabinetTypes';
import { channelsNames, partnerNotifications, useAvailableChannels } from '../../utils';
import Button from '../../../../../components/Button';
import ChannelIcon from '../ChannelIcon';


type NotificationConnectProps = {
  channelType: Channels;
};

function NotificationConnect({ channelType }: NotificationConnectProps) {
  const { t } = useTranslation();
  const available = useAvailableChannels(channelType);
  const { setOpenNotification, setChannelType } = useContext(partnerNotifications);
  
  const connect = () => {
    setOpenNotification(true);
    setChannelType(channelType);
  };

  return (
    <div className='notificationChannel'>
      <div className="notificationChannel__logo">
        <ChannelIcon channel={channelType} />
        <h3>{channelsNames[channelType]}</h3>
      </div>
      <div className="notificationChannel__info">
        <p className='smallText'>{t('partner_cabinet.notifications.available', { limit: available })}</p>
        <Button type='button' color='white' textType='small' text={t('connect')} onClick={connect}
          disabled={!available} />
      </div>
    </div>
  );
}

export default NotificationConnect;
