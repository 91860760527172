import './WabaStartList.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as PlusIcon } from '../../../../../../../../assets/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/trash.svg';
import Button from '../../../../../../../../components/Button';
import Input from '../../../../../../../../components/Input';


const WabaStartList = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const messages = useFieldArray({ name: 'ice_breakers', control });

  const iceBreakers = watch('ice_breakers');
  const emptyIceBreakers = iceBreakers.length === 1 && iceBreakers[0].value.length === 0;

  const onAddMessage = () => {
    messages.append({ value: '' })
  };

  const onDeleteMessage = (index: number) => {
    messages.remove(index);
  };

  return (
    <div className="wabaStartList">
      {messages.fields.map(({ id }, index) => (
        <div key={id} className="wabaStartList__input">
          <Input name={`ice_breakers.${index}.value`}
            label={t('connections.waba.start_messages.message_label', { index: index + 1 })}
            rightText={t('connections.waba.start_messages.up_to_max', { number: 40 })} />
          {index !== 0 && <DeleteIcon className="deleteIcon" onClick={() => onDeleteMessage(index)} />}
        </div>
      ))}
      <Button type='button' color="white" textType="small" text={t('connections.waba.start_messages.add_message')}
        image={<PlusIcon />} onClick={onAddMessage} disabled={messages.fields.length >= 4 || emptyIceBreakers} />
    </div>
  );
};

export default WabaStartList;
