import { useState } from 'react';
import './InteractiveMessages.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { wabaInteractiveSelector } from '../../templatesSelector';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../components/Button';
import Search from '../../../../components/Search/Search';
import InteractiveSwitchTypes from '../InteractiveSwitchTypes';
import InteractiveMessageTemplateCard from '../InteractiveMessageTemplateCard/InteractiveMessageTemplateCard';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';


function InteractiveMessages() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const [selectedMessageType, setSelectedMessageType] = useState<string>('all');
  const [searchValue, setSearchValue] = useState<string>('');
  const wabaInteractiveTemplates = useSelector(wabaInteractiveSelector);

  const onSelectMessageType = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonMessageType = e.currentTarget.id;

    setSelectedMessageType(buttonMessageType);
  };

  const filteredTemplatesByTabType = () => {
    if (selectedMessageType !== 'all') {
      let filteredTemplateByTab = wabaInteractiveTemplates.filter(
        (template) => template.params.header && template.params.header.type === selectedMessageType
      );
      if (searchValue) {
        filteredTemplateByTab = filteredTemplateByTab.filter((template) =>
          template.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      return filteredTemplateByTab.map((template) => (
        <InteractiveMessageTemplateCard key={template.id} template={template} />
      ));
    }
    if (searchValue) {
      return wabaInteractiveTemplates
        .filter((template) => template.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((template) => (
          <InteractiveMessageTemplateCard key={template.id} template={template} />
        ));
    }
    return wabaInteractiveTemplates.map((template) => (
      <InteractiveMessageTemplateCard key={template.id} template={template} />
    ));
  };
  
  const createTemplate = () => push(`${url}/create`);

  return (
    <div className="interactiveMessages">
      <div className="interactiveMessages__header">
        <div className="interactiveMessages__header_title">
          <h1>{t('templates.interactive_messages')}</h1>
          <WrapperPermission permission='templatesCreate'>
            <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
              onClick={createTemplate} image={<PlusIcon />} />
          </WrapperPermission>
        </div>
        <div className="interactiveMessages__header_bottom">
          <InteractiveSwitchTypes tabs={['all', 'text', 'image', 'video', 'document']}
            onSelectTab={onSelectMessageType} selectedTab={selectedMessageType} />
          <Search onChangeSearchInput={setSearchValue} value={searchValue} />
        </div>
      </div>
      <div className="interactiveMessages__templates">{filteredTemplatesByTabType()}</div>
    </div>
  );
}

export default InteractiveMessages;
