import './TelegramEdit.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/store';
import { updateConnection } from '../../../../connectionsSlice';
import { TelegramConnection } from '../../../../../../../api/CompanyAPI';
import Button from '../../../../../../../components/Button';
import GoBackWrapper from '../../../../../../../components/GoBackWrapper';
import Input from '../../../../../../../components/Input';
import SwitchControl from '../../../../../../../components/SwitchControl';


type TelegramData = {
  name: string;
  group_chats_enabled: boolean;
 };

function TelegramEdit() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { connectionId } = useParams<{ connectionId: string }>();
  const company = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.telegram);
  const [connection, setConnection] = useState<TelegramConnection>();
  const [clicked, setClicked] = useState<boolean>(false);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    group_chats_enabled: yup.boolean(),
  });

  const methods = useForm<TelegramData>({
    mode: 'onSubmit', defaultValues: { name: '', group_chats_enabled: false }, resolver: yupResolver(schema) });

  useEffect(() => {
    const itemConnection = allConnections.find((item) => item.id === Number(connectionId)) as TelegramConnection;
    if (itemConnection) {
      setConnection(itemConnection);
      methods.reset({ name: itemConnection.name, group_chats_enabled: itemConnection.params.groupChatsEnabled });
    }
  }, [allConnections]);

  const onSave = (data: TelegramData) => {
    if (connection) {
      setClicked(true);
      dispatch(updateConnection({
        companyId: Number(company?.params.companyId),
        itemId: connection.id,
        name: data.name,
        type: connection.type,
        params: { group_chats_enabled: data.group_chats_enabled }
      })).then(() => {history.goBack()});
    }
  };

  return (
    <div className='telegramEdit'>
      <GoBackWrapper title={t('edit')} >
        <p>{t('connections.connection_number')}: #{connection?.id}</p>
        <p>{connection?.params.phone}</p>
        {connection && connection?.params.username &&
          <p>@{connection.params.username}</p>
        }
        <FormProvider {...methods}>
          <Input label={t('connections.connection_name')} name='name' type='text' />
          <SwitchControl label={t('connections.telegram.group_chats')} name='group_chats_enabled' />
          <div className={`telegramEdit__footer ${clicked ? 'clicked' : ''}`}>
            <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                    onClick={methods.handleSubmit(onSave)} disabled={!methods.formState.isDirty} />
          </div>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
}

export default TelegramEdit;
