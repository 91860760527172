import './Payments.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../store/store';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { fetchPartnerCompanies, fetchPartnerCompanyPayments } from '../PartnerCabinetSlice';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { invoicesUploadContext } from './types';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg'
import Button from '../../../components/Button';
import InvoicesView from './components/InvoicesView';
import SidePanel from '../../../components/Modals/SidePanel';
import InvoiceCreate from './components/InvoiceCreate';


const INVOICES_LIMIT = 25;

function Payments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [onCreate, setOnCreate] = useState<boolean>(false);
  const company = useSelector(currentCompanySelector);
  const invoicesNumber = useAppSelector((state) => state.partners.partnerPayments.length);

  useEffect(() => {
    if (company) {
      dispatch(fetchPartnerCompanyPayments({ company_id: company.id, partner_company_id: 0 }));
      dispatch(fetchPartnerCompanies({ company_id: company.id, limit: 100 }));
    }
  }, []);

  const onCreateHandler = () => setOnCreate(true);

  const onUpload = () => {
    if (company) {
      dispatch(fetchPartnerCompanyPayments(
        { company_id: company.id, partner_company_id: 0, offset: invoicesNumber, limit: INVOICES_LIMIT }));
    }
  };

  return (
    <>
      {onCreate &&
        <SidePanel setIsOpen={setOnCreate} background='light'>
          <InvoiceCreate setOnPay={setOnCreate} />
        </SidePanel>
      }
      <div className='payments'>
        <div className='payments__header'>
          <h1>{t('partner_cabinet.payments.title')}</h1>
          <WrapperPermission permission='partnersEdit'>
            <Button type='button' color='orange' textType='regular' text={t('partner_cabinet.payments.create.title')}
              image={<PlusIcon />} onClick={onCreateHandler} />
          </WrapperPermission>
        </div>
        <div className='payments__content'>
          <invoicesUploadContext.Provider value={{ hasMore: true, onUpload }}>
            <InvoicesView />
          </invoicesUploadContext.Provider>
        </div>
      </div>
    </>
  );
}
export default Payments;
