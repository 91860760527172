import { useContext } from 'react';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TelegramConnection } from '../../../../../api/CompanyAPI';
import { connectionItemContext } from '../../utils/connections';
import Button from '../../../../../components/Button/Button';
import './TelegramStatus.scss';


const TelegramStatus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connection: connectionItem } = useContext(connectionItemContext);
  const connection = connectionItem as TelegramConnection;

  const updateStatus = () => {
    if (currentCompanyId?.url) {
      dispatch(push(`${currentCompanyId?.url}/settings/connections/telegram/auth`, {
        name: connection.name,
        phone: connection.params.phone,
        connectionId: connection.id,
      }));
    }
  };

  return (
    <div className="telegramStatus">
      {connection?.lastStatus && connection.lastStatus.statusType === 'bad' && (
        <>
          <div className='telegramStatus__sessionEnded'>
            {t('connections.telegram.session.end')}
          </div>
          <Button type='button' textType='small' color='white' onClick={updateStatus}
            text={t('connections.telegram.session.login')} />
        </>
      )}
    </div>
  );
}

export default TelegramStatus;
