import './GoBackWrapper.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../../assets/arrow-back.svg';
import { getGoBackUrl } from './utils/utils';
import Button from '../Button';


type GoBackWrapperProps = {
  title: string;
  children: React.ReactNode;
};

const GoBackWrapper = ({ title, children }: GoBackWrapperProps) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();

  const gotoBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      const backUrl = getGoBackUrl(url);
      backUrl && history.push(backUrl);
    }
  };

  return (
    <>
      <div className="goBackWrapper">
        <Button color='white' textType='small' text={t('back')} onClick={gotoBack} image={<ArrowBack />} />
        <h1>{title}</h1>
      </div>
      {children}
    </>
  );
};

export default GoBackWrapper;
