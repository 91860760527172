import React, { FC, useContext } from 'react';
import './ProductsList.scss';
import { useTranslation } from 'react-i18next';
import ProductItem from '../ProductItem/ProductItem';
import { productItemContext } from '../../../types';
import { Product } from '../../../SalesAPI';

type Props = {
  products: Product[];
};

const ProductsList: FC<Props> = ({ products }) => {
  const { onEdit, onDelete, onPreview } = useContext(productItemContext);
  const { t } = useTranslation();

  return (
    <div className="products_list">
      <div className='products__title'>
        <div style={{ paddingLeft: '16px' }}>{t('sales.products.title_list.image')}</div>
        <div>{t('sales.products.title_list.name')}</div>
        <div>{t('sales.products.title_list.price')}</div>
      </div>
      <div className='products__content'>
        {products.map((product) =>
          <ProductItem key={product.id}
                       product={product}
                       onViewDetail={onPreview}
                       onDelete={onDelete}
                       onEdit={onEdit}
        />
        )}
      </div>
    </div>
  );
};

export default ProductsList;
