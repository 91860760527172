import { useContext } from 'react';
import './NotificationCard.scss';
import { NotificationChannel, NotificationChannelEvents } from '../../NotificationsTypes';
import { listLogosNotification, notificationContext } from '../../utils/types';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { dataEditNotifications } from '../../utils/utils';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import Button from '../../../../../components/Button';
import NotificationCardEvents from '../NotificationCardEvents';


export type Props = {
  channel: NotificationChannel;
};

function NotificationCard({ channel }: Props) {
  const { onDelete, onEdit } = useContext(notificationContext);

  const onToggle = (event: NotificationChannelEvents) => {
    const channelData = dataEditNotifications(channel, event);
    if (onEdit) onEdit(channelData);
  };

  const onDeleteHandler = () => {
    if (channel.id !== 0 && onDelete) {
      onDelete(channel);
    }
  };

  return (
    <div className='notificationCard'>
      <WrapperPermission permission="notificationsDelete">
        <Button type="button" color="white" textType="small"
          onClick={onDeleteHandler} image={<DeleteIcon />} id="delete" />
      </WrapperPermission>
      <div className="notificationCard_phone">
        {listLogosNotification[channel.type]}
        <p className="regularText">
          {channel.type === 'whatsapp'
            ? parsePhoneNumberFromString(channel[channel.type].phone)?.formatInternational()
            : channel.name}
        </p>
      </div>
      <NotificationCardEvents channel={channel} onToggle={onToggle} />
    </div>
  );
}

export default NotificationCard;
