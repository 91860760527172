import { useEffect, useRef } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { getError } from '../../../../../../../../../components/utils/forms';
import { SelectPosition } from '../../../../../../../../Templates/utils/waba';
import './WabaTextarea.scss';


type TextareaProps = {
  name: string;
  label?: string; 
  rightText?: string;
  setPosition?: (position: SelectPosition) => void;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

function WabaTextarea({ name, label, rightText, setPosition, ...props }: TextareaProps) {
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const { register, formState: { errors } } = useFormContext();
  const { ref, ...rest } = register(name);

  useEffect(() => {
    if (!textRef.current) return;
    if (setPosition) setPosition({
      start: textRef.current.selectionStart ?? 0, end: textRef.current.selectionEnd ?? 0
    });
  }, [textRef.current?.value]);

  const checkPositions = () => {
    if (!textRef.current) return;
    if (setPosition) setPosition({
      start: textRef.current.selectionStart ?? 0, end: textRef.current.selectionEnd ?? 0
    });
  };

  return (
    <div className="wabaTextarea">
      {(label || rightText) && 
        <div className={`wabaTextarea__label ${getError(errors, name) && 'error'}`}>
          {label && <p>{label}</p>}
          {rightText && <p>{rightText}</p>}
        </div>
      }
      <textarea {...props} {...rest} ref={(e) => { ref(e); if (e) textRef.current = e; }}
        onPointerUp={checkPositions} onKeyUp={checkPositions} onBlur={checkPositions} />
      <ErrorMessage errors={errors} name={name} render={({ message }) => <p className="smallText error">{message}</p>} />
    </div>
  );
}

export default WabaTextarea;
