import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { optionsContext, useConnectionsInfo, useMessagingUpdate, useOptions, usePipelines } from '../../api';
import { MessagingSchema } from '../../types';
import { integrationContext, optionsListAmo, optionsListBitrix } from '../../utils';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../components/Button/Button';
import SwitchControl from '../../../../../components/SwitchControl';
import EditName from '../EditName';
import WhatsAppPrompt from './components/WhatsAppPrompt';
import ConnectionAdd from '../ConnectionAdd';
import OptionList from './components/OptionList';
import './Messaging.scss';
import SwitchRequest from '../SwitchRequest';


function Messaging() {
  const { t } = useTranslation();
  const { area, service, integration } = useContext(integrationContext);
  const { available, connected, domain } = useConnectionsInfo(integration.id);
  const { pipelines, fetched } = usePipelines({ area, integration: integration.id });
  const optionsSettings = useOptions({ area, integration, available, connected, pipelines, fetched });
  const updateIntegration = useMessagingUpdate();
  const { onChange, onDelete, onInsert } = optionsSettings;
  const [switchRequest, setSwitchRequest] = useState<boolean>(false);

  const defaultValues: MessagingSchema = {
    writeFirstConn: String(integration?.params.writeFirstConn),
    checkPhonesEnabled: integration?.params.checkPhonesEnabled,
    handleAmoDialogEvents: integration?.params.handleAmoDialogEvents,
    createInvoices: integration?.params.createInvoices,
    smsCreateChatEnabled: integration?.params.smsCreateChatEnabled,
    status: integration?.status === 'enabled',
  };

  const methods = useForm<MessagingSchema>({ mode: 'onSubmit', defaultValues });

  const saveData = async(data: MessagingSchema) => {
    const result = await updateIntegration({
      id: integration.id, name: integration.name, type: integration.type, ...data, options: optionsSettings.options
    });
    if (result) {
      methods.reset(data);
      optionsSettings.onReset();
    }
  };

  const onSwitchOn = () => {
    setSwitchRequest(false);
    const data = methods.getValues();
    saveData({ ...data, status: !data.status });
  };

  const onSwitchOff = () => {
    setSwitchRequest(false);
    saveData(methods.getValues());
  };

  const onSubmit = async (data: MessagingSchema) => {
    if (integration && !data.status && integration.status === 'disabled') {
      setSwitchRequest(true);
    } else {
      saveData(data);
    }
  };

  const updateName = async (name: string) => await updateIntegration({
    name, id: integration.id, type: integration.type, ...defaultValues, options: optionsSettings.initOptions
  });

  const optionsFields = () => {
    if ((area === 'amocrm') && service) return optionsListAmo[service];
    if ((area === 'bitrix') && service) return optionsListBitrix[service];
  };

  const getTranslation = (translateKey: string) => {
    const translate = t(translateKey);
    return translate !== translateKey ? translate : undefined;
  };

  return (
    <div className='messaging'>
      {switchRequest && <SwitchRequest onAction={onSwitchOn} onClose={onSwitchOff} setIsOpen={setSwitchRequest} />}
      <EditName integration={integration} onUpdate={updateName} />
      <p className='regularText middle'>{t('settings.integrations.domain')}: {domain}</p>
      <p className='regularText middle'>{t('settings.integrations.integration_id')}: {integration.id}</p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <optionsContext.Provider value={{ onChange, onDelete, pipelines }}>
            <OptionList options={optionsSettings.options} />
            <WrapperPermission permission='integrationsEdit'>
              <ConnectionAdd addons={optionsSettings.addons} onInsert={onInsert} />
            </WrapperPermission>
          </optionsContext.Provider>
          <div className='messaging__footer'>
            {optionsFields()?.map((field: string) =>
              <div className='messaging__footer_switch' key={field}>
                <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
                  <SwitchControl name={field} label={t(`settings.integrations.options_list.${field}`)}
                    info={getTranslation(`settings.integrations.options_info.${field}`)} />
                </WrapperPermission>
                {field === 'checkPhonesEnabled' && <WhatsAppPrompt />}
              </div>
            )}
            <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
              <SwitchControl name='status' label={t('settings.integrations.status')} />
            </WrapperPermission>
            <WrapperPermission permission='integrationsEdit'>
              <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                disabled={!methods.formState.isDirty && !optionsSettings.isChanged} />
            </WrapperPermission>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Messaging;
