import './AnaliticsCheck.scss';
import * as yup from 'yup';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { useAppDispatch } from '../../../../store/store';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { getAnalytics } from '../../AnalyticsAPI';
import Button from "../../../../components/Button";
import Input from '../../../../components/Input';

export type Analytics = {
  url: string,
};

const AnaliticsCheck = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const [isConnected, setIsConnected] = useState<boolean | undefined>();

  const schema = yup.object().shape({
    url: yup.string().url(t('error.form.url')).required(t('error.form.empty_field')),
  });

  const methods = useForm<Analytics>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleCheckStatus = async (data: Analytics) => {
    try {
      const analyticsResponse = await getAnalytics(companyId, data.url);
      setIsConnected(analyticsResponse.success);
    } catch (error) {
      dispatch(addAlertWithCustomText({ type: 'alarm', message: 'error.form.url' }));
    }
  };

  return (
    <div className='analiticsCheck'>
      <FormProvider {...methods}>
        <h3>{t('analytics.check')}</h3>
        <p className='regularText'>{t('analytics.connection_check')}</p>
        <Input name="url" />
        <Button type='submit' textType='small' text={t('analytics.status')} color='orange'
          onClick={methods.handleSubmit(handleCheckStatus)} disabled={!methods.formState.isDirty} />
        {isConnected !== undefined &&
          <div className={`analiticsCheck__status ${isConnected ? 'green' : 'red'}`} >
            <div className='analiticsCheck__status_point'/>
            <p>{`${isConnected ? t('analytics.connected') : t('analytics.notConnected')}`}</p>
          </div>
        }
      </FormProvider>
    </div>
  );
};

export default AnaliticsCheck;

