import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTags, Tag, TagsListRequestType } from '../Settings/Tags/TagsAPI';
import * as api from './BroadcastsAPI';
import * as typ from './BroadcastsTypes';
import { broadcastServices, isDateAfter } from './utils';


type StatusType = 'load' | 'idle';

export type InitialState = {
  tags: Tag[];
  broadcast: typ.IBroadcast | null;
  broadcasts: typ.IBroadcast[];
  broadcastsPlan: typ.IBroadcast[];
  broadcastsLog: typ.IBroadcast[];
  broadcastsLogTotal: number;
  broadcastsStatistic: typ.IBroadcastsStatistics;
  sources: typ.ISourceType[];
  stashTags: Tag[];
  companyId: number;
  hasMoreTags: boolean;
  offsetTags: number;
  status: StatusType;
  statusLog: StatusType;
  wabaTemplates: typ.WabaTemplate[];
};

const initialState: InitialState = {
  tags: [],
  broadcast: null,
  broadcasts: [],
  broadcastsPlan: [],
  broadcastsLog: [],
  broadcastsLogTotal: 0,
  broadcastsStatistic: {} as typ.IBroadcastsStatistics,
  sources: [],
  stashTags: [],
  companyId: 0,
  hasMoreTags: true,
  offsetTags: 0,
  status: 'load',
  statusLog: 'load',
  wabaTemplates: []
};

export const fetchTagsList = createAsyncThunk('broadcasts/fetchTagsList', async (requestOptions: TagsListRequestType) =>
  await getTags(requestOptions)
);

export const getBroadcasts = createAsyncThunk(
  'broadcasts/getBroadcasts', async (requestOptions: typ.BroadcastsRequest) =>
  await api.getBroadcastsList(requestOptions)
);

export const getBroadcastsPlan = createAsyncThunk(
  'broadcasts/getBroadcastsPlan', async (requestOptions: typ.BroadcastsRequest, { dispatch }) => {
    dispatch(setStatus('load'));
    return await api.getBroadcastsList({ ...requestOptions, statuses: 'PENDING' });
  });

export const getBroadcastsLog = createAsyncThunk(
  'broadcasts/getBroadcastsLog', async (requestOptions: typ.BroadcastsRequest, { dispatch }) => {
    dispatch(setStatusLog('load'));
    return await api.getBroadcastsList({ ...requestOptions, statuses: ['IN_PROGRESS', 'FINISHED', 'FAILED'] });
  });

export const getBroadcast = createAsyncThunk<typ.IBroadcast, typ.IBroadcastRequest, { rejectValue: number; } >(
  'broadcasts/getBroadcast', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getBroadcastItem(requestOptions)
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const createBroadcast = createAsyncThunk<typ.IBroadcast, typ.BroadcastCreateRequest, { rejectValue: number; }>(
  'broadcasts/createBroadcast', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.postBroadcast(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const updateBroadcast = createAsyncThunk<typ.IBroadcast, typ.BroadcastUpdateRequest, { rejectValue: number; }>(
  'broadcasts/updateBroadcast', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.patchBroadcast(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const finishBroadcast = createAsyncThunk<typ.IBroadcast, typ.BroadcastStopRequest, { rejectValue: number; }>(
  'broadcasts/finishBroadcast', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.patchBroadcast(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removeBroadcast = createAsyncThunk<unknown, typ.IBroadcastRequest, { rejectValue: number; }>(
  'broadcasts/removeBroadcast', async (requestOptions, { rejectWithValue }) => {
    try {
      await api.deleteBroadcast(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const getBroadcastsStatistic = createAsyncThunk(
  'broadcasts/getBroadcastsStatistic', async (requestOptions: typ.BroadcastsStatisticsRequest) =>
  await api.getBroadcastsStatistics(requestOptions)
);

export const getSources = createAsyncThunk('broadcasts/getSources', async (requestOptions: { companyId: number; }) =>
  await api.getBroadcastSources(requestOptions)
);

export const getBroadcastWabaTemplates =
  createAsyncThunk<typ.WabaTemplate[], {companyId: number, connectionId: number },{ rejectValue: number }>(
    'templates/getBroadcastWabaTemplates', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getWabaTemplates(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState,
  reducers: {
    stashTag: (state, action: PayloadAction<number>) => {
      if (state.tags) {
        const index = state.tags.findIndex((tag) => tag.id === action.payload);
        const stashed = state.tags.splice(index, 1);
        state.stashTags.push(...stashed);
      }
    },
    unstashTag: (state, action: PayloadAction<number>) => {
      if (state.stashTags) {
        const index = state.stashTags.findIndex((tag) => tag.id === action.payload);
        const stashed = state.stashTags.splice(index, 1);
        state.tags.push(...stashed);
      }
    },
    initTagState: (state) => {
      state.tags = [];
      state.stashTags = [];
      state.companyId = 0;
      state.hasMoreTags = true;
      state.offsetTags = 0;
    },
    initStashTags: (state, action: PayloadAction<Tag[]>) => {
      state.stashTags.push(...action.payload);
      state.companyId = action.payload[0].companyId;
      if (state.tags) {
        const ids = action.payload.map((item) => item.id);
        state.tags = state.tags.filter(item => !ids.includes(item.id) && item.companyId === state.companyId);
      }
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload;
    },
    setStatusLog: (state, action: PayloadAction<StatusType>) => {
      state.statusLog = action.payload;
    },
    clearBroadcasts: (state) => {
      state.tags = [];
      state.broadcast = null;
      state.broadcasts = [];
      state.broadcastsPlan = [];
      state.broadcastsLog = [];
      state.broadcastsStatistic = {} as typ.IBroadcastsStatistics;
      state.sources = [];
      state.stashTags = [];
      state.companyId = 0;
      state.hasMoreTags = true;
      state.offsetTags = 0;
      state.status = 'load';
      state.wabaTemplates = [];
    },
    clearBroadcast: (state) => {state.broadcast = null; },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagsList.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.company_id) {
          state.tags = action.payload;
          state.stashTags = [];
          state.companyId = action.meta.arg.company_id;
          state.offsetTags = action.payload.length;
        } else {
          const ids = state.tags.map((item) => item.id);
          state.stashTags.map((item) => ids.push(item.id));
          state.tags.push(...action.payload.filter((item) => !ids.includes(item.id)));
          state.offsetTags += action.payload.length;
        }
        if (action.meta.arg.limit) {
          state.hasMoreTags = action.meta.arg.limit === action.payload.length;
        }
      })
      .addCase(getBroadcast.fulfilled, (state, action) => {
        state.broadcast = action.payload;
        state.status = 'idle';
      })
      .addCase(getBroadcast.pending, (state) => {
        state.status = 'load';
      })
      .addCase(getBroadcasts.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.companyId || !state.broadcasts) {
          state.broadcasts = action.payload.broadcasts;
          state.companyId = action.meta.arg.companyId;
        } else {
          const ids = state.broadcasts.map((item) => item.id);
          state.broadcasts.push(...action.payload.broadcasts.filter((item) => !ids.includes(item.id)));
        }
      })
      .addCase(getBroadcastsPlan.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.companyId || !state.broadcasts) {
          state.broadcasts = action.payload.broadcasts;
          state.companyId = action.meta.arg.companyId;
        } else {
          const ids = state.broadcasts.map((item) => item.id);
          state.broadcasts.push(...action.payload.broadcasts.filter((item) => !ids.includes(item.id)));
        }
        state.broadcastsPlan = action.payload.broadcasts.filter((item) => isDateAfter(item.plannedTime));
        state.status = 'idle';
      })
      .addCase(getBroadcastsLog.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.companyId || !state.broadcasts) {
          state.broadcasts = action.payload.broadcasts;
          state.companyId = action.meta.arg.companyId;
        } else {
          const ids = state.broadcasts.map((item) => item.id);
          state.broadcasts.push(...action.payload.broadcasts.filter((item) => !ids.includes(item.id)));
        }
        state.broadcastsLog = action.payload.broadcasts;
        state.broadcastsLogTotal = action.payload.responseMetadata.totalCount;
        state.statusLog = 'idle';
      })
      .addCase(updateBroadcast.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.companyId || !state.broadcasts) {
          if (action.payload.status === 'PENDING' && action.meta.arg.planned_time) {
            state.broadcastsPlan = [action.payload];
          } else {
            state.broadcastsLog = [action.payload];
          }
          state.broadcasts = [action.payload];
          state.companyId = action.meta.arg.companyId;
        } else {
          let index = state.broadcasts.findIndex(item => item.id === action.payload.id);
          if (index !== -1) {
            state.broadcasts[index] = { ...action.payload };
          } else {
            state.broadcasts.push(action.payload);
          }
          let broadcasts = [];
          if (action.payload.status === 'PENDING') {
            broadcasts = state.broadcastsPlan;
          } else {
            broadcasts = state.broadcastsLog;
          }
          index = broadcasts.findIndex(item => item.id === action.payload.id);
          if (index !== -1) {
            if (action.payload.status === 'PENDING' && action.meta.arg.planned_time === null) {
              broadcasts.splice(index, 1);
            } else {
              broadcasts[index] = { ...action.payload };
            }
          } else {
            broadcasts.push(action.payload);
          }
        }
      })
      .addCase(finishBroadcast.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.companyId || !state.broadcasts) {
          state.broadcastsLog = [action.payload];
          state.broadcasts = [action.payload];
          state.companyId = action.meta.arg.companyId;
        } else {
          let index = state.broadcasts.findIndex(item => item.id === action.payload.id);
          if (index !== -1) {
            state.broadcasts[index] = { ...action.payload };
          } else {
            state.broadcasts.push(action.payload);
          }
          index = state.broadcastsLog.findIndex(item => item.id === action.payload.id);
          if (index !== -1) {
            state.broadcastsLog[index] = { ...action.payload };
          } else {
            state.broadcastsLog.push(action.payload);
          }
        }
      })
      .addCase(removeBroadcast.fulfilled, (state, action) => {
        state.broadcasts = state.broadcasts.filter(item => item.id !== action.meta.arg.broadcastId);
        state.broadcastsPlan = state.broadcastsPlan.filter(item => item.id !== action.meta.arg.broadcastId);
      })
      .addCase(getBroadcastsStatistic.fulfilled, (state, action) => {
        state.broadcastsStatistic = action.payload;
      })
      .addCase(getSources.fulfilled, (state, action) => {
        state.sources = [];
        broadcastServices.forEach((service) => {
          state.sources.push(...action.payload.filter((item) => item.type === service));
        });
      })
      .addCase(getBroadcastWabaTemplates.fulfilled, (state, action) => {
        state.wabaTemplates = action.payload;
      })
      .addCase(getBroadcastWabaTemplates.rejected, (state) => {
        state.wabaTemplates = [];
      });
  }
});

export const {
  clearBroadcasts, clearBroadcast, initStashTags, initTagState, stashTag, unstashTag,
  setStatus, setStatusLog } = broadcastsSlice.actions;

export default broadcastsSlice.reducer;
