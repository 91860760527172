import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '../../../store/store';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { createBroadcast, updateBroadcast, getBroadcast, clearBroadcast } from '../BroadcactsSlice';
import { broadcastsItemSelector } from '../BroadcastsSelector';
import { getMessageForSubmit, getUrlMessage, TextMessageType } from '../utils';
import {
  BroadcastCreateRequest, ConditionsLogicOperations, ConditionsType,
  IBroadcastMessageCreate, IChats, ICondition
} from '../BroadcastsTypes';
import { uploadFile } from "../../Chat/ChatAPI";
import { ContactSourceType, BroadcastSchema, ErrorStateType } from './types';
import { addAlertWithCustomText } from "../../../components/Alert/alertSlice";


export function useBroadcastItem(broadcastId?: number) {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const broadcastItem = useSelector(broadcastsItemSelector);

  useEffect(() => () => {
    dispatch(clearBroadcast());
  }, []);

  useEffect(() => {
    if (broadcastId) dispatch(getBroadcast({ companyId: currentCompany.id, broadcastId }));
  }, [broadcastId]);

  return broadcastItem;
}

export type BroadcastSubmitType = {
  data: BroadcastSchema;
  message: IBroadcastMessageCreate;
  messageText: TextMessageType | undefined;
  broadcastId?: number;
  contactSource: ContactSourceType;
  recipients?: string[];
  operation: ConditionsLogicOperations;
  conditions?: ICondition[];
  recipientsEstimated?: number;
};

export type ResponseType = {
  status: boolean;
  error: ErrorStateType;
}

export function useSubmitBroadcast() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const onSubmitBroadcast = async (submitData: BroadcastSubmitType ) => {
    const { data, broadcastId, contactSource, recipients, conditions, operation, message,
      messageText, recipientsEstimated } = submitData;
    let response: ResponseType = { status: false,
      error: { text: false, template: false,
        recipients: false, recipientsRadist: false }
    };
    let messageFileUrl: string = '';
    let conditionsParams: ConditionsType | IChats = { chats: [] };
    // contacts conditions
    if (contactSource === 'radist' && conditions) {
      // conditions for radist contacts
      conditionsParams = {
        logic_operation: operation,
        conditions: conditions.map(item => ({
          comparison: item.comparison, condition_type: item.conditionType, value: item.value }))
      }
    }

    if (contactSource === 'client' && recipients) {
      // contact list
      conditionsParams = { chats: recipients };
    }

    if (messageText && messageText.file){
      await uploadFile({
        file: messageText.file, companyId: currentCompany.id
      }).then((url) => {messageFileUrl = url.url});
    } else messageFileUrl = getUrlMessage(message);

    const messageSubmit = getMessageForSubmit(message, messageFileUrl);

    const dataPrepared: BroadcastCreateRequest = {
      companyId: currentCompany.id,
      ...{ ...data, params: { ...data.params, conditions: conditionsParams },
        message: messageSubmit! }
    };

    const wabaInputCount = message.waba_template?.params_count;
    const wabaBodyLength = messageSubmit?.waba_template?.body_params?.filter((text) => text.length > 0).length;

    const isEmptyConditions = contactSource === 'client' && recipients && recipients?.length <= 0;
    const isEmptyRecipients = contactSource === 'radist' && recipientsEstimated! <= 0;
    const isEmptyMessage = messageSubmit?.text && messageSubmit.text.text.length <= 0;
    const isEmptyTemplate = messageSubmit === undefined || (wabaInputCount! > 0 && wabaBodyLength !== wabaInputCount);

    if (!isEmptyConditions && !isEmptyRecipients && !isEmptyTemplate && !isEmptyMessage) {
      if (broadcastId) {
        await dispatch(updateBroadcast({
          ...dataPrepared,
          broadcastId
        })).then(res => {
          response = { ...response, status: res.meta.requestStatus === 'fulfilled' };
        });
      } else {
        await dispatch(createBroadcast(dataPrepared)).then(res => {
          response = { ...response, status: res.meta.requestStatus === 'fulfilled' };
        });
      }
    } else dispatch(addAlertWithCustomText({ message: t('error.form.has_empty_fields'), type: 'alarm' }));

    return { ...response, error: {
      text: isEmptyMessage, template: isEmptyTemplate,
      recipients: isEmptyConditions, recipientsRadist: isEmptyRecipients
     } };
  };

  return onSubmitBroadcast;
}
