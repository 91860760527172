import React, { useRef } from 'react';
import './SupportButton.scss';
import { useTranslation } from "react-i18next";
import { bdAnchorsSupport } from "../../../utils/consts";
import { getViewPopup } from "../../utils";
import { ReactComponent as HelpIcon } from "../../../../../assets/help.svg";
import PopupTitle from "../PopupTitle";


type Props = {
  isRolledUp?: boolean;
};

const SupportButton = ({ isRolledUp }: Props) => {
  const { t, i18n } = useTranslation();
  const refPopup = useRef<HTMLAnchorElement>(null);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();

  return (
    <a href={bdAnchorsSupport[i18n.language]} target="_blank" rel="noreferrer" ref={refPopup}>
      {!isRolledUp ? (
        <div className="supportButton">
          <HelpIcon />
          <p>{t('navbar.support')}</p>
        </div>
      ) : (
        <PopupTitle text={t('navbar.support')}
                    image={<HelpIcon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />} viewPopup={viewPopup}
                    refPopup={refPopup} />
      )}
    </a>
  );
};

export default SupportButton;
