import { useLayoutEffect, useState } from 'react';
import ym from 'react-yandex-metrika';
import { getDateTime } from './getDateTime/getDateTime';


export const getFileName = (fileUrl: string) => {
  const splittedFileUrl = fileUrl.split('/');
  return splittedFileUrl[splittedFileUrl.length - 1];
};

export function getRandomInt(max:number) {
  return Math.floor(Math.random() * max);
}

export const arrayLanguages: string[] = ['ru','en','es'];

export const getHourMinIfCreatedUpdatedInOneDay = (createdAt: string | null, updateAt: string | null ) => {
  if (createdAt && updateAt){
    const createDate = getDateTime().moment(createdAt);
    const updateDate = getDateTime().moment(updateAt);
    const isCreatedUpdatedInOneDay = createDate.diff(updateDate, 'days') === 0
    if (isCreatedUpdatedInOneDay) {
      return `, ${getDateTime().format(updateAt, 'hourMinutes')}`;
    }
  }
  return '';
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const delay = (()=>{
  let timer:NodeJS.Timeout |null = null;
  return (callback:()=>void, ms:number)=>{
    if (timer) clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();

export const formatNumberLocale = (num: number, digits: number = 2, locale: string): string => {
  let precision = digits;
  const value = num.toString();
  if (num % 1 === 0) {
    precision = 0;
  }
  if (locale !== undefined) {
    return parseFloat(value).toLocaleString(locale, {
      minimumFractionDigits: precision, maximumFractionDigits: precision });
  }
  return value;
};

export const getTruncateString=(str:string='', indexEnd:number)=>{
  if (str.length >=indexEnd){
    return `${str.substring(0,indexEnd)}...`
  }
  return str
}

export const isDifferenceArrayStr = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) {
    return true;
  }
  for (const i of a) {
    if (!b.includes(i)) {
      return true;
    }
  }
  return false;
};

export function YandexMetrikaCall(methodName: string, param: string) {
  try {
    ym(methodName, param);
    return true;
  } catch {
    return false;
  }
}
