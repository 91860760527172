import { useContext } from 'react';
import './ChatTemplatePreview.scss';
import { chatTemplatesContext } from '../../ChatTemplatesProvider/ChatTemplatesProvider';
import InteractiveTemplateComponent from '../InteractiveTemplateComponent/InteractiveTemplateComponent';
import ChatMessageTemplate from '../ChatMessageTemplate';
import WabaTemplateComponent from "../WabaTemplateComponent";


const ChatTemplatePreview = () => {
  const { wabaTemplate, customTemplate, setMessageTemplate, onClose } = useContext(chatTemplatesContext);

  return (
    <div className="chatTemplatePreview">
      {wabaTemplate && <WabaTemplateComponent />}
       {customTemplate && (customTemplate.type === 'waba_interactive'
        ? <InteractiveTemplateComponent template={customTemplate}
            setSelectedTemplate={setMessageTemplate} onCloseDropdown={onClose} />
        : <ChatMessageTemplate template={customTemplate} />
       )}
    </div>
  );
};

export default ChatTemplatePreview;
