export const arrayPossibleUrls = [
  'chats', 'shops', 'products', 'catalogs', 'orders', 'my-templates',
  'interactive-messages', 'waba-templates', 'contacts', 'broadcasts',
  'partners', 'clients', 'payments', 'profile', 'requisite', 'notifications',
  'waba', 'whatsapp', 'telegram', 'telegram_bot', 'odnoklassniki',
  'bePaid', 'tinkoff', 'sber', 'modulbank', 'paykeeper', 'yookassa',
  'settings', 'connections', 'members', 'subscriptions', 'integrations',
  'tags', 'company_settings', 'notifications', 'profile',
];

export const getGoBackUrl = (url: string) => {
  const splitUrl = url.split('/').reverse();
  for (let i = 0; i < splitUrl.length; i++) {
    if (arrayPossibleUrls.includes(splitUrl[i])) {
      return url.slice(0, url.indexOf(splitUrl[i]) + splitUrl[i].length);
    }
  }
};
