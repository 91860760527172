import './WabaTemplateItem.scss';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector } from '../../../../../Chat/companiesSelector';
import { WabaTemplateComponentBody, WabaTemplateInfo } from '../../../../templatesTypes';
import { actionsPermission, wabaTemplateActions, WabaTemplateActions } from '../../../../utils/waba';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import { useWabaTemplateRequest } from '../../../api';
import { PERMISSIONS } from '../../../../../../utils/WrapperPermission/permission-maps';
import ActionDialog from '../../../../../../components/Modals/ActionDialog';
import WabaTemplateAction from '../WabaTemplateAction';
import WabaTemplateCategory from '../WabaTemplateCategory';
import WabaTemplateLocale from '../WabaTemplateLocale';
import WabaTemplateName from '../WabaTemplateName';
import ModalWrapper from '../../../../../../components/Modals/ModalWrapper';
import WabaTemplateRename from '../WabaTemplateRename';


type WabaTemplateItemProps = {
  template: WabaTemplateInfo;
};

function WabaTemplateItem({ template }: WabaTemplateItemProps) {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const [deleteItem, setDeleteItem] = useState(false);
  const [renameItem, setRenameItem] = useState(false);
  const { connectionId, updateWabaTemplateState } = useContext(wabaTemplateContext);
  const { wabaTemplateConvertAction, removeWabaTemplate, renameWabaTemplate } = useWabaTemplateRequest();
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const [actions, setActions] = useState<WabaTemplateActions[]>([]);

  useEffect(() => {
    if (currentRole?.permissions && currentRole.permissions.length > 0) {
      setActions(wabaTemplateActions.filter((action) => 
        (currentRole?.permissions.includes(PERMISSIONS[actionsPermission[action]])) 
      ));
    }
  }, [currentRole]);

  const getBodyText = () => {
    if (template.components) {
      return template.components
        .filter((component) => component.type === 'BODY')
        .map((component) => (component as WabaTemplateComponentBody).text)
        .join(' ');
    }
    return '';
  };

  const onItemAction = (action: WabaTemplateActions) => {
    setDeleteItem(action === 'delete');
    setRenameItem(action === 'rename');

    if (action === 'copy' || action === 'language') {
      updateWabaTemplateState({ type: 'copy', payload: wabaTemplateConvertAction(template, action) });
      push(path.replace('list', 'create'));
    }
  };

  const onDeleteItem = () => {
    removeWabaTemplate(template.name, connectionId);
    setDeleteItem(false);
  };

  const onUpdateItem = (newName: string) => {
    renameWabaTemplate(template.name, template.locale, newName);
    setRenameItem(false);
  };

  return (
    <div className='wabaTemplatesList__list_item'>
      {deleteItem &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          message={t('templates.delete_confirm.warning')} title={t('templates.delete_confirm.head')}
          onAction={onDeleteItem} setIsOpen={setDeleteItem} />
      }
      {renameItem &&
        <ModalWrapper setIsOpen={setRenameItem} background='light'>
          <WabaTemplateRename template={template} onCancel={() => setRenameItem(false)} onSave={onUpdateItem} />
        </ModalWrapper>
      }
      <WabaTemplateName name={template.customName || template.name} text={getBodyText()} template={template} />
      <WabaTemplateCategory category={template.category} />
      <WabaTemplateLocale locale={template.locale} status={template.status} />
      <WabaTemplateAction status={template.status} actions={actions} onAction={onItemAction}/>
    </div>
  );
}

export default WabaTemplateItem;
