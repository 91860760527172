import React from 'react';
import './PopupWithTail.scss';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';
import { ReactComponent as TailIcon } from '../../../../../../assets/dropdown-after-triangle.svg';


type PopupWithTailProps = {
  children: React.ReactNode;
  handlerClickIcon?: () => void;
};

const PopupWithTail = ({ children, handlerClickIcon }: PopupWithTailProps) => (
  <div className='popupWithTail'>
    <span onClick={handlerClickIcon} className='popupWithTail_icon'><InfoIcon /></span>
    <div className='popupWithTail_message'>
      {children}
      <TailIcon className='popupWithTail_tail' />
    </div>
  </div>
);

export default PopupWithTail;
