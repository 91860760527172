import React, { FC } from 'react';
import './ProductItem.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import { ReactComponent as EmptyProductIcon } from '../../../../../assets/image.svg';
import { formatNumberLocale } from '../../../../../utils/utils';
import { Product } from '../../../SalesAPI';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../components/Button';


type Props = {
  product: Product,
  onDelete?: (product: Product) => void,
  onEdit?: (product: Product) => void,
  onViewDetail?: (product: Product) => void,
}

const ProductItem: FC<Props> = ({ product, onDelete, onEdit, onViewDetail }) => {
  const { i18n } = useTranslation();

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onEdit !== undefined) onEdit(product);
  };

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(product);
  }

  const onProductClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onViewDetail) onViewDetail(product);
  };

  return (
    <div key={product.id} className='productItem' onClick={onProductClick}>
      <div className="productItem_preview">
        {product.images[0] ? (
          <img alt="Фото товара" src={product.images[0]} />
        ) : (
          <EmptyProductIcon />
        )}
      </div>
      <div>{product.name}</div>
      <div className='productItem_option'>
        <div className='productItem_option_price'>{formatNumberLocale(product.prices[0].price, 2, i18n.language)} ₽</div>
        <div className='productItem_option_buttons'>
          <WrapperPermission permission='salesCatalogEdit'>
            <Button image={<EditIcon />} color='white' textType='small' onClick={onEditClick} id='edit' />
          </WrapperPermission>
          <WrapperPermission permission='salesCatalogDelete'>
            <Button image={<DeleteIcon />} color='white' textType='small' onClick={onDeleteClick} id='delete' />
          </WrapperPermission>
        </div>
      </div>
    </div>
  )
};

export default ProductItem;
