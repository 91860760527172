import './ClientsView.scss';
import { useTranslation } from 'react-i18next';
import ClientsList from '../ClientsList';


const ClientsView = () => {
  const { t } = useTranslation();

  return (
    <div className="clientsList">
      <div className="clientsList__list">
        <div className="clientsList__list_header smallText semibold">
          <span>{t('partner_cabinet.clients.client')}</span>
          <span>{t('partner_cabinet.clients.pay_subscriptions')}</span>
          <span>{t('partner_cabinet.clients.dialogs')}</span>
          <span>{t('partner_cabinet.clients.percent')}</span>
          <span>{t('partner_cabinet.clients.status')}</span>
        </div>
        <div className="clientsList__list_content">
          <ClientsList />
        </div>
      </div>
    </div>
  );
};

export default ClientsView;
