import { useEffect } from 'react';
import './Settings.scss';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector, onboardingGuideSelector } from '../Chat/companiesSelector';
import { listConnectionsEdit, listOfTabsInSettings } from './utility/listOfTabsInSettings';
import { useAccessMobile, useMobile } from '../../components/utils/hooks';
import Error404 from '../../pages/Error404/Error404';
import Connection from './Connections/Connection';
import ConnectionCreate from './Connections/ConnectionCreate';
import SettingsList from './SettingsList/SettingsList';
import GuideProvider from '../../components/Guide/GuideProvider';


const Settings = () => {
  const { url } = useRouteMatch();
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const permissions = currentRole ? [...currentRole.permissions, 'profile'] : ['profile'];
  const tabs = listOfTabsInSettings.filter(tab => permissions.includes(tab.permission));
  const match = useRouteMatch<{ tab: string; }>('/companies/:companyId/settings/:tab');
  const history = useHistory();
  const isMobile = useMobile();
  const isMobileComponent = useAccessMobile();

  useEffect(() => {
    if (currentRole) {
      let permTabName : string = '';
      const permTab = tabs.find(tab => tab.tab === match?.params.tab);

      if (permTab) {
        permTabName = permTab.tab;
      } else {
        permTabName = tabs[0].tab;
      }

      if (!permTab) history.push(`${url}/${permTabName}`);
    }
  }, [match]);

  return (
    <>
      {(!isMobileComponent || !isMobile) && <SettingsList />}
      <div className={`settingsContent ${onboardingGuideOn ? 'guideOn' : ''}`}>
          {currentRole && (
            <Switch>
              <GuideProvider>
              {tabs.map(({ tab, Component, common }) =>
                <Route key={tab} path={`${url}/${tab}`} exact={!common}>
                  <Component />
                </Route>
              )}
              <Route path={`${url}/connections/:serviceUrl`} exact>
                <Connection />
              </Route>
              <Route path={`${url}/connections/:serviceUrl/create`} exact>
                <ConnectionCreate />
              </Route>
              <Route path={`${url}/connections/:serviceUrl/auth`} exact>
                <ConnectionCreate />
              </Route>
              {listConnectionsEdit.map(({ path, Component, name }) =>
                <Route key={path} path={url + path}>
                  <Component />
                </Route>
              )}
              </GuideProvider>
              <Route path="*">
                <Error404 />
                <Redirect from="*" to="/404" />
              </Route>
            </Switch>
          )}
        </div>
    </>
  );
};

export default Settings;
