import './WabaStartPreview.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReactComponent as EmojiIcon } from '../../../../../../../../assets/fill-smile.svg';
import { ReactComponent as AttachmentIcon } from '../../../../../../../../assets/fill-attach.svg';
import { ReactComponent as PhotoIcon } from '../../../../../../../../assets/fill-photo.svg';
import { ReactComponent as SendIcon } from '../../../../../../../../assets/fill-send.svg';


const WabaStartPreview = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const messages: Array<{ value: string }> = useWatch({ name: 'ice_breakers', control });
  const plugMessages = Array.from({ length: 3 }).map((m, index) =>
    t(`connections.waba.start_messages.preview.plug_text${index + 1}`)
  );

  if (!messages || messages.length === 0) {
    return <></>;
  }

  return (
    <div className="wabaStartPreview">
      <h3>{t('connections.waba.start_messages.preview.title')}</h3>
      <div className="wabaStartPreview__wrapper">
        <div className="wabaStartPreview__content">
          {messages[0].value.length === 0 ? plugMessages.map((value, index) => (
            <div key={value + String(index)} className="wabaStartPreview__content_item">
              <p className="smallText">{value}</p>
              <SendIcon />
            </div>
            )) : messages.map((m, index) => (
              <div key={m.value + String(index)} className="wabaStartPreview__content_item">
                <p className="smallText">{m.value}</p>
                {m.value.length > 0 && <SendIcon />}
              </div>
          ))}
          <div className="wabaStartPreview__content_footer">
            <div className="left">
              <EmojiIcon />
              <p className="smallText">{t('connections.waba.start_messages.preview.message')}</p>
            </div>
            <div className="right">
              <AttachmentIcon />
              <PhotoIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WabaStartPreview;
