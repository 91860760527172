import './WabaTemplateForm.scss';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useWabaTemplateRequest } from '../api';
import { wabaTemplateContext } from '../WabaTemplateProvider/WabaTemplateProvider';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import WabaPreview from '../WabaPreview';
import WabaTemplateBasic from './components/WabaTemplateBasic';
import WabaTemplateMessage from './components/WabaTemplateMessage';
import WabaTemplateAuth from './components/WabaTemplateAuth';
import WabaTemplateCarousel from './components/WabaTemplateCarousel';


function WabaTemplateForm() {
  const { t } = useTranslation();
  const { push, replace, goBack } = useHistory();
  const { makeWabaTemplateRequest, makeWabaTemplate } = useWabaTemplateRequest();
  const {
    connectionId, wabaTemplateState, previewWabaTemplate, updateWabaTemplateState
  } = useContext(wabaTemplateContext);
 
  useEffect(() => () => {
    if (!location.pathname.endsWith('moderation')) updateWabaTemplateState({ type: 'clear', payload: undefined });
  }, []);

  useEffect(() => {
    if (wabaTemplateState.stage === 'moderation') push(location.pathname.replace('create', 'moderation'));
    if (wabaTemplateState.stage === 'create') {
      const template = makeWabaTemplateRequest(wabaTemplateState);
      makeWabaTemplate(template).then((res) => {
        if (res) {
          replace({ pathname: location.pathname.replace('create', 'list'), state: { connectionId } });
          goBack();
        } else {
          updateWabaTemplateState({ type: 'stage', payload: { stage: 'form' } });
        }
      });
    }
  }, [wabaTemplateState]);

  return (
    <div className='wabaTemplateForm'>
      <GoBackWrapper title={t('templates.create_templates')}>
        <div className={`wabaTemplateForm__content ${wabaTemplateState.stage}`}>
          {wabaTemplateState.stage === 'basic' && <WabaTemplateBasic /> }
          {(wabaTemplateState.stage === 'form' || wabaTemplateState.stage === 'create') && <>
            {wabaTemplateState.kind === 'message' &&
              <WabaTemplateMessage />}
            {wabaTemplateState.kind === 'auth' &&
              <WabaTemplateAuth />}
            {wabaTemplateState.kind === 'carousel' &&
              <WabaTemplateCarousel />}
          </>}
        </div>
      </GoBackWrapper>
      {wabaTemplateState.stage === 'form' &&
        <WabaPreview body={previewWabaTemplate?.body} header={previewWabaTemplate?.header}
          footer={previewWabaTemplate?.footer} buttons={previewWabaTemplate?.buttons}
          carousel={previewWabaTemplate?.carousel} />}
    </div>
  );
}

export default WabaTemplateForm;
