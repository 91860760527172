import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../../../Settings/CompanySettings/currency';
import './InvoiceAmount.scss';


type InvoiceAmountProps = {
  amount: number;
  currency: string;
  discount: boolean;
  status: string;
};

function InvoiceAmount({ amount, currency, discount, status }: InvoiceAmountProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className={`invoiceAmount ${status}`}>
      <p className='monospaceRegText'>{currencyFormat(amount, currency, i18n.language)}</p>
      <p className='monospaceSmText'>{discount && t('partner_cabinet.payments.discount')}</p>
    </div>
  )
};

export default InvoiceAmount;
