import { createContext, RefObject } from "react";

export type GuideContext = {
  elementRef: RefObject<HTMLDivElement> | null;
  style: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  setup: boolean;
};

export const guideContext = createContext<GuideContext>({ } as GuideContext);

export type GuideDispatch = {
  setElementRef: (elementRef: RefObject<HTMLDivElement> | null) => void,
}

export const guideDispatchContext = createContext<GuideDispatch>({
  setElementRef: () => {}
});

export const initialState: GuideContext = {
  elementRef: null,
  style: { top: 0, left: 0, width: 0, height: 0 },
  setup: false
};

export type GuideAction = {
  type: 'setElement';
  payload: RefObject<HTMLDivElement> | null;
};
