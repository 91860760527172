import { ReactElement } from "react";
import { WabaTemplateComponentBody, WabaTemplateComponentButtonsButtonUrl, WabaTemplateComponentHeader, WabaTemplateComponentHeaderCreateRequest, WabaTemplateComponentHeaderFormat, WabaTemplateListButtons } from "../../templatesTypes";
import { ReactComponent as PDFIcon } from "../../../../assets/pdf.svg";
import { ReactComponent as ImageIcon } from '../../../../assets/image.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/video.svg';
import { ReactComponent as FilesIcon } from '../../../../assets/files.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone-outgoing.svg';
import { ReactComponent as NewWindowIcon } from '../../../../assets/new-window.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import parse from "html-react-parser";


type WabaTemplateFileConfig = {
  title: string;
  description: string;
  accept: string;
  maxSize: number;
  icon: ReactElement;
};

type WabaTemplateButtonConfig = {
  text: (buttons: WabaTemplateListButtons) => string;
  icon?: ReactElement;
};

type WabaTemplateComponentButtonsType = 'URL' | 'PHONE_NUMBER' | 'QUICK_REPLY' | 'OTP';

export const configWabaTemplateFile: Record<WabaTemplateComponentHeaderFormat, WabaTemplateFileConfig> = {
  IMAGE: {
    title: 'templates.waba_moderation.fileload.load_image',
    description: 'templates.waba_moderation.fileload.accept_image',
    accept: '.png, .jpeg, .jpg',
    maxSize: 5242880,
    icon: <ImageIcon className="previewIcon" />,
  },
  VIDEO: {
    title: 'templates.waba_moderation.fileload.load_video',
    description: 'templates.waba_moderation.fileload.accept_video',
    accept: '.mp4, .3gp',
    maxSize: 16777216,
    icon: <VideoIcon className="previewIcon" />,
  },
  DOCUMENT: {
    title: 'templates.waba_moderation.fileload.load_document',
    description: 'templates.waba_moderation.fileload.accept_document',
    accept: '.pdf',
    maxSize: 3145728,
    icon: <FilesIcon className="previewIcon" />,
  },
  TEXT: { title: '', description: '', accept: '', maxSize: 0, icon: <></> },
};

export const useWabaTemplateFileType = (headerType: WabaTemplateComponentHeaderFormat, attachmentFile: File) => {
  let component: JSX.Element = <></>;

  switch (headerType) {
    case 'IMAGE':
      component = <img alt="preview" className="wabaFileLoad__preview" src={URL.createObjectURL(attachmentFile)} />;
      break;
    case 'VIDEO':
      component = <video controls className="wabaFileLoad__preview" src={URL.createObjectURL(attachmentFile)} />;
      break;
    case 'DOCUMENT':
      component = (
        <div className="wabaFileLoad__document">
          <PDFIcon width={60} height={60} />
          <span>{attachmentFile.name}</span>
        </div>
      );
      break;
  }

  return component;
};

const getText = (button: WabaTemplateListButtons) => {
  switch (button.type) {
    case 'QUICK_REPLY':
    case 'PHONE_NUMBER':
    case 'URL':
    case 'OTP':
      return button.text;
    default:
      return '';
  }
};

export const configWabaTemplateButton: Record<WabaTemplateComponentButtonsType, WabaTemplateButtonConfig> = {
  QUICK_REPLY: { text: getText },
  PHONE_NUMBER: { text: getText, icon: <PhoneIcon /> },
  URL: { text: getText, icon: <NewWindowIcon /> },
  OTP: { text: getText, icon: <CopyIcon /> },
};

export const parseWabaBodyText = (text?: string | null | string[]) => {
  if (text && typeof text === 'string') {
    const htmlFormat = text
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // bold
      .replace(/_(.*?)_/g, "<em>$1</em>") // italic
      .replace(/~(.*?)~/g, "<span style='text-decoration: line-through'>$1</span>") // strikethrough
      .replace(/```(.*?)```/g, "<span style='font-family: monospace'>$1</span>"); // monospace

    return parse(htmlFormat);
  }

  return text;
};

export type ParamsType = {
  id: number;
  value: string;
  label: string;
};

export type IndexedParams = {
  id: number;
  params: ParamsType[];
};

export const getTextParams = (text: string): ParamsType[] => {
  const findParams = text.split(/{{(\d+)}}/gm);
  const numbers: number[] = [];
  const params: ParamsType[] = [];

  findParams.forEach((param, index) => {
    if (index % 2 === 1 && !numbers.includes(Number(param))) {
      numbers.push(Number(param));
      params.push({ id: Number(param), label: param, value: '' });
    }
  });

  return params;
};

export const getButtonsUrlParams = (buttons: WabaTemplateComponentButtonsButtonUrl[]) => {
  const params: ParamsType[] = [];
  buttons.forEach((item, index) => {
      const findParams = item.url.split(/{{(\d+)}}/gm);
      if (findParams[1]) params.push({ id: index, label: findParams[1], value: '' });
  });

  return params;
};

export const checkEmptyParamError = (array: ParamsType[], id: number) => {
  const findParam = array.find((param) => param.id === id);
  if (findParam && findParam.value.length === 0) {
    return 'error';
  } else return '';
};


type ParametrizedComponent = WabaTemplateComponentHeader | WabaTemplateComponentHeaderCreateRequest
  | WabaTemplateComponentBody | WabaTemplateComponentButtonsButtonUrl;

export const getComponentText = (arrayParams: ParamsType[], component: ParametrizedComponent) => {
  if (component && component.text && (arrayParams && arrayParams.length > 0)) {
    const findParams = component.text.split(/{{(\d+)}}/gm);

    return findParams.map((param, index) => {
      if (index % 2 === 1) {
        const paramItem = arrayParams.find((item) => item.id === Number(param));
        return paramItem && paramItem.value.length > 0 ? paramItem.value : `{{${param}}}`;
      }

      return param;
    }).join('');
  } else if (component) {
    return component.text;
  }
};
