import { useEffect, useRef, useState } from 'react';
import './MessageTemplateSettings.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../store/store';
import { createCustomTemplate, editCustomTemplate } from '../../templatesSlice';
import { CreateCustomTemplateRequestOptions } from '../../templatesTypes';
import { templateTypeViews } from '../../../Chat/components/ChatField/WabaTemplateComponent/consts';
import { uploadFile } from '../../../Chat/ChatAPI';
import { getFileName } from '../../../../utils/utils';
import { useMessageTemplate } from '../../utils/message';
import Button from '../../../../components/Button';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import TextAreaResize from '../../Components/TextAreaResize';
import TextAreaResizeEmoji from '../../Components/TextAreaResizeEmoji';
import { ReactComponent as AttachmentIcon } from '../../../../assets/attach.svg';


type EditData = {
  name: string;
  text: string;
  fileUrl?: string | null;
  document?: string;
};

function MessageTemplateSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { goBack } = useHistory();
  const { templateId } = useParams<{ templateId: string }>();
  const template = useMessageTemplate(templateId, 'text')
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const wrapperRef = useRef(null);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    text: yup.string().max(8192, t('error.form.max_length', { limit: 8192 })),
    fileUrl: yup.string().nullable(),
    document: yup.string().nullable(),
  });

  const methods = useForm<EditData>({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    if (template) {
      methods.reset({
        name: template?.name ?? '',
        text: template?.text ?? '',
        fileUrl: template?.fileUrl || null,
        document: template?.fileUrl ? getFileName(template?.fileUrl) : undefined
      });
    }
  }, [template]);

  const onConfirmSendTemplate = async (data: EditData) => {
    if (currentCompanyId?.params.companyId) {
      const newData: CreateCustomTemplateRequestOptions = {
         companyId: Number(currentCompanyId?.params.companyId),
         type: 'text',
         name: data.name,
         text: data.text,
      };
      if (attachedFile) {
        const { url: fileUrl } =
          await uploadFile({ file: attachedFile, companyId: Number(currentCompanyId?.params.companyId) });
        newData.fileUrl = fileUrl;
      }
      if (!template) {
        await dispatch(createCustomTemplate(newData)).then(() => goBack());
      } else {
        await dispatch(editCustomTemplate({
          ...newData,
          templateId: template.id,
          fileUrl:newData?.fileUrl ? newData.fileUrl : data.fileUrl
        })).then(() => goBack());
      }
    }
  };

  const onChangeFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let type = e.target.files[0].type.split('/')[0];
      if (type === 'application') {
        type = 'document';
      }
      setFileType(type);
      setAttachedFile(e.target.files[0]);
      methods.setValue('document', e.target.files[0].name, { shouldDirty: true });
    }
  };

  const onChangeFileAttachmentData = () => {
    setAttachedFile(undefined);
    methods.setValue('document', undefined, { shouldDirty: true });
    methods.setValue('fileUrl', undefined, { shouldDirty: true });
  };

  const getAttachmentsPreview = () => {
    if (attachedFile && fileType) {
      const TemplatePreviewComponent = templateTypeViews.get(fileType);
      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            attachedFile={attachedFile}
            key={attachedFile.name}
            setAttachedFiles={onChangeFileAttachmentData}
            width={150}
            height={100}
          />
        );
      }
    }
    if (templateId && template?.fileUrl && methods.getValues('document')) {
      const TemplatePreviewComponent = templateTypeViews.get(template.type);
      let documentName = null;
      if (template.type === 'document' && template.fileUrl) {
        documentName = getFileName(template.fileUrl);
      }
      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            key={template.id}
            setAttachedFiles={onChangeFileAttachmentData}
            width={150}
            height={100}
            customTemplateEditPayload={{
              fileUrl: template.fileUrl as string,
              documentName,
            }}
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="addTemplateView">
      <GoBackWrapper title={templateId ? t('templates.edit_templates.title') : t('templates.add_templates.title')}>
        <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onConfirmSendTemplate)}>
            <div className="addTemplateView__body">
              <TextAreaResize className='regularText' name='name' title={t('templates.add_templates.heading_templates')}
                placeholder={t('templates.add_templates.placeholder_templates')}
                maxRows={2} minRows={1} maxLength={256} />
              <TextAreaResizeEmoji className='regularText' name='text'
                title={t('templates.add_templates.text_templates')}
                placeholder={t('templates.add_templates.placeholder_text')} 
                maxRows={22} minRows={8} maxLength={8192}
              />
              <div className="addTemplateView__body_attachment" ref={wrapperRef}>
                <input type="file" id="file-attachment" value="" accept=".jpeg,.png,.pdf,video/mp4"
                  onKeyDown={(event) => event.preventDefault()} onChange={onChangeFileInput} />
                <label htmlFor="file-attachment">
                  <AttachmentIcon />
                </label>
              </div>
              <div className="addTemplateView__attachmentsPreview">{getAttachmentsPreview()}</div>
            </div>
            <Button type='submit' color='orange' text={t('save_edit')} textType='regular'
              disabled={!methods.formState.isDirty || methods.formState.isSubmitting} />
          </form>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
}

export default MessageTemplateSettings;
