import './ClientPercent.scss';

type Props = {
  percent: number;
}

const ClientPercent = ({ percent }: Props) => {
  return (
    <div className='clientPercent'>
      <div className='percent'>{percent}%</div>
    </div>
  );
};

export default ClientPercent;
