import { useState } from "react";
import './ChatTemplateList.scss';
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { RootState } from "../../../../../../store/store";
import ChatTemplateItem from "../ChatTemplateItem";
import Search from "../../../../../../components/Search";


type Props = {
  switchPosition: string;
};

const keys: Record<string, string> = {
  waba: 'name',
  my: 'id',
  interactive: 'id',
};

const stateKeys: Record<string, string> = {
  waba: 'wabaTemplates',
  my: 'customTemplates',
  interactive: 'interactiveMessageTemplates',
};

const ChatTemplateList = ({ switchPosition }: Props) => {
  const templates = useSelector((state: RootState) => state.templates[stateKeys[switchPosition]]);
  const [search, setSearch] = useState<string>('');
  const [query] = useDebounce(search, 400);

  type TemplateType = typeof templates[0];

  return (
    <div className="chatTemplateList">
      <Search onChangeSearchInput={setSearch} />
      {templates && templates.length > 0 &&
        (templates as TemplateType[]).filter((t) =>
          t.name.toLowerCase().includes(query.toLowerCase()) ||
          t.customName?.toLowerCase().includes(query.toLowerCase()) ||
          t.components?.body?.text.toLowerCase().includes(query.toLowerCase()) ||
          t.text?.toLowerCase().includes(query.toLowerCase()))
          .map((template) => <ChatTemplateItem key={template[keys[switchPosition]]} template={template} />)}
    </div>
  );
};

export default ChatTemplateList;
