import './SberCreate.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { createConnection } from '../../../connectionsSlice';
import { SberbankCreateParams } from '../../../ConnectionsTypes';
import { settingConnections } from '../../../utils/connections';
import {
  DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME, surePreffix, switchItems, taxationTypes
} from '../../../utils/terminals';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { ReactComponent as ArrowBack } from "../../../../../../assets/arrow-back.svg";
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import Dropdown from '../../../../Dropdown/Dropdown';
import Switch from '../../../../Switch/Switch';
import LifetimePayment from "../../../components/LifetimePayment";


type SberSchema = {
  name: string;
  params: SberbankCreateParams;
};

const SberCreate = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      login: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      password: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      is_testing: yup.boolean().required().default(false),
      success_url: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      fail_url: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      taxation: yup.string().default('osn'),
      invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<SberSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      params: {
        is_testing: false,
        taxation: 'osn',
        invoice_lifetime_minutes: DEFAULT_LIFETIME,
      },
    },
  });

  const gotoBack = () => goBack();
  const onSubmit = (data: SberSchema) => {
    const urlPrefix = 'https://';
    data.params.fail_url = surePreffix(data.params.fail_url, urlPrefix);
    data.params.success_url = surePreffix(data.params.success_url, urlPrefix);
    dispatch(createConnection({
      companyId: currentCompany.id,
      name: data.name,
      type: 'sberbank',
      params: { ...data.params }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        gotoBack()
      }
    });
  };

  return (
    <div className="sberCreate">
      <div className="sberCreate__header">
        <Button color='white' textType='small' text={t('back')} onClick={gotoBack} image={<ArrowBack />} />
        <h3>{t(`${settingConnections.sberbank?.name}`)}</h3>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input name="name" label={t('connections.connection_name')} />
          <Input name="params.login" label="Имя пользователя" />
          <Input name="params.password" label="Пароль" inputType="password" />
          <Input name="params.success_url" label="Страница для перенаправления после успешной оплаты"
            placeholder="https://" id="url" />
          <Input name="params.fail_url" label="Страница для перенаправления после неуспешной оплаты"
            placeholder="https://" id="url" />
          <Dropdown
            items={taxationTypes}
            label="Система налогообложения"
            name="params.taxation"
            labelKey="option"
            valueKey="id"
          />
          <Switch name="params.is_testing" items={switchItems} />
          <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={DEFAULT_LIFETIME} />
          <Button type='submit' color='orange' textType='regular' text={t('connections.sberbank.add_button')}
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
};

export default SberCreate;
