import { MessageRequest } from '../../../ChatTypes';
import { getDateTime } from '../../../../../utils/getDateTime/getDateTime';


type SortMessagesType = Array<{
  date: string;
  messages: MessageRequest[];
}>;

const getConvertMessageResults = (sortMessages: Map<string, { messages: MessageRequest[] }>)=>{
  const messagesList: SortMessagesType = [];
  sortMessages.forEach(({ messages }, key) =>
    messagesList.push({ date: key, messages })
  );
  return messagesList.reverse();
};

export const getSortMessages = (messages: MessageRequest[] | null) => {
  const sortMessages = new Map<string, { messages: MessageRequest[]; }>();
  messages?.forEach(i => {
    const getDayMonthYear = getDateTime().format(i.createdAt, 'dayMonthYear');
    const hasKeyDate = sortMessages.has(getDayMonthYear);
    if (hasKeyDate) {
      const copy = sortMessages.get(getDayMonthYear);
      if (copy) {
        sortMessages.set(getDayMonthYear, { messages: [...copy.messages, i] });
      }
    } else {
      sortMessages.set(getDayMonthYear, { messages: [i] });
    }
  });
  return getConvertMessageResults(sortMessages);
};
