import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import { CompanyInvoiceRequestDialog, CompanyInvoiceRequestItem, CompanyPayment, PaymentMethod } from "../PartnerCabinetTypes";
import { currentCompanySelector } from "../../Chat/companiesSelector";
import { addPartnerCompanyInvoice } from "../PartnerCabinetSlice";


export type PartnerInvoiceSchema = {
  subscriptions: number[];
  period_months: number;
  company_requisite_id: number | null;
  promo_code: string;
  dialogs: number | null;
  payment_method: PaymentMethod;
  partner_email: string | null;
};

export function useInvoice() {
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const getInvoice = async (data: PartnerInvoiceSchema, companyId: number, preview: boolean) => {
    let invoices: CompanyPayment | undefined;
    const paymentMethod = currentCompany.paymentCurrency === 'USD' ? "CARD_OTHER" : data.payment_method;
    const items: Array<CompanyInvoiceRequestItem | CompanyInvoiceRequestDialog> = [];
    if (data.subscriptions && data.subscriptions.length > 0) {
      items.push(...data.subscriptions.map(
        (item) => ({ type: 'SUBSCRIPTION', id: item } as CompanyInvoiceRequestItem)));
    }
    if (data.dialogs && data.dialogs > 0) {
      items.push({ type: 'WABA_DIALOGS', count: data.dialogs } as CompanyInvoiceRequestDialog);
    }
    if (items.length > 0) {
      await dispatch(addPartnerCompanyInvoice({
        partner_company_id: companyId,
        company_id: currentCompany.id, preview, items,
        payment_method: paymentMethod,
        period_months: data.period_months,
        ...(data.promo_code ? { promo_code: data.promo_code } : {}),
        ...(data.partner_email ? { partner_email: data.partner_email } : {}),
        ...(data.company_requisite_id &&
          paymentMethod === "BANK_TRANSFER" ? { company_requisite_id: data.company_requisite_id } : {})
      })).then((res) => {
        if (typeof res.payload === 'object') {
          invoices = res.payload;
        }
      });
    }
    return invoices;
  };
  return getInvoice;
}
