import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyOnboarding, companyOnboardingSteps, CompanyOnboardingSteps } from "../../../api/types";
import { onboardingSelector } from "../../../views/Chat/companiesSelector";
import { ConnectionsType } from "../../../views/Settings/Connections/ConnectionsTypes";
import { ConnectionServiceTypes, settingConnections } from "../../../views/Settings/Connections/utils/connections";
import { IntegrationsTypesNames, integrationTypes } from "../../../views/Settings/Integrations/types";
import { BdAnchors, bdAnchorsConnections, bdAnchorsIntegrations } from "../../../pages/Home/utils/consts";

type GuideConfig = {
  path: string;
  guided: (onboarding?: CompanyOnboarding) => boolean;
  action?: (onboarding?: CompanyOnboarding) => string | undefined;
  documentation: boolean;
  subtitle?: string;
  yametrika: string;
};

export const guideDocumentations: Partial<Record<CompanyOnboardingSteps, BdAnchors>> = {
  add_connection: bdAnchorsConnections,
  add_integration: bdAnchorsIntegrations
};

function isGuided() {
  return true;
}

export function isConnectingIntegration(onboarding: CompanyOnboarding) {
  const crmStep =  onboarding.steps.find((item) => item.type === 'choose_crm');
  const connection = onboarding.steps.find((item) => item.type === 'add_connection')?.result?.value;
  if (connection && (crmStep && ['amocrm', 'bitrix'].includes(crmStep?.result?.value ?? ''))) {
    const area = crmStep?.result?.value;
    const integration = [connection, area].join('_');
    if (integrationTypes.includes(integration as IntegrationsTypesNames)) return integration;
  }
}

export function isGuidedIntegration(onboarding?: CompanyOnboarding) {
  if (onboarding) return !!isConnectingIntegration(onboarding);
  return false;
}

function actionConnection(onboarding?: CompanyOnboarding) {
  let action: string | undefined;
  if (onboarding) {
    const actionStep = onboarding.steps.find((item) => item.type === 'add_connection');
    if (actionStep && actionStep.result) action = `/${settingConnections[actionStep.result.value].url}/create`;
  }
  return action;
}

function actionIntegration(onboarding?: CompanyOnboarding) {
  let action: string | undefined;
  if (onboarding) {
    const actionStep = onboarding.steps.find((item) => item.type === 'add_integration');
    const integration = isConnectingIntegration(onboarding);
    if (actionStep && integration) action = `/description/${integration}`;
  }
  return action;
}

export const guidedOnboardingSteps: Partial<Record<CompanyOnboardingSteps, GuideConfig>> = {
  add_connection: { guided: isGuided, path: '/settings/connections', action: actionConnection, documentation: true,
    yametrika: 'onb-step-add-connection' },
  add_integration: { guided: isGuidedIntegration, path: '/settings/integrations', action: actionIntegration,
    documentation: true, yametrika: 'onb-step-add-integration' },
  setup_notifications: { guided: isGuided, path: '/settings/notifications', documentation: false,
    subtitle: 'WhatsApp, Telegram Bot', yametrika: 'onb-step-setup-notifications' }
};

type GuideStep = {
  type: CompanyOnboardingSteps;
  complete: boolean;
  disable: boolean;
};

export function getGuidesStatus() {
  const onboarding = useSelector(onboardingSelector);
  const [guides, setGuides] = useState<GuideStep[]>([]);
  const [conectionType, setConectionType] = useState<ConnectionServiceTypes>();

  useEffect(() => {
    if (onboarding) {
      const guiders: GuideStep[] = [];
      const connection = onboarding.steps.find((item) => item.type === 'add_connection');
      companyOnboardingSteps.forEach((step) => {
        const guideConfig = guidedOnboardingSteps[step];
        if (guideConfig && guideConfig.guided(onboarding)) {
          const guideStep = onboarding.steps.find((item) => item.type === step);
          if (guideStep) guiders.push({
            type: guideStep.type,
            complete: guideStep.isFinished,
            disable: guideStep.type !== 'add_connection'
              ? !connection?.isFinished ?? true
              : false
          });
        }
      });
      setGuides(guiders);
      if (connection?.result?.value) {
        setConectionType(settingConnections[connection.result.value as ConnectionsType]?.type);
      }
    }
  }, [onboarding]);

  return { guides, conectionType };
}
