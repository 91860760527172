import React from 'react';
import './FilePreview.scss';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import { useBroadcastItem } from "../../../api";
import VideoView from "./VideoView";


type ImagePreviewProps = {
  attachedFile?: File,
  setAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  fileType: string,
  setFileType: React.Dispatch<React.SetStateAction<string>>,
  attachedUrl?: string,
  setAttachedUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

const FilePreview = ({ attachedFile, setAttachedFile, fileType,
                       setFileType, attachedUrl, setAttachedUrl }: ImagePreviewProps) => {
  const broadcastItem = useBroadcastItem();

  const onDeleteFile = () => {
    setAttachedFile(undefined);
    setAttachedUrl(undefined);
    setFileType('');
  };

  const getFileType = () => {
    if (fileType === 'image') {
      return (
        <div className='filePreview'>
          <img alt='preview' src={attachedFile ? URL.createObjectURL(attachedFile) : attachedUrl}/>
        </div>
      )
    }
    if (fileType === 'video') {
      return (
        <VideoView attachedFile={attachedFile} attachedFileUrl={attachedUrl} />
      )
    }
    return (
      <div className='filePreview document'>
        {broadcastItem?.displayMessage.file?.name || attachedFile?.name}
      </div>
    )
  }

  return(
    <div className="textMessage__field_file">
      {getFileType()}
    <DeleteIcon onClick={onDeleteFile} />
  </div>
  )

};

export default FilePreview;
