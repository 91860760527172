import axiosInstance from '../../../api/AxiosInstance';
import * as typ from "./SubscriptionsTypes";


export const getSubscriptions = async (company_id: number): Promise<typ.Subscription[]> =>
  axiosInstance.get(`/companies/${company_id}/subscriptions/`, {
    params: {
      company_id,
    },
  });

export const patchSubscriptions = async (
  subscriptionId: number,
  companyId: number,
  data: typ.PatchSubscription): Promise<typ.Subscription> =>
  axiosInstance.patch(`/companies/${companyId}/subscriptions/${subscriptionId}`, data);

export const getPaymentRequisite = async (company_id: number, only_approved?: boolean): Promise<typ.PaymentRequisite[]> =>
  axiosInstance.get(`/companies/${company_id}/subscriptions/requisites/`, {
    params: {
      company_id,
      only_approved,
    },
  });

export const postPaymentRequisite = async (requestOption: typ.PostPaymentRequisite): Promise<typ.PaymentRequisite> =>
  axiosInstance.post(`/companies/${requestOption.company_id}/subscriptions/requisites/`, {
    company_id: requestOption.company_id,
    tin: requestOption.tin,
    name: requestOption.name,
  });

export const patchPaymentRequisite = async (requestOption: typ.UpdatePaymentRequisite): Promise<typ.PaymentRequisite> =>
  axiosInstance.put(`/companies/${requestOption.company_id}/subscriptions/requisites/${requestOption.requisite_id}`, {
    requisite_id: requestOption.requisite_id,
    company_id: requestOption.company_id,
    tin: requestOption.tin,
    name: requestOption.name,
  });

export const deletePaymentRequisite = async (company_id: number, requisite_id: number):
  Promise<typ.PaymentRequisite> => axiosInstance.delete(`/companies/${company_id}/subscriptions/requisites/${requisite_id}`);

export const getSubscriptionInvoice = async (
  requestOption: typ.SubscriptionsInvoicesRequest): Promise<typ.SubscriptionInvoice[]> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/subscriptions/invoices/`,
    { params: { limit: requestOption.limit, offset: requestOption.offset } }
  );

export const postSubscriptionInvoice = async (
  requestOption: typ.PostSubscriptionInvoice): Promise<typ.SubscriptionInvoice> =>
  axiosInstance.post(`/companies/${requestOption.company_id}/subscriptions/invoices/`, {
    preview: requestOption.preview,
    items: requestOption.items,
    payment_method: requestOption.payment_method,
    period_months: requestOption.period_months,
    company_requisite_id: requestOption.company_requisite_id,
    promo_code: requestOption.promo_code,
  });

export const getSubscriptionDialogs = async (
  requestOption: typ.SubscriptionDialogsRequest): Promise<typ.SubscriptionDialogs[]> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/subscriptions/dialogs/`);

export const getUpsellRecommendations = async (companyId: number): Promise<typ.UpsellRecommendation[]> =>
  axiosInstance.get(`/companies/${companyId}/subscriptions/invoices/upsell_recommendations/`);
