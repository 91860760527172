import axiosInstance from '../../../api/AxiosInstance';


export type ParamsGetShops = {
  companyId: string;
  offset?: number;
  limit?: number;
};

export type ParamsGetShopOne = {
  companyId: string;
  shopId: number;
};

export type ParamsGetCatalogs = {
  companyId: string;
  productId?: number;
  offset?: number;
  limit?: number;
};

export type ShopType = {
  id: number,
  name: string,
  link: string,
  useDeviceColorScheme: boolean,
  companyId: number,
  catalogId: number,
  connectionId: number;
};

export type PromiseGetShops = {
  count: number,
  items: ShopType[];
};

export type CatalogType = {
  id: number,
  name: string,
  currency: string;
  productsCount: number;
};

export type ParamsCreateShop = {
  companyId: string;
  name: string,
  catalogId: number,
  connectionId: number,
};

export type ParamsUpdateShop = {
  shopId: number;
  companyId: string;
  name: string,
  catalogId: number,
  connectionId: number,
};

export type ParamsDeleteShop = {
  shopId: number;
  companyId: string;
};

export type PromiseGetCatalogs = {
  count: number,
  items: CatalogType[];
};

export const getCatalogsAPI = async (requestOptions: ParamsGetCatalogs): Promise<PromiseGetCatalogs> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/catalogs/`, {
    params: {
      product_id: requestOptions.productId,
      offset: requestOptions.offset,
      limit: requestOptions.limit
    }
  });

export const getShopsAPI = async (requestOptions: ParamsGetShops): Promise<PromiseGetShops> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/shops/`, {
    params: {
      offset: requestOptions.offset,
      limit: requestOptions.limit
    }
  });

export const getShopOneAPI = async (requestOptions: ParamsGetShopOne): Promise<ShopType> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/shops/${requestOptions.shopId}`);

export const createShopAPI = async (requestOptions: ParamsCreateShop): Promise<ShopType> =>
  await axiosInstance.post(`/companies/${requestOptions.companyId}/sales/shops/`, {
    name: requestOptions.name,
    catalog_id: requestOptions.catalogId,
    connection_id: requestOptions.connectionId,
    // надо потом удалить
    "use_device_color_scheme": true
  });

export const updateShopAPI = async (requestOptions: ParamsUpdateShop): Promise<ShopType> =>
  await axiosInstance.patch(`/companies/${requestOptions.companyId}/sales/shops/${requestOptions.shopId}`, {
    name: requestOptions.name,
    catalog_id: requestOptions.catalogId,
    connection_id: requestOptions.connectionId,
    "use_device_color_scheme": true
  });

export const deleteShopAPI = async (requestOptions: ParamsDeleteShop): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOptions.companyId}/sales/shops/${requestOptions.shopId}`);
