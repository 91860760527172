import React from 'react';
import { useTranslation } from 'react-i18next';
import UserCatalog from '../../models/UserCatalog';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import './CatalogsCard.scss';


type CatalogsCardProps = {
  catalog: UserCatalog;
  onEdit?: (catalog: UserCatalog) => void;
  onDelete?: (catalog: UserCatalog) => void;
  onAbout?: (catalog: UserCatalog) => void;
};

const CatalogsCard = ({ catalog, onAbout, onDelete, onEdit }: CatalogsCardProps) => {
  const { t } = useTranslation();

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onEdit) onEdit(catalog);
  };

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(catalog);
  }

  const onCatalogClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onAbout) onAbout(catalog);
  };

  return (
    <div className="catalogsCard" onClick={onCatalogClick}>
      <p className="catalogsCard__title">{catalog.name}</p>
      <p className="catalogsCard__products">
        {t('sales.catalogs.products')}{catalog.numberProducts}
      </p>
      <div className="catalogsCard__buttonsGroup">
        {onEdit !== undefined ? (
          <button className="catalogsCard__buttonsGroup_editButton" type="button" onClick={onEditClick}>
            <EditIcon />
            {t('edit')}
          </button>) : null
        }
        {onDelete !== undefined ? (
          <button className="catalogsCard__buttonsGroup_deleteButton" type="button" onClick={onDeleteClick}>
            <DeleteIcon />
            {t('delete')}
          </button>) : null
        }
      </div>
    </div>
  );
};

export default CatalogsCard;
