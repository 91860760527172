import { useAppSelector } from "../../../../store/store";
import { NotificationChannel, NotificationChannelEvents, NotificationChannelTypes } from "../NotificationsTypes";


export function updateStringArray<T>(array: T[], newItem: T) {
  let list = array.slice();

  if (list.includes(newItem)) {
    list = list.filter((item) => item !== newItem);
  } else list.push(newItem);

  return list;
}

export const dataEditNotifications = (channel: NotificationChannel, event: NotificationChannelEvents) => {
  const events: NotificationChannelEvents[] = updateStringArray(channel.params.events, event);

  const channelData: NotificationChannel = {
    id: channel.id,
    companyId: channel.companyId,
    name: channel.name,
    params: { events },
    type: channel.type,
    whatsapp: { phone: channel.whatsapp?.phone },
  };

  return channelData;
};

export const advaidNumbers = (connectionType: NotificationChannelTypes) => {
  let createConnection = 0;
  const maxChannel = 2;
  const notifications = useAppSelector(state => state.notifications.notifications);

  if (notifications) {
    notifications.forEach((notification) => {
      if (notification.type === connectionType) {
        createConnection += 1;
      }
    });
  }
  return  maxChannel - createConnection;
};

