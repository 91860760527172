import './InputsPhones.scss';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Button from '../../../../components/Button';
import PhoneField from '../../../../components/PhoneInput/PhoneField';


const InputsPhones = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const phones = useFieldArray({ control, name: 'phones' });

  const addPhone = () => {
    phones.append({ value: '' });
  };

  return (
    <div className="inputsPhones">
      <p className='regularText middle'>{t('contacts.contactsList.phone')}</p>
      {phones.fields.map((phone, index) => (
        <div key={phone.id} className="phoneItem">
          <PhoneField name={`phones.${index}.value`} data-testid='phone' />
          <Button color='white' textType='small' image={<DeleteIcon />}
            onClick={() => phones.remove(index)} data-testid='delete-phone' />
        </div>
      ))}
      <div className="inputsPhones__footer">
        <Button text={t('contacts.editContact.addPhoneNumber')} color='white'
          textType='small' image={<PlusIcon />} onClick={addPhone} data-testid='add-phone' />
      </div>
    </div>
  );
};

export default InputsPhones;
