import { DeepMap, FieldError, FieldValues } from "react-hook-form";

export const getError = (errorsMap: DeepMap<FieldValues, FieldError>, field: string) => {
  const items = field.split('.');
  let error: DeepMap<FieldValues, FieldError> | undefined;
  items.forEach((item) => {
    if (error) {
      if (item.startsWith('[') && item.endsWith(']')) {
        item = item.slice(1, -1);
      }
      error = error[item];
    } else {
      error = errorsMap[item];
    }
  })
  return error;
};
