import { useTranslation } from 'react-i18next';
import { checkEmptyParamError, ParamsType } from '../utils';
import './ParamsList.scss';


type ParamsListProps = {
  caption: string;
  arrayParams: ParamsType[];
  hasError: boolean;
  setArrayParams: (params: ParamsType[]) => void;
};

function ParamsList({ caption, arrayParams, hasError,  setArrayParams }: ParamsListProps) {
  const { t } = useTranslation();

  const onChangeInputs = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const find = arrayParams.find((param) => param.id === id);

    if (find) {
      const editInput = arrayParams.map((input) => {
        if (input.id === find.id) {
          return { ...input, value: e.target.value };
        } else return input;
      });
      setArrayParams(editInput);
    }
  };

  return (
    <div className='paramsList'>
      {arrayParams.map((input) => (
        <div key={input.id} className='inputContainer'>
          <p className="regularText middle">{`${caption} {{${input.label}}}`}</p>
          <input className={`inputContainer__input ${hasError ? checkEmptyParamError(arrayParams, input.id) : ''}`}
            onChange={(e) => onChangeInputs(e, input.id)}
          />
          {hasError && checkEmptyParamError(arrayParams, input.id) ?
            <p className='smallText'>{t('error.form.empty_field')}</p> : ''}
        </div>
      ))}
    </div>
  );
}

export default ParamsList;
