import React from 'react';
import { ReactComponent as SalesIcon } from '../../../../../assets/sales.svg';
import { ReactComponent as SettingsIcon } from '../../../../../assets/settings.svg';
import { SubMenuTabType } from '../../subMenuLists';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import CompaniesList from '../../components/CompaniesList';
import LinkItem from '../../components/LinkItem/LinkItem';
import SubLink from '../../components/SubLink/SubLink';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';
import SupportButton from '../../components/SupportButton/SupportButton';
import ExitButton from '../../components/ExitButton/ExitButton';
import ReferenceButton from '../../components/ReferenceButton/ReferenceButton';
import PromotionBanner from '../../components/PromotionBanner';


type Props = {
  setSelectSubTab: React.Dispatch<React.SetStateAction<SubMenuTabType | null>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MainTabs = ({ setSelectSubTab, setIsOpen }: Props) => {

  return (
    <>
      <div>
        <div className="NavbarMobile__header">
          <div className="NavbarMobile__header_logo">
            <CompanyLogo />
          </div>
        </div>
        <div className="NavbarMobile__supportMenu">
          <div className="NavbarMobile__supportMenu_reference">
            <ReferenceButton />
          </div>
          <div className="NavbarMobile__supportMenu_support">
            <SupportButton />
          </div>
          <ExitButton />
        </div>
        <CompaniesList isRolledUp={false} setIsOpen={setIsOpen} position='bottom' />
      </div>
      <PromotionBanner setIsOpen={setIsOpen} />
      <div className="NavbarMobile__tabs">
        <WrapperPermission permission="messagingChatView">
          <LinkItem location="chats" setIsOpen={setIsOpen} />
        </WrapperPermission>
        <WrapperPermission permissions={['salesShopView', 'salesCatalogView', 'salesProductView', 'banksInvoiceView']}>
          <SubLink location="sales" icon={<SalesIcon />} setSelectSubTab={setSelectSubTab} />
        </WrapperPermission>
        <WrapperPermission permission="templatesView">
          <LinkItem location="templates" setIsOpen={setIsOpen} />
        </WrapperPermission>
        <WrapperPermission permission="contactsView">
          <LinkItem location="contacts" setIsOpen={setIsOpen} />
        </WrapperPermission>
        <WrapperPermission permission="broadcastsView">
          <LinkItem location="broadcasts" setIsOpen={setIsOpen} />
        </WrapperPermission>
        <WrapperPermission permission="partnersView">
          <LinkItem location="partners" setIsOpen={setIsOpen} />
        </WrapperPermission>
        <SubLink location="settings" icon={<SettingsIcon />} setSelectSubTab={setSelectSubTab} />
      </div>
    </>
  );
};

export default MainTabs;
