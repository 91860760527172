import React, { useRef } from 'react';
import './ReferenceButton.scss'
import { useTranslation } from "react-i18next";
import { bdAnchors } from "../../../utils/consts";
import { getViewPopup } from "../../utils";
import { ReactComponent as InformationIcon } from "../../../../../assets/info.svg";
import PopupTitle from "../PopupTitle";


type Props = {
  isRolledUp?: boolean;
  onClickHelp?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ReferenceButton = ({ isRolledUp, onClickHelp }: Props) => {
  const { t, i18n } = useTranslation();
  const refPopup = useRef<HTMLAnchorElement>(null);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();

  return (
    <a href={bdAnchors[i18n.language]} target="_blank" rel="noreferrer" onClick={onClickHelp} ref={refPopup}>
      {!isRolledUp ? (
        <div className="referenceButton">
          <InformationIcon />
          <p>{t('navbar.reference')}</p>
        </div>
      ) : (
        <PopupTitle
          text={t('navbar.reference')} image={<InformationIcon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}
          viewPopup={viewPopup} refPopup={refPopup} />
      )}
    </a>
  );
};

export default ReferenceButton;
