import { Switch } from 'react-router-dom';
import { Route, useRouteMatch } from 'react-router';
import Shops from './Shops';
import CreateEditShop from './components/CreateEditShop';


const ShopsRoute = () => {
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/shops');

  return (
    <Switch>
      <Route path={[url!.url]} exact>
        <Shops />
      </Route>
      <Route path={`${url!.url}/create`} exact>
        <CreateEditShop />
      </Route>
      <Route path={`${url!.url}/edit`} exact>
        <CreateEditShop />
      </Route>
    </Switch>
  );
};

export default ShopsRoute;
