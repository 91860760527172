import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { CompanyOnboardingUpdate } from '../../../api/types';
import { YandexMetrikaCall } from '../../../utils/utils';
import { onboardingSelector } from '../../../views/Chat/companiesSelector';
import { clearOnboardingGuide, patchOnboarding } from '../../../views/Chat/companiesSlice';


export const useUpdateStep = () => {
  const dispatch = useDispatch();

  const onSubmitOnboardingStep = async (data: CompanyOnboardingUpdate) => {
    dispatch(patchOnboarding(data));
  }

  return onSubmitOnboardingStep;
};

export const getIsOnbourdingModal = (index: string) => {
  const state = localStorage.getItem(index);
  if (state !== null) return JSON.parse(state);
  return true;
};


export const updateIsOnbourdingModal = (index: string, value: boolean) => {
  const state = JSON.stringify(value);
  localStorage.setItem(index, state);
};

export const useOnbordingMenu = () => {
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [ isIntegrator, setIsIntegartor ] = useState<boolean>(false);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const onboarding = useSelector(onboardingSelector);


  useEffect(() => {
    if (onboarding) {
      const chooseRole = onboarding.steps.find((step) => step.type === 'choose_role');
      if (chooseRole) setIsIntegartor(chooseRole.result?.value === 'integrator');

      const connection = onboarding.steps.find((step) => step.type === 'add_connection');
      if (connection) setIsCollapse(connection.result?.value !== undefined && !!connection.result?.value);
    }
  }, [onboarding]);

  const closeBoarding = () => {
    dispatch(clearOnboardingGuide());
    dispatch(patchOnboarding(
      {
        company_id: Number(url?.params.companyId),
        is_cancelled: true,
        steps: []
      })
    );
    YandexMetrikaCall('reachGoal', 'close-onboarding');
  };

  return { closeBoarding, isIntegrator, isCollapse };
}
