import { createContext } from "react";


export const METHODS_PAYMENT = ['CARD_RU', 'CARD_OTHER', 'BANK_TRANSFER'];
export type MethodsPaymentType = typeof METHODS_PAYMENT[number];

const subscriptionServices = [
  'whatsapp', 'waba', 'telegram', 'telegram_bot', 'odnoklassniki',
  'tinkoff', 'modulbank', 'sberbank', 'paykeeper', 'paypal', 'bepaid', 'yookassa', 'payselection'
] as const;

export const subscriptionServiceMap = Object.fromEntries(subscriptionServices.map((item) => [
  `CONNECTION_${item.toUpperCase()}`, item]));

export type InvoicesUploadContext = {
  hasMore: boolean;
  onUpload: () => void;
};

export const invoicesUploadContext = createContext<InvoicesUploadContext>({ hasMore: false, onUpload: () => {} });
