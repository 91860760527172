import { Reducer, useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../../../../../store/store";
import { YandexMetrikaCall } from "../../../../../../utils/utils";
import { currentCompanySelector } from "../../../../../Chat/companiesSelector";
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { useGuide } from "../../../api/guide";
import { createConnection } from "../../../connectionsSlice";
import { AuthType, QrLogin, TelegramInitStatus } from "./api";
import { initTelegramSession, sendTelegramLogin } from "./thunk";
import { TelegramData } from "../../shemas";


export type TelegramState = {
  name: string | null;
  connectionId: number | null;
  authType: AuthType;
  sessionId: string | null;
  status: TelegramInitStatus | null;
  phone: string | null;
  code: number | null;
  password: string | null;
  qrLogin: QrLogin | null;
  error: number | null;
  updating: boolean;
};

// make initial state of type TelegramState
export const initialState: TelegramState = {
  name: null,
  connectionId: null,
  authType: 'qr',
  sessionId: null,
  status: null,
  phone: null,
  code: null,
  password: null,
  qrLogin: null,
  error: null,
  updating: false
};

const telegramActionKinds = ['authType', 'init', 'status', 'password', 'code', 'error', 'updating', 'data'] as const;

type TelegaramActionKind = typeof telegramActionKinds[number];

type TelegramActionGeneric<T extends TelegaramActionKind, P extends Partial<TelegramState>> = {
  type: T;
  payload: P;
};

export type TelegramActionAuth = TelegramActionGeneric<'authType', Pick<TelegramState, 'authType'>>;
export type TelegramActionInit = TelegramActionGeneric<'init', Pick<TelegramState, 'sessionId' | 'status' | 'qrLogin'>>;
export type TelegramActionLogin = TelegramActionGeneric<'status', Pick<TelegramState, 'status' | 'qrLogin'>>;
export type TelegaramActionPassword = TelegramActionGeneric<'password', Pick<TelegramState, 'password'>>;
export type TelegaramActionCode = TelegramActionGeneric<'code', Pick<TelegramState, 'code'>>;
export type TelegaramActionError = TelegramActionGeneric<'error', Partial<TelegramState>>;
export type TelegramActionUpdate = TelegramActionGeneric<'updating', Pick<TelegramState, 'updating'>>;
export type TelegaramActionData = TelegramActionGeneric<'data', Pick<TelegramState, 'name' | 'phone' | 'connectionId'>>;

export type TelegramActions = TelegramActionAuth | TelegramActionInit | TelegramActionLogin | TelegaramActionError
  | TelegaramActionPassword | TelegaramActionCode | TelegramActionUpdate | TelegaramActionData;

export function telegramReducer(prevState: TelegramState, action: TelegramActions): TelegramState {
  switch (action.type) {
    case 'authType':
      return {
        ...prevState,
        sessionId: null,
        status: null,
        qrLogin: null,
        code: null,
        password: null,
        error: null,
        updating: false,
        authType: action.payload.authType
      }
      case 'init':
      case 'status':
      case 'password':
      case 'code':
      case 'data':
        return {
          ...prevState,
          ...action.payload,
          error: null,
          updating: false
        };
      case 'error':
      return {
        ...prevState,
        ...action.payload,
        updating: false
      };
      case 'updating':
        return {
          ...prevState,
          ...action.payload
        };
    default:
      return prevState;
  }
}

const UNKNONW_ERROR = 99999;
const CHECK_INTERVAL = 5000;  // wait time interval between telegram login sessions in milliseconds

export function useTelegramLogin() {
  const dispatch = useAppDispatch();
  const { goBack } = useHistory();
  const { service, update } = useGuide();
  const { id: companyId, hadConnections } = useSelector(currentCompanySelector);
  const [telegramState, updateTelegramState] =
    useReducer<Reducer<TelegramState, TelegramActions>>(telegramReducer, initialState);
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => () => {
    if (intervalId.current) clearInterval(intervalId.current);
  }, [intervalId]);

  useEffect(() => {
    if (telegramState.status === 'authenticated') createTelegram();
    if (telegramState.sessionId === null || telegramState.error !== null) return;
    if (telegramState.status === 'waiting_for_password' && telegramState.password === null) return;
    if (telegramState.status === 'waiting_for_code' && telegramState.code === null) return;
    if (telegramState.sessionId !== null && telegramState.status !== 'authenticated') {
      checkStatus();
      const id = setInterval(checkStatus, CHECK_INTERVAL);  // repeat checking with interval every CHECK_INTERVAL ms
      intervalId.current = id;
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    }
  }, [telegramState.sessionId, telegramState.status, telegramState.code, telegramState.password, telegramState.error]);

  const checkStatus = async () => {
    if (telegramState.sessionId === null) return;
    updateTelegramState({ type: 'updating', payload: { updating: true } });
    const res = await dispatch(sendTelegramLogin({
      company_id: companyId,
      session_id: telegramState.sessionId,
      code: telegramState.code,
      password: telegramState.password })
    ).unwrap()
    .catch((e) => {
      updateTelegramState({ type: 'error', payload: { error: e } });
    });

    if (typeof(res) === 'object')
      updateTelegramState({ type: 'status', payload: { status: res.status, qrLogin: res.qrLogin } });
  };

  const createTelegram = () => {
    if (telegramState.sessionId === null || telegramState.name === null) return;
    if (telegramState.connectionId !== null) {
      goBack();
      return;
    }
    dispatch(createConnection({
      companyId,
      name: telegramState.name,
      type: 'telegram',
      params: { session_id: telegramState.sessionId }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        goBack();
        if (service === 'telegram') update();
      } else {
        updateTelegramState({ type: 'error', payload: {
          error: typeof(res.payload) === 'number' ? res.payload : UNKNONW_ERROR,
          sessionId: null, status: null, qrLogin: null, name: null, phone: null, password: null, code: null } });
      }
    });
  };

  const initTelegram = async (data?: TelegramData) => {
    if (data) updateTelegramState({ type: 'data',
      payload: { name: data.name, phone: data.phone ?? null, connectionId: telegramState.connectionId } });

    const init = await dispatch(initTelegramSession({
      company_id: companyId,
      auth_type: telegramState.authType,
      ...(telegramState.authType === 'code'
        ? { phone: data && data.phone ? data.phone: telegramState.phone }
        : { phone: null } ),
      connection_id: telegramState.connectionId
    })).unwrap()
    .catch((e) => {
      updateTelegramState({ type: 'error', payload: { error: e, name: null, phone: null } });
    });;

    if (typeof(init) === 'object') updateTelegramState({
        type: 'init', payload: { sessionId: init.sessionId, status: init.status, qrLogin: init.qrLogin } });
  };

  return { telegramState, updateTelegramState, initTelegram };
}
