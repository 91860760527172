import { useEffect, useRef } from 'react';
import './MessageWabaTemplate.scss';
import { BaseMessageComponent } from '../types';
import LinkifyText from '../../../../../components/LinkifyText/LinkifyText';
import WabaHeader from './WabaHeader';
import WabaButtons from './WabaButtons';
import WabaCarousel from './WabaCarousel';


const MessageWabaTemplate: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current && handleSizeChange) {
      handleSizeChange(textElementRef.current.offsetHeight);
    }
  }, [textElementRef.current]);

  return (
    <div ref={textElementRef} className="messageWabaTemplate">
      {message.wabaTemplate.carousel && message.wabaTemplate.carousel.length > 0
        ? <>
          <div className="messageWabaTemplate__text">
            <LinkifyText className="regularText" text={message.wabaTemplate.text} />
          </div>
          <WabaCarousel carousel={message.wabaTemplate.carousel} />
        </> : <>
          {message.wabaTemplate.mediaHeader && <WabaHeader header={message.wabaTemplate.mediaHeader} />}
          <div className="messageWabaTemplate__text">
            <LinkifyText className="regularText" text={message.wabaTemplate.text} />
          </div>
          {message.wabaTemplate.buttons && message.wabaTemplate.buttons.length > 0 &&
            <div className="messageWabaTemplate__buttons">
              <WabaButtons buttons={message.wabaTemplate.buttons} />
            </div>
          }
        </>
      }
    </div>
  );
};

export default MessageWabaTemplate;
