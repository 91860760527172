import { useCallback, useEffect, useState } from 'react';
import './ChatBill.scss';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/store';
import { currentCompanySelector, userInfoSelector } from '../../companiesSelector';
import { currentContactSelector } from '../../../Contacts/contactsSlice/contactsSelector';
import { isPageBillSelector, isLoadingAddInvoiceSelector, terminalsSelector } from '../../billSelector';
import { ProductsSelector } from "../../../Sales/SalesSelector";
import {
  fetchInvoices, postInvoices, updateIsPageBill, updateIsVisibleLinkInvoice, updateRadistOrder
} from '../../billSlice';
import { fetchProducts } from "../../../Sales/SalesSlice";
import { selectedChatById } from '../../chatsSlice';
import { fetchContact } from '../../../Contacts/contactsSlice/contactsSlice';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { fetchSubscriptions } from '../../../Settings/Subscriptions/SubscriptionsSlice';
import { InvoicesProduct, Terminal } from '../../BillAPI';
import { Product } from '../../../Sales/SalesAPI';
import { WabaAndOrderType } from "../../ChatTypes";
import { SubscriptionStatusEnum } from '../../../Settings/Subscriptions/types';
import { SubmitSchemaInvoices } from './schema/schema';
import { useConnectSubscriptionStatus, bannedSubscriptionStatus } from '../../../../components/utils/hooks';
import {
  addLogosToTerminals, ItemWithLogo, currenciesListToItems, currencyCodeToSymbol, paymentMethodsList, useAmount
} from './components/helpers';
import { currencyFormat } from "../../../Settings/CompanySettings/currency";
import { usePhoneSchema } from '../../../../components/PhoneInput/utils';
import { ReactComponent as ArrowBack } from '../../../../assets/arrow-back.svg';
import DropdownEmptyLink from '../../../../components/DropdownEmptyLink/DropdownEmptyLink';
import Dropdown from '../../../Settings/Dropdown/Dropdown';
import Textarea from '../../../Settings/Textarea/Textarea';
import ProductItemEdit from './components/ProductItemEdit/ProductItemEdit';
import Button from '../../../../components/Button/Button';
import PhoneField from '../../../../components/PhoneInput/PhoneField';
import ComboList from './components/ComboList';
import Input from '../../../../components/Input';
import LableInfo from './components/LableInfo';
import Loader from "../../../../components/Loader/Loader";


const ChatBill = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useRouteMatch<{ companyId: string; contactId: string; chatId: string; }>(
    '/companies/:companyId/chats/:contactId/:chatId'
  );
  const currentCompany = useSelector(currentCompanySelector);
  const terminals = useSelector(terminalsSelector);
  const isLoadingAddInvoice = useSelector(isLoadingAddInvoiceSelector);
  const userInfo = useSelector(userInfoSelector);
  const currentContact = useSelector(currentContactSelector);
  const isPageBill = useSelector(isPageBillSelector);
  const [price, setPrice] = useState<number>(0);
  const [currentTerminal, setCurrentTerminal] = useState<Terminal>();
  const [terminalList, setTerminalList] = useState<Array<ItemWithLogo<Terminal>>>();
  const nameRegex = /^[а-яА-ЯёЁa-zA-Z0-9]+$/;
  const subscriptionStatus = useConnectSubscriptionStatus(
    { connectionId: currentTerminal?.id, subscriptionId: currentTerminal?.subscriptionId });
  const products = useSelector(ProductsSelector);
  const location = useLocation<WabaAndOrderType>();
  const { state } = location;

  const itemsSchema = {
    name: yup.string().required(t('empty_field')).max(128, t('chats.bill.error.char_limit', { limit: 128 })),
    quantity: yup.number().min(0.1, t('empty_field')).max(99999, t('chats.bill.error.char_limit', { limit: 5 }))
      .typeError(t('chats.bill.error.field_null')).required(t('empty_field')),
    price: yup.number().min(0, t('chats.bill.error.price_minus'))
      .max(9999999999, t('chats.bill.error.char_limit', { limit: 10 })).typeError(t('chats.bill.error.field_null'))
      .required(t('empty_field')),
    vat: yup.string().default('none'),
    type: yup.string().default('commodity'),
    payment_type: yup.string().default('full_prepayment'),
    tax_name: yup.string(),
    tax_value: yup.string().nullable(),
  };

  const buyerSchema = {
    name: yup.string(),
    tin: yup.string(),
    iec: yup.string(),
    psrn: yup.string(),
    bic: yup.string(),
    address: yup.string(),
  };

  const schema = yup.object().shape({
    company_id: yup.number(),
    connection_id: yup.number(),
    amount: yup.number(),
    order_id: yup.string().max(36, t('chats.bill.error.char_limit', { limit: 36 })).when({
      is: (order: string) => order.length > 0,
      then: yup.string().matches(nameRegex, t('chats.bill.error.order_format')),
    }),
    external_id: yup.string().nullable(),
    currency: yup.number().default(643),
    description: yup.string().max(250, t('chats.bill.error.char_limit', { limit: 250 })),
    signer: yup.number().nullable(),
    receipt: yup.object().shape({
      ...{
        items: yup.array().of(yup.object().shape(itemsSchema)),
        client_email: yup.string().email(t('chats.bill.error.email_format')),
        client_name: yup.string(),
        buyer: yup.array().of(yup.object().shape(buyerSchema)).nullable(),
        tax_included: yup.boolean(),
      }, ...usePhoneSchema({ fieldName: 'client_phone' })
    }),
    meta: yup.object().shape({
      payment_methods: yup.string(),
    }),
    integration_id: yup.number(),
    live_due_date: yup.string().nullable(),
    contact_id: yup.number(),
  });

  const methods = useForm<SubmitSchemaInvoices>({
    resolver: yupResolver(schema), mode: 'onChange',
    defaultValues: {
      company_id: currentCompany.id,
      amount: 0,
      order_id: '',
      external_id: null,
      currency: 643,
      description: '',
      signer: null,
      receipt: {
        items: [{
          name: '',
          quantity: undefined,
          price: undefined,
          vat: 'none',
          type: 'commodity',
          payment_type: 'full_prepayment',
          tax_name: 'НДС',
          tax_value: null,
        }],
        client_email: '',
        client_phone: '',
        client_name: '',
        buyer: {
          name: '',
          tin: null,
          iec: null,
          psrn: null,
          bic: null,
          address: null
        },
        tax_included: true,
      },
      meta: {
        payment_methods: 'all'
      },
      live_due_date: null,
    },
  });

  const watchCurrency = methods.watch('currency');
  const watchPay = methods.watch('meta.payment_methods');
  const arrItems = methods.watch('receipt.items');
  const { totalSum } = useAmount({ price, arrItems });

  const orderArray: InvoicesProduct[] = [];

  useEffect(() => {
    if (params?.params.chatId) {
      dispatch(selectedChatById(Number(params.params.chatId)));
    }
    methods.setValue('connection_id', null);

    if (params?.params.companyId) {
      dispatch(fetchInvoices({ companyId: Number(params?.params.companyId), limit: 50, offset: 0 }));
      dispatch(fetchSubscriptions({ companyId: Number(params?.params.companyId) }));
      dispatch(fetchProducts({ companyId: Number(params?.params.companyId) }));
    }
    dispatch(updateRadistOrder(null));

    return () => {
      dispatch(updateIsPageBill(false));
    };
  }, []);

  useEffect(() => {
    if (params) {
      dispatch(fetchContact({
        contactId: Number(params?.params.contactId),
        companyId: Number(params?.params.companyId),
      }));
    }
  }, [params?.params.contactId, params?.params.companyId]);

  useEffect(() => {
    if (state) {
      let findProduct: Product | undefined;
      for (let i = 0; i < state.items.length; i++) {
        if (products && state.items[i].productId) {
          findProduct = products.items.find((product) => product.id === state.items[i].productId);
        }
        orderArray.push({
          name: state.items[i].name,
          quantity: state.items[i].quantity,
          price: state.items[i].price,
          vat: findProduct?.vat ? findProduct.vat : 'none',
          type: findProduct?.paymentObjectType ? findProduct.paymentObjectType : 'commodity',
          payment_type: 'full_prepayment',
          tax_name: 'НДС',
          tax_value: null,
        });
      }
      methods.reset({
        amount: state.amount,
        currency: 643,
        receipt: {
          items: orderArray,
          client_phone: ''
        },
        meta: {
          payment_methods: 'all'
        },
      });
    }
  }, [products]);

  useEffect(() => {
    if (!isPageBill) {
      history.push(`/companies/${params?.params.companyId}/chats`);
    }
  }, [isPageBill, params?.params.companyId]);

  useEffect(() => {
    if (terminals) {
      setTerminalList(addLogosToTerminals(terminals?.filter((terminal) => terminal.type !== 'paypal')));
    }
  }, [terminals]);

  useEffect(() => {
    if (currentTerminal && subscriptionStatus !== undefined) {
      if (bannedSubscriptionStatus.includes(subscriptionStatus)) {
        const service = 'banking';
        const statusName = SubscriptionStatusEnum[subscriptionStatus];
        const messageText = t(`subscriptions.connection_status.${statusName}.${service}`);
        dispatch(addAlertWithCustomText({ message: messageText, color: '#F54242' }));
      }
    }
  }, [currentTerminal, subscriptionStatus]);

  const onSelectTerminal = useCallback((terminal: Terminal) => {
    if (terminal) {
      if (terminalList) {
        setCurrentTerminal(terminalList.find((item) => item.id === terminal.id));
      }
      if (state) {
        methods.setValue('currency', 643);
      }
      methods.setValue('meta.payment_methods', 'all');
      methods.setValue('connection_id', terminal.id);
    }
  }, [terminalList]);

  const translateMessage = {
    yes: t('yes'),
    no: t('no'),
    message: t('leave_page_bill'),
  };

  const onCloseBill = () => {
    history.push(`${params?.url}`);
  };

  const onSubmit = (data: SubmitSchemaInvoices) => {
    if (currentTerminal) {
      if (currentTerminal.type === 'tinkoff'
        && (data.receipt.client_email.length === 0 && data.receipt.client_phone.length === 0)) {
        dispatch(addAlertWithCustomText({
          message: t('chats.bill.error.email_and_phone_null'),
          type: 'alarm'
        }));
      } else if (totalSum > 99999999) {
        dispatch(addAlertWithCustomText({
          message: t('chats.bill.error.total_not_10char'),
          type: 'alarm'
        }));
      } else if (totalSum === 0) {
        dispatch(addAlertWithCustomText({
          message: t('chats.bill.error.total_not_null'),
          type: 'alarm'
        }));
      } else if ((currentContact !== null) && userInfo) {
        dispatch(postInvoices({
          company_id: currentCompany.id,
          connection_id: data.connection_id,
          amount: Math.round((totalSum) * 100) / 100,
          order_id: data.order_id,
          external_id: data.external_id,
          currency: data.currency,
          description: data.description,
          signer: data.signer,
          receipt: {
            items: data.receipt.items,
            client_email: data.receipt.client_email,
            client_phone: data.receipt.client_phone,
            client_name: currentContact.name,
            buyer: {
              name: currentContact.name,
              tin: data.receipt.buyer?.tin,
              iec: data.receipt.buyer?.iec,
              psrn: data.receipt.buyer?.psrn,
              bic: data.receipt.buyer?.bic,
              address: data.receipt.buyer?.address,
            },
          tax_included: data.receipt.tax_included,
          },
          live_due_date: data.live_due_date,
          meta: {
            payment_methods: [watchPay]
          },
          integration_id: data.integration_id,
          contact_id: Number(params?.params.contactId),
        })).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(updateIsVisibleLinkInvoice(true));
            history.push(`${params?.url}`);
          }
          if (res.meta.requestStatus === 'rejected') {
            if (res.payload) {
              let errorMesage = String(res.payload);
              if (String(res.payload) === 'Ошибка создания платежа [Код 3]. Неизвестная валюта') {
                errorMesage = t('chats.bill.error.error_code3');
              } else if (String(res.payload) ===
                'Ошибка создания платежа [Код 8]. [orderBundle.customerDetails.email] неверный формат') {
                errorMesage = t('chats.bill.error.error_code8');
              }
              dispatch(addAlertWithCustomText({ message: errorMesage, type: 'alarm' }));
            }
          }
        });
      }
    }
  };

  if (isLoadingAddInvoice) {
    return (
      <div className='chatBill'>
        <div className='chatBill__wrapper'>
          <div className='chatBillContainer'>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (terminalList && terminalList.length === 0) {
    return (
      <div className='chatBill'>
        <div className='chatBill__wrapper'>
          <div className='chatBillContainer'>
            <div className='chatBillContainer__header'>
              <Button text={t('back')} style={{ padding: '6px 12px 6px 8px' }}
                onClick={onCloseBill} image={<ArrowBack />} color='white' textType='small' />
              <h1>{t('chats.bill.title_bill')}</h1>
            </div>
            <div className='chatBillContainer__content'>
              <DropdownEmptyLink
                label={t('chats.bill.terminal')} link='settings'
                linkText={t('chats.bill.add_new_terminal')} placeholder={t('chats.bill.select_no_connection')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='chatBill'>
      <div className='chatBill__wrapper'>
        <div className='chatBillContainer'>
          <div className='chatBillContainer__header'>
            <Button text={t('back')} onClick={onCloseBill} image={<ArrowBack />} color='white' textType='small' />
            <h1>{t('chats.bill.title_bill')}</h1>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className='chatBillContainer__content'>
                {terminalList &&
                  <Dropdown name="connection_id" label={t('chats.bill.terminal')} labelKey="name" valueKey="id"
                    placeholder={t('select')} items={terminalList} callback={onSelectTerminal} />
                }
                {currentTerminal && !bannedSubscriptionStatus.includes(subscriptionStatus) &&
                  (<>
                    <LableInfo lable={t('chats.bill.number_order')} message={t('chats.bill.info_number_order')}>
                      <Input name='order_id' />
                    </LableInfo>
                    <ComboList name='receipt.client_phone' values={currentContact?.phones.map(phone => phone.value)}>
                      <PhoneField name='receipt.client_phone' label={t('chats.bill.phone_check')} />
                    </ComboList>
                    <ComboList name='receipt.client_email' values={currentContact?.emails.map(email => email.value)}>
                      <Input name='receipt.client_email' label={t('chats.bill.email_check')} />
                    </ComboList>
                    {currentTerminal?.currencies
                      ? <Dropdown name="currency" label={t('chats.bill.currency')} labelKey="name" valueKey="id"
                          items={currenciesListToItems(currentTerminal?.currencies)} />
                      : null}
                    <ProductItemEdit setPrice={setPrice} watchCurrencyTerminal={currentTerminal} />
                    {currentTerminal?.type === 'modulbank' &&
                      <Dropdown name="meta.payment_methods" label={t('chats.bill.payment_method')}
                      items={paymentMethodsList} labelKey="name" valueKey="id" />}
                    <Textarea label={t('chats.bill.description')} name='description' />
                    <h2>
                      {t('chats.bill.amount')}: {currencyFormat(totalSum, currencyCodeToSymbol[watchCurrency], i18n.language)}
                    </h2>
                    <div className='chatBillContainer__content_bottom'>
                      <Button text={t('chats.bill.save_button')} color='orange' textType='regular' type='submit' />
                      <Button text={t('cancel')} onClick={onCloseBill} color='white' textType='regular' />
                    </div>
                  </>)}
              </div>
            </form>
          </FormProvider>
        </div>
        <Prompt when={currentTerminal !== undefined && !methods.formState.isSubmitSuccessful}
          message={JSON.stringify(translateMessage)} />
      </div>
    </div>
  );
};

export default ChatBill;
