import './FinishStep.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { updateIsOnbourdingModal } from '../../utils/onboarding';
import { YandexMetrikaCall } from "../../../../utils/utils";
import Button from '../../../../components/Button';


type Props = {
  onSwitchModal: () => void;
};

const FinishStep = ({ onSwitchModal }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');

  const onStartWork = () => {
    history.push(`/companies/${url?.params.companyId}/chats`);
    onSwitchModal();
    updateIsOnbourdingModal('isOnbourdingModal', false);
  };

  const onPayWithDiscount = () => {
    history.push(`/companies/${url?.params.companyId}/settings/subscriptions`, true);
    onSwitchModal();
    updateIsOnbourdingModal('isOnbourdingModal', false);
    YandexMetrikaCall('reachGoal', 'onb-step-click-pay');
  };

  return (
    <div className='finishStep'>
      <p className="regularText">
        <Trans i18nKey="company_onboarding.discount">plain
          <strong>strong</strong>
        </Trans>
      </p>
      <div className="finishStep__buttons">
        <Button text={t('company_onboarding.payWithDiscount')} color="orange" textType="small"
          onClick={onPayWithDiscount} />
        <Button text={t('company_onboarding.startWork')} color="white" textType="small"
          onClick={onStartWork} />
      </div>
    </div>
  );
};

export default FinishStep;
