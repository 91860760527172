import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { useAppDispatch } from '../../../store/store';
import {
  WabaTemplateCategory, WabaTemplateComponentHeaderFormat,
  WabaTemplateComponentButtonsButtonUrl, WabaTemplateComponentButtonsButtonPhoneNumber,
  WabaTemplateComponentButtonsButtonQuickReply, CreateWabaTemplateRequest,
  WabaTemplateInfo, WabaTemplateComponentButtons,
  WabaTemplateComponentHeader, WabaTemplateComponentBody, WabaTemplateComponentFooter,
  WabaTemplateComponentAuthBody, WabaTemplateComponentAuthFooter, WabaTemplateComponentCarousel,
  WabaTemplateComponentCarouselButtons,
} from "../templatesTypes";
import { WabaTemplateActions } from '../utils/waba';
import { useContext } from 'react';
import { wabaTemplateContext } from './WabaTemplateProvider/WabaTemplateProvider';
import { createWabaTemplate, deleteWabaTemplate, updateWabaTemplate } from '../templatesSlice';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { WabaTemplateState } from './WabaTemplateProvider/utils';


export const wabaTemplateKinds = ['message', 'auth', 'carousel'] as const;

export type WabaTemplateKind = typeof wabaTemplateKinds[number];

export type Named = {
  id: string | number;
  name: string;
};

type WabaTemplateOtpButtonCopy = {
  type: 'COPY_CODE';
  text: string;
};

type WabaTemplateOtpButtonTap = {
  type: 'ONE_TAP';
  text: string;
  autofill_text?: string | null;
  package_name?: string | null;
  signature_hash?: string | null;
};

export type WabaTemplateSchema = {
  custom_name: string;
  template_locale: string;
  category: WabaTemplateCategory;
  allow_category_change?: boolean;
  add_security_recommendation?: boolean;
  code_expiration_minutes?: number;
  header?: {
    format: WabaTemplateComponentHeaderFormat;
    text?: string | null;
  };
  body?: string;
  footer?: string;
  buttons?: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumber
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateOtpButtonCopy
    | WabaTemplateOtpButtonTap
  >;
  cards?: Array<{description: string}>;
};

export function checkComponentText(
  item: WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter
  | WabaTemplateComponentAuthBody
  | WabaTemplateComponentAuthFooter): item is WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter {
  if (item && ('text') in item) {
    return true;
  }
  return false;
}

export function useWabaTemplateRequest() {
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const { connectionId } = useContext(wabaTemplateContext);

  const makeWabaTemplateRequest = (data: WabaTemplateState) => {
    const request: CreateWabaTemplateRequest = {
      company_id: companyId,
      connection_id: connectionId,
      custom_name: data.custom_name,
      category: data.category,
      allow_category_change: data.allow_category_change,
      components: [],
      template_locale: data.template_locale,
      examples: {}
    };

    if (data.kind === 'auth') {
      request.components.push(
        { type: 'BODY', add_security_recommendation: data.add_security_recommendation ?? false });

      if (data.code_expiration_minutes) request.components.push(
        { type: 'FOOTER', code_expiration_minutes: data.code_expiration_minutes });

      if (data.buttons && data.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtons['buttons'] = [];
        data.buttons.forEach(button => {
          if (button.type === 'COPY_CODE') buttons.push({ type: 'OTP', otp_type: button.type, text: button.text });
          if (button.type === 'ONE_TAP') buttons.push(
            {
              type: 'OTP', otp_type: button.type, text: button.text,
              autofill_text: button.autofill_text, package_name: button.package_name,
              signature_hash: button.signature_hash
            });
        });
        request.components.push({ type: 'BUTTONS', buttons });
      }
    }

    if (data.kind === 'message') {
      if ((data.header) && ((data.header.text && data.header.format === 'TEXT') || data.header.format !== 'TEXT')) {
        request.components.push({ type: 'HEADER', text: data.header.text, format: data.header.format });
      }
      if (data.body) request.components.push({ type: 'BODY', text: data.body });
      if (data.footer) request.components.push({ type: 'FOOTER', text: data.footer });
      if (data.buttons && data.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtons['buttons'] = [];
        data.buttons.forEach(button => {
          if (button.type === 'QUICK_REPLY') buttons.push({ type: 'QUICK_REPLY', text: button.text });
          if (button.type === 'PHONE_NUMBER') buttons.push(
            { type: 'PHONE_NUMBER', text: button.text, phone_number: button.phone_number });
          if (button.type === 'URL') buttons.push({ type: 'URL', text: button.text, url: button.url });
        });
        request.components.push({ type: 'BUTTONS', buttons });
      }
    }

    if (data.kind === 'carousel') {
      if (data.body) request.components.push({ type: 'BODY', text: data.body });
      const buttons: WabaTemplateComponentCarouselButtons['buttons'] = [];
      if (data.buttons && data.buttons?.length > 0) {
        data.buttons.forEach(button => {
          if (button.type === 'QUICK_REPLY') buttons.push({ type: 'QUICK_REPLY', text: button.text });
          if (button.type === 'PHONE_NUMBER') buttons.push(
            { type: 'PHONE_NUMBER', text: button.text, phone_number: button.phone_number });
          if (button.type === 'URL') buttons.push({ type: 'URL', text: button.text, url: button.url });
        });
      }
      if (data.cards && data.cards?.length > 0) {
        const format = data.header?.format as Extract<WabaTemplateComponentHeaderFormat, 'IMAGE' | 'VIDEO'>;
        const carousel: WabaTemplateComponentCarousel = { type: 'CAROUSEL', cards: [] };
        data.cards.forEach(card => {
          carousel.cards.push({
            components: [
              { type: 'HEADER', format },
              { type: 'BODY', text: card.description },
              { type: 'BUTTONS', buttons }
            ]
           });
        });
        request.components.push(carousel);
      }
    }

    return request;
  };

  const  wabaTemplateConvertAction = (template: WabaTemplateInfo, action: WabaTemplateActions) => {
    const data: WabaTemplateState = {
      stage: 'basic',
      kind: 'message',
      custom_name: template.customName ?? template.name,
      category: template.category,
      allow_category_change: true,
      template_locale: action === 'copy' ? template.locale : '',
      add_security_recommendation: undefined,
      body: '',
      code_expiration_minutes: undefined,
      footer: undefined,
      header: undefined,
      buttons: undefined,
      cards: []
    };

    if (template.category === 'AUTHENTICATION') {
      data.kind = 'auth';
      template.components.forEach(component => {
        if (component.type === 'BODY') data.add_security_recommendation = component.addSecurityRecommendation ?? undefined;
        if (component.type === 'FOOTER') data.code_expiration_minutes = component.codeExpirationMinutes ?? undefined;
        if (component.type === 'BUTTONS') {
          const buttons: Array<WabaTemplateOtpButtonTap | WabaTemplateOtpButtonCopy> = [];
          component.buttons.forEach(button => {
            if (button.type === 'OTP') {
              buttons.push({
                type : button.otpType, text: button.text, autofill_text: button.autofillText,
                package_name: button.packageName, signature_hash: button.signatureHash
              });
            }
          });
          data.buttons = buttons;
        }
      });
    }

    if (template.category === 'MARKETING' || template.category === 'UTILITY') {
      template.components.forEach(component => {
        if (component.type === 'HEADER') data.header = ({ text: component.text, format: component.format });
        if (component.type === 'BODY') data.body = component.text;
        if (component.type === 'FOOTER') data.footer = component.text;
        if (component.type === 'BUTTONS') {
          const buttons: Array<
          | WabaTemplateComponentButtonsButtonUrl
          | WabaTemplateComponentButtonsButtonPhoneNumber
          | WabaTemplateComponentButtonsButtonQuickReply> = [];
          component.buttons.forEach(button => {
            if (button.type === 'QUICK_REPLY') buttons.push({ type: button.type, text: button.text });
            if (button.type === 'PHONE_NUMBER') buttons.push(
              { type: button.type, text: button.text, phone_number: button.phoneNumber });
            if (button.type === 'URL') buttons.push({ type: button.type, text: button.text, url: button.url });
          });
          data.buttons = buttons;
        }
        if (component.type === 'CAROUSEL') {
          data.kind = 'carousel';
          component.cards.forEach(card => {
            const description = card.components
              .find((component) => component.type === 'BODY') as WabaTemplateComponentBody;
              data.cards = [...(data.cards ? data.cards : []), { description: description.text }];
          });
          const fistComponent = component.cards[0].components;
          if (fistComponent) {
            fistComponent.forEach(component => {
              if (component.type === 'HEADER') data.header = { format: component.format };
              if (component.type === 'BUTTONS') {
                const buttons: Array<
                  | WabaTemplateComponentButtonsButtonUrl
                  | WabaTemplateComponentButtonsButtonPhoneNumber
                  | WabaTemplateComponentButtonsButtonQuickReply> = [];
                component.buttons.forEach(button => {
                  if (button.type === 'QUICK_REPLY') buttons.push({ type: button.type, text: button.text });
                  if (button.type === 'PHONE_NUMBER') buttons.push(
                    { type: button.type, text: button.text, phone_number: button.phoneNumber });
                  if (button.type === 'URL') buttons.push({ type: button.type, text: button.text, url: button.url });
                });
                data.buttons = buttons;
              }
            }) 
          }
        }
      });
    }

    return data;
  };

  const makeWabaTemplate = async (template: CreateWabaTemplateRequest) => {
    let result = false;

    await dispatch(createWabaTemplate(template))
      .then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });

    return result;
  };

  const removeWabaTemplate = (templateName: string, connectionId: number) => {
    dispatch(addAlertWithCustomText({ type: 'info', message: 'templates.waba_list.remove_template' }));
    dispatch(deleteWabaTemplate({
      company_id: companyId,
      connection_id: connectionId,
      template_name: templateName
    }));
  };

  const renameWabaTemplate = (templateName: string, locale: string, newName: string) => {
    dispatch(updateWabaTemplate({
      company_id: companyId,
      connection_id: connectionId,
      template_name: templateName,
      template_locale: locale,
      custom_name: newName
    }));
  };

  return {
    makeWabaTemplateRequest, makeWabaTemplate,
    wabaTemplateConvertAction, removeWabaTemplate, renameWabaTemplate };
}
