import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { connectionItemContext } from '../../utils/connections';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/trash.svg';
import ConnectionNameEdit from '../ConnectionNameEdit';
import './ConnectionCard.scss';


type ConnectionCardProps = {
  editNameOnly?: boolean;
  children?: React.ReactNode;
};

const ConnectionCard = ({ editNameOnly, children }: ConnectionCardProps) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { connection, onDelete, onEdit } = useContext(connectionItemContext);
  const [isEditName, setEditName] = useState<boolean>(false);

  const onEditHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    if (editNameOnly) {
      setEditName(true);
    } else if (onEdit) {
      onEdit(connection);
    } else {
      dispatch(push(`${url}/edit/${connection.id}`));
    }
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(connection);
  };

  const getSubtitle = () => {
    switch (connection.type) {
      case 'waba':
      case 'whatsapp':
      case 'telegram':
        return connection.params.phone;
      default:
        return null;
    }
  };

  return (
    <div className='connectionCard'>
      {isEditName
        ? <ConnectionNameEdit connection={connection} setEditName={setEditName} />
        : <p className="connectionCard__title">{connection.name}</p>
      }
      <p className="connectionCard__connectionId">{`# ${connection.id}`}</p>
      {getSubtitle() && <p className="connectionCard__subtitle">{getSubtitle()}</p>}
      {children}
      {isEditName
        ? <div className="connectionCard__buttonsGroup" />
        : (
          <div className="connectionCard__buttonsGroup">
            <WrapperPermission permission='connectionsEdit'>
              <button className="connectionCard__buttonsGroup_editButton" type="button" onClick={onEditHandler}>
                <EditIcon />
                {t('edit')}
              </button>
            </WrapperPermission>
            <WrapperPermission permission='connectionsDelete'>
              <button className="connectionCard__buttonsGroup_deleteButton" type="button" onClick={onDeleteHandler}>
                <TrashIcon />
                {t('delete')}
              </button>
            </WrapperPermission>
          </div>
        )
      }
    </div>
  );
};

export default ConnectionCard;
