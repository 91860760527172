import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { usePhoneSchema } from '../../../../../components/PhoneInput/utils';
import { countVariables, isValidUrl } from '../../../utils/waba';


export function useWabaTemplateMessageSchema() {
  const { t } = useTranslation();
  const phoneSchema = usePhoneSchema({ required: true });

  return yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    header: yup.object().shape({
      format: yup.string().required(),
      text: yup.string().when('format', {
        is: 'TEXT',
        then: yup.string().max(60, t('error.form.max_length', { limit: 60 })),
        otherwise: yup.string().nullable()
      }).test({
        name: 'header_text',
        test: (val, { createError }) => {
          if (val) {
            return countVariables(val) > 1
              ? createError({ message: t('templates.waba_list.form.variable_limit') }) : true;
          }
          return true;
        }
      })
    }),
    body: yup.string().required(t('error.form.empty_field')).max(1024, t('error.form.max_length', { limit: 1024 }))
      .test({
        name: 'body_text',
        test: (val, { createError }) => {
          if (val) {
            return countVariables(val) > 99
              ? createError({ message: t('templates.waba_list.form.variable_limit') }) : true;
          }
          return true;
        }
      }),
    footer: yup.string().max(60, t('error.form.max_length', { limit: 60 })),
    buttons: yup.array().of(yup.object().shape({
      type: yup.string().required(),
      text: yup.string().required(t('error.form.empty_field')).max(20, t('error.form.max_length', { limit: 20 })),
      url: yup.string().when('type', {
        is: 'URL',
        then: yup.string().required(t('error.form.empty_field')),
        otherwise: yup.string().nullable() }).test({ name: 'url',
          test: (val, { createError }) => {
            let message = '';
            if (val) {
              if (countVariables(val) > 1) {
                message = t('templates.waba_list.form.variable_limit');
              } else {
                let link = val;
                if (countVariables(val) === 1) {
                  link = val.replace(/(\{\{\d+\}\})?$/g, '');
                }
                if ((countVariables(link) > 0) || !isValidUrl(link)) {
                  message = t('error.form.url');
                }
              }
            }
            return message ? createError({ message }) : true;
          }
      }),
      phone_number: yup.string().when('type', {
        is: 'PHONE_NUMBER',
        then: phoneSchema.phone,
        otherwise: yup.string().nullable()
      })
    })).nullable().test({
      name: 'buttons_number',
      test: (val, { createError }) => {
        if (val) {
          const urlButtons = val.reduce((acc, curr) => acc + (curr.type === 'URL' ? 1 : 0), 0);
          const phoneButtons = val.reduce((acc, curr) => acc + (curr.type === 'PHONE_NUMBER' ? 1 : 0), 0);
          return (urlButtons > 2 || phoneButtons > 1)
            ? createError({ path: 'buttons', message: t('templates.waba_list.form.buttons.buttons_limit') }) : true;
        }
        return true;
      } })
  });
}
