import { useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WabaTemplateMessageData } from '../../../WabaTemplateProvider/utils';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import { useWabaTemplateMessageSchema } from '../../schemas';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import WabaTemplateBody from '../WabaTemplateBody';
import WabaTemplateButtons from '../WabaTemplateButtons';
import WabaTemplateFooter from '../WabaTemplateFooter';
import WabaTemplateHeader from '../WabaTemplateHeader';
import { ReactComponent as SmallLoader } from '../../../../../../assets/small-loader.svg';


function WabaTemplateMessage() {
  const { t } = useTranslation();
  const { wabaTemplateState, updateWabaTemplateState } = useContext(wabaTemplateContext);
  const { category, custom_name: customName, kind } = wabaTemplateState;
  const schema = useWabaTemplateMessageSchema();

  const methods = useForm<WabaTemplateMessageData>({ mode: 'all', resolver: yupResolver(schema) });

  useEffect(() => {
    if (wabaTemplateState) {
      methods.reset({
        custom_name: customName,
        header: wabaTemplateState.header === undefined ? { format: 'TEXT', text: '' } : wabaTemplateState.header,
        body: wabaTemplateState.body ?? undefined,
        footer: wabaTemplateState.footer ?? undefined,
        buttons: wabaTemplateState.buttons ?? undefined
      }, { keepDefaultValues: wabaTemplateState.header !== undefined });
    }
  }, []);

  const header = useWatch({ name: 'header', control: methods.control });
  const body = useWatch({ name: 'body', control: methods.control });
  const footer = useWatch({ name: 'footer', control: methods.control });
  const buttons = useWatch({ name: 'buttons', control: methods.control });

  useEffect(() => {
    updateWabaTemplateState({ type: 'preview', payload: methods.getValues() });
  }, [header, header?.format, header?.text, body, footer, buttons]);

  const onSubmit = (payload: WabaTemplateMessageData) => {
    updateWabaTemplateState({ type: 'message', payload });
  };

  return (
    <FormProvider {...methods}>
      <Input name='custom_name' label={t('templates.waba_list.form.name')} />
      <WabaTemplateHeader />
      <WabaTemplateBody />
      <WabaTemplateFooter />
      <WabaTemplateButtons category={category} kind={kind} />
      <div className='wabaTemplateForm__submit'>
        {wabaTemplateState.stage === 'create' && <SmallLoader />}
        <Button type='submit' color='orange' textType='regular' text={t('save')} onClick={methods.handleSubmit(onSubmit)}
          disabled={!methods.formState.isDirty || methods.formState.isSubmitting}  />
      </div>
    </FormProvider>
  );
}

export default WabaTemplateMessage;
