import { useContext, useEffect, useState } from 'react';
import './WabaPreview.scss';
import {
  WabaTemplateComponentBody, WabaTemplateComponentButtonsIn,
  WabaTemplateComponentFooter, WabaTemplateComponentHeader, WabaTemplateComponentCarouselIn,
} from '../../templatesTypes';
import {  getComponentText, parseWabaBodyText } from '../WabaTemplatesModeration/utils';
import { wabaModeration } from "../WabaTemplatesModeration/context";
import { ReactComponent as Arrow } from '../../../../assets/arrow-left.svg';
import PreviewHeader from './PreviewHeader';
import PreviewButtons from './PreviewButtons';
import PreviewBuble from './PreviewBuble';
import PreviewCarousel from './PreviewCarousel';


type Props = {
  header?: WabaTemplateComponentHeader;
  body?: WabaTemplateComponentBody;
  footer?: WabaTemplateComponentFooter;
  buttons?: WabaTemplateComponentButtonsIn;
  carousel?: WabaTemplateComponentCarouselIn;
};

const WabaPreview = ({ header, body, footer, buttons, carousel }: Props) => {
  const { arrayHeaderParams, arrayBodyParams, arrayCardParams } = useContext(wabaModeration);
  const [cardIndex, setCardIndex] = useState<number>(0);
  const previewButtons = carousel 
    ? carousel.cards[0]?.components.find((c) => c.type === 'BUTTONS') as WabaTemplateComponentButtonsIn
    : buttons;

  const onSlide = (step: number) => {
    if (!carousel) return;
    setCardIndex((prev) => {
      if (cardIndex + step >= 0 && cardIndex + step < carousel.cards.length) return cardIndex + step;
      return prev;
    });
  };

  useEffect(() => {
    if (!carousel) return;
    setCardIndex((prev) => {
      if (!carousel.cards[prev]) return 0;
      return prev;
    });
  }, [carousel]);

  return (
    <div className="wabaPreview">
      {carousel 
        ? (<>
          <PreviewBuble>
            {body && <p className="smallText">{parseWabaBodyText(getComponentText(arrayBodyParams, body))}</p>}
          </PreviewBuble>
          <PreviewBuble>
            <div className="wabaPreview__carousel">
              <button className={`leftArrow ${carousel.cards[cardIndex - 1] ? '' : 'disabled'}`}
                onClick={() => onSlide(-1)} >
                <Arrow />
              </button>
              <button className={`rightArrow ${carousel.cards[cardIndex + 1] ? '' : 'disabled'}`}
                onClick={() => onSlide(1)}>
                <Arrow />
              </button>
            </div>
            {carousel.cards[cardIndex]?.components.length > 0 &&
              <PreviewCarousel cardIndex={cardIndex} components={carousel.cards[cardIndex].components}
                arrayCardParams={arrayCardParams} />}
          </PreviewBuble>
        </>) : (<>
          <PreviewBuble>
            {header && <PreviewHeader header={header} arrayHeaderParams={arrayHeaderParams} />}
            {body && <p className="smallText">{parseWabaBodyText(getComponentText(arrayBodyParams, body))}</p>}
            {footer && <p className="footerText">{footer.text}</p>}
          </PreviewBuble>
        </>)
      }
      <div className="wabaPreview__buttons">
        {previewButtons && <PreviewButtons buttons={previewButtons.buttons} />}
      </div>
    </div>
  );
};

export default WabaPreview;
