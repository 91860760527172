import './OrderPlug.scss';
import { useTranslation } from 'react-i18next';
import orderLogo from '../../../../../assets/order-plug.png';


const OrderPlug = () => {
  const { t } = useTranslation();

  return (
    <div className='OrderPlug'>
      <img src={orderLogo} alt='order logo'/>
      <h1>{t('orders.title')}</h1>
      <p className='regularText'>{t('orders.text_null_one')}</p>
      <p className='regularText'>{t('orders.text_null_two')}</p>
    </div>
  );
};

export default OrderPlug;
