import axiosInstance from '../../../api/AxiosInstance';
import * as typ from './IntegrationTypes';

export const getIntegrationTypes = async (
  requestOptions: { language: string, companyId: number; }): Promise<typ.IntegrationType[]> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/integrations/types`, {
    params: { language: requestOptions.language },
  });

export const postIntegrationState = async (companyId: number): Promise<typ.IntegrationsState> =>
  await axiosInstance.post(`companies/${companyId}/integrations/state`);

export const getIntegrationStateInfo = async (
  requestOptions: { companyId: number, state_value: string; }): Promise<typ.IntegrationsStateInfo> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/integrations/state/${requestOptions.state_value}`);

export const getIntegrations = async (companyId: number): Promise<typ.Integrations> =>
  await axiosInstance.get(`companies/${companyId}/integrations/`);

export const getIntegrationItemFilteredAPI = async (requestOptions: {
  itemId: number;
  filter: 'available' | 'connected';
  companyId: number;
}): Promise<typ.IntegrationsFilter> => {
  const { companyId, itemId, filter } = requestOptions;
  return await axiosInstance.get(`companies/${companyId}/integrations/${itemId}/connections/?filter=${filter}`);
};

export const getIntegrationsItem =
  async (requestOptions: { companyId: number; itemId: number; }): Promise<typ.IntegrationsItem> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/integrations/${requestOptions.itemId}`);

export const getPipelines = async (requestOptions: typ.IntegrationRequest): Promise<typ.IntegrationsPipelines> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/amocrm/${requestOptions.integrationId}/pipelines`);

export const updateIntegrations = async (
  companyId: number,
  integrationId: number,
  requestOption: typ.IntegrationsUpdate
): Promise<typ.IntegrationsItem> =>
  await axiosInstance.patch(`companies/${companyId}/integrations/${integrationId}`, {
    name: requestOption.name,
    status: requestOption.status,
    type: requestOption.type,
    waba_amocrm: requestOption.wabaAmocrm,
    bepaid_amocrm: requestOption.bepaidAmocrm,
    whatsapp_amocrm: requestOption.whatsappAmocrm,
    retie_whatsappchatapi_amocrm: requestOption.retieWhatsAppChatApiAmoCrm,
    odnoklassniki_amocrm: requestOption.odnoklassnikiAmocrm,
    telegram_amocrm: requestOption.telegramAmocrm,
    modulbank_amocrm: requestOption.modulbankAmocrm,
    paykeeper_amocrm: requestOption.paykeeperAmocrm,
    sberbank_amocrm: requestOption.sberbankAmocrm,
    tinkoff_amocrm: requestOption.tinkoffAmocrm,
    telegram_bot_amocrm: requestOption.telegramBotAmocrm,
    telegram_bitrix: requestOption.telegramBitrix,
    whatsapp_bitrix: requestOption.whatsappBitrix,
    waba_bitrix: requestOption.wabaBitrix,
    telegram_bot_bitrix: requestOption.telegramBotBitrix,
    yookassa_amocrm: requestOption.yookassaAmocrm,
    create_fields: requestOption.createFields,
    write_first_conn: requestOption.writeFirstConn,
    amo_invoices: requestOption.amoInvoices,
    params: {
      scopes: requestOption.params?.scopes,
      reset_api_key: requestOption.params?.resetApiKey,
    },
    check_phones_enabled: requestOption.checkPhonesEnabled,
    handle_amo_dialog_events: requestOption.handleAmoDialogEvents,
    default_connection: requestOption.defaultConnection,
    extra: requestOption.extra,
    create_invoices: requestOption.createInvoices,
    sms_create_chat_enabled: requestOption.smsCreateChatEnabled,
  });

export const getIntegrationBitrixBindLink = async (requestOptions: {
  companyId: number;
  state: string;
  domain: string;
  app_code: string;
}): Promise<typ.BitrixBindLink> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`companies/${companyId}/bitrix/get_bind_link`, { ...rest });
};

export const getItegrationBitrixOpenLines =
  async (requestOptions: typ.IntegrationRequest): Promise<typ.IntegrationsPipelines> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/bitrix/${requestOptions.integrationId}/open_lines`);

export const postIntegrationAPI = async (requestOption: {
  companyId: number;
  data: typ.IntegrationAPISchema;
}): Promise<typ.IntegrationsItem> =>
  await axiosInstance.post(`companies/${requestOption.companyId}/integrations/`, requestOption.data);

export const deleteIntegration = async (requestOptions: {
  companyId: number;
  integrationId: number;
}): Promise<typ.IntegrationsItem> =>
  await axiosInstance.delete(`companies/${requestOptions.companyId}/integrations/${requestOptions.integrationId}`);

export const getAmoInvoiceStatusesFromIntegration = async (requestOptions: {
  integrationId: number;
  companyId: number;
}): Promise<typ.InvoiceStatuses> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/amocrm/${requestOptions.integrationId}/invoice_statuses`);

export const createAmoInvoiceStatuses = async (requestOptions: {
  integrationId: number;
  companyId: number;
  data: typ.InvoiceStatusesPost;
}): Promise<typ.InvoiceStatuses> => {
  const { companyId, integrationId, data } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/amocrm/${integrationId}/create_invoice_statuses`, data);
};
