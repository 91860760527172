import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RoleType } from '../../../../../../api/CompanyAPI';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import { ReactComponent as CrossIcon } from '../../../../../../assets/cross.svg';
import './RoleCreate.scss';


type RoleCreateProps = {
  roles: RoleType[];
  onClose: () => void;
  onCreate: (role: string) => void;
};

function RoleCreate({ roles, onCreate, onClose }: RoleCreateProps) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    roleName: yup.string().required(t('error.form.empty_field'))
  });
  
  const methods = useForm<{ roleName: string }>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const onSubmit = (data: { roleName: string }) => {
    if (roles.find(i => i.name === data.roleName)) {
      methods.setError('roleName', { type: 'custom', message: t('settings.users.user.duplicate_role') });
      return;
    }
    onCreate(data.roleName);
  };

  return (
    <div className='roleCreate'>
      <h3>{t('settings.users.user.create_role')}</h3>
      <div className='roleCreate__close' onClick={onClose}><CrossIcon /></div>
      <FormProvider {...methods}>
        <Input name='roleName' label={t('settings.users.user.role_name')} />
        <div className='roleCreate__buttons'>
          <Button type='submit' color='orange' textType='small' text={t('settings.users.user.create_role')}
            disabled={methods.formState.isSubmitting || !methods.formState.isDirty}
            onClick={methods.handleSubmit(onSubmit)} />
          <Button type='button' color='white' textType='small' text={t('cancel')} onClick={onClose} />
        </div>
      </FormProvider>
    </div>
  );
}

export default RoleCreate;
