import { useContext, useEffect, useState } from 'react';
import './ChatTemplateWabaCarousel.scss';
import { WabaTemplateCarousel } from '../../../../../../../Templates/templatesTypes';
import { configChatTemplateWabaFile } from '../utils';
import { replaceCardWabaParams } from '../../../../utils';
import { chatTemplatesFillContext } from '../../../../ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import { ReactComponent as ArrowIcon } from '../../../../../../../../assets/arrow-left.svg';
import ChatTemplateWabaButtons from '../ChatTemplateWabaButtons';
import ChatWabaTemplateFileType from '../../../ChatTemplateFill/components/ChatWabaTemplateFileType';


type Props = {
  carousel: WabaTemplateCarousel;
};

const ChatTemplateWabaCarousel = ({ carousel }: Props) => {
  const [cardIndex, setCardIndex] = useState<number>(0);
  const card = carousel.cards[cardIndex];
  const { arrayCardParams, cardFiles } = useContext(chatTemplatesFillContext);

  useEffect(() => {
    setCardIndex(0);
  }, [carousel]);

  const onChangeCard = (step: number) => {
    setCardIndex((prev) => {
      if (prev + step < carousel.cards.length && prev + step >= 0) {
        return prev + step;
      }
      return prev;
    });
  };

  if (!card) {
    return <></>;
  }

  return (
    <div className="chatTemplateWabaCarousel">
      <div className="chatTemplateWabaCarousel__content">
        <div className="chatTemplateWabaCarousel__content_header">
          {cardFiles && cardFiles[cardIndex] !== undefined
            ? <ChatWabaTemplateFileType type={card.header.format}
                attachmentFile={cardFiles[cardIndex] as File} />
            : (<>
              {card.header.format !== 'text' && (
                <div className="chatTemplateWabaCarousel__content_file">
                  {configChatTemplateWabaFile[card.header.format].icon}
                </div>
              )}
            </>)
          }
          {card.header.text && <p className="smallText semibold">{card.header.text}</p>}
        </div>
        <p className="smallText">{replaceCardWabaParams(arrayCardParams[cardIndex]?.params, card.body.text)}</p>
        <button className="back" onClick={() => onChangeCard(-1)} disabled={cardIndex === 0}>
          <ArrowIcon />
        </button>
        <button className="forward" onClick={() => onChangeCard(1)} disabled={cardIndex === carousel.cards.length - 1}>
          <ArrowIcon />
        </button>
      </div>
      <div className="chatTemplateWabaCarousel__buttons">
        <ChatTemplateWabaButtons buttons={card.buttons} />
      </div>
    </div>
  );
};

export default ChatTemplateWabaCarousel;
