import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { SubscriptionsSelector } from '../../views/Settings/Subscriptions/SubscriptionsSelector';
import UserSubscription from '../../views/Settings/Subscriptions/models/UserSubscription';
import { SubscriptionStatusEnum } from '../../views/Settings/Subscriptions/types';
import { MessageLoadingPayload, MessageRequest } from '../../views/Chat/ChatTypes';
import axiosInstance from '../../api/AxiosInstance';
import { useAppDispatch } from '../../store/store';
import { deleteUploadFileMessage, sendMessage } from '../../views/Chat/chatsSlice';
import { currentChatSelector } from '../../views/Chat/chatsSelector';
import { Subscription } from '../../views/Settings/Subscriptions/SubscriptionsTypes';
import { currentCompanySelector } from '../../views/Chat/companiesSelector';


export function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  componentHandleClick: (e: React.MouseEvent<HTMLElement>) => void,
  triggerButton?: React.RefObject<HTMLElement | SVGSVGElement>
): void {
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (ref.current && !ref.current?.contains(e.target as Node) &&
        (triggerButton === undefined ? true : !triggerButton.current?.contains(e.target as Node))
      ) {
        componentHandleClick(e as unknown as React.MouseEvent<HTMLElement>);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);
}

export const useOutsideClickForEmoji = (
  pickerTriggerButtonRef: React.RefObject<SVGSVGElement>,
  setPicker: React.Dispatch<React.SetStateAction<boolean>>,
  picker: boolean
) => {

  useEffect(() => {
    const pickerNode = document.querySelector('.emoji-mart');
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        pickerNode &&
        !pickerNode.contains(e.target as Node) &&
        pickerTriggerButtonRef.current &&
        !pickerTriggerButtonRef.current.contains(e.target as Node)
      ) {
        setPicker(false);
      }
    };
    if (picker && pickerNode) {
      document.body.addEventListener('mousedown', handleOutsideClick);
    }

    return () => document.body.removeEventListener('mousedown', handleOutsideClick);
  }, [picker]);
};

export const useQuery = () => new URLSearchParams(useLocation().search);

type ConnectionSubscriptionStatus = {
  connectionId: number | undefined;
  subscriptionId: number | undefined
 };

export const bannedSubscriptionStatus = [
  SubscriptionStatusEnum.unknown, SubscriptionStatusEnum.unpaid, SubscriptionStatusEnum.expired];

export const useConnectSubscriptionStatus = ({ connectionId, subscriptionId }: ConnectionSubscriptionStatus) => {
  const subscriptions = useSelector(SubscriptionsSelector);
  return useMemo(() => {
    let status = SubscriptionStatusEnum.unknown;
    if (connectionId) {
      let subscription: Subscription | undefined;
      if (subscriptionId) {
        subscription = subscriptions?.find((item) => item.id === subscriptionId);
      } else {
        subscription = subscriptions?.find((item) => item.connections.includes(connectionId));
      }
      if (subscription) {
        const userSubscription = new UserSubscription(subscription);
        if (userSubscription.connections.includes(connectionId)) {
          status = userSubscription.status;
        }
      }
    }
    return status;
  }, [connectionId, subscriptionId, subscriptions]
)};

export type FilterType<T> = {
  items: Array<T>;
  attribute: Extract<keyof T, string>;
};

export const useFilter = <T>({ items, attribute }: FilterType<T>) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const filteredItems = filterValue
    ? items.filter((item) => RegExp(String(filterValue), 'i').test(String(item[attribute])))
    : items;
  return { filterValue, setFilterValue, filteredItems };
};

export const useFileUpload = async (
  message: MessageRequest & MessageLoadingPayload,
  setUploadStatusData: React.Dispatch<React.SetStateAction<{ loaded: number; total: number | undefined; }>>,
  setIsFileLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { id: companyId } = useSelector(currentCompanySelector);
  const currentChat = useSelector(currentChatSelector);
  const { messageId } = message;
  const dispatch = useAppDispatch();

  useMemo(async () => {
    const blob = await fetch(message.fileUploaded)
      .then((file) => file.blob())
      .then((blobFile) => new File([blobFile], message.fileName));

    const formData = new FormData();
    formData.append('file', blob);
    const { url } = await axiosInstance
      .post<File, { url: string }>(`companies/${companyId}/messaging/files/`, formData, {
        onUploadProgress: (progressEvent) => {
          setUploadStatusData({
            loaded: progressEvent.loaded,
            total: progressEvent.total!,
          });
        },
      })
      .then((res) => {
        setUploadStatusData((prevState) => ({
          ...prevState,
          loaded: prevState.total || 0,
        }));
        return res;
      });

    setIsFileLoaded(true);
    if (companyId) {
      dispatch(sendMessage({
        messageType: message.fileType!,
        caption: message.caption,
        url,
        chatId: currentChat,
        companyId
      })).then(() => {
        dispatch(deleteUploadFileMessage(messageId));
      });
    }
  }, [messageId, companyId])
};

const getMatches = (query: string): boolean => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
};

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();
    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
    } else {
      matchMedia.addListener(handleChange);
    }

    return () => {
      if (matchMedia.removeEventListener) {
        matchMedia.removeEventListener('change', handleChange);
      } else {
        matchMedia.removeListener(handleChange);
      }
    }

  }, [query, handleChange]);

  return matches;
}

export function useMobile () {
  return useMediaQuery('(max-width: 800px)');
}

export function useAccessMobile () {
  const isMobile = useMobile();
  const params = useRouteMatch<{ companyId: string; tab?: string }>('/companies/:companyId/:tab');
  const settingsParams = useRouteMatch<{ companyId: string; tab?: string }>('/companies/:companyId/settings/:tab');
  const listMobileComponents = ['subscriptions', 'company_settings', 'chats'];
  return isMobile && (listMobileComponents.includes(settingsParams?.params.tab!) || listMobileComponents.includes(params?.params.tab!));
}

export const getRolledUp = () => {
  const isRolledState = localStorage.getItem('isRolledUp');
  if (isRolledState !== null) return JSON.parse(isRolledState);
  return false;
};

export function UpdateRolledUp () {
  const [isRolledUp, setIsRolledUp] = useState<boolean>(getRolledUp());

  useEffect(() => {
    setIsRolledUp(getRolledUp())
  }, []);

  useEffect(() => {
    const openSalesState = JSON.stringify(isRolledUp);
    localStorage.setItem('isRolledUp', openSalesState);
  }, [isRolledUp]);

  return { isRolledUp, setIsRolledUp };
}
