import React, { useEffect, useRef, useState } from 'react';
import './VideoPlayer.scss';
import play from "../../assets/play-button.svg";


type Props = {
  videoSource: string;
  onPlayYaMetrika?: () => boolean;
}

const VideoPlayer = ({ videoSource, onPlayYaMetrika }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState<boolean>(false);

  useEffect(() => {
    if (!videoRef.current) return;
    if (isPlay) {
      videoRef.current.play();
    } else videoRef.current.pause();
  }, [isPlay]);

  const onPlaying = (e: React.MouseEvent<HTMLVideoElement, MouseEvent> | React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    setIsPlay(!isPlay);
    if (onPlayYaMetrika) {
      onPlayYaMetrika();
    }
  }

  const onEnded = () => {
    if (videoRef.current) {
      setIsPlay(false);
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div className='videoPlayer'>
      <video src={videoSource} controls ref={videoRef} onClick={(e) => onPlaying(e)} onEnded={onEnded} />
      <div className="video-controls">
        {!isPlay && <div className='pauseBackground' />}
        {!isPlay && <img alt="play" src={play} onClick={(e) => onPlaying(e)} />}
      </div>
    </div>
  );
};

export default VideoPlayer;
