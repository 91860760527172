import { NichesType, RolesType } from "../modals/utils/arrays";

export const companyOnboardingSteps = [
  'watch_intro_video', 'choose_role', 'choose_niche', 'choose_crm', 'add_connection',
  'add_integration', 'setup_notifications', 'remind_test_payment'
] as const;

export const connectionsOnboarding = [
  'whatsapp', 'telegram', 'waba', 'telegram_bot', 'odnoklassniki',
  'tinkoff', 'modulbank', 'paykeeper', 'bepaid', 'yookassa'] as const;

export const connectionsMessengerOnboarding = [
  'whatsapp', 'telegram', 'waba', 'telegram_bot'] as const;

export type ConnectionsMessengerOnboarding = typeof connectionsMessengerOnboarding[number];

export type ConnectionsOnboarding = typeof connectionsOnboarding[number];

export type CompanyOnboardingSteps = typeof companyOnboardingSteps[number];

export type CompanyOnboardingStepResult = {
  value: string;
  customValue?: string;
};

export type CompanyOnboardingStep = {
	isFinished: boolean;
	type: CompanyOnboardingSteps;
  result?: CompanyOnboardingStepResult;
};

export type CompanyOnboarding = {
	fromPartner: boolean;
  isCancelled: boolean;
  isFinished: boolean;
	steps: CompanyOnboardingStep[];
};

type GenericOnboardingStepUpdate<T, B extends CompanyOnboardingSteps> = {
  is_finished: boolean;
  type: B;
  result?: T;
};

export type CompanyOnboardingStepResultUpdate = {
  value: string;
  custom_value?: string;
};

type ChooseRoleResult = CompanyOnboardingStepResultUpdate;
type ChooseNicheResult = CompanyOnboardingStepResultUpdate;
type ChooseCRMResult = CompanyOnboardingStepResultUpdate;
type AddConnectionResult = {
  value: ConnectionsOnboarding;
};

type WatchVideoStepUpdate = GenericOnboardingStepUpdate<undefined, 'watch_intro_video'>;
type ChooseRoleStepUpdate = GenericOnboardingStepUpdate<ChooseRoleResult, 'choose_role'>;
type ChooseNicheStepUpdate = GenericOnboardingStepUpdate<ChooseNicheResult, 'choose_niche'>;
type ChooseCRMStepUpdate = GenericOnboardingStepUpdate<ChooseCRMResult, 'choose_crm'>;
type AddConnectionStepUpdate = GenericOnboardingStepUpdate<AddConnectionResult, 'add_connection'>;
type AddIntegrationStepUpdate = GenericOnboardingStepUpdate<undefined, 'add_integration'>;
type NotificationStepUpdate = GenericOnboardingStepUpdate<undefined, 'setup_notifications'>;
type RemindTestPaymentStepUpdate = GenericOnboardingStepUpdate<undefined, 'remind_test_payment'>;

type CompanyOnboardingStepUpdate =
  WatchVideoStepUpdate
  | ChooseRoleStepUpdate
  | ChooseNicheStepUpdate
  | ChooseCRMStepUpdate
  | AddConnectionStepUpdate
  | AddIntegrationStepUpdate
  | NotificationStepUpdate
  | RemindTestPaymentStepUpdate;

export type CompanyOnboardingUpdate = {
  company_id: number;
  is_cancelled?: boolean;
	steps: CompanyOnboardingStepUpdate[];
};

export type QuestioningType = 'ROLE_AND_NICHE';

export type RoleAndIndustryUserType = {
  role: RolesType;
  custom_role?: string;
  niche: NichesType;
  custom_niche?: string;
};

export type AvailableQuestioning = {
  availableFormTypes: (QuestioningType | null)[];
};

export type QuestioningCreate = {
  type: QuestioningType;
  company_id: number;
  is_cancelled: boolean;
  params: RoleAndIndustryUserType | null;
};
