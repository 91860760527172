import { FC, RefObject, useReducer } from "react";
import { guideContext, guideDispatchContext, GuideContext, GuideAction, initialState } from "../guide";


function reducer(state: GuideContext, action: GuideAction) {
  switch (action.type) {
    case 'setElement': {
      return {
        elementRef: action.payload,
        style: {
          top: action.payload?.current ? action.payload.current.getBoundingClientRect().top : 0,
          left: action.payload?.current ? action.payload.current.getBoundingClientRect().left : 0,
          height: action.payload?.current ? action.payload.current.getBoundingClientRect().height : 0,
          width: action.payload?.current ? action.payload.current.getBoundingClientRect().width : 0
        },
        setup: !!action.payload?.current
      };
    }
    default:
      return state;
  }
}

const GuideProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setElementRef = (elementRef: RefObject<HTMLDivElement> | null) =>{
    dispatch({ type: 'setElement', payload: elementRef });
  };

  const actionsDispatch = { setElementRef };

  return (
    <guideContext.Provider value={state}>
      <guideDispatchContext.Provider value={actionsDispatch}>
        {children}
      </guideDispatchContext.Provider>
    </guideContext.Provider>
  );
};

export default GuideProvider;
