import './IntegrationsList.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { integrationsListSelector, integrationsTypesSelector } from '../../integrationSelectors';
import { currentCompanySelector, onboardingGuideSelector } from '../../../../Chat/companiesSelector';
import { getIntegrationsList, getIntegrationsTypes } from '../../integrationSlice';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import IntegrationsAvailable from './IntegrationsAvailable';
import IntegrationsInstalled from './IntegrationsInstalled';
import GuideWrapper from '../../../../../components/Guide/GuideWrapper';

const statusWeights = {
  enabled: 1,
  disabled: 0,
};

function IntegrationsList() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const integrationsTypes = useSelector(integrationsTypesSelector);
  const integrations = useSelector(integrationsListSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  useEffect(() => {
    if (company) {
      dispatch(getIntegrationsList(company.id));
      dispatch(getIntegrationsTypes({
        companyId: company.id,
        language: i18n.language
      }));
    }
  }, [company]);

  const listIntegrations = () => {
    return integrations?.map((item) => ({
      id: item.id,
      name: item.name,
      status: item.status,
      logoUrl: integrationsTypes.find((i) => item.type === i.type)?.logoUrl || '',
    }))
    .sort((a, b) => {
      return (statusWeights[b.status] || 0) - (statusWeights[a.status] || 0);
    });
  };

  return (
    <div className={`integrationsList ${onboardingGuideOn && 'guideOn'}`}>
      {integrations && <IntegrationsInstalled integrations={listIntegrations()} />}
      <WrapperPermission permission='integrationsCreate'>
        <>
          <GuideWrapper />
          <IntegrationsAvailable integrations={integrationsTypes} />
        </>
      </WrapperPermission>
    </div>
  );
}

export default IntegrationsList;

