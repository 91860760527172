import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { onboardingGuideSelector } from '../../../views/Chat/companiesSelector';
import { guideContext } from '../guide';
import GuideSelector from '../GuideSelector';


function GuideWrapper() {
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const { setup } = useContext(guideContext);

  if (!onboardingGuideOn || !setup) return null;

  return (<><GuideSelector /></>);
}

export default GuideWrapper;
