import React, { createContext, useState } from 'react';
import { MessageTemplate, WabaTemplate } from '../../../../Templates/templatesTypes';


type ChatTemplatesProviderProps = {
  children: React.ReactNode;
  onClose: () => void;
  setMessageTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
};

export type ChatTemplatesContext = {
  customTemplate: MessageTemplate | undefined;
  setCustomTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
  wabaTemplate: WabaTemplate | undefined;
  setWabaTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | undefined>>;
  setMessageTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
  onClose: () => void;
};

export const chatTemplatesContext = createContext<ChatTemplatesContext>({} as ChatTemplatesContext);

function ChatTemplatesProvider({ children, onClose, setMessageTemplate }: ChatTemplatesProviderProps) {
  const [customTemplate, setCustomTemplate] = useState<MessageTemplate>();
  const [wabaTemplate, setWabaTemplate] = useState<WabaTemplate>();

  const valueContext = {
    customTemplate, setCustomTemplate,
    wabaTemplate, setWabaTemplate,
    onClose, setMessageTemplate,
  };

  return <chatTemplatesContext.Provider value={valueContext}>{children}</chatTemplatesContext.Provider>;
}

export default ChatTemplatesProvider;
