import React, { useContext } from 'react';
import './InvoicesSubscriptions.scss';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { CompanyPayment } from '../../../../PartnerCabinetTypes';
import { currencyFormat } from '../../../../../Settings/CompanySettings/currency';
import { clientViewContext } from '../../../ulils/Context';
import { ClientInvoiceItems } from "../helper";


type Props = {
  invoices: CompanyPayment;
};

const InvoicesSubscriptions = ({ invoices }: Props) => {
  const { t, i18n } = useTranslation();
  const { selectedSubscriptions } = useContext(clientViewContext);

  return (
    <div className="selectedSubscriptions">
      <h3>{t('partner_cabinet.clients.create.subscriptions')}</h3>
      <div className="selectedSubscriptions__container">
        <div className="selectedSubscriptions__container_title">
          <span>{t('subscriptions.invoice.sub_list.name')}</span>
          <span>{t('subscriptions.invoice.sub_list.price')}</span>
          <span>{t('subscriptions.invoice.sub_list.discount')}</span>
          <span>{t('subscriptions.invoice.sub_list.amount')}</span>
        </div>
        <div className="selectedSubscriptions__container_content">
          {ClientInvoiceItems(invoices.summary.items, selectedSubscriptions, i18n.language).map((item) =>
            <div className="subscriptionsItem" key={nanoid()}>
                <span className="regularText" data-title={t('subscriptions.invoice.sub_list.name')}>
                  {t(`subscriptions.invoice.title_item.${item.itemType}`)} {t(`subscriptions.types.${item.type}`)}
                  <h6>{`${t(`subscriptions.invoice.sub_list.${item.serviceType}`)}${item.volume}`}</h6>
                </span>
              <span data-title={t('subscriptions.invoice.sub_list.price')}>{item.amount}</span>
              <span data-title={t('subscriptions.invoice.sub_list.discount')}>{item.discount}</span>
              <span data-title={t('subscriptions.invoice.sub_list.amount')} className="amountStyle">
                  {item.total}
                </span>
            </div>
          )}
        </div>
      </div>
      <div className="selectedSubscriptions__container_bottom">
        <h2>{t('subscriptions.invoice.total_amount')}:</h2>
        <div className="amountStyle">{currencyFormat(invoices.amount, invoices.currency, i18n.language)}</div>
      </div>
    </div>
  );
};

export default InvoicesSubscriptions;
