import './WabaTemplateCards.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { WabaTemplateCarouselData } from '../../../WabaTemplateProvider/utils';
import Button from '../../../../../../components/Button';
import Counter from '../Counter';
import HeaderFormat from '../HeaderFormat';
import WabaTemplateCard from '../WabaTemplateCard';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


const MAX_CARDS = 10;

function WabaTemplateCards() {
  const { t } = useTranslation();
  const methods = useFormContext<WabaTemplateCarouselData>();
  const fieldsMethods = useFieldArray<WabaTemplateCarouselData>({ control: methods.control, name: 'cards' });
  const { errors } = methods.formState;

  const onAddCard = () => {
    if (fieldsMethods.fields.length < MAX_CARDS) {
      fieldsMethods.append({ description: '' });
    }
  };

  return (
    <div className='wabaTemplateCards'>
      <div className='wabaTemplateCards__header'>
        <h3>{t('templates.waba_list.form.cards.title')}</h3>
        <Button type='button' color='orange' textType='small' text={t('add')}
          image={<PlusIcon />} onClick={onAddCard} disabled={fieldsMethods.fields.length >= MAX_CARDS} />
      </div>
      <HeaderFormat name='header.format' formats={['IMAGE', 'VIDEO']} />
      <div className='wabaTemplateCards__cards'>
        {fieldsMethods.fields?.map((card, index) => (
          <WabaTemplateCard key={card.id} id={index} {...methods} {...fieldsMethods} />
        ))}
      </div>
      <ErrorMessage errors={errors} name='cards'
        render={({ message }) => <p className="smallText error">{message}</p>} />
      <Counter counter={fieldsMethods.fields?.length || 0} maximum={MAX_CARDS}
        text={t('templates.waba_list.form.card.card', { count: MAX_CARDS })} />
    </div>
  );
}

export default WabaTemplateCards;
