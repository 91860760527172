import './WabaTemplateAction.scss';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WabaTemplateActions } from '../../../../utils/waba';
import { WabaTemplateStatus } from '../../../../templatesTypes';
import { useTooltip } from '../../../../../../components/Modals/ToolTip/utils';
import ToolTip from '../../../../../../components/Modals/ToolTip';
import { ReactComponent as DotsIcon } from '../../../../../../assets/dot-more.svg';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';
import { ReactComponent as ApproveIcon } from '../../../../../../assets/play-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../../../assets/cross.svg';
import { ReactComponent as SubmitIcon } from '../../../../../../assets/search.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/prohibit.svg';
import { ReactComponent as LangIcon } from '../../../../../../assets/earth.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/copy.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/edit.svg';


type WabaTemplateActionProps = {
  status: string;
  actions: WabaTemplateActions[];
  onAction?: (action: WabaTemplateActions) => void;
};

const statusIcons: Record<WabaTemplateStatus, JSX.Element> = {
  'APPROVED': <ApproveIcon />,
  'REJECTED': <CrossIcon />,
  'SUBMITTED': <SubmitIcon />,
  'UNKNOWN': <ErrorIcon />
};

const actionsItems: Record<WabaTemplateActions, JSX.Element> = {
  'language': <LangIcon />,
  'copy': <CopyIcon />,
  'delete': <TrashIcon />,
  'rename': <EditIcon />
};

function WabaTemplateAction({ status, actions, onAction }: WabaTemplateActionProps) {
  const { t } = useTranslation();
  const { viewPopupOn: onDetailEnter, viewPopupOff: onDetailLeave, viewPopup: viewPopupDetail } = useTooltip();
  const { viewPopupOn: onInfoEnter, viewPopupOff: onInfoLeave, viewPopup: viewPopupInfo } = useTooltip();
  const infoRef = useRef<SVGSVGElement>(null);
  const detailRef = useRef<SVGSVGElement>(null);

  const onActionClick = (action: WabaTemplateActions) => {
    return () => {
      onDetailLeave();
      if (onAction) onAction(action);
    };
  };

  return (<>
    {viewPopupInfo && (
      <ToolTip refElement={infoRef} tail>
        <div className='wabaTemplateAction__status smallText'>
          <p>{t(`templates.waba_list.status.${status}.info`)}</p>
          <div className={`wabaTemplateAction__status_icon ${status.toLowerCase()}`}>
            {statusIcons[status]}<span>{t(`templates.waba_list.status.${status}.title`)}</span>
          </div>
        </div>
      </ToolTip>
    )}
    {viewPopupDetail && (
      <ToolTip refElement={detailRef} modal onClose={onDetailLeave}>
        <div className='wabaTemplateAction__actions smallText'>
          <h3>{t('templates.waba_list.actions.title')}</h3>
          <div className='wabaTemplateAction__actions_close' onClick={onDetailLeave}><CrossIcon /></div>
          {actions && actions.length > 0 && actions.map((action) => (
            <div className={`wabaTemplateAction__actions_item ${action}`} key={action} onClick={onActionClick(action)}>
              {actionsItems[action]} <span>{t(`templates.waba_list.actions.${action}`)}</span>
            </div>
          ))}
        </div>
      </ToolTip>
    )}
    <div className='wabaTemplateAction'>
      <InfoIcon ref={infoRef} className='wabaTemplateAction__info' onMouseEnter={onInfoEnter} onMouseLeave={onInfoLeave} />
      {actions && actions.length > 0 &&
        <DotsIcon ref={detailRef} className='wabaTemplateAction__dots' onClick={onDetailEnter} />}
    </div>
  </>);
}

export default WabaTemplateAction;
