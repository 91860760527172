import { useEffect, useState } from 'react';
import './Tags.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { tagsSelector } from './tagsSelector';
import { getTagsList } from './tagsSlice';
import { Tag } from './TagsAPI';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg'
import loader from '../../../assets/grid.svg';
import tagsDude from '../../../assets/TagsDude.png';
import TagsItem from './TagItem';
import CreateAndEditTag from './CreateAndEditTag';
import Button from '../../../components/Button';


const Tags = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const tags = useSelector(tagsSelector);
  const permissionCreate = useHasPermission('tagsCreate');
  const [isEdit, setIsEdit] = useState(false);
  const [idEditTag, setIdEditTag] = useState<number>();
  const [isDisable, setIsDisable] = useState(false);
  const [tagItem, setTagItem] = useState<Tag>();

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(getTagsList({ company_id: currentCompany.id }));
    }
  }, [currentCompany]);

  useEffect(() => {
    if (tags) {
      setTagItem(tags.find((item: Tag) => item.id === idEditTag));
    }
  }, [idEditTag, isEdit]);

  if (tags === null) {
    return (
      <div className="loaderContainer">
        <img src={loader} alt=""/>
      </div>
    );
  }

  return (
    <div className="tagsContainer">
      {isDisable && <CreateAndEditTag
        setIsDisable={setIsDisable}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        tagItem={tagItem}
        setTagItem={setTagItem}
      />}
      {tags.length > 0 ? (
        <>
          <div className="tagsContainer_header">
            <h1>{t('settings.tag.title')}</h1>
            {permissionCreate &&
              <Button text={t('settings.tag.create_button')} color='orange' image={<PlusIcon />}
                onClick={() => setIsDisable(true)} textType='regular' />
            }
          </div>
          <div className="tagsContainer__table">
            <div className="tagsContainer__table_caption">
                <p className='smallText semibold'>{t('settings.tag.text_table1')}</p>
                <p className='smallText semibold'>{t('settings.tag.text_table2')}</p>
            </div>
            <div className="tagsContainer__table_tags">
              {tags.map((tag: Tag) => (
                <TagsItem
                  key={tag.id}
                  tag={tag}
                  setIsEdit={setIsEdit}
                  setIdEditTag={setIdEditTag}
                  setIsDisable={setIsDisable}
                />
              ))}
            </div>
          </div>
        </>) : (
        <div className="tagsContainer_noneTag">
          <img className="tagsContainer_noneTag_dude" src={tagsDude} alt="tag dude"/>
          <h1>{t('settings.tag.title')}</h1>
          {permissionCreate ? (
            <>
              <p>{t('settings.tag.noTag')}</p>
              <Button text={t('settings.tag.create_button')} color='orange' image={<PlusIcon />}
                onClick={() => setIsDisable(true)} textType='regular' />
            </>) : (
              <p>{t('settings.tag.noTagCreate')}</p>
            )
          }
        </div>
      )}
    </div>
  );
};

export default Tags;
