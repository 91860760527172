import { useEffect, useState } from 'react';
import './WabaStart.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../store/store';
import { fetchWabaIceBreakers, updateWabaIceBreakers } from '../../../../connectionsSlice';
import WabaStartList from './WabaStartList';
import WabaStartPreview from './WabaStartPreview';
import Button from '../../../../../../../components/Button';
import Loader from '../../../../../../../components/Loader/Loader';


type SubmitSchema = {
  ice_breakers: Array<{ value: string }>;
};

const WabaStart = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const url = useRouteMatch<{ companyId: string; connectionId: string }>(
    '/companies/:companyId/settings/connections/waba/edit/:connectionId'
  );
  const [clicked, setClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const valueSchema = {
    value: yup.string().required(t('empty_field')).max(40, `${t('error.form.max_length', { limit: 40 })}`)
  };

  const schema = yup.object({
    ice_breakers: yup.array().when({
      is: (array: Array<{ value: string }>) => array.length === 1 && array[0].value.length === 0,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.of(yup.object().shape(valueSchema)),
    })
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ice_breakers: [{ value: '' }] },
  });
  const iceBreakers = methods.watch('ice_breakers');
  const emptyIceBreakers = iceBreakers.length === 1 && iceBreakers[0].value.length === 0;

  useEffect(() => {
    setLoading(true);
    dispatch(fetchWabaIceBreakers({
      company_id: Number(url?.params.companyId),
      waba_connection_id: Number(url?.params.connectionId),
      })
    ).then((res) => {
      if (res.payload && typeof res.payload === 'object') {
        const iceData: Array<{ value: string }> = [];
        res.payload.iceBreakers.map((i) => iceData.push({ value: i }));
        if (iceData.length === 0) {
          methods.setValue('ice_breakers', [{ value: '' }]);
        } else methods.setValue('ice_breakers', iceData);
      }
      if (res.meta.requestStatus) {
        setLoading(false);
      }
    });
  }, []);

  const onSubmit = (data: SubmitSchema) => {
    setClicked(true);
    const iceData = data.ice_breakers.map((i) => i.value);
    dispatch(
      updateWabaIceBreakers({
        company_id: Number(url?.params.companyId),
        waba_connection_id: Number(url?.params.connectionId),
        ...(emptyIceBreakers ? { ice_breakers: [] } : { ice_breakers: iceData }),
      })
    ).then(() => setClicked(false));
  };

  if (loading) {
    return (
      <div className="wabaStart">
        <Loader />
      </div>
    );
  }

  return (
    <div className="wabaStart">
      <FormProvider {...methods}>
        <div className='wabaStart__content'>
          <WabaStartList />
          <WabaStartPreview />
        </div>
        <Button type="submit" color="orange" textType="regular" text={t('save_edit')}
          onClick={methods.handleSubmit(onSubmit)}
          disabled={
          iceBreakers.length > 1
            ? !methods.formState.isDirty || clicked
            : methods.formState.isDirty && iceBreakers[0].value.length === 0
          }
        />
      </FormProvider>
    </div>
  );
};

export default WabaStart;
