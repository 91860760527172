import { useContext } from 'react';
import './InvoicesView.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PartnerPaymentsSelector } from '../../../PartnerCabinetSelector';
import { invoicesUploadContext } from '../../types';
import InfiniteScroll from 'react-infinite-scroll-component';
import InvoicesList from '../../../components/InvoicesList';


function InvoicesView () {
  const { t } = useTranslation();
  const invoices = useSelector(PartnerPaymentsSelector);
  const { hasMore, onUpload  } = useContext(invoicesUploadContext);

  return (
    <div className='invoicesView'>
      <div className='invoicesView__list'>
        <div className='invoicesView__list_header smallText semibold'>
          <span>{t('partner_cabinet.payments.number')}</span>
          <span>{t('partner_cabinet.payments.amount')}</span>
          <span>{t('partner_cabinet.payments.status')}</span>
        </div>
          <InfiniteScroll
            next={onUpload}
            hasMore={hasMore}
            inverse={false}
            scrollableTarget='scrollList'
            loader={null}
            dataLength={invoices.length}
          >
          <div className='invoicesView__list_content' id='scrollList'>
            <InvoicesList invoices={invoices} showCompany />
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default InvoicesView;
