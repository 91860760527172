import './WabaTemplatesPlug.scss';
import { useTranslation } from 'react-i18next';
import { WrapperPermission } from '../../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../../components/Button';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';
import plugImage from '../../../../../../assets/Saly-31.png';


type WabaTemplatesPlugProps = {
  name?: string;
  phone?: string;
  block?: boolean;
  onClick?: () => void;
};

function WabaTemplatesPlug({ name, phone, block=false, onClick }: WabaTemplatesPlugProps) {
  const { t } = useTranslation();

  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <div className='wabaTemplatesPlug'>
      <img src={plugImage} alt="plug img" />
      <h1>{name}</h1>
      <p className='regularText'>{phone}</p>
      <WrapperPermission permission='templatesCreate'>
        <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
          image={<PlusIcon />} onClick={onClickHandler} disabled={block} />
      </WrapperPermission>
    </div>
  )
};

export default WabaTemplatesPlug;
