import { useAppDispatch } from '../../../../../store/store';
import { addPaymentRequisite, updatePaymentRequisite } from '../../SubscriptionsSlice';
import { PaymentRequisite, PostPaymentRequisite, UpdatePaymentRequisite } from '../../SubscriptionsTypes';
import { createContext } from 'react';


type RequisiteItemContextType = {
  onEdit?: (requisite: PaymentRequisite) => void;
  onDelete?: (requisite: PaymentRequisite) => void;
};

export const subRequisiteItemContext = createContext<RequisiteItemContextType>({});

export function useSubscriptionsRequisiteRequest() {
  const dispatch = useAppDispatch();

  const makeRequisite = async (requisite: PostPaymentRequisite, editData?: UpdatePaymentRequisite) => {
    let result = false;

    if (editData) {
      await dispatch(updatePaymentRequisite(editData)).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    } else {
      await dispatch(addPaymentRequisite(requisite)).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    }

    return result;
  };

  return { makeRequisite };
}
