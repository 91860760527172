import { useState } from 'react';
import './WabaEdit.scss';
import { useTranslation } from 'react-i18next';
import { Tabs } from '../../../../../../components/SwitchTabs/SwitchTabs';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import WabaCommon from './WabaCommon';
import WabaStart from "./WabaStart";
import SwitchTabs from '../../../../../../components/SwitchTabs';
import WabaProfile from './WabaProfile';


const WabaEdit = () => {
  const { t } = useTranslation();
  const [switchPosition, setSwitchPosition] = useState<string>('common');

  const switchTabs = () => {
    const tabs: Tabs = [];
    ['common', 'profile', 'start'].forEach((item) => {
      tabs.push({ tabId: item, tabName: t(`connections.waba.sections.${item}`) }) });
    return tabs;
  };

  const onSwitchPosition = (e: React.MouseEvent<HTMLButtonElement>) => {
    const switcherValue = e.currentTarget.id;
    setSwitchPosition(switcherValue);
  };

  return (
    <div className="wabaEdit">
      <GoBackWrapper title={t('edit')}>
        <SwitchTabs tabs={switchTabs()} selectedTab={switchPosition} onSelectHandler={onSwitchPosition} />
        {switchPosition === 'common' && <WabaCommon />}
        {switchPosition === 'start' && <WabaStart />}
        {switchPosition === 'profile' && <WabaProfile />}
      </GoBackWrapper>
    </div>
  );
};

export default WabaEdit;
