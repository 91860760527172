import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleType } from '../../../../../../api/CompanyAPI';
import { getNameRole } from '../../utlity';
import Select from '../../../../../../components/Select';
import './RoleSelector.scss';


type RoleSelectorProps = {
  setChangeRole: React.Dispatch<React.SetStateAction<number | null>>;
  roles: RoleType[];
  currentRole: RoleType | null;
  isOwner: boolean;
};

type Named = {
  id: number;
  name: string;
};

function RoleSelector({ currentRole, setChangeRole, roles, isOwner }: RoleSelectorProps) {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<Named>();
  
  const optionsRoles = roles.filter(i => i.id !== currentRole?.id)
    .map(m => ({ id: m.id, name: getNameRole(m.name, isOwner, t) }));

  useEffect(() => {
    const manager = roles.find(i => i.name === 'MANAGER');
    setSelectedRole(manager ? { id : manager.id, name: manager.name } : undefined);
    setChangeRole(manager?.id ?? null);
  }, []);

  const selectRole = (role: Named) => {
    setSelectedRole(role);
    setChangeRole(role.id);
  };

  return (
    <div className='roleSelector'>
      <Select items={optionsRoles} labelKey='name' caption={t('settings.users.user.choose_role')}
        value={selectedRole?.name ?? ''} onChoose={selectRole} />
    </div>
  );
}

export default RoleSelector;
