import { useTranslation } from 'react-i18next';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import './SwitchRequest.scss';


type SwitchRequestProps = {
  onAction: () => void;
  onClose: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SwitchRequest({ onAction, onClose, setIsOpen }: SwitchRequestProps) {
  const { t } = useTranslation();

  return (
    <ActionDialog onAction={onAction} onCancel={onClose} setIsOpen={setIsOpen}
      title={t('settings.integrations.switch_request.title')}
      message={t('settings.integrations.switch_request.message')}
      action={t('settings.integrations.switch_request.action')} cancel={t('settings.integrations.switch_request.save')}
    />
  );
}

export default SwitchRequest;
