import { useState } from 'react';
import './WabaTemplateCard.scss';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReturn, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { WabaTemplateCarouselData } from '../../../WabaTemplateProvider/utils';
import { countVariables, SelectPosition } from '../../../../utils/waba';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';
import Button from '../../../../../../components/Button';
import Counter from '../Counter';
import Textarea from '../Textarea';


type WabaTemplateCardProps = {
  id: number;
} & UseFormReturn<WabaTemplateCarouselData> & UseFieldArrayReturn<WabaTemplateCarouselData>;

const MAX_LENGTH_DESCRIPTION = 160;

function WabaTemplateCard({ id, ...props }: WabaTemplateCardProps) {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<WabaTemplateCarouselData>();
  const [position, setPosition] = useState<SelectPosition>();

  const text = useWatch({ name: `cards.${id}.description` });

  const onVariableClick = () => {
    const text = getValues(`cards.${id}.description`);
    const variables = countVariables(text);
    setValue(`cards.${id}.description`, [text.slice(0, position?.end), `{{${variables + 1}}}`, text.slice(position?.end)].join(''),
      { shouldDirty: true, shouldValidate: true });
  };

  const onDelete = () => {
    props.remove(id);
  };

  return (
    <div className='wabaTemplateCard'>
      <div className='wabaTemplateCard__header'>
        <h3>{t('templates.waba_list.form.cards.description')}</h3>
        <Button type='button' color='white' textType='small' text={t('templates.waba_list.form.variable')}
          image={<PlusIcon />} onClick={onVariableClick} />
        <Button id='delete' type='button' color='white' textType='small' text='' image={<DeleteIcon />}
          onClick={onDelete} />
      </div>
      <Textarea name={`cards.${id}.description`} className='regularText' autoComplete='off' setPosition={setPosition}
        placeholder={t('templates.waba_list.form.cards.placeholder')} maxLength={MAX_LENGTH_DESCRIPTION} />
      <Counter counter={text?.length || 0} maximum={MAX_LENGTH_DESCRIPTION}
        text={'templates.waba_list.form.character.character'} />
    </div>
  );
}

export default WabaTemplateCard;
