import React, { useRef } from 'react';
import './ModalPaymentInvoiceLink.scss';
import '../../ChatBill.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { push } from "connected-react-router";
import { ReactComponent as CloseIcon } from "../../../../../../assets/cross.svg";
import { ReactComponent as CheckIcon } from "../../../../../../assets/checkmark.svg";
import { updateIsPageBill, updateIsVisibleLinkInvoice } from '../../../../billSlice';
import { isVisibleLinkInvoiceSelector, urlSelector } from '../../../../billSelector';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { useOutsideClick } from "../../../../../../components/utils/hooks";
import Button from '../../../../../../components/Button';


const ModalPaymentInvoiceLink = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const IsVisibleLinkInvoice = useSelector(isVisibleLinkInvoiceSelector);
  const urlInvoices = useSelector(urlSelector);
  const url = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
    '/companies/:companyId/chats/:contactId/:chatId'
  );

  const onClickClose = () => {
    dispatch(updateIsVisibleLinkInvoice(false));
  };

  const onClickNewInvoice = () => {
    dispatch(updateIsVisibleLinkInvoice(false));
    dispatch(push(`${url?.url}/bill`));
    dispatch(updateIsPageBill(true));
  };

  const onCopy = () => {
    if (urlInvoices) {
      navigator.clipboard.writeText(urlInvoices);
      dispatch(addAlertWithCustomText({ message: t('settings.users.modal_text') }));
    }
  };

  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);
  useOutsideClick(wrapperRef, onClickClose, triggerButtonRef);

  if (IsVisibleLinkInvoice && urlInvoices) {
    return (
      <div className='modalPaymentInvoiceLink' ref={wrapperRef}>
        <div className='modalPaymentInvoiceLink__header'>
          <CloseIcon className='closeIcon' ref={triggerButtonRef} onClick={onClickClose} />
          <CheckIcon className='checkIcon' />
        </div>
        <div className='modalPaymentInvoiceLink__content'>
          <h3>{t('chats.bill.modal_invoice.link_title')}</h3>
          <div className="inputContainer">
            <div className="inputContainer__wrapper">
              <input value={urlInvoices} disabled className="inputContainer__input" />
            </div>
          </div>
        </div>
        <div className='modalPaymentInvoiceLink__bottom'>
          <Button text={t('chats.bill.modal_invoice.copy_button')} color='orange' textType='small' onClick={onCopy} />
          <Link to={`${url?.url}/bill`}>
            <Button text={t('chats.bill.modal_invoice.new_bill_button')} color='white' onClick={onClickNewInvoice}
                    textType='small' />
          </Link>
        </div>
      </div>
    );
  } return <></>
};

export default ModalPaymentInvoiceLink;
