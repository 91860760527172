import './ClientStatus.scss';
import { useTranslation } from "react-i18next";
import { PartnerCompany } from "../../../../PartnerCabinetTypes";
import { getFormatClientSubscriptionDateStatus, getCompanySubscriptionStatus } from '../../../ulils/utils';


type Props = {
  client: PartnerCompany;
}

const ClientStatus = ({ client }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="clientStatus">
      <p className={`status ${getCompanySubscriptionStatus(client)}`}>
        {t(`partner_cabinet.clients.statuses.${getCompanySubscriptionStatus(client)}`)}
      </p>
      <p className="smallText">
        {getFormatClientSubscriptionDateStatus(i18n.language, client)}
      </p>
    </div>
  );
};

export default ClientStatus;
