import { createContext, Dispatch, useState } from 'react';
import { useWabaTemplateCreate, WabaTemplateAction, WabaTemplatePreview, WabaTemplateState } from './utils';


type WabaTemplateProviderProps = {
  children: React.ReactNode
};

export type WabaTemplateContext = {
  connectionId: number;
  setConnectionId: React.Dispatch<React.SetStateAction<number>>;
  wabaTemplateState: WabaTemplateState;
  updateWabaTemplateState: Dispatch<WabaTemplateAction>;
  previewWabaTemplate?: WabaTemplatePreview;
};

export const wabaTemplateContext = createContext<WabaTemplateContext>({} as WabaTemplateContext);

function WabaTemplateProvider({ children }: WabaTemplateProviderProps) {
  const [connectionId, setConnectionId] = useState<number>(0);
  const { wabaTemplateState, updateWabaTemplateState, preview: previewWabaTemplate } = useWabaTemplateCreate();

  return (
    <wabaTemplateContext.Provider
      value={{ connectionId, setConnectionId, wabaTemplateState, updateWabaTemplateState, previewWabaTemplate }} >
      {children}
    </wabaTemplateContext.Provider>
  );
}

export default WabaTemplateProvider;
