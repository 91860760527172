import { createContext } from "react";
import { Channels, PartnerCompany, PartnerNotificationChannel } from '../PartnerCabinetTypes';
import { ReactComponent as WhatsAppIcon } from '../../../assets/logo-whatsapp-no-fill.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/logo-telegram.svg';
import { useSelector } from "react-redux";
import { PartnerNotifications } from "../PartnerCabinetSelector";


export const getSortCompanies = (partnerCompanies: PartnerCompany[]) => {
  const sortCompanies: PartnerCompany[] = [...partnerCompanies];

  sortCompanies.sort((a, b) => {
    return b.id - a.id;
  });

  return sortCompanies;
};

export type ChannelIcons = Record<Channels, React.FC<React.SVGProps<SVGSVGElement>>>;

export const channelsIcons: ChannelIcons = {
  whatsapp: WhatsAppIcon,
  telegram: TelegramIcon
};

export const channelsNames: Record<Channels, string> = {
  whatsapp: 'WhatsApp',
  telegram: 'Telegram'
};

type PartnerNotificationsContext = {
  openNotification: boolean;
  setOpenNotification: React.Dispatch<React.SetStateAction<boolean>>;
  channel: PartnerNotificationChannel | null;
  setChannel: React.Dispatch<React.SetStateAction<PartnerNotificationChannel | null>>;
  channelType: Channels | null;
  setChannelType: React.Dispatch<React.SetStateAction<Channels | null>>;
};

export const partnerNotifications = createContext<PartnerNotificationsContext>({} as PartnerNotificationsContext);

const maxChannels = 2;

export function useAvailableChannels(channelType: Channels) {
  const partnerNotifications = useSelector(PartnerNotifications);

  return maxChannels - partnerNotifications.reduce(
    (acc, channel) => {acc += channel.type === channelType ? 1 : 0; return acc; }, 0);
}
