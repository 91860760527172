import { useTranslation } from 'react-i18next';
import Switch from '../../../../../Settings/Switch';
import './HeaderFormat.scss';


type HeaderFormatProps = {
  name: string;
  formats: string[];
};

function HeaderFormat({ name, formats }: HeaderFormatProps) {
  const { t } = useTranslation();

  const formatTabs = formats.map((format) => ({
      id: format, payload: format, name: t(`templates.waba_list.form.header.formats.${format}`)
  }));

  return (
    <div className="headerFormat">
      <Switch name={name} items={formatTabs} />
    </div>
  );
}

export default HeaderFormat;
