import { ReactComponent as ConnectionsIcon } from '../../../assets/left-right.svg';
import { ReactComponent as UsersIcon } from '../../../assets/users.svg';
import { ReactComponent as SubscriptionsIcon } from '../../../assets/subscription.svg';
import { ReactComponent as IntegrationsIcon } from '../../../assets/puzzle.svg';
import { ReactComponent as TagsIcon } from '../../../assets/tag.svg';
import { ReactComponent as SettingCompanyIcon } from '../../../assets/briefcase.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/mail.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/ghost.svg';
import { ConnectionsType } from '../Connections/ConnectionsTypes';
import Connections from '../Connections/components/Connections';
import Users from '../Users/Users';
import Subscriptions from '../Subscriptions/Subscriptions';
import IntegrationsRoute from '../Integrations/IntegrationsRoute';
import Tags from '../Tags/Tags';
import Notifications from '../Notifications';
import Profile from '../Profile';
import BePaidEdit from '../Connections/ConnectionsViews/BePaid/BePaidEdit';
import TinkoffEdit from '../Connections/ConnectionsViews/Tinkoff/TinkoffEdit';
import SberEdit from '../Connections/ConnectionsViews/Sber/SberEdit';
import OdnoklassnikiEdit from '../Connections/ConnectionsViews/Odnoklassniki/OdnoklassnikiEdit/OdnoklassnikiEdit';
import ModulBankEdit from '../Connections/ConnectionsViews/ModulBank/ModulBankEdit';
import PayKeeperEdit from '../Connections/ConnectionsViews/Paykeeper/PayKeeperEdit';
import WabaEdit from '../Connections/ConnectionsViews/Waba/WabaEdit';
import TelegramBotForm from '../Connections/ConnectionsViews/Telegram/TelegramBot/TelegramBotForm';
import TelegramEdit from '../Connections/ConnectionsViews/Telegram/TelegramPersonal/TelegramEdit';
import CompanySettings from '../CompanySettings/CompanySettings';
import YookassaEdit from '../Connections/ConnectionsViews/Yookassa/YookassaEdit';
import WhatsAppEdit from '../Connections/ConnectionsViews/WhatsApp/WhatsAppEdit';



export const listOfTabsInSettings = [
  {
    tab: 'connections',
    ComponentIcon: ConnectionsIcon,
    title: 'settings.settings_list.connections',
    permission: 'connections_view',
    Component: Connections,
    url: 'settings/connections'
  },
  {
    tab: 'members',
    ComponentIcon: UsersIcon,
    title: 'settings.settings_list.users',
    permission: 'members_view',
    Component: Users,
    url: 'settings/members'
  },
  {
    tab: 'subscriptions',
    ComponentIcon: SubscriptionsIcon,
    title: 'settings.settings_list.subscriptions',
    permission: 'subscriptions_view',
    Component: Subscriptions,
    url: 'settings/subscriptions'
  },
  {
    tab: 'integrations',
    ComponentIcon: IntegrationsIcon,
    title: 'settings.settings_list.integrations',
    permission: 'integrations_view',
    Component: IntegrationsRoute,
    url: 'settings/integrations',
    common: true
  },
  {
    tab: 'tags',
    ComponentIcon: TagsIcon,
    title: 'settings.settings_list.tags',
    permission: 'tags_view',
    Component: Tags,
    url: 'settings/tags'
  },
  {
    tab: 'company_settings',
    ComponentIcon: SettingCompanyIcon,
    title: 'settings.settings_list.company_settings',
    permission:'company_settings_view',
    Component: CompanySettings,
    url: 'settings/company_settings'
  },
  {
    tab: 'notifications',
    ComponentIcon: NotificationIcon,
    title: 'settings.settings_list.notifications',
    permission: 'notifications_view',
    Component: Notifications,
    url: 'settings/notifications'
  },
  {
    tab: 'profile',
    ComponentIcon: ProfileIcon,
    title: 'settings.settings_list.profile',
    permission: 'profile',
    Component: Profile,
    url: 'settings/profile'
  },
];

export type ConnectionEdit = {
  path: string;
  Component: () => JSX.Element | null;
  name: ConnectionsType;
};

export const listConnectionsEdit: ConnectionEdit[] = [
  { path: '/connections/bePaid/edit/:connectionId', Component: BePaidEdit, name: 'bepaid' },
  { path: '/connections/tinkoff/edit/:connectionId', Component: TinkoffEdit, name: 'tinkoff' },
  { path: '/connections/sber/edit/:connectionId', Component: SberEdit, name: 'sberbank' },
  { path: '/connections/odnoklassniki/edit/:connectionId', Component: OdnoklassnikiEdit, name: 'odnoklassniki' },
  { path: '/connections/modulbank/edit/:connectionId', Component: ModulBankEdit, name: 'modulbank' },
  { path: '/connections/paykeeper/edit/:connectionId', Component: PayKeeperEdit, name: 'paykeeper' },
  { path: '/connections/waba/edit/:connectionId', Component: WabaEdit, name: 'waba' },
  { path: '/connections/telegram_bot/edit/:connectionId', Component: TelegramBotForm, name: 'telegram_bot' },
  { path: '/connections/telegram/edit/:connectionId', Component: TelegramEdit, name: 'telegram' },
  { path: '/connections/yookassa/edit/:connectionId', Component: YookassaEdit, name: 'yookassa' },
  { path: '/connections/whatsapp/edit/:connectionId', Component: WhatsAppEdit, name: 'whatsapp' }
];
