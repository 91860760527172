import {
  WabaTemplateComponentCarouselHeader,
  WabaTemplateComponentHeader, WabaTemplateComponentHeaderCreateRequest
} from '../../../templatesTypes';
import { configWabaTemplateFile, getComponentText, ParamsType } from '../../WabaTemplatesModeration/utils';
import './PreviewHeader.scss';


type PreviewHeaderProps = {
  header: WabaTemplateComponentHeader | WabaTemplateComponentHeaderCreateRequest | WabaTemplateComponentCarouselHeader;
  arrayHeaderParams: ParamsType[];
};

function PreviewHeader({ header, arrayHeaderParams }: PreviewHeaderProps) {
  return (<>
    {header.format === 'TEXT'
    ? (
      <p className="smallText semibold">{getComponentText(arrayHeaderParams, header)}</p>
    ) : (
      <div className="wabaPreview__body_preview">{configWabaTemplateFile[header.format].icon}</div>
    )}
  </>);
}

export default PreviewHeader;
