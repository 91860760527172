import './NotificationCardEvents.scss';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ALL_CHANNEL_EVENTS, NotificationChannel, NotificationChannelEvents } from '../../NotificationsTypes';
import Switcher from '../../../../../components/Switcher';


export type Props = {
  channel: NotificationChannel;
  onToggle: (event: NotificationChannelEvents) => void;
};

const NotificationCardEvents = ({ channel, onToggle }: Props) => {
  const { t } = useTranslation();
  const hasPermission = useHasPermission('notificationsEdit');

  return (
    <div className='notificationCardEvents'>
      {ALL_CHANNEL_EVENTS.map((event) => (
        <Switcher key={event} toggled={channel.params.events.includes(event)} onToggle={() => onToggle(event)}
          lable={t(`settings.notifications.switches.${event}`)} disabled={!hasPermission} />
      ))}
    </div>
  );
};

export default NotificationCardEvents;
