import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chatTemplatesFillContext } from './ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import { uploadFile } from '../../ChatAPI';
import { MessageTemplateWabaCarouselParams, MessageWabaTemplate } from '../../ChatTypes';
import { sendWabaTemplate, updateWabaLoader } from '../../chatsSlice';
import { chatTemplatesContext } from './ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesMobileContext } from './ChatTemplatesMobileProvider/ChatTemplatesMobileProvider';
import { currentCompanySelector } from '../../companiesSelector';
import { useMobile } from '../../../../components/utils/hooks';


export const useSubmitChatWabaTemplate = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const currentCompany = useSelector(currentCompanySelector);
  const { wabaTemplate, onClose } = useContext(chatTemplatesContext);
  const { currentStep, setCurrentStep } = useContext(chatTemplatesMobileContext);
  const {
    file, headerParams, bodyParams, arrayButtonParams, arrayCardParams, arrayCardButtonsParams, cardFiles
  } = useContext(chatTemplatesFillContext);

  const header = wabaTemplate?.components.header;

  const onSubmitChatWabaTemplate = async (setIsError?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const hasEmptyHeaderParams = headerParams.some((item) => item.value === '');
    const hasEmptyBodyParams = bodyParams.some((item) => item.value === '');
    const hasEmptyHeaderFile = header && header.format !== 'text' && !file;
    const hasEmptyButtonParams = arrayButtonParams.some((item) => item.value === '');
    const hasEmptyCardParams = arrayCardParams.some((item) => item.params.some((param) => param.value === ''));
    const hasEmptyCardButtons = arrayCardButtonsParams.some((item) => item.params.some((button) => button.value === ''));
    const hasEmptyCardFile = cardFiles ? cardFiles.includes(undefined) : false;

    const error = hasEmptyHeaderParams || hasEmptyBodyParams || hasEmptyHeaderFile || hasEmptyButtonParams
      || hasEmptyCardParams || hasEmptyCardButtons || hasEmptyCardFile;
    setIsError && setIsError(true);
    if (error) return;

    if (isMobile && currentStep === 'fill') {
      setCurrentStep('finish');
      return;
    }

    const headerItems = headerParams.map((item) => ({ text: item.value }));
    const bodyItems = bodyParams.map((item) => item.value);
    const buttonUrlItems = arrayButtonParams.map((item) => item.value);

    dispatch(updateWabaLoader(!!file));
    const headerUrl = file &&
      (await uploadFile({ companyId: currentCompany.id, file }).then((res) => {
        dispatch(updateWabaLoader(false));
        return res.url;
      }));

    let cardItems: MessageTemplateWabaCarouselParams[] | undefined;
    if (cardFiles) {
      cardItems = [];
      dispatch(updateWabaLoader(true));
      const requests: Array<Promise<{ url: string }>> = [];
      cardFiles.forEach(async (file, index) => {
        if (file) {
          requests.push(uploadFile({ companyId: currentCompany.id, file }))
        }
      });
      const responses = await Promise.all(requests).catch(() => []);
      dispatch(updateWabaLoader(false));
      responses.forEach((res, index) => {
        cardItems?.push({
          header_url: res.url ?? '',
          body_params: arrayCardParams[index].params.map((item) => item.value),
          button_params: arrayCardButtonsParams[index].params.map((item) => item.value)
        });
      });
    }

    if (wabaTemplate) {
      const params: MessageWabaTemplate = {
        template_locale: wabaTemplate.locale,
        template_name: wabaTemplate.name,
        ...(headerItems.length > 0 ? { header_params: headerItems } : {}),
        ...(headerUrl ? { header_params: [{ url: headerUrl }] } : {}),
        ...(bodyItems.length > 0 ? { body_params: bodyItems } : {}),
        ...(buttonUrlItems && buttonUrlItems.length > 0 ? { button_params: buttonUrlItems } : {}),
        ...(cardItems ? { carousel_media_card_params: cardItems } : {}),
      };
      dispatch(sendWabaTemplate({
        companyId: currentCompany.id,
        waba_template: { ...params },
      }));
      onClose();
    }
  }

  return onSubmitChatWabaTemplate;
}
