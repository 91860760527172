import React from "react";
import './GuidePanel.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../../assets/arrow-left.svg';
import { ReactComponent as Document } from '../../../assets/template.svg';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';


type Props = {
  step: number;
  title: string;
  guideOn: (e: React.MouseEvent<HTMLElement>) => void;
  disable?: boolean;
  complete?: boolean;
  documentation?: string;
  subtitle?: string;
};

const GuidePanel = ({ step, title, guideOn, disable, complete, documentation, subtitle }: Props) => {
  const { t } = useTranslation();
  const getStyles = () => ['complete', 'disable'].filter((_, index) => [complete, disable][index]).join(' ').trim();

  const onDocumentation = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(documentation, '_blank');
  };

  return (
    <div className={`guidePanel ${getStyles()}`} onClick={guideOn}>
      <div className='guidePanel__number'>
        {step}
        {complete && <CheckIcon />}
      </div>
      <div>
        <div className="guidePanel__title">{title}</div>
        {documentation && (
          <button className="guidePanel__documentation" type='button' onClick={onDocumentation}>
            <Document />{t('company_onboarding.guides.docs')}
          </button>
        )}
        <p className='guidePanel__subtitle'>{subtitle}</p>
      </div>
      <Arrow className="guidePanel__arrow" />
    </div>
  );
};

export default GuidePanel;
