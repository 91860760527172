import { useEffect, useState } from 'react';
import './WabaCommon.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { disableWabaCatalog, updateConnection } from '../../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../../Chat/companiesSelector';
import { ConnectionWabaParams, WabaConnection } from '../../../../../../../api/CompanyAPI';
import { FacebookCatalogStatus, WabaCatalogStatus } from '../../../../ConnectionsTypes';
import { EditData, useWabaEditSchema, wabaCatalogStausIcons } from '../../utils';
import { useAppSelector } from '../../../../../../../store/store';
import Input from '../../../../../../../components/Input';
import SwitchControl from '../../../../../../../components/SwitchControl';
import Switcher from '../../../../../../../components/Switcher';
import ActionDialog from '../../../../../../../components/Modals/ActionDialog';
import SidePanel from '../../../../../../../components/Modals/SidePanel';
import WabaCatalogs from '../../WabaCatalogs';
import Button from '../../../../../../../components/Button';


const getIcon = (status: FacebookCatalogStatus) => {
  const Icon = wabaCatalogStausIcons[status];
  if (Icon) return <Icon />;
  return null;
};

function WabaCommon() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const { connectionId } = useParams<{ connectionId: string }>();
  const allConnections = useAppSelector((state) => state.connections.connectionsStore);
  const catalogStatuses = useAppSelector((state) => state.connections.wabaCatalogsStatus);
  const [connection, setConnection] = useState<WabaConnection>();
  const [clicked, setClicked] = useState<boolean>(false);
  const [catalogStatus, setCatalogStatus] = useState<WabaCatalogStatus>();
  const [showRequest, setShowRequest] = useState<boolean>(false);
  const [showAddCatalog, setShowAddCatalog] = useState<boolean>(false);
  const wabaSchema = useWabaEditSchema();
  const methods = useForm<EditData>({ mode: 'onChange', resolver: yupResolver(wabaSchema) });

  useEffect(() => {
    const itemConnection = allConnections.waba.find((item) => item.id === Number(connectionId));
    if (itemConnection) {
      setConnection(itemConnection as WabaConnection);
      if (catalogStatuses) setCatalogStatus(catalogStatuses[itemConnection.id]);
      methods.setValue('name', itemConnection.name);
      const params = itemConnection.params as ConnectionWabaParams;
      methods.setValue('waba_auto_templates_enabled', params.wabaAutoTemplatesEnabled);
    }
  }, [allConnections, catalogStatuses]);

  const onSave = (data: EditData) => {
    if (connection) {
      setClicked(true);
      dispatch(updateConnection({
        companyId,
        itemId: connection.id,
        name: data.name,
        type: connection.type,
        params: { waba_auto_templates_enabled: data.waba_auto_templates_enabled }
      }));
    }
  };

  const onDisable = () => {
    if (connection) dispatch(disableWabaCatalog({
      company_id: companyId,
      waba_connection_id: connection.id
    }));
    setShowRequest(false);
  };

  const toggleCatalog = () => {
    if (catalogStatus?.status !== 'connected') {
      setShowAddCatalog(true);
    } else if (catalogStatus?.status === 'connected') {
      setShowRequest(true);
    }
  };

  if (!connection) {
    return <></>;
  }

  return (<>
    <FormProvider {...methods}>
      {showRequest &&
        <ActionDialog action={t('disable')} cancel={t('cancel')} onAction={onDisable}
                      setIsOpen={setShowRequest} background='light'
                      title={t('connections.waba.catalogs.disable.title')}
                      message={t('connections.waba.catalogs.disable.message')}/>
      }
      {showAddCatalog && connection &&
        <SidePanel setIsOpen={setShowAddCatalog}>
          <WabaCatalogs connection={connection} setShow={setShowAddCatalog}/>
        </SidePanel>
      }
      <p>{t('connections.connection_number')}: #{connection.id}</p>
      <p>{connection.params.phone}</p>
      <Input type='text' label={t('connections.connection_name')} name='name'/>
      <h3>{t('connections.waba.catalogs.caption')}</h3>
      <Switcher lable={t('connections.waba.catalogs.connect')}
                onToggle={toggleCatalog} toggled={catalogStatus?.status === 'connected'}/>
      <SwitchControl label={t('connections.waba.catalogs.template')} name='waba_auto_templates_enabled'
                     info={t('connections.waba.catalogs.info_template')}/>
      {catalogStatus &&
        <div className={`wabaEdit__catalog ${catalogStatus.status}`}>
          {getIcon(catalogStatus.status)}
          <p>
            {t(`connections.waba.catalogs.status.${catalogStatus.status}`)}
            {catalogStatus.status === 'connected' && <span>:&nbsp;{catalogStatus.payload}</span>}
          </p>
        </div>
      }
      <div className={`wabaEdit__footer ${clicked ? 'clicked' : ''}`}>
        <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                onClick={methods.handleSubmit(onSave)} disabled={!methods.formState.isDirty}/>
      </div>
    </FormProvider>
  </>);
}

export default WabaCommon;
