import axiosInstance from '../../../api/AxiosInstance';
import * as typ from './NotificationsTypes';


export const getNotificationsChannelsAPI = async (
  requestOption: typ.NotificationChannelRequest): Promise<typ.GetNotificationChannelsType> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/notifications/channels/`);

export const getNotificationsChannelAPI = async (
  requestOption: typ.NotificationChannelUpdateRequest): Promise<typ.NotificationChannel> => {
  const { companyId, channelId } = requestOption;
  return axiosInstance.get(`/companies/${companyId}/notifications/channels/${channelId}`);
};

export const createNotificationsChannelsAPI = async (
  requestOption: typ.NotificationChannelCreate): Promise<typ.NotificationCreateResponse> => {
  const { companyId, ...rest } = requestOption;
  return axiosInstance.post(`/companies/${companyId}/notifications/channels/`, rest);
};

export const updateNotificationsChannelAPI = async (
  requestOption: typ.NotificationChannelUpdate): Promise<typ.NotificationChannel> => {
  const { companyId, channelId, ...rest } = requestOption;
  return axiosInstance.put(`/companies/${companyId}/notifications/channels/${channelId}`, rest);
};

export const deleteNotificationsChannelsAPI = async (
  requestOption: typ.NotificationChannelUpdateRequest): Promise<void> =>
  axiosInstance.delete(`/companies/${requestOption.companyId}/notifications/channels/${requestOption.channelId}`);
