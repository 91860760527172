import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import CatalogsForm from './components/CatalogsForm';
import CatalogsPlug from './components/CatalogsPlug';
import CatalogsView from './components/CatalogsView';
import SidePanel from '../../../components/Modals/SidePanel';
import UserCatalog from './models/UserCatalog';
import { ICatalog } from '../SalesAPI';
import { SalesCatalogsSelector, SalesLoadSelector } from '../SalesSelector';
import { fetchCatalogs, setSalesLoad } from '../SalesSlice';
import { catalogsUploadContext } from '../types';
import loader from '../../../assets/grid.svg';
import { useAppSelector } from '../../../store/store';


const CATALOGS_LIMIT = 25;

const getUserCatalogs = (catalogs: ICatalog[], companyId: number): UserCatalog[] =>
  catalogs.map(catalog => new UserCatalog(catalog.id, catalog.name, catalog.productsCount, companyId));

const Catalogs = () => {
  const dispatch = useDispatch();
  const params = useRouteMatch<{ companyId: string}>('/companies/:companyId');
  const catalogs = useSelector(SalesCatalogsSelector);
  const load = useSelector(SalesLoadSelector);
  const hasMore = useAppSelector((state) => state.sales.hasMore);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(setSalesLoad(true));
      dispatch(fetchCatalogs({ companyId: Number(params.params.companyId), limit: 6 }));
    }
  }, [params?.params.companyId]);

  const createCatalog = () => {
    setOpenCreateForm(!openCreateForm);
  };

  const onUpload = () => {
    if (params?.params.companyId) {
      dispatch(fetchCatalogs(
        { companyId: Number(params.params.companyId), offset: catalogs.length, limit: CATALOGS_LIMIT }));
    }
  };

  return (
    <>
      {load ? (<div className="load"><img src={loader} alt='loading...' /></div>) :
      (<>
        {openCreateForm &&
          <SidePanel setIsOpen={setOpenCreateForm}>
            <CatalogsForm setFormOpen={setOpenCreateForm}/>
          </SidePanel>
        }
        {catalogs.length === 0
          ? <CatalogsPlug createCatalog={createCatalog} />
          : (
          <catalogsUploadContext.Provider value={{ hasMore, onUpload }}>
            <CatalogsView createCatalog={createCatalog}
              catalogs={getUserCatalogs(catalogs, Number(params?.params.companyId))} />
          </catalogsUploadContext.Provider>)
        }
      </>)}
    </>
  );
};

export default Catalogs;
