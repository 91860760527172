import React, { createContext, useState } from 'react';
import { MessageWabaTemplate } from '../../../ChatTypes';
import { IndexedParams, ParamsType } from "../components/ChatTemplateFill/utils";


type ChatTemplatesFillProviderProps = {
  children: React.ReactNode;
};

export type ChatTemplatesFillContext = {
  fillTemplate: boolean;
  setFillTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  headerParams: ParamsType[];
  setHeaderParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  bodyParams: ParamsType[];
  setBodyParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayButtonParams: ParamsType[];
  setArrayButtonParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayCardParams: IndexedParams[];
  setArrayCardParams: React.Dispatch<React.SetStateAction<IndexedParams[]>>;
  arrayCardButtonsParams: IndexedParams[];
  setArrayCardButtonsParams: React.Dispatch<React.SetStateAction<IndexedParams[]>>;
  cardFiles: Array<File | undefined> | undefined;
  setCardFiles: React.Dispatch<React.SetStateAction<Array<File | undefined> | undefined>>;
  filledTemplate: MessageWabaTemplate | undefined;
  setFilledTemplate: React.Dispatch<React.SetStateAction<MessageWabaTemplate | undefined>>;
};

export const chatTemplatesFillContext = createContext<ChatTemplatesFillContext>({} as ChatTemplatesFillContext);

const ChatTemplatesFillProvider = ({ children }: ChatTemplatesFillProviderProps) => {
  const [fillTemplate, setFillTemplate] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [headerParams, setHeaderParams] = useState<ParamsType[]>([]);
  const [bodyParams, setBodyParams] = useState<ParamsType[]>([]);
  const [arrayButtonParams, setArrayButtonParams] = useState<ParamsType[]>([]);
  const [arrayCardParams, setArrayCardParams] = useState<IndexedParams[]>([]);
  const [arrayCardButtonsParams, setArrayCardButtonsParams] = useState<IndexedParams[]>([]);
  const [cardFiles, setCardFiles] = useState<Array<File | undefined> | undefined>();
  const [filledTemplate, setFilledTemplate] = useState<MessageWabaTemplate>();

  const valueContext = {
    fillTemplate, setFillTemplate,
    file, setFile,
    headerParams, setHeaderParams,
    bodyParams, setBodyParams,
    arrayButtonParams, setArrayButtonParams,
    arrayCardParams, setArrayCardParams,
    arrayCardButtonsParams, setArrayCardButtonsParams,
    cardFiles, setCardFiles,
    filledTemplate, setFilledTemplate,
  };

  return <chatTemplatesFillContext.Provider value={valueContext}>{children}</chatTemplatesFillContext.Provider>;
};

export default ChatTemplatesFillProvider;
