import './FillParams.scss';
import { errorWabaParams } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { ParamsType } from "../../utils";


type Props = {
  array: ParamsType[];
  setArray: (params: ParamsType[]) => void;
  isError: boolean;
  titleKey: string;
};

const FillParams = ({ array, setArray, isError, titleKey }: Props) => {
  const { t } = useTranslation();

  const onChangeInputs = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const find = array.find((param) => param.id === id);

    if (find) {
      const editInput = array.map((input) => {
        if (input.id === find.id) {
          return { ...input, value: e.target.value };
        } else return input;
      });
      setArray(editInput);
    }
  };

  return (
    <>
      {array.map((input, index) => (
        <div key={index} className="fillInput">
          <p className="smallText middle">{`${t(`chats.templates.fill.${titleKey}`)} {{${input.label}}}`}</p>
          <input className={`fillInput__input ${isError ? errorWabaParams(array, input.id) : ''}`}
            onChange={(e) => onChangeInputs(e, input.id)} />
          {isError && errorWabaParams(array, input.id) &&
            <p id="error" className="smallText">{t('error.form.empty_field')}</p>}
        </div>
      ))}
    </>
  );
};

export default FillParams;
