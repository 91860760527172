import './InteractiveSwitchTypes.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ImageTypeIcon } from '../../../../assets/image.svg';
import { ReactComponent as TextTypeIcon } from '../../../../assets/article.svg';
import { ReactComponent as DocumentTypeIcon } from '../../../../assets/template.svg';
import { ReactComponent as VideoTypeIcon } from '../../../../assets/video.svg';
import SwitchTabs from '../../../../components/SwitchTabs';


type InteractiveSwitchTypesProps = {
  tabs: string[];
  onSelectTab: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedTab: string;
};

type TabProps = {
  tabName: string;
  tabId: string;
  tabIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const tabsConfig: Record<string, TabProps> = {
  'all': { tabName: 'templates.interactive_attachments_types.all', tabId: 'all' },
  'document': { tabName: 'templates.interactive_attachments_types.document', tabId: 'document', tabIcon: DocumentTypeIcon },
  'image': { tabName: 'templates.interactive_attachments_types.image', tabId: 'image', tabIcon: ImageTypeIcon },
  'text': { tabName: 'templates.interactive_attachments_types.text', tabId: 'text', tabIcon: TextTypeIcon },
  'video': { tabName: 'templates.interactive_attachments_types.video', tabId: 'video', tabIcon: VideoTypeIcon },
  'no_title': { tabName: 'templates.interactive_attachments_types.no_title', tabId: 'withoutAttachment' }
};

function InteractiveSwitchTypes({ tabs, onSelectTab, selectedTab }: InteractiveSwitchTypesProps) {
  const { t } = useTranslation();

  const tabsList = tabs.map((tab) => {
    const item = tabsConfig[tab];
    return  { tabName: t(item.tabName), tabId: item.tabId, tabIcon: item.tabIcon };
  });

  return (
    <SwitchTabs tabs={tabsList} onSelectHandler={onSelectTab} selectedTab={selectedTab} />
  );
}

export default InteractiveSwitchTypes;
