import './PaymentMethods.scss';
import { useTranslation } from 'react-i18next';
import { METHODS_PAYMENT } from '../../Payments/types';
import OptionsControl from '../../../../components/OptionsControl';


type PaymentMethodProps = {
  label: string;
  name: string;
  clientPaymentsLocked: boolean;
};

function PaymentMethod({ label, name, clientPaymentsLocked }: PaymentMethodProps) {
  const { t } = useTranslation();

  const clientPayments = METHODS_PAYMENT.map(item => ({
    label: t(`partner_cabinet.payments.create.payment.${item}.client.label`),
    info: t(`partner_cabinet.payments.create.payment.${item}.client.info`),
    value: item }));
  const partnerPayments = METHODS_PAYMENT.map(item => ({
    label: t(`partner_cabinet.payments.create.payment.${item}.partner.label`),
    info: t(`partner_cabinet.payments.create.payment.${item}.client.info`),
    value: item }));
  
  return (
    <div className='paymentMethod'>
      <OptionsControl label={label} name={name} options={ clientPaymentsLocked ? partnerPayments : clientPayments} />
    </div>
  );
}

export default PaymentMethod;
