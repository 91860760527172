import './ListAction.scss';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InteractiveTemplate } from '../../../api';
import RemoveActionButton from '../RemoveActionButton/RemoveActionButton';
import TextAreaResize from '../../../../Components/TextAreaResize';


type Props = {
  id: number;
} & UseFormReturn<InteractiveTemplate> & UseFieldArrayReturn<InteractiveTemplate>;

const ListAction: React.FC<Props> = ({ id, remove }) => {
  const { t } = useTranslation();

  const onRemoveListAction = () => {
    remove(id);
  };

  return (
    <div className="listAction">
      <div className="listAction__head">
        <h3>{`${t('templates.add_interactive_templates.template_actions.list.rows.title')} ${id + 1}`}</h3>
        {id > 0 && <RemoveActionButton onRemoveAction={onRemoveListAction} />}
      </div>
      <div className="listAction__list">
        <TextAreaResize className='regularText' name={`action.sections.sections.0.rows.${id}.title`}
          title={t('templates.add_interactive_templates.template_actions.list.rows.title_name')}
          subtitle={t('templates.add_interactive_templates.template_actions.list.rows.subtitle')}
          minRows={1} maxRows={2} maxLength={20} />
        <TextAreaResize className='regularText' name={`action.sections.sections.0.rows.${id}.description`}
          title={t('templates.add_interactive_templates.template_actions.list.rows.title_description')}
          subtitle={t('templates.add_interactive_templates.template_actions.list.rows.subtitle_description')}
          minRows={2} maxRows={3} maxLength={72} />
      </div>
    </div>
  );
};

export default ListAction;
