import './TgCode.scss';
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { TelegramActions, TelegramState } from '../utils';
import { TelegramData } from '../../../shemas';
import Button from '../../../../../../../components/Button';
import Input from '../../../../../../../components/Input';
import SwitchTimer from '../SwitchTimer';


type TgCodeProps = {
  state: TelegramState;
  update: Dispatch<TelegramActions>;
  init: (data?: TelegramData) => Promise<void>;
};

type SubmitSchema = {
  code: number;
};

const CODE_INTERVAL = 60;

function TgCode({ state, update, init }: TgCodeProps) {
  const { t } = useTranslation();
  const [timer, setTimer] = useState<number>(CODE_INTERVAL);
  const [disabled, setDisabled] = useState(true);

  const retryCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setTimer(CODE_INTERVAL);
    setDisabled(true);
    init();
  };

  useEffect(() => {
    if (!disabled)
      setTimer(0);
  }, [disabled]);

  const schema = yup.object().shape({
    code: yup.number().transform((current, original) => original === '' ? null : current)
      .nullable().required(t('error.form.empty_field')),
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: SubmitSchema  ) =>
    update({ type: 'code', payload: { code: data.code } });

  return (
    <div className='tgCode'>
      <p className='regularText'>
        {t('connections.telegram.auth.send_code')}<br/>
        {t('connections.telegram.auth.retry_code_in')}&nbsp;
        <SwitchTimer timeLimit={timer} switchAction={setDisabled} />
      </p>
      <div className='tgCode__init'>
        <FormProvider {...methods}>
          <Input name='code' label={t('connections.telegram.auth.code')} inputType='number' />
          <Button type='button' color='white' textType='regular' text={t('connections.telegram.auth.retry_code')}
            disabled={disabled} onClick={retryCode} />
        </FormProvider>
      </div>
      <Button type='button' color='orange' textType='regular' text={t('continue')}
        disabled={!methods.formState.isDirty || state.updating}
        onClick={methods.handleSubmit(onSubmit)} />
    </div>
  );
}

export default TgCode;
