import "./NotificationsList.scss";
import { NotificationChannel } from "../../NotificationsTypes";
import NotificationCard from '../NotificationCard';


type NotificationsListProps = {
  channels: NotificationChannel[];
};


const NotificationsList = ({ channels }: NotificationsListProps) => (
  <div className='notificationsList'>
    {channels.map(channel => <NotificationCard key={channel.id} channel={channel} />)}
  </div>
);

export default NotificationsList;
