import './EditName.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IntegrationsItem } from '../../IntegrationTypes';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Input';


type EditNameProps = {
  integration: IntegrationsItem;
  onUpdate: (name: string) => Promise<boolean | undefined>;
};

function EditName({ integration, onUpdate }: EditNameProps) {
  const { t } = useTranslation();
  const [editName, setEditName] = useState<boolean>(false);

  const schema = yup.object({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 }))
  });

  const methods = useForm<{ name: string; }>({
    mode: 'onSubmit', defaultValues: { name: integration.name }, resolver: yupResolver(schema)
  });

  const onSubmit = async (data: { name: string; }) => {
    if (data.name !== integration.name) {
      const result = await onUpdate(data.name);
      if (result) methods.reset(data);
    }
    setEditName(false);
  };

  return (
    <>
      {editName
        ? <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className='editName'>
            <Input type='text' name='name' />
            <Button type='submit' color='white' image={<EditIcon />} textType='small' text={t('save')} />
          </form>
        </FormProvider>
        : <div className='editName'>
          <h1>{methods.getValues('name')}</h1>
          <WrapperPermission permission='integrationsEdit'>
            <Button type='button' color='white' image={<EditIcon />} textType='small' text={t('change')}
              onClick={() => setEditName(true)} />
          </WrapperPermission>
        </div>
      }
    </>
  );
}

export default EditName;
