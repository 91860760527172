import React, { FC, useEffect, useState } from 'react';
import './CreateAndEditProduct.scss';
import { FormProvider, useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import * as yup from 'yup';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Button/Button";
import { taxBepaidArr, typeArr } from '../../../../../utils/payments';
import Dropdown from "../../../../Settings/Dropdown/Dropdown";
import { useAppDispatch } from "../../../../../store/store";
import Textarea from "../../../../Settings/Textarea/Textarea";
import { checkProductsCompany, editProduct, fetchCatalogs, postProducts, productUpdateCatalogs } from "../../../SalesSlice";
import { Product, uploadProductImage } from "../../../SalesAPI";
import { SalesCatalogsSelector, SalesLoadSelector } from "../../../SalesSelector";
import ImagesContent from "../ImagesContent/ImagesContent";
import loader from "../../../../../assets/grid.svg";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import DropdownWithCheckbox from "../../../../../components/DropdownWithCheckbox";

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product?: Product;
  setProductSelected?: React.Dispatch<React.SetStateAction<Product | undefined>>;
}

type PriceSales = {
  currency: string,
  price: number
}

type OnSubmitSchema = {
  name: string,
  description: string,
  catalogs: Array<number>,
  category: string,
  paymentObjectType: string,
  vat: string,
  sku: string,
  images: Array<string> | null,
  prices: Array<PriceSales>,
  price: number,
  currency: string
}

const CreateAndEditProduct: FC<Props> = ({ setIsOpen, product, setProductSelected }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [attachedFile, setAttachedFile] = useState<File[]>([]);
  const [arrayImage, setArrayImage] = useState<Array<string | File>>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const load = useSelector(SalesLoadSelector);
  const arrayCatalogs = useSelector(SalesCatalogsSelector);

  const schema = yup.object().shape({
    name: yup.string().required(t('empty_field')).max(50, t('sales.products.error.max_length', { limit: 50 })),
    description: yup.string().max(500, t('sales.products.error.max_length', { limit: 500 })).nullable(),
    catalogs: yup.object().nullable(),
    category: yup.string().nullable(),
    paymentObjectType: yup.string(),
    vat: yup.string(),
    sku: yup.string().max(50, t('sales.products.error.max_length', { limit: 50 })).nullable(),
    images: yup.object().nullable(),
    prices: yup.object().nullable(),
    price: yup.number().required(t('empty_field')).typeError(t('sales.products.error.type_error')).min(0, t('sales.products.error.price_minus'))
      .max(99999999, t('sales.products.error.max_length', { limit: 8 })),
    currency: yup.string()
  });


  const methods = useForm<OnSubmitSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      catalogs: [],
      category: '',
      paymentObjectType: 'commodity',
      vat: 'none',
      sku: '',
      images: null,
      prices: [],
      price: undefined,
      currency: 'RUB'
    },
  });

  useEffect(() => () => {
    if (setProductSelected) {
      setProductSelected(undefined);
    }
  }, [])

  useEffect(() => {
    const loadIdCatalog = async () => {
      if (product) {
        methods.reset({
          name: product.name,
          description: product.description,
          catalogs: product.catalogIds,
          category: product.category,
          paymentObjectType: product.paymentObjectType,
          vat: product.vat,
          sku: product.sku,
          images: product.images,
          prices: [],
          price: product.prices[0].price,
          currency: product.prices[0].currency,
        });
      }
    };
    loadIdCatalog();

    dispatch(fetchCatalogs({ companyId: Number(url?.params.companyId) }));
  }, []);

  useEffect(() => {
    if (product && (product?.images.toString() !== arrayImage.toString())) {
      setIsChanged(true);
    } else setIsChanged(false);
  }, [arrayImage]);

  const onSubmit = async (data: OnSubmitSchema) => {
    const arrayUrl = [];
    setClicked(true);
    if (product) {
      for (let i = 0; i < arrayImage.length; i++) {
        if (typeof arrayImage[i] === "object") {
          // eslint-disable-next-line no-await-in-loop
          const { url: FileUrl } = await uploadProductImage({
            companyId: Number(url?.params.companyId),
            file: arrayImage[i] as File
          });
          arrayUrl.push(FileUrl);
        } else arrayUrl.push(arrayImage[i] as string);
      }
      dispatch(editProduct({
        companyId: Number(url?.params.companyId),
        productId: product.id,
        name: data.name,
        ...(data.description ? { description: data.description } : {}),
        payment_object_type: data.paymentObjectType,
        vat: data.vat,
        ...(data.sku ? { sku: data.sku } : {}),
        images: arrayUrl,
        prices: [{ price: data.price, currency: data.currency }]
      })).then(async (res) => {
          if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
            const catalogsId = methods.getValues('catalogs');
            const { id } = res.payload
            if (catalogsId.length > 0) {
              dispatch(productUpdateCatalogs({
                companyId: Number(url?.params.companyId),
                productId: id,
                catalog_ids: catalogsId
              }))
            }
            setIsOpen(false);
            dispatch(addAlertWithCustomText({
              message: t('sales.products.successful_edit'),
              color: '#2CBA5F',
            }));
          }
        }
      );
    } else {
      for (let i = 0; i < attachedFile.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const { url: FileUrl } = await uploadProductImage({
          companyId: Number(url?.params.companyId),
          file: attachedFile[i]
        });
        arrayUrl.push(FileUrl);
      }
      dispatch(postProducts({
        companyId: Number(url?.params.companyId),
        name: data.name,
        ...(data.description ? { description: data.description } : {}),
        payment_object_type: data.paymentObjectType,
        vat: data.vat,
        ...(data.sku ? { sku: data.sku } : {}),
        images: arrayUrl,
        prices: [{ price: data.price, currency: data.currency }]
      })).then(async (res) => {
          if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
            const catalogsId = methods.getValues('catalogs');
            const { id } = res.payload
            if (catalogsId.length > 0) {
              dispatch(productUpdateCatalogs({
                companyId: Number(url?.params.companyId),
                productId: id,
                catalog_ids: catalogsId
              }))
            }
            setIsOpen(false);
            dispatch(checkProductsCompany({ companyId: Number(url?.params.companyId) }));
            dispatch(addAlertWithCustomText({
              message: t('sales.products.successful_create'),
              color: '#2CBA5F',
            }));
          }
        }
      );
    }
  }

  if (load) {
    return (<div className='createAndEditProduct'>
      <div className="loaderContainer">
        <img src={loader} alt="" />
      </div>
    </div>)
  }

  return (
    <FormProvider {...methods}>
      <div className='createAndEditProduct'>
        <div className='createAndEditProduct__header'>
          <h1>{product ? t('sales.products.edit.title') : t('sales.products.add_product')}</h1>
        </div>
        <div className='createAndEditProduct__content'>
          <Input label={t('sales.products.add.name')} name='name'
                 rightText={t('sales.products.add.name_limit')} style={{ width: '100%' }} />
          <Textarea label={t('sales.products.add.description')} name='description'
                    rightText={t('sales.products.add.description_limit')} />
          <Dropdown label={t('sales.products.add.type')} items={typeArr}
                    labelKey='name' valueKey='id' name='paymentObjectType'
                    style={{ width: '400px' }} placeholder={t('select')} />
          <div className='priceAndCurrency'>
            <Input inputType='number' label={t('sales.products.add.price')} name='price' placeholder='0' />
            <Input label={t('sales.products.add.currency')} name='currency' placeholder='RUB' readOnly={true} />
          </div>
          <Dropdown label={t('sales.products.add.vat')} items={taxBepaidArr}
            labelKey='name' valueKey='id' name='vat'
            style={{ width: '200px' }} placeholder='Выбрать' />
          <Input label={t('sales.products.add.sku')} name='sku' style={{ width: '200px' }} />
           {arrayCatalogs.length > 0 &&
            <DropdownWithCheckbox name='catalogs' label={t('sales.products.add.dropdown_catalogs')} listArray={arrayCatalogs} />}
          <div className='imagesGroup'>
            <div className='imagesGroup_header'>
              <p>{t('sales.products.add.images')}</p>
              <p>{t('sales.products.add.images_limit')}</p>
            </div>
            <ImagesContent
              attachedFile={attachedFile} setAttachedFile={setAttachedFile}
              product={product} arrayImage={arrayImage} setArrayImage={setArrayImage} />
          </div>
        </div>
        <div className={`createAndEditProduct__bottom ${clicked ? 'clicked': ''}`}>
          <Button text={product ? t('save_edit') : t('sales.products.add_product')} color='orange' textType='regular'
                  onClick={methods.handleSubmit(onSubmit)} disabled={!methods.formState.isDirty && !isChanged} />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateAndEditProduct;
