import { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TelegramActions, TelegramState } from '../utils';
import Button from '../../../../../../../components/Button';
import Input from '../../../../../../../components/Input';
import './Password.scss';


type PasswordProps = {
  state: TelegramState;
  update: Dispatch<TelegramActions>;
};

function Password({ state, update }: PasswordProps) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup.string().required(t('error.form.empty_field')),
  });

  const methods = useForm({
    mode: 'onSubmit', reValidateMode: 'onChange', defaultValues: { password: '' }, resolver: yupResolver(schema) });

  useEffect(() => {
    if (state.error === 17006) {
      methods.setError('password', { type: 'custom', message: t(`errors.${17006}`) }, { shouldFocus: true });
    }
  }, [state.error]);

  const onSubmit = (data: { password: string; }) => update({ type: 'password', payload: { password: data.password } });

  return (
    <div className='password'>
      <p className='regularText'>{t('connections.telegram.auth.2fa')}</p>
      <FormProvider {...methods}>
        <Input name='password' label={t('connections.telegram.auth.password')} inputType='password' />
        <Button type='submit' color='orange' textType='regular' text={t('continue')}
          disabled={!methods.formState.isDirty || state.updating}
          onClick={methods.handleSubmit(onSubmit)} />
      </FormProvider>
    </div>
  );
}

export default Password;
