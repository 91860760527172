import React, { useRef, useState } from 'react';
import './DropdownTemplate.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TemplateIcon } from '../../../../../assets/template.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-down.svg';
import { MessageTemplate } from '../../../../Templates/templatesTypes';
import { useMobile } from '../../../../../components/utils/hooks';
import ToolTip from "../../../../../components/Modals/ToolTip";
import ChatTemplates from "../../ChatTemplates";


type Props = {
  subscriptionIsBan: boolean;
  setSelectedCustomTemplate: React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>;
};

const DropdownTemplate = (props: Props) => {
  const { subscriptionIsBan, setSelectedCustomTemplate } = props;
  const { t } = useTranslation();
  const triggerButtonRef = useRef<HTMLDivElement>(null);
  const [openTemplates, setOpenTemplates] = useState(false);
  const isMobile = useMobile();

  const handlerCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const handlerOpenTemplates = () => {
    setOpenTemplates(true);
  };

  return (
    <>
      <div className={`dropdownTemplate ${subscriptionIsBan ? 'blocked' : ''}`}
        onClick={subscriptionIsBan ? () => {} : handlerOpenTemplates}>
        <TemplateIcon className={`templateIcon ${subscriptionIsBan && 'blocked'}`} />
        {!isMobile && <span className="smallText middle">{t('chats.chat.templates')}</span>}
        <div className={`arrowIcon ${openTemplates ? 'open' : ''}`} ref={triggerButtonRef}>
          <ArrowIcon className={`${subscriptionIsBan ? 'blocked' : ''}`} />
        </div>
      </div>
      {openTemplates && (
        <ToolTip refElement={triggerButtonRef} tail={!isMobile} modal onClose={handlerCloseTemplates}>
          <ChatTemplates onClose={handlerCloseTemplates} setMessageTemplate={setSelectedCustomTemplate} />
        </ToolTip>
      )}
    </>
  );
};

export default DropdownTemplate;
