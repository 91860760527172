import {
  WabaTemplateComponentBody,
  WabaTemplateComponentCarouselButtons, WabaTemplateComponentCarouselButtonsIn, WabaTemplateComponentCarouselHeader
} from '../../../templatesTypes';
import { getComponentText, IndexedParams, parseWabaBodyText } from '../../WabaTemplatesModeration/utils';
import PreviewHeader from '../PreviewHeader';
import './PreviewCarousel.scss';


type PreviewCarouselProps = {
  cardIndex: number;
  arrayCardParams: IndexedParams[];
  components: Array<WabaTemplateComponentBody 
    | WabaTemplateComponentCarouselButtonsIn | WabaTemplateComponentCarouselButtons
    | WabaTemplateComponentCarouselHeader>;
};

function PreviewCarousel({ components, arrayCardParams, cardIndex }: PreviewCarouselProps) {
  const header = components.find((component) => component.type === 'HEADER') as WabaTemplateComponentCarouselHeader;
  const body = components.find((component) => component.type === 'BODY') as WabaTemplateComponentBody;

  const arrayBodyParams = arrayCardParams?.find((item) => item.id === cardIndex)?.params ?? [];

  return (<>
    {header && <PreviewHeader header={header} arrayHeaderParams={[]} />}
    {body && <p className="smallText">{parseWabaBodyText(getComponentText(arrayBodyParams, body))}</p>}
  </>);
}

export default PreviewCarousel;
