import { useContext } from 'react';
import './NotificationsView.scss';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { partnerNotifications } from '../../utils';
import SidePanel from '../../../../../components/Modals/SidePanel';
import ClientsNotifications from '../ClientsNotifications';
import NotificationsBanner from '../NotificationsBanner';
import NotificationsList from '../NotificationsList';


function NotificationsView() {
  const { setOpenNotification, openNotification } = useContext(partnerNotifications);

  return (
    <div className='notificationsView'>
      {openNotification && (
        <SidePanel setIsOpen={setOpenNotification} background="light">
          <ClientsNotifications />
        </SidePanel>
      )}
      <WrapperPermission permission="partnersEdit">
        <div className='notificationsView__banner'>
          <NotificationsBanner />
        </div>
      </WrapperPermission>
      <NotificationsList />
    </div>
  );
}

export default NotificationsView;
