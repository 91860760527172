import React, { useEffect, useState } from 'react';
import './Finances.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getFinancesSwitch } from './utils/utils';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { fetchPartnerBalance, fetchPartnerPayouts, fetchPartnerRewards } from '../PartnerCabinetSlice';
import Button from '../../../components/Button';
import SwitchTabs from '../../../components/SwitchTabs';
import RewardsView from './components/RewardsView';
import PayoutsView from './components/PayoutsView';
import WithdrawPanel from './components/WithdrawPanel';
import SidePanel from '../../../components/Modals/SidePanel';


const LIMIT_BALANCE = 1000;

const Finances = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const balance = useAppSelector((state) => state.partners.partnerBalance);
  const [switchPosition, setSwitchPosition] = useState<string>('rewards');
  const [openPanel, setOpenPanel] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPartnerBalance({ companyId: currentCompany.id }));
    dispatch(fetchPartnerRewards({ company_id: currentCompany.id }));
    dispatch(fetchPartnerPayouts({ company_id: currentCompany.id }));
  }, []);

  const onSwitchPosition = (e: React.MouseEvent<HTMLButtonElement>) => {
    const switcherValue = e.currentTarget.id;
    setSwitchPosition(switcherValue);
  };

  return (
    <div className="finances">
      <h1>{t('partner_cabinet.finances.title')}</h1>
      <div className="finances__balance">
        <p className="regularText">
          {t('partner_cabinet.finances.balance')}: <span className="regularText semibold">{balance}₽</span>
        </p>
        <Button text={t('partner_cabinet.finances.withdraw')} color="white" textType="small"
          onClick={() => setOpenPanel(true)} disabled={balance !== null ? balance < LIMIT_BALANCE : false} />
      </div>
      <p className="finances__balance_description smallText">{t('partner_cabinet.finances.balance_description')}</p>
      <SwitchTabs tabs={getFinancesSwitch()} selectedTab={switchPosition} onSelectHandler={onSwitchPosition} />
      {switchPosition === 'rewards' && <RewardsView />}
      {switchPosition === 'payouts' && <PayoutsView />}
      {openPanel && (
        <SidePanel setIsOpen={setOpenPanel} background="light">
          <WithdrawPanel setOpen={setOpenPanel} />
        </SidePanel>
      )}
    </div>
  );
};

export default Finances;
