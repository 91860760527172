import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import chatsReducer from '../views/Chat/chatsSlice';
import integrationReducer from '../views/Settings/Integrations/integrationSlice';
import companiesReducer from '../views/Chat/companiesSlice';
import billReducer from '../views/Chat/billSlice';
import shopsReducer from '../views/Sales/Shops/shopsSlice';
import subscriptionsReducer from '../views/Settings/Subscriptions/SubscriptionsSlice';
import SalesReducer from '../views/Sales/SalesSlice';
import contactsReducer from '../views/Contacts/contactsSlice/contactsSlice';
import alertsReducer from '../components/Alert/alertSlice';
import templatesReducer from '../views/Templates/templatesSlice';
import TagsReducer from '../views/Settings/Tags/tagsSlice';
import onboardingSlice from '../modals/Onboarding/onboardingSlice';
import connectionsReducer from '../views/Settings/Connections/connectionsSlice';
import broadcastsReducer from '../views/Broadcasts/BroadcactsSlice';
import partnerReducer from '../views/PartnerCabinet/PartnerCabinetSlice'
import notificationsReducer from '../views/Settings/Notifications/NotificationsSlice'


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    chats: chatsReducer,
    integrations: integrationReducer,
    companies: companiesReducer,
    bill :billReducer,
    subscriptions: subscriptionsReducer,
    sales: SalesReducer,
    templates: templatesReducer,
    tags: TagsReducer,
    contacts: contactsReducer,
    alerts: alertsReducer,
    onboarding: onboardingSlice,
    shops:shopsReducer,
    connections: connectionsReducer,
    broadcasts: broadcastsReducer,
    partners: partnerReducer,
    notifications: notificationsReducer
  });

export default createRootReducer;
