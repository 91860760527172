import { useEffect, useState } from 'react';
import './WabaTemplateButtons.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { wabaAuthenticationButtons, wabaTemplateButtons, wabaTemplateButtonsMaximum } from '../../../../utils/waba';
import { WabaTemplateAuthData, WabaTemplateMessageData } from '../../../WabaTemplateProvider/utils';
import { WabaTemplateCategory } from '../../../../templatesTypes';
import { WabaTemplateKind } from '../../../api';
import Button from '../../../../../../components/Button';
import Counter from '../Counter';
import WabaTemplateButton from '../WabaTemplateButton';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


type WabaTemplateButtonProps = {
  category: WabaTemplateCategory;
  kind: WabaTemplateKind;
};

function WabaTemplateButtons({ category, kind }: WabaTemplateButtonProps) {
  const { t } = useTranslation();
  const methods = useFormContext<WabaTemplateAuthData | WabaTemplateMessageData>();
  const fieldsMethods = useFieldArray<WabaTemplateAuthData | WabaTemplateMessageData>({ control: methods.control, name: 'buttons' });
  const [buttonsMax, setButtonsMax] = useState(0);
  const { errors } = methods.formState;

  useEffect(() => {
    setButtonsMax(wabaTemplateButtonsMaximum[kind]);
  }, [kind]);

  const onAddButton = () => {
    if (fieldsMethods.fields.length < buttonsMax) {
      fieldsMethods.append({
        type: category === 'AUTHENTICATION' ? wabaAuthenticationButtons[0] : wabaTemplateButtons[0],
        text: '' });
    }
  };

  return (
    <div className='wabaTemplateButtons'>
      <div className='wabaTemplateButtons__header'>
        <h3>{t('templates.waba_list.form.buttons.title')}</h3>
        <Button type='button' color='orange' textType='small' text={t('templates.waba_list.form.buttons.add')}
          image={<PlusIcon />} onClick={onAddButton} disabled={fieldsMethods.fields.length >= buttonsMax} />
      </div>
      <div className='wabaTemplateButtons__buttons'>
        {fieldsMethods.fields?.map((button, index) => (
          <WabaTemplateButton key={button.id} category={category} id={index} {...methods} {...fieldsMethods} />
        ))}
      </div>
      <ErrorMessage errors={errors} name='buttons'
        render={({ message }) => <p className="smallText error">{message}</p>} />
      <Counter counter={fieldsMethods.fields?.length || 0} maximum={buttonsMax}
        text={t('templates.waba_list.form.button.button', { count: buttonsMax })} />
    </div>
  );
}

export default WabaTemplateButtons;
