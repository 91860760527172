import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./ActionDialog.scss";
import { OverlayBackgroundType } from "../types";
import { ReactComponent as CloseIcon } from "../../../assets/cross.svg";
import Button from "../../Button";


type ActionDialogProps = {
  title: string;
  message?: string;
  action: string;
  cancel: string;
  children?: ReactNode;
  onAction: () => void;
  onCancel?: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

const ActionDialog = (
  { title, message, action, cancel, children, onAction, onCancel, setIsOpen, background='none' }: ActionDialogProps) => {

  const onCloseDefault = () => {
    if (setIsOpen) setIsOpen(false);
  };

  return ReactDOM.createPortal(
    <div className="actionDialog__container">
      <div className={`actionDialog__wrapper ${background}`} onClick={onCloseDefault}>{}</div>
      <div className="actionDialog">
        <CloseIcon onClick={onCloseDefault} />
        <h4>{title}</h4>
        {message &&<div className="actionDialog__message">{message}</div>}
        {children}
        <div className="actionDialog__bottom">
          <Button text={action} color='orange' textType='regular' onClick={onAction} />
          <Button text={cancel} color='white' textType='regular' onClick={onCancel || onCloseDefault} />
        </div>
      </div>
    </div>, document.body
  );
};

export default ActionDialog;
