import React from 'react';
import './RequisitesList.scss';
import { useTranslation } from "react-i18next";
import { PaymentRequisite } from "../../SubscriptionsTypes";
import RequisiteItem from '../RequisiteItem';


type Props = {
  requisitesList: Array<PaymentRequisite>;
};

function RequisitesList({ requisitesList }: Props) {
  const { t } = useTranslation();

  return (
    <div className="subRequisitesList">
      <div className="subRequisitesList__header">
        <div className="smallText semibold">{t('subscriptions.requisites.req_list.name')}</div>
        <div className="smallText semibold">{t('subscriptions.requisites.req_list.tin')}</div>
        <div className="smallText semibold">{t('subscriptions.requisites.req_list.status')}</div>
      </div>
      {requisitesList.map((requisite) => (
        <RequisiteItem key={requisite.id} requisite={requisite} />
      ))}
    </div>
  );
}

export default RequisitesList;
