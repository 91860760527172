import { memo } from 'react';
import './CompanyItem.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross.svg';


type CompanyItemProps = {
  partertnerCompany: number;
  onRemovePartnerCompany: (partertnerCompany: number) => void;
};

function CompanyItem({ partertnerCompany, onRemovePartnerCompany }: CompanyItemProps) {
  const company = useSelector(
    (state: RootState) => state.partners.partnerCompanies.find(company => company.id === partertnerCompany));

  const onDelete = () => {
    onRemovePartnerCompany(partertnerCompany);
  };

  return (
    <div className='companyItem'>
      <p className='smallText'>{company?.name}</p>
      <WrapperPermission permission="partnersEdit">
        <CloseIcon onClick={onDelete} />
      </WrapperPermission>
    </div>
  );
}

export default memo(CompanyItem);
