import './InvoicesList.scss';
import { CompanyPayment } from '../../PartnerCabinetTypes';
import InvoiceItem from './components/InvoiceItem';


type InvoicesListProps = {
  invoices: CompanyPayment[];
  showCompany?: boolean;
};

function InvoicesList({ invoices, showCompany=false }: InvoicesListProps) {
  
  return (
    <>
      {invoices.length > 0 &&
        invoices.map((item) => <InvoiceItem key={item.id} invoiceItem={item} showCompany={showCompany} />)
      }
    </>
  );
}

export default InvoicesList;
