import './ClientPayInfo.scss';
import { useTranslation } from 'react-i18next';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { useAppDispatch } from '../../../../../store/store';
import { useHasPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { updatePartnerCompanyPayment } from '../../../PartnerCabinetSlice';
import { PartnerCompany } from '../../../PartnerCabinetTypes';
import { ClientPayerType, getArrayPayer, getTitlePaymentsLocked } from '../../ulils/utils';
import ItemPercent from '../ClientItemComponents/ClientPercent';
import Select from '../../../../../components/Select';


type ClientPayInfoProps = {
  companyId: number;
  partnerCompanyId: number;
  partnerCompany: PartnerCompany;
};

function ClientPayInfo({ companyId, partnerCompanyId, partnerCompany }: ClientPayInfoProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const peyerArr = getArrayPayer();
  const permissionEdit = useHasPermission('partnersEdit');

  const onSelect = (item: ClientPayerType) => {
    dispatch(updatePartnerCompanyPayment({
      company_id: companyId,
      partner_company_id: partnerCompanyId,
      is_locked: item.value,
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(addAlertWithCustomText({ message: t('errors.100000'), type: 'info' }));
      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(addAlertWithCustomText({ message: t('errors.99999'), type: 'alarm' }));
      }
    });
  };

  return (
    <div className="clientPayInfo">
      <div className="partnerPercent">
        <h4>{t('partner_cabinet.clients.percent')}</h4>
        <ItemPercent percent={partnerCompany.partnerPercent} />
      </div>
      <div className="partnerPayer">
        <h4>{t('partner_cabinet.clients.pay_subscriptions')}</h4>
        {permissionEdit ? (
          <Select items={peyerArr} labelKey="name" onChoose={onSelect} value={getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)}
            initValue={getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)} />
        ) : (
          <p className='regularText'>{getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)}</p>
        )}
      </div>
    </div>
  );
}

export default ClientPayInfo;
