import './PermissionsList.scss';
import { useTranslation } from 'react-i18next';
import { PermissionWithKeys } from '../../utlity';
import PermissionItem from '../PermessionItem/PermissionItem';


type PermissionsListType = {
  permissions: PermissionWithKeys;
  onChangePermission: (data: Record<string, Record<string, boolean>>) => void;
  isAdminManager: boolean;
};

const PermissionsList = ({ permissions, onChangePermission, isAdminManager }: PermissionsListType) => {
  const { t } = useTranslation();
  return (
    <div className='permissionsList'>
      <h4>{t('settings.users.user.access_rights')}</h4>
      <div className='permissionsList__list'>
        {Object.entries(permissions).map(([key, value]) =>
          <PermissionItem
            key={key}
            keyPermission={key}
            listPermission={value}
            isAdminManager={isAdminManager}
            onChangePermission={onChangePermission}
          />
        )}
      </div>
    </div>
  );
};

export default PermissionsList;
