import React, { useContext, useEffect, useState } from 'react';
import './ChatTemplateSelector.scss';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getTemplatesSwitch, useFetchTemplates } from '../../utils';
import { fetchCustomTemplates, fetchWabaTemplates } from '../../../../../Templates/templatesSlice';
import { chatTemplatesContext } from "../../ChatTemplatesProvider/ChatTemplatesProvider";
import { currentCompanySelector } from '../../../../companiesSelector';
import { currentChatSelector } from '../../../../chatsSelector';
import { useMobile } from "../../../../../../components/utils/hooks";
import SwitchTabs from '../../../../../../components/SwitchTabs';
import ChatTemplateList from '../ChatTemplateList';


const ChatTemplateSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const currentChat = useSelector(currentChatSelector);
  const [switchPosition, setSwitchPosition] = useState<string>('');
  const { setWabaTemplate, setCustomTemplate } = useContext(chatTemplatesContext);
  const typeChat = useFetchTemplates();
  const isMobile = useMobile();

  useEffect(() => {
    dispatch(fetchCustomTemplates({ companyId: currentCompany.id }));
    if (typeChat === 'waba') {
      dispatch(fetchWabaTemplates({ connectionId: currentChat.connectionId, companyId: currentCompany.id }));
      setSwitchPosition('waba');
    } else setSwitchPosition('my');
  }, []);

  const onSwitchPosition = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (switchPosition !== e.currentTarget.id) {
      setSwitchPosition(e.currentTarget.id);
      setCustomTemplate(undefined);
      setWabaTemplate(undefined);
    }
  };

  return (
    <div className="chatTemplateSelector">
      {typeChat === 'waba' && (
        <SwitchTabs tabs={getTemplatesSwitch()} onSelectHandler={onSwitchPosition} selectedTab={switchPosition} />
      )}
      <div className="chatTemplateSelector__header">
        {isMobile ? <h3>{t('chats.templates.selected.title')}</h3> : <h4>{t('chats.templates.selected.title')}</h4>}
      </div>
      {typeChat && <ChatTemplateList switchPosition={switchPosition} />}
    </div>
  );
};

export default ChatTemplateSelector;
