import './BePaidCreate.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { settingConnections } from '../../../utils/connections';
import { BepaidCreateParams } from '../../../ConnectionsTypes';
import { createConnection } from '../../../connectionsSlice';
import { DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME, switchItems } from '../../../utils/terminals';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { useGuide } from '../../../api/guide';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import Switch from '../../../../Switch/Switch';
import LifetimePayment from "../../../components/LifetimePayment";
import GoBackWrapper from '../../../../../../components/GoBackWrapper';


type BePaidSchema = {
  name: string;
  params: BepaidCreateParams;
};

const BePaidCreate = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const { service, update } = useGuide();
  const currentCompany = useSelector(currentCompanySelector);

  const schema = yup.object({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object({
      secret_key: yup.string().max(128, t('error.form.max_length', { limit: 128 })),
      is_testing: yup.boolean(),
      invoice_lifetime_minutes: yup.number()
        .min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    })
      .transform((value) => {
        if (value && Object.values(value).some((v) => v !== '')) {
          return value;
        }
        return undefined;
      })
      .default(undefined),
  });

  const methods = useForm<BePaidSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      params: {
        is_testing: false,
        invoice_lifetime_minutes: DEFAULT_LIFETIME,
      },
    },
  });

  const onSubmit = (data: BePaidSchema) => {
    dispatch(createConnection({
      companyId: currentCompany.id,
      name: data.name,
      type: 'bepaid',
      params: { ...data.params }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        goBack();
        if (service === 'bepaid') update();
      }
    });
  };

  return (
    <div className="bePaidCreate">
      <GoBackWrapper title={t(`${settingConnections.bepaid?.name}`)}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input name="name" label={t('connections.connection_name')} />
            <Input name="params.shop_id" label="ID магазина" />
            <Input name="params.secret_key" label="Секретный ключ" inputType="password" />
            <Switch name="params.is_testing" items={switchItems} />
            <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={DEFAULT_LIFETIME} />
            <Button type='submit' color='orange' textType='regular' text={t('connections.bepaid.add_button')}
                    disabled={!methods.formState.isDirty} />
          </form>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
};

export default BePaidCreate;
