import RadioControl from '../RadioControl';
import './OptionsControl.scss';


type Option = {
  label: string;
  value: string;
  info?: string;
};

type OptionsControlProps = {
  label: string;
  name: string;
  options: Option[];
};

function OptionsControl({ label, name, options }: OptionsControlProps) {
  return (
    <div className='optionsControl'>
      <h3>{label}</h3>
      {options.map((item, index) =>
        <RadioControl key={item.value} name={name} value={item.value} defaultChecked={index === 0 && true}
          label={item.label} info={item.info} />
      )}
    </div>
  )
};

export default OptionsControl;
