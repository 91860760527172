import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../../../../store/store';
import { setAlertWithClosing } from '../../../../../components/Alert/alertSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { ConnectionsType } from '../../ConnectionsTypes';
import { useIsConnectMaxLimit } from "../../../../../utils";
import { settingConnections, connectionsUploadContext } from '../../utils/connections';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { NewConnection } from '../../../../../api/CompanyAPI';
import { removeConnection } from '../../connectionsSlice';
import Button from '../../../../../components/Button';
import ConnectionList from '../ConnectionList';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import './ConnectionView.scss';


export const linkInstructionsWhatsapp: Record<'ru' | 'en' | 'es', string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/amocrm/seryi-whatsapp/kak-smenit-podklyuchennyi-nomer-whatsapp-na-drugoi',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp/como-cambiar-el-numero-de-whatsapp-conectado-a-otro',
  en: 'https://docs.radist.online/docs/our-products/whatsapp/how-to-change-a-connected-whatsapp-number-to-another-one',
};

const ConnectionView = () => {
  const { t, i18n } = useTranslation();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [connectionSelected, setConnectionSelected] = useState<NewConnection | undefined>();
  const { service } = useContext(connectionsUploadContext);
  const currentCompany = useSelector(currentCompanySelector);
  const connections = useAppSelector((state) => state.connections.connectionsStore[service]);
  const isMaxLimit = useIsConnectMaxLimit(service);
  const isViewTariff = service === 'waba' &&
    currentCompany.paymentCurrency === 'RUB' && !currentCompany.waba360DialogPartnerId;

  const createConnection = () => {
    if (!isMaxLimit()) {
      if (isViewTariff) {
        push(`${url}/tariff`);
      } else push(`${url}/create`);
    }
  };

  const deleteConnection = () => {
    if (connectionSelected) {
      dispatch(removeConnection({ companyId: currentCompany.id, connectionId: connectionSelected.id }));
      setConnectionSelected(undefined);
      setDeleteOpen(false);
    }
  };

  const onDelete = (connection: NewConnection) => {
    if (service === 'waba') {
      dispatch(setAlertWithClosing({
        type: 'alarm', payload: {
          title: t(`connections.${service}.delete_confirm`),
          text: t(`connections.${service}.delete_text`)
        }
      }));
    } else {
      setConnectionSelected(connection);
      setDeleteOpen(true);
    }
  };

  if (service) {
    return (
      <div className="connectionView">
        <div className="connectionView__header">
          <h1>{t(`${settingConnections[service as ConnectionsType]?.name}`)}</h1>
          <WrapperPermission permission='connectionsCreate'>
            <Button type='button' color='orange' image={<PlusIcon />} textType='regular'
              text={t(`connections.${service}.create`)} onClick={createConnection} />
          </WrapperPermission>
        </div>
        {connections && <>
          {deleteOpen &&
            <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
              title={t(`connections.${service}.delete_confirm`)}
              message={t(`connections.${service}.delete_text`)}
              onAction={deleteConnection} setIsOpen={setDeleteOpen}>
              <div className='connectionView__confirm'>
                {service === 'whatsapp' &&
                  <p className='regularText'>
                    <Trans i18nKey={`connections.whatsapp.link_instructions`}>text
                      <a className='link__number' target="_blank" rel="noreferrer"
                        href={linkInstructionsWhatsapp[i18n.language] ?? ''}>link</a>
                    </Trans>
                  </p>
                }
              </div>
            </ActionDialog>}
          <h3>{t(`connections.${service}.subtitle`)}</h3>
          <ConnectionList connections={connections} onDelete={onDelete} />
        </>}
      </div>
    );
  }
  return null;
};

export default ConnectionView;
