import { useRef, useState } from 'react';
import { BaseEmoji } from 'emoji-mart';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextAreaResizeProps } from '../TextAreaResize/TextAreaResize';
import { SelectPosition } from '../../utils/waba';
import TextAreaResize from '../TextAreaResize';
import EmojiComponent from '../../../../components/EmojiComponent';
import './TextAreaResizeEmoji.scss';


type TextAreaResizeEmojiProps = TextAreaResizeProps;

function TextAreaResizeEmoji( { name, title, subtitle, ...props }: TextAreaResizeEmojiProps ) {
  const pickerRef = useRef<SVGSVGElement>(null);
  const [picker, setPicker] = useState<boolean>(false);
  const [position, setPosition] = useState<SelectPosition>();
  const { setValue } = useFormContext();

  const text = useWatch({ name });

  const onEmojiPick = (emoji: BaseEmoji) => {
    setValue(name, 
      [text?.slice(0, position?.end ?? text.length), emoji.native, text?.slice(position?.end ?? text.length)].join(''),
      { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className='textAreaResizeEmoji'>
      <TextAreaResize name={name} title={title} subtitle={subtitle} {...props} setPosition={setPosition} />
      <div className='textAreaResizeEmoji__tools'>
        <EmojiComponent pickerRef={pickerRef} picker={picker} setPicker={setPicker}
          onClick={() => setPicker(!picker)} onSelect={onEmojiPick} />
      </div>
    </div>
  );
}

export default TextAreaResizeEmoji;
