import { Channels } from '../../../PartnerCabinetTypes';
import { channelsIcons } from '../../utils';


type ChannelIconProps = {
  channel: Channels;
};

function ChannelIcon({ channel }: ChannelIconProps) {
    const Icon = channelsIcons[channel];
    
    if (Icon) return <Icon />;
    return null;
}

export default ChannelIcon;
