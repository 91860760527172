import React from 'react';
import './SettingsList.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector, onboardingGuideSelector } from '../../Chat/companiesSelector';
import { listOfTabsInSettings } from '../utility/listOfTabsInSettings';


const SettingsList = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const params = useRouteMatch<{ tab: string; }>('/companies/:companyId/settings/:tab');
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  const guideOn = onboardingGuideOn ? 'guideOn' : '';

  const tabsWithOther = currentRole ? [...currentRole.permissions, 'profile'] : [];
  const tabs = listOfTabsInSettings.filter(i => tabsWithOther.includes(i.permission));

  return (
    <div className={`settingList ${guideOn}`}>
      {currentRole && tabs.map(({tab, ComponentIcon, title}) => (
        <Link key={tab} to={`${url}/${tab}`}>
          <div key={tab} className={`settingList__item ${params?.params.tab === tab ? 'active' : ''}`}>
            <ComponentIcon />
            {t(`${title}`)}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SettingsList;
