import { useEffect } from 'react';
import './Clients.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnerCompanies, fetchPartnerCompanyNotifications } from '../PartnerCabinetSlice';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import ClientsView from './components/ClientsView';



const Clients = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  useEffect(() => {
    dispatch(fetchPartnerCompanies({ company_id: currentCompany.id }));
    dispatch(fetchPartnerCompanyNotifications({ companyId: currentCompany.id }));
  }, [currentCompany.id]);

  return (
    <div className='partnerClients'>
      <h1>{t('partner_cabinet.clients.title')}</h1>
      <ClientsView />
    </div>
  );
};

export default Clients;
