import { useTranslation } from 'react-i18next';
import { getTitlePaymentsLocked } from '../../../ulils/utils';


type Props = {
  paymentsLocked: boolean;
}

const ClientPaymentLocked = ({ paymentsLocked }: Props) => {
  const { t } = useTranslation();

  return (
    <p className="regularText">
      {getTitlePaymentsLocked(t, paymentsLocked)}
    </p>
  );
};

export default ClientPaymentLocked;
