import './Integration.scss';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import IntegrationsList from './components/IntegrationsList';
import IntegrationSettings from './IntegrationSettings';
import IntegrationDescriptions from './IntegrationDescriptions';
import APIIntegration from './components/APIIntegration';


const Integration = () => {
  const matchRoute = useRouteMatch();

  return (
    <Switch>
      <Route path={matchRoute.path} exact>
        <IntegrationsList />
      </Route>
      <Route path={`${matchRoute.url}/:integrationId`} exact>
        <IntegrationSettings />
      </Route>
      <WrapperPermission permission='integrationsCreate'>
        <>
          <Route path={`${matchRoute.url}/description/:integrationName`} exact>
            <IntegrationDescriptions />
          </Route>
          <Route path={`${matchRoute.url}/setup/api`} exact>
            <APIIntegration />
          </Route>
        </>
      </WrapperPermission>
    </Switch>
  );
};

export default Integration;
