import { Subscription } from "../SubscriptionsTypes";
import UserSubscription from "./UserSubscription";

class UserSubscriptions {
  userSubscriptions: Array<UserSubscription> = [];

  constructor(subscriptions: Array<Subscription>) {
    this.defUserSubscriptions(subscriptions);
    this.sortSubscriptions();
  }

  public defUserSubscriptions(subscriptions: Array<Subscription>) {
    subscriptions.forEach(subscription => {
      this.userSubscriptions.push(new UserSubscription(subscription));
    });
  }

  public updateSubscriptions(subscriptions: Array<UserSubscription>) {
    this.userSubscriptions.forEach((item) => {
      const subscription = subscriptions.find((sub) => sub.id === item.id);
        if (subscription) {
          item.selected = subscription.selected;
          item.updateStatus();
        }
    });
  }

  private sortSubscriptions() {
    this.userSubscriptions.sort((a, b) => (a.status - b.status));
  }

  public get selected(): Array<UserSubscription> {
    /** get selected subscriptions with connections */
    return this.userSubscriptions.filter((item) => item.selected && item.currentSize > 0);
  }

}

export default UserSubscriptions;
