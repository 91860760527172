import { useEffect, useState } from 'react';
import './Contacts.scss';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { fetchAllTags, fetchContacts } from '../../contactsSlice/contactsSlice';
import {
  contactsSelector, allTagsContactSelector, contactsTotalSelector, isLoaderContactsSelector
} from '../../contactsSlice/contactsSelector';
import { useDebounce } from "use-debounce";
import { ContactItem } from '../ContactItem/ContactItem';
import { useAppDispatch } from '../../../../store/store';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { getPageLimit } from '../../../../components/Paginator/utils';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader/Loader';
import Paginator from '../../../../components/Paginator';
import Search from "../../../../components/Search/Search";
import Dropdown from "../../../Settings/Dropdown/Dropdown";
import DropdownWithCheckbox from "../../../../components/DropdownWithCheckbox";


type OnSubmitSchema = {
  dropdownTags: number[];
  dropdownSort: string;
  query: string;
};

const Contacts = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/contacts');
  const [search, setSearch] = useState('');
  const [query] = useDebounce(search, 400);
  const isLoader = useSelector(isLoaderContactsSelector);

  const methods = useForm<OnSubmitSchema>({
    mode: 'onChange',
    defaultValues: {
      dropdownTags: [],
      dropdownSort: 'name:asc',
      query: '',
    },
  });

  const allContacts = useSelector(contactsSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const total = useSelector(contactsTotalSelector);
  const allTagsContact = useSelector(allTagsContactSelector);

  const tags = methods.getValues('dropdownTags');
  const sort = methods.getValues('dropdownSort');

  const watchTags = methods.watch('dropdownTags');
  const watchSort = methods.watch('dropdownSort');
  const isNotFound = allContacts?.length === 0 && methods.getValues('query')?.length !== 0;

  const contactsPageLimit = 'limit';

  const arraySort: Array<{name: string; id: string}> = [
    { name: t('contacts.contactsList.byContactName_asc'), id: 'name:asc' },
    { name: t('contacts.contactsList.byContactName_desc'), id: 'name:desc' },
    { name: t('contacts.contactsList.newFirst'), id: 'created_at:desc' },
    { name: t('contacts.contactsList.oldFirst'), id: 'created_at:asc' },
  ];

  const onUpdate = (page: number, limit: number) => {
    dispatch(fetchContacts({
        companyId: currentCompany.id, offset: (limit * (page - 1)), limit, tags, sort, query
      })
    );
  };

  useEffect(() => {
    dispatch(fetchContacts({
        companyId: currentCompany.id, limit: getPageLimit(contactsPageLimit), offset: 0, tags, sort, query
      })
    );
  }, [watchTags, query, watchSort]);

  useEffect(() => {
    if (url?.params.companyId) {
      dispatch(fetchAllTags({ companyId: Number(url?.params.companyId) }));
      dispatch(fetchContacts({
        companyId: currentCompany.id, limit: getPageLimit(contactsPageLimit), offset: 0 })
      );
    }
  }, [url?.params.companyId]);

  if (!allContacts) {
    return (
      <div className='contacts'>
        <Loader/>
      </div>
    );
  }

  return (
    <FormProvider {...methods} >
      <div className='contacts'>
        <div className='contacts__header'>
          <div className='contacts__header__sort'>
            <div className='contacts__header__sort__titleButton'>
              <div className='contacts__header__sort__titleButton__title'>
                <h1>{t('contacts.contactsList.contacts')}</h1>
              </div>
              <div className='contacts__header__sort__titleButton__addButton'>
                <WrapperPermission permission='contactsCreate'>
                  <Button type='button' color='orange' textType='regular'
                  text={t('contacts.contactsList.addContacts')} image={<PlusIcon />}
                  onClick={() => dispatch(push(`${url?.url}/add`))} data-testid='create-contact' />
                </WrapperPermission>
              </div>
            </div>
            <div className='contacts__header__sort__inputs'>
              <Dropdown label={t('contacts.contactsList.sort')} items={arraySort} labelKey='name' valueKey='id'
                name='dropdownSort' data-testid='sort-name' />
              <DropdownWithCheckbox name='dropdownTags' label={t('contacts.contactsList.filteredByTags')}
                listArray={allTagsContact} data-testid='sort-tags' />
              <div className='contacts__header__sort__inputs__keyWords'>
                <Search onChangeSearchInput={setSearch} value={search} data-testid='search-contact' />
              </div>
            </div>
          </div>
          <div className='contacts__header__list'>
            {['photo', 'nameSurname', 'email', 'phone', 'tags'].map((item) =>
              <div key={item}>{t(`contacts.contactsList.${item}`)}</div>
            )}
          </div>
        </div>
        <div className='contacts__list'>
          {isNotFound ? <div className='contacts__list__nothing'>{t('contacts.contactsList.notFound')}</div>
            : isLoader ? <Loader/> : allContacts?.map((contact) => (
              <ContactItem companyId={currentCompany.id} contact={contact} key={contact.id}/>
            ))
          }
        </div>
        {total > getPageLimit(contactsPageLimit) &&
        <Paginator totalCount={total} pageSelect={onUpdate} pageLimitStoreIndex={contactsPageLimit} />}
      </div>
    </FormProvider>
  );
};

export default Contacts;
