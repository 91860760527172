import { useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { integrationsUpdate } from "../integrationSlice";
import { BankingUpdate, IntegrationsTypesParams } from "../types";
import { integrationContext } from "../utils";
import { useGuide } from './guide';

export function useBankingUpdate() {
  const dispatch = useAppDispatch();
  const company = useSelector(currentCompanySelector);
  const { setIntegration } = useContext(integrationContext);
  const { guided, update } = useGuide();

  const integrationUpdate = async (data: BankingUpdate) => {
    let response: boolean = false;

    if (company) {
      await dispatch(integrationsUpdate({
        companyId: company.id,
        integration_id: data.id,
        requestOption: {
          name: data.name,
          status: data.status ? 'enabled' : 'disabled',
          type: data.type,
          [IntegrationsTypesParams[data.type]]: data.terminals?.map(({ id }) => ({ id })),
          defaultConnection: Number(data.defaultConnection),
          createFields: data.createFields,
          ...(data.amoInvoices ? {
            amoInvoices: {
              invoice_catalog_id: data.amoInvoices.invoiceCatalogId,
              statuses: {
                new: data.amoInvoices.statuses.new,
                confirmed: data.amoInvoices.statuses.confirmed,
                rejected: data.amoInvoices.statuses.rejected,
                expired: data.amoInvoices.statuses.expired,
                refunded: data.amoInvoices.statuses.refunded,
                partial_refunded: data.amoInvoices.statuses.partialRefunded,
              }
            }
          } : {}),
          extra: {
            ...(data.extraTax ? { tax: data.extraTax } : {}),
            ...(data.extraType ? { type: data.extraType } : {}),
            ...(data.extraPayment ? { payment: data.extraPayment } : {}),
          },
        },
      }))
        .then((result) => {
          response = result.meta.requestStatus === 'fulfilled';
          if (response && typeof result.payload === 'object'){
            if (guided) {
              update();
            } else {
              setIntegration(result.payload);
            }
          }
        });
    }

    return response;
  };

  return integrationUpdate;
}
