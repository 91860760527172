import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getError } from '../../../../../../components/utils/forms';
import TextAreaResize from '../../../../Components/TextAreaResize';
import TextAreaResizeEmoji from '../../../../Components/TextAreaResizeEmoji';
import FilePayloadContent from '../FilePayloadContent/FilePayloadContent';
import './InteractiveMessageContent.scss';


type InteractiveMessageContentProps = {
  attachedFile?: File;
  setAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

function InteractiveMessageContent({ attachedFile, setAttachedFile }: InteractiveMessageContentProps) {
  const { t } = useTranslation();
  const { setValue, watch, formState: { errors } } = useFormContext();
  const isError = getError(errors, 'header.url');
 
  const header = watch('header');

  const setUrl = (url: string) => {
    setValue('header.url', url, { shouldDirty: true, shouldValidate: url !== '' } );
  };
  
  return (
    <div className='interactiveMessageContent'>
      {header && header.type === 'text' &&
        <TextAreaResize className='regularText' name='header.text' minRows={1} maxRows={2}
          title={t('templates.add_interactive_templates.template_header.header_text_title')}
          subtitle={t('error.form.max_length', { limit: 60 })} />
      }
      {header && ['document', 'image', 'video'].includes(header.type) && <>
        <FilePayloadContent payload={header} error={!!isError} setUrl={setUrl}
          attachedFile={attachedFile} setAttachedFile={setAttachedFile} />
        <ErrorMessage errors={errors} name={'header.url'}
          render={({ message }) => <p className="smallText error">{message}</p>} />
      </>
      }
      <TextAreaResizeEmoji className='regularText' name='body' minRows={2} maxRows={4}
        title={t('templates.add_interactive_templates.template_header.header_body_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_body_subtitle')}
       />
       <TextAreaResizeEmoji className='regularText' name='footer' minRows={2} maxRows={4}
        title={t('templates.add_interactive_templates.template_header.header_footer_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_footer_subtitle')}
      />
    </div>
  );
}

export default InteractiveMessageContent;
