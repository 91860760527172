import './WabaTemplateLocale.scss';


type WabaTemplateLocaleProps = {
  locale: string;
  status: string;
};

function WabaTemplateLocale({ locale, status }: WabaTemplateLocaleProps) {
  return (
    <div className={`wabaTemplateLocale smallText ${status?.toLowerCase()}`}>{locale.toUpperCase()}</div>
  );
}

export default WabaTemplateLocale;
