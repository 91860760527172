import axiosInstance from '../../../api/AxiosInstance';

export type Tag = {
  id: number;
  companyId: number;
  name: string;
  description: string;
  backgroundColor: string;
};

export type CreateTag = {
  company_id: number;
  name: string;
  description?: string;
  backgroundColor: string;
};

export type UpdateTag = {
  name: string;
  description: string | null;
  backgroundColor: string;
};

export type TagsListRequestType = {
  company_id: number;
  limit?: number;
  offset?: number;
};

export const getTags = async ({ company_id, limit, offset }: TagsListRequestType): Promise<Tag[]> =>
  await axiosInstance.get(`/companies/${company_id}/tags/`, { params: { limit, offset } });

export const getTag = async (tag_id: number, company_id: number): Promise<Tag> =>
  await axiosInstance.get(`/companies/${company_id}/tags/${tag_id}`, {
    params: {
      tag_id,
      company_id,
    },
  });

export const createTag = async (requestOption: CreateTag): Promise<Tag> =>
  await axiosInstance.post(`/companies/${requestOption.company_id}/tags/`, {
    name: requestOption.name,
    description: requestOption.description,
    background_color: requestOption.backgroundColor,
  });

export const updateTag = async (tag_id: number, company_id: number, requestOption: UpdateTag): Promise<Tag> =>
  await axiosInstance.put(`/companies/${company_id}/tags/${tag_id}`, {
    name: requestOption.name,
    description: requestOption.description,
    background_color: requestOption.backgroundColor,
  });

export const deleteTag = async(tag_id: number, company_id: number): Promise<Tag> =>
  await axiosInstance.delete(`/companies/${company_id}/tags/${tag_id}`, {
    params: {
      tag_id,
      company_id,
    },
  });
