import './ClientsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PartnerCompaniesSelector } from '../../../PartnerCabinetSelector';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchPartnerCompanies } from '../../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import ClientItem from '../ClientItem';


const ClientsList = () => {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const partnerCompanies = useSelector(PartnerCompaniesSelector);

  const onUpload = () => {
    dispatch(fetchPartnerCompanies({ company_id: currentCompany.id, limit: 10, offset: partnerCompanies.length }));
  };

  return (
    <InfiniteScroll next={onUpload} hasMore={true} loader={null} dataLength={partnerCompanies.length}>
      {partnerCompanies.length > 0 && partnerCompanies.map((client) => <ClientItem key={client.id} client={client} />)}
    </InfiniteScroll>
  );
};

export default ClientsList;
