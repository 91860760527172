import React, { useEffect, useRef, useState } from 'react';
import './LinkItem.scss';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { getIconNavbar, getViewPopup } from "../../utils";
import PopupTitle from "../PopupTitle/PopupTitle";


type LinkProps = {
  location: string;
  isRolledUp?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const LinkItem = ({ location, isRolledUp, setIsOpen }: LinkProps) => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const currentTab = useRouteMatch<{ tab: string; }>('/companies/:companyId/:tab');
  const [selectedTab, setSelectedTab] = useState<string>();
  const refDiv = useRef<HTMLDivElement>(null);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();

  useEffect(() => {
    if (currentTab) {
      setSelectedTab(currentTab.params.tab);
    } else {
      setSelectedTab('chats');
    }
  }, [url]);

  const onSelectTab = (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedTab(e.currentTarget.id);
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const Icon = getIconNavbar[location];

  return (
    <Link to={`${url!.url}/${location}`}>
      <div className={`tab ${selectedTab === location ? 'active' : ''} ${isRolledUp ? 'rolledUp' : ''}`}
        id={location} ref={refDiv} onClick={onSelectTab}>
        {isRolledUp ? <PopupTitle text={t(`navbar.${location}`)}
          image={<Icon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}
          refPopup={refDiv} viewPopup={viewPopup} />
          : <Icon />}
        {!isRolledUp && <p>{t(`navbar.${location}`)}</p>}
      </div>
    </Link>
  );
};

export default LinkItem;
