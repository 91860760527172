import React, { useEffect, useRef, useState } from 'react';
import './Select.scss';
import { useOutsideClick } from '../utils/hooks';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';


type SelectProps<T> = {
  items: T[];
  labelKey: Extract<keyof T, string>;
  caption?: string;
  onChoose?: (item: T) => void;
  initValue?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Select = <T extends { id: string | number | null }>(
  { caption, items, labelKey, onChoose, initValue, ...props }: SelectProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef =  useRef<HTMLDivElement>(null);

  useOutsideClick(inputRef, () => setIsOpen(false), triggerRef);

  useEffect(()=> {
    if (initValue && inputRef.current) inputRef.current.value = initValue;
  }, [initValue]);

  const onSelectHandler = (value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value;
      setIsOpen(false);
      if (onChoose) {
        const item = items.find((itm) => itm[labelKey] === value);
        if (item) onChoose(item);
      }
    }
  };

  const isSelected = (value: string) => {
    if (inputRef.current) {
      return inputRef.current.value === value;
    }
    return false;
  };

  return (
    <div ref={triggerRef} className="select">
      <label>{caption}</label>
      <fieldset>
        <input ref={inputRef} readOnly={true} onClick={(e) => setIsOpen((prev) => !prev)} {...props} />
        <div className={`arrowIcon ${isOpen && 'opened'}`}>
          <ArrowIcon />
        </div>
        {isOpen && <ul>
          {items.map((item) => (
            <li key={item.id} className={isSelected(String(item[labelKey])) ? 'selected' : ''}
              onClick={() => onSelectHandler(String(item[labelKey]))}>
              {String(item[labelKey])}
            </li>
          ))}
        </ul>}
      </fieldset>
    </div>
  );
}

export default Select;
