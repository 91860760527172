import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { addAlertWithCustomText } from "../../../../components/Alert/alertSlice";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { getPartnerCompanies } from "../../PartnerCabinetAPI";
import { PartnerCompaniesSelector } from "../../PartnerCabinetSelector";
import { PartnerCompany } from "../../PartnerCabinetTypes";


const PARTNER_COMPANIES_LIMIT = 10;

export function usePartnerCompanies() {
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const companies = useSelector(PartnerCompaniesSelector);
  const [partnerCompanies, setPartnerCompanies] = useState<PartnerCompany[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [query] = useDebounce(search, 400);

  useEffect(() => {
    let isFetchData = true;

    const loadCompanies = async () => {
      const listCompanies = await getPartnerCompanies(
        { company_id: companyId, limit: PARTNER_COMPANIES_LIMIT, ...(query ? { query } : {}) });
      if (listCompanies) {
        setPartnerCompanies(listCompanies);
        setHasMore(listCompanies.length === PARTNER_COMPANIES_LIMIT);
      }
    }

    if (isFetchData) {
      if (query) {
        loadCompanies().catch((e) => dispatch(addAlertWithCustomText({ type: 'alarm', message: e.message })));
      } else {
        setPartnerCompanies(companies);
      }
    }

    return () => { isFetchData = false };

  }, [query]);
  
  const onUpload = () => {
    getPartnerCompanies({ company_id: companyId, limit: PARTNER_COMPANIES_LIMIT,
      offset: partnerCompanies.length, ...(query ? { query } : {}) })
    .then((res) => {
      setPartnerCompanies((prev) => {
        const ids = prev.map(company => company.id);
        return ([...prev, ...res.filter((item) => !ids.includes(item.id))]);
      });
      setHasMore(res.length === PARTNER_COMPANIES_LIMIT);
    })
    .catch((e) => dispatch(addAlertWithCustomText({ type: 'alarm', message: e.message })));

  };

  return { partnerCompanies, hasMore, onUpload, setSearch };
}
