import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationsSocket } from '../../../api/NotificationsProvider';
import { RootState } from '../../../store/store';
import { getNotificationsChannel } from '../Notifications/NotificationsSlice';


export function useNotificationSocketSetup() {
  const socket = useNotificationsSocket();
  const dispatch = useDispatch();
  const currentCompany = useSelector((state: RootState) => state.companies.currentCompany);

  useEffect(() => {
    if (currentCompany.id) {
      socket.emit('subscribe_notification_channels', { company_id: currentCompany.id });

      const handler = (payload: { channel_id: number }) => {
        dispatch(getNotificationsChannel({ channelId: payload.channel_id, companyId: currentCompany.id }));
      };
      socket.on('new_notification_channel', handler);

      return () => {
        socket.emit('unsubscribe_notification_channels', { company_id: currentCompany.id });
        socket.off('new_notification_channel', handler)
      };
    }
  }, [currentCompany]);
}
