import React, { useEffect, useRef, useState } from 'react';
import './Conversation.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { currentChatSelector } from '../../chatsSelector';
import { isActionBillSelector } from '../../billSelector';
import { selectChat } from '../../chatsSlice';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ChatsWithContacts } from '../../ChatTypes';
import { getLastMessage, getLastMessageDate } from './helpers';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow-down.svg';
import { ReactComponent as ChatTabIcon } from '../../../../assets/chat.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/chat-group.svg';
import NotificationBadge from '../NotificationBadge/NotificationBadge';
import ConversationChatsList from '../ConversationChatsList/ConversationChatsList';
import CreateChatPopup from '../CreateChatPopup/CreateChatPopup';
import Avatar from "../../../../components/Avatar";


type Props = {
  contactChats: ChatsWithContacts;
};

const Conversation: React.FC<Props> = ({ contactChats }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const params = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
    '/companies/:companyId/chats/:contactId/:chatId'
  );
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const currentChat = useSelector(currentChatSelector);
  const isActionBill = useSelector(isActionBillSelector);
  const [isCloseBill, setIsCloseBill] = useState(false);
  const [isOpenedDropdown, setIsOpenedDropdown] = useState(false);
  const [popupPosition, setPopupPosition] = useState('bottom');
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target) && triggerRef && !triggerRef.current.contains(e.target)) {
        // @ts-ignore
        setPopupIsVisible(false);
      }
    };

    if (isOpenedDropdown) {
      document.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, isOpenedDropdown, triggerRef]);

  useEffect(() => {
    if (currentChat && contactChats && contactChats.lastMessageInfo) {
      const isThisChatAvailable = contactChats.chats.find((chat) => chat.chatId === currentChat.chatId);

      if (contactChats.lastMessageInfo.chatId !== currentChat.chatId && isThisChatAvailable) {
        setIsOpenedDropdown(true);
      }

      if (!isThisChatAvailable) {
        setIsOpenedDropdown(false);
      }
    }
  }, [currentChat]);

  useEffect(() => {
    if(!isActionBill && isCloseBill){
      const contactChat = contactChats.chats.find((chat) => Number(params?.params.chatId) === chat.chatId);
      if(contactChat){
        dispatch(selectChat(contactChat));
        setIsCloseBill(false);
      }
    }
  }, [isActionBill]);


  const onSelectChat = () => {
    if (contactChats && contactChats.lastMessageInfo) {
      const contactChat = contactChats.chats.find(
        (chat) =>
          contactChats.lastMessageInfo!.chatId === chat.chatId &&
          contactChats.lastMessageInfo!.connectionId === chat.connectionId
      );
      if (contactChat && contactChat.chatId !== currentChat.chatId) {
        if (!isActionBill) {
          dispatch(selectChat(contactChat));
          dispatch(push(`${url?.url}/chats/${contactChats.contactId}/${contactChat.chatId}`));
        } else {
          dispatch(selectChat(currentChat));
          dispatch(push(`${url?.url}/chats/${contactChats.contactId}/${contactChat.chatId}`));
          setIsCloseBill(true);
        }
      }
    }
  };

  const onTriggerDropdown = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setIsOpenedDropdown(!isOpenedDropdown);
    setPopupIsVisible(false);
  };

  const onOpenPopup = (e: React.MouseEvent<HTMLDivElement>) => {
    const popupHeight = 300;

    if (window.innerHeight - e.clientY < popupHeight) {
      setPopupPosition('top');
    } else {
      setPopupPosition('bottom');
    }
    e.stopPropagation();
    setPopupIsVisible(true);
  };

  const checkIsActiveConversation = () => {
    if (contactChats && contactChats.chats.find(
      (contactChat) =>
        contactChat.chatId === currentChat.chatId && contactChat.connectionId === currentChat.connectionId
      )) {
      return 'active';
    }
    return '';
  };

  const getImage = () => {
    if (contactChats.isGroup) {
      return !contactChats.avatarUrl ? <GroupIcon /> : contactChats.avatarUrl;
    }
    return contactChats.avatarUrl ?? ''
  };

  return (
    <div className={`conversation ${checkIsActiveConversation()}`} onClick={onSelectChat}>
      <ArrowIcon className={`conversation__arrowIcon ${isOpenedDropdown ? 'active' : ''}`}
        onClick={onTriggerDropdown} />
      <div className={`conversation__content ${
        contactChats && contactChats.chats.find(
          (contactChat) =>
            contactChat.chatId === currentChat.chatId && contactChat.connectionId === currentChat.connectionId
        ) && 'active'
        }`}
      >
        <Avatar image={getImage()} name={contactChats.contactName} />
        <div className={`conversation__content__description ${isOpenedDropdown && 'active'}`}>
          <div className="conversation__content__description_upperBlock">
            <p className="conversation__content__description_upperBlock__username">{contactChats.contactName}</p>
            {contactChats && contactChats.lastMessageInfo && contactChats.lastMessageInfo.lastMessage && (
              <p className="conversation__content__description_upperBlock__lastMessageDate">
                {contactChats?.chats.length > 0 && getLastMessageDate(contactChats?.chats[0].lastMessage.createdAt, t)}
              </p>
            )}
          </div>
          {contactChats?.lastMessageInfo?.lastMessage && (
            <div className="conversation__content__description_bottomBlock">
              <p className={contactChats.lastMessageInfo.lastMessage.readAt
                  ? 'conversation__content__description_bottomBlock__lastMessageText'
                  : 'conversation__content__description_bottomBlock__lastMessageText unread'
                }
              >
                {contactChats?.chats.length>0 && getLastMessage(contactChats?.chats[0].lastMessage, t)}
              </p>
              <NotificationBadge notificationCount={contactChats.unansweredCount} />
            </div>
          )}
        </div>
      </div>
      {isOpenedDropdown && (
        <div className="conversation__chatsListContainer">
          {contactChats?.chats.length > 0 &&
           <ConversationChatsList contactId={contactChats.contactId} chats={contactChats?.chats}
            openDropdown={setIsOpenedDropdown} />}
          {!contactChats.isGroup &&
            <WrapperPermission permission='messagingChatCreate'>
              <div className="conversation__chatsListContainer_createChatButton" ref={triggerRef} onClick={onOpenPopup} >
                <ChatTabIcon />
                {t('chats.chats-list.create-button')}
                {popupIsVisible && (
                  <CreateChatPopup ref={ref} contactId={contactChats.contactId} position={popupPosition}
                    setIsVisibleCreateChatPopup={setPopupIsVisible} />
                )}
              </div>
            </WrapperPermission>}
        </div>
      )}
    </div>
  );
}

export default Conversation;
