import { useEffect, useState } from 'react';
import './BroadcastAlertWarn.scss';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SourceType } from '../../utils';
import {
  bdAnchorsBroadcast, getResourceForAlertWarning, necessaryResourcesAlertWarn
} from './lib/consts';
import ActionDialog from '../../../../components/Modals/ActionDialog';


type AlertBroadcastType = 'telegram' | 'whatsapp' | 'CANCEL_MODE_EDIT';

type BroadcastAlertWarnProps = {
  connectionId?: number;
  selectedSource: SourceType | null;
};

const BroadcastAlertWarn = ({ connectionId, selectedSource }: BroadcastAlertWarnProps) => {
  const { t, i18n } = useTranslation();
  const modeEdit = useRouteMatch<{ broadcastId: string }>('/companies/:companyId/broadcasts/edit/:broadcastId');
  const { reset, setValue, getValues, formState: { isDirty } } = useFormContext();
  const currentBroadcast = getValues();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const [currentResource, setCurrentResource] = useState<AlertBroadcastType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const onSuccess = () => {
    setCurrentResource(null);
  };

  const onCancel = () => {
    if (modeEdit && connectionId) {
      reset({ ...currentBroadcast, connection_id: connectionId });
      setCurrentResource('CANCEL_MODE_EDIT');
    } else {
      setValue('connection_id', '');
      setCurrentResource(null);
    }
  };

  useEffect(() => {
    if (selectedSource?.type && isDirty) {
      const resourceForAlertWarning = getResourceForAlertWarning(selectedSource.type);
      if (resourceForAlertWarning) {
        setCurrentResource(prevState => {
          if (prevState === 'CANCEL_MODE_EDIT') {
            return null;
          }
          return resourceForAlertWarning;
        });
      }
    }
  }, [selectedSource]);

  useEffect(() => {
    if (!openDialog) {
      setCurrentResource(null);
    }
  }, [openDialog]);

  useEffect(() => {
      setOpenDialog(currentResource !== null && currentResource !== 'CANCEL_MODE_EDIT');
  }, [currentResource]);

  return (<>
    {openDialog && currentResource !== null &&
      <ActionDialog title={t('broadcasts.form.alert.title')} action={t('broadcasts.form.alert.yes_continue')}
        cancel={t('cancel')} onAction={onSuccess} onCancel={onCancel} setIsOpen={setOpenDialog} background='light'>
        <div className='broadcastAlertWarn'>
          {currentResource === 'whatsapp' &&
            <>
              <p className='regularText'>
                {t('broadcasts.form.alertWhatsapp.text.warning',
                  { resource: necessaryResourcesAlertWarn[currentResource] })}</p>
              <p className='regularText'>{t('broadcasts.form.alertWhatsapp.text.advice')}&nbsp;
                <a href={`${url?.url}/settings/connections/waba`} target='_blank' rel='noreferrer'>
                  {t('broadcasts.form.alertWhatsapp.link.waba')}
                </a>
              </p>
              <p className='regularText'>{t('broadcasts.form.alertWhatsapp.text.advice_refine')}&nbsp;
                <a href={bdAnchorsBroadcast[currentResource][i18n.language]} target='_blank' rel='noreferrer'>
                  {t('broadcasts.form.alertWhatsapp.link.whatsapp')}
                </a>
                <br/>
                {t('broadcasts.form.alertWhatsapp.text.refine')}
              </p>
            </>
          }
          {currentResource === 'telegram' &&
            <>
              <p className='regularText'>
                {t('broadcasts.form.alert.text.warning', { resource: necessaryResourcesAlertWarn[currentResource] })}
              </p>
              <p className='regularText'>{t('broadcasts.form.alert.text.advice')}&nbsp;
                <a href={bdAnchorsBroadcast[currentResource][i18n.language]} target='_blank' rel='noreferrer'>
                  {t('broadcasts.form.alert.link.telegram')}
                </a>{t('broadcasts.form.alert.text.no_return')}
              </p>
              <p className='regularText'>{t('broadcasts.form.alert.text.refine')}</p>
            </>
          }
        </div>
      </ActionDialog>
    }
  </>);
};

export default BroadcastAlertWarn;
