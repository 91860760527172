import { useContext } from 'react';
import { NewConnection } from '../../../../../api/CompanyAPI';
import { connectionItemContext, connectionsUploadContext } from '../../utils/connections';
import InfiniteScroll from 'react-infinite-scroll-component';
import ConnectionCardInner from '../ConnectionCardTyped';
import './ConnectionList.scss';


type ConnectionListProps = {
  connections: NewConnection[];
  onDelete: (connection: NewConnection) => void;
};

const ConnectionList = ({ connections, onDelete }: ConnectionListProps) => {
  const { service, hasMore, onUpload } = useContext(connectionsUploadContext);
  
  return (
    <InfiniteScroll
      next={onUpload}
      hasMore={hasMore}
      inverse={false}
      scrollableTarget='scrollingDiv'
      loader={null}
      dataLength={connections.length}
    >
      <div className="connectionList" id='scrollingDiv'>
        {connections && connections.map((connection) => (
            <connectionItemContext.Provider key={connection.id} value={{ connection, onDelete }} >
              <ConnectionCardInner service={service} />
            </connectionItemContext.Provider>
          ))}
      </div>
    </InfiniteScroll>
  );
};

export default ConnectionList;
