import { useEffect } from 'react';
import './PartnerNotifications.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnerCompanies, fetchPartnerCompanyNotifications } from '../PartnerCabinetSlice';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import NotificationContext from './components/NotificationContext';
import NotificationsView from './components/NotificationsView';


function PartnerNotifications() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  useEffect(() => {
    dispatch(fetchPartnerCompanies({ company_id: currentCompany.id }));
    dispatch(fetchPartnerCompanyNotifications({ companyId: currentCompany.id }));
  }, []);

  return (
    <div className="partnerNotifications">
      <NotificationContext>
        <div className="partnerNotifications__header">
          <h1>{t('partner_cabinet.notifications.title')}</h1>
          <p className='regularText'>{t('partner_cabinet.notifications.text')}</p>
        </div>
        <NotificationsView />
      </NotificationContext>
    </div>
  );
}

export default PartnerNotifications;
