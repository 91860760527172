import { CompanyOnboarding, companyOnboardingSteps, CompanyOnboardingSteps } from '../../../api/types';
import WatchVideo from '../Steps/WatchVideo';
import ChooseRole from '../Steps/ChooseRole';
import ChooseNiche from '../Steps/ChooseNiche';
import ChooseCrm from '../Steps/ChooseCrm';
import AddConnection from '../Steps/AddConnection';
import RemindTestPayment from '../Steps/RemindTestPayment/RemindTestPayment';


type StepsConfig = {
  component: () => JSX.Element;
  isFinish: (onboarding: CompanyOnboarding, type: CompanyOnboardingSteps) => boolean;
  isWizardView: boolean;
};

type ProgressStepType = {
  type: CompanyOnboardingSteps;
  component: () => JSX.Element;
};

const isFinish = (onboarding: CompanyOnboarding, type: CompanyOnboardingSteps) => {
  const step = onboarding.steps.find((step) => step.type === type);
  if (step && step.isFinished) {
    return true;
  }
  return false;
};

export const guideSteps = ['add_connection', 'add_integration', 'setup_notifications'];

export const configForOnboardingSteps: Record<CompanyOnboardingSteps, StepsConfig> = {
  watch_intro_video: { component: WatchVideo, isFinish, isWizardView: true },
  choose_role: { component: ChooseRole, isFinish, isWizardView: true },
  choose_niche: { component: ChooseNiche, isFinish, isWizardView: true },
  choose_crm: { component: ChooseCrm, isFinish, isWizardView: true },
  add_connection: { component: AddConnection, isFinish, isWizardView: true },
  add_integration: { component: AddConnection, isFinish, isWizardView: true },
  setup_notifications: { component: AddConnection, isFinish, isWizardView: true },
  remind_test_payment: { component: RemindTestPayment, isFinish, isWizardView: false },
};

export const currentOnboardingStep = (onboarding: CompanyOnboarding) => {
  for (let i = 0; i < companyOnboardingSteps.length; i++) {
    if (!configForOnboardingSteps[companyOnboardingSteps[i]].isFinish(onboarding, companyOnboardingSteps[i])) {
      return companyOnboardingSteps[i];
    }
  }
};

export const getProgressSteps = () => {
  const array: ProgressStepType[] = [];

  Object.entries(configForOnboardingSteps).forEach((step) =>
    array.push({ type: step[0], component: step[1].component } as ProgressStepType)
  );

  return array.reduce((arr: ProgressStepType[], step) => {
    if (!arr.find((step1) => step1.component === step.component)) {
      if (configForOnboardingSteps[step.type].isWizardView) {
        arr.push(step);
      }
    }
    return arr;
  }, []);
};
