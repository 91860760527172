import './RewardItem.scss';
import { useTranslation } from 'react-i18next';
import { PartnerReward } from '../../../PartnerCabinetTypes';
import { dateFormatReward } from '../../utils/utils';


type Props = {
  reward: PartnerReward;
};

const RewardItem = ({ reward }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="rewardItem">
      <p className="regularText middle">{dateFormatReward(reward.period)}</p>
      <div className="rewardItem__client">
        <p className="regularText">{reward.companyName}</p>
        <p className="smallText">ID {reward.id}</p>
      </div>
      <p className="regularText">{t(`partner_cabinet.finances.type_pay.${reward.rewardType.toLowerCase()}`)}</p>
      <p className="regularText">{reward.periodTopupsValue}</p>
      <p className="regularText">{reward.periodSpendingsValue || ''}</p>
      <p className="regularText">{reward.rewardValue}</p>
    </div>
  );
};

export default RewardItem;
