import React from "react";
import './NotificationBanner.scss';
import { ALL_CHANNEL_TYPES, NotificationChannelTypes } from '../../NotificationsTypes';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import NotificationConnect from '../NotiificationConnect';


type Props = {
  setChannelConnection: React.Dispatch<NotificationChannelTypes | null>;
};

const NotificationBanner = ({ setChannelConnection }: Props) => {
  return (
    <WrapperPermission permission="notificationsCreate">
      <div className="notificationBanner">
        {ALL_CHANNEL_TYPES.map((type) => (
          <NotificationConnect key={type} type={type} setChannelConnection={setChannelConnection} />
        ))}
      </div>
    </WrapperPermission>
  );
};
export default NotificationBanner;
