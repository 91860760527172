import { useContext } from "react";
import { partnerNotifications } from "../../utils";
import ChannelCompanies from "./ChannelCompanies";
import ChannelCreate from "./ChannelCreate";


const ClientsNotifications = () => {
  const { channel } = useContext(partnerNotifications);

  return (<>
    {channel === null ? <ChannelCreate />: <ChannelCompanies />}
  </>);
};

export default ClientsNotifications;
