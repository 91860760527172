import { useState } from "react";


export function useTooltip() {
  const [viewPopup, setViewPopup] = useState<boolean>(false);

  const viewPopupOn = () => {
    setViewPopup(true);
  };

  const viewPopupOff = () => {
    setViewPopup(false);
  };
  
  return { viewPopup, viewPopupOn, viewPopupOff };
}
