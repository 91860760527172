import React, { useEffect, useState } from 'react';
import './DropdownChatPopup.scss';
import { ReactComponent as ArrowIcon } from "../../../../../assets/arrow-down.svg";
import { useTranslation } from "react-i18next";
import { updateErrors } from "../../../chatsSlice";
import { useDispatch, useSelector } from "react-redux";
import { chatsIconsPopupTypes, ConnectionPopup, getConnectionsPopup } from "../utils";
import { allConnectionsForChatSelector } from "../../../companiesSelector";
import { useMobile } from "../../../../../components/utils/hooks";


type Props = {
  selectedConnection: ConnectionPopup | undefined;
  setSelectedConnection:  React.Dispatch<React.SetStateAction<ConnectionPopup | undefined>>;
  setSwitchContactType: React.Dispatch<React.SetStateAction<string>>;
}

const DropdownChatPopup = ({ selectedConnection, setSelectedConnection, setSwitchContactType }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const [sortedConnections, setSortedConnections] = useState<ConnectionPopup[]>();
  const allConnectionsForChat = useSelector(allConnectionsForChatSelector);
  const isMobile = useMobile();

  useEffect(() => {
    // fill list of connections for sender
    dispatch(updateErrors({ key: 'createNewChat', value: null }));
    setSortedConnections(getConnectionsPopup(allConnectionsForChat));
  }, []);

  const checkAvailableNumbers = () => !(sortedConnections?.length === 0);

  const getPlaceholder = (isEmpty: boolean): string => {
    if (isEmpty) {
      return t('chats.chats-list.create-chat-popup.no_sender');
    }
    return t('chats.chats-list.create-chat-popup.placeholder');
  };

  const getNumberPlaceholder = () => {
    if (selectedConnection) {
      return selectedConnection.name;
    }
    return getPlaceholder(!checkAvailableNumbers());
  };

  const onSelectConnection = (connection: ConnectionPopup, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedConnection(connection);
    dispatch(updateErrors({ key: 'createNewChat', value: null }));
    setIsOpened(false);
    if (connection.type !== 'telegram') {
      setSwitchContactType('phone');
    }
  };

  const onClickConnectionDropdown = () => {
    if (checkAvailableNumbers()) {
      setIsOpened(!isOpened);
    }
  };

  return (
    <div className="dropdownChatPopup">
      <p className={isMobile ? 'regularText' : 'smallText'}>{t('chats.chats-list.create-chat-popup.connection_label')}</p>
      <div className={`dropdownChatPopup_currentConnection ${
        !checkAvailableNumbers() ? 'disabled' : ''}`}
           onClick={onClickConnectionDropdown}
      >
        <div className='dropdownChatPopup_currentConnection__icon'>
          {selectedConnection && chatsIconsPopupTypes[selectedConnection.type]}
        </div>
        <p className="dropdownChatPopup_currentConnection__number">
          {getNumberPlaceholder()}
        </p>
        <div className={`dropdownChatPopup_currentConnection__arrowIcon ${isOpened ? 'active' : ''}`}>
          <ArrowIcon />
        </div>
      </div>
      {isOpened && (
        <div className="dropdownChatPopup_dropdown">
          {sortedConnections?.map((connect) => (
            <div className="dropdownChatPopup_dropdown__item"
                 key={connect.id}
                 onClick={(e) => onSelectConnection(connect, e)}
            >
              <p>{chatsIconsPopupTypes[connect.type]}</p>
              <p className="dropdownChatPopup_dropdown__item-phone">
                {connect.name}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownChatPopup;
