import React from 'react';
import './AttachmentImagePreview.scss';
import { nanoid } from 'nanoid';
import { AttachmentPreviewComponent } from '../ChatField/WabaTemplateComponent/types';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';

const AttachmentImagePreview: AttachmentPreviewComponent = ({
  attachedFile,
  setAttachedFiles,
  width,
  height,
  customTemplateEditPayload,
  removeTrashIcon,
}) => (
  <div className="imagePreviewContainer" style={{ width, height }}>
    <div className="imagePreviewContainer__image">
      <img
        src={attachedFile?.name
          ? URL.createObjectURL(attachedFile)
          : customTemplateEditPayload?.fileUrl ? customTemplateEditPayload.fileUrl : ''}
        key={nanoid()}
        alt="preview img"
        width={width - 40}
        height={height - 15}
      />
    </div>
    {!removeTrashIcon && <DeleteIcon className="imagePreviewContainer__trashIcon" onClick={setAttachedFiles} />}
  </div>
);

export default AttachmentImagePreview;
