import { useTranslation } from 'react-i18next';


type Named = {
  id: string;
  name: string;
};

const roles = ['sales_head', 'technical_specialist', 'integrator', 'business_owner', 'marketing', 'manager', 'other'] as const;

const niches = [
  'automotive', 'administrative_staff', 'security', 'top_middle_management', 'mining', 'home_service_personnel',
  'procurement', 'information_technologies', 'art_entertainment_media', 'marketing_pr', 'medicine_pharmaceutical',
  'science_education', 'sales_customer_service', 'manufacturing_service', 'working_personnel', 'retail', 'agriculture',
  'sport_fitness_beauty', 'strategy_investments_consulting', 'insurance', 'construction_real_estate',
  'transport_logistics', 'tourism_hotels_restaurants', 'hr_management_training', 'finance_accounting', 'lawyers',
  'other'] as const;

export type RolesType = typeof roles[number];
export type NichesType = typeof niches[number];

export const getTranslationRoles = () => {
  const { t } = useTranslation();
  const arrayRoles: Named[] = [];

  for (let i = 0; i < roles.length; i++) {
    arrayRoles.push({ id: roles[i], name: t(`company_onboarding.role.${roles[i]}`) });
  }

  return arrayRoles;
};

export const getTranslationNiches = () => {
  const { t } = useTranslation();
  const arrayNiches: Named[] = [];

  for (let i = 0; i < niches.length; i++) {
    niches[i] !== 'other' && arrayNiches.push({ id: niches[i], name: t(`company_onboarding.niche.${niches[i]}`) });
  }

  arrayNiches.sort((a, b) => a.name.localeCompare(b.name));
  arrayNiches.push({ id: 'other', name: t(`company_onboarding.niche.other`) });

  return arrayNiches;
};
