import React, { useState, useEffect } from "react";
import moment from 'moment';
import { ReactComponent as TimerIcon } from '../../../../../assets/clock24.svg';
import { getDateTime } from '../../../../../utils/getDateTime/getDateTime';

interface ITimer {
  date:string
  handlerEndTime: (timeIsOver:boolean) => void
}
function Timer({ date,handlerEndTime }: ITimer) {
  const [timeLeft, setTimeLeft] = useState("");
  const endTime = getDateTime().moment(date)
  const isOverTime = timeLeft === "00:00";

  useEffect(() => {
    const interval = setInterval(() => {
      const now = getDateTime().moment();
      const diff = moment.duration(endTime.diff(now));

      if (diff.asSeconds() < 0) {
        setTimeLeft("00:00");
        handlerEndTime(true);
      } else {
        const hours = diff.hours().toString().padStart(2, "0");
        const minutes = diff.minutes().toString().padStart(2, "0");
       // const seconds = diff.seconds().toString().padStart(2, "0");
        handlerEndTime(false);
        setTimeLeft(`${hours}:${minutes}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return timeLeft ? (
      <div className={`chatField__timer ${isOverTime && 'timeLeft'}`}>
        <TimerIcon />
        <span className="chatField__timer_text">{timeLeft}</span>
      </div>
    )
    :null
}

export default Timer;
