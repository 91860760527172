import { ReactNode, RefObject, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as TriangleIcon } from '../../../assets/modal-tail.svg';
import './ToolTip.scss';


type ToolTipProps = {
  refElement: RefObject<HTMLElement | SVGSVGElement>;
  children: ReactNode;
  modal?: boolean;
  tail?: boolean;
  onClose?: () => void;
};

type StylePosition = {
  top: number;
  left: number;
  width: number | string;
  height: number | string;
};

const TOOLTIP_PADDING = 8;
const SIZE_EXTENTION = 1.1;

function ToolTip ({ children, refElement, modal=false, tail=false, onClose }: ToolTipProps) {
  const [stylePosition, setStylePosition] = useState<StylePosition>();
  const [tailPosition, setTailPosition] = useState<string>('down right');
  const tootipRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (refElement.current && tootipRef.current) {
      const width = tootipRef.current.getBoundingClientRect().width;
      const height = tootipRef.current.getBoundingClientRect().height;
      let top = 0;
      let left = 0;
      let vertical = 'down';
      let horizontal = 'right';
      if (window.innerWidth - refElement.current.getBoundingClientRect().right > SIZE_EXTENTION * width
        && refElement.current.getBoundingClientRect().left > width) {
        left = refElement.current.getBoundingClientRect().left + refElement.current.getBoundingClientRect().width / 2
          - Math.floor(width / 6);
        } else {
          left = refElement.current.getBoundingClientRect().left + refElement.current.getBoundingClientRect().width / 2
          - Math.floor(width / 6 * 5);
          horizontal = 'left';
      }
      if (window.innerHeight - refElement.current.getBoundingClientRect().bottom > SIZE_EXTENTION * height) {
        top = refElement.current.getBoundingClientRect().bottom + TOOLTIP_PADDING;
      } else {
        top = refElement.current.getBoundingClientRect().top - height - TOOLTIP_PADDING;
        vertical = 'up';
      }

      const pos = { top, left, width, height };
      setStylePosition(pos);
      setTailPosition(`${vertical} ${horizontal}`);
    }
  }, [refElement]);

  return ReactDOM.createPortal((<>
    {modal && <div className='toolTip__wrapper' onClick={onClose}>{}</div>}
    <div ref={tootipRef} className='toolTip' style={stylePosition}>
      {children}
      {tail && <TriangleIcon className={`toolTip__tail ${tailPosition}`}/>}
    </div>
  </>), document.body);
}

export default ToolTip;
