import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import ru from 'date-fns/locale/ru';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg'
import './DateTimePicker.scss';


const formatDate: Record<string, string> = { ru: 'dd.MM.yyyy HH:mm', en: 'MM/dd/yyyy h:mm aa', es: 'dd/MM/yyyy HH:mm' };
registerLocale("es", es);
registerLocale("ru", ru);

type DateTimePickerProps = {
  name: string;
  lable?: string;
  withTime?: boolean;
  todayButton?: boolean;
  dateStart?: Date;
  dateEnd?: Date;
};

function DateTimePicker({ name, lable, withTime=false, dateStart, dateEnd, todayButton=false }: DateTimePickerProps) {
  const { t, i18n } = useTranslation();
  const { control, getValues } = useFormContext();

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className='dateTimePicker'>
      {lable && <p>{lable}</p>}
      <Controller name={name} control={control} render={({ field }) =>
        <DatePicker {...field} name={name} fixedHeight
          showTimeSelect={withTime} timeCaption={t('time')} timeFormat='p' timeIntervals={5}
          filterTime={filterPassedTime}
          locale={i18n.language} dateFormat={formatDate[i18n.language]}
          selected={getValues(name)}
          onChange={(value) => {field.onChange(value)}}
          todayButton={todayButton ? t('todayDate') : null}
          minDate={dateStart} maxDate={dateEnd} autoComplete='off' />
      } />
      <CalendarIcon />
    </div>
  );
}

export default DateTimePicker;
