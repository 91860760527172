import './InputsEmails.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';


const InputsEmails = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const emails = useFieldArray({ control, name: 'emails' });

  const addEmail = () => {
    emails.append({ value: '' });
  };

  return (
    <div className="inputsEmails">
      <p className='regularText middle'>{t('contacts.contactsList.email')}</p>
      {emails.fields.map(({ id }, index: number) => (
        <div key={id} className="emailItem">
          <Input name={`emails.${index}.value`} data-testid='email' />
          <Button color='white' textType='small' image={<DeleteIcon />}
            onClick={() => emails.remove(index)} data-testid='delete-email' />
        </div>
      ))}
      <div className="inputsEmails__footer">
        <Button text={t('contacts.editContact.addEmailAddress')} color='white'
          textType='small' image={<PlusIcon />} onClick={addEmail} data-testid='add-email' />
      </div>
    </div>
  );
};

export default InputsEmails;
