import React from 'react';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Button from '../../../../components/Button';
import BroadcastsLog from '../BroadcastsLog';
import BroadcastsPlan from '../BroadcastsPlan';
import './BroadcastsView.scss';


function BroadcastsView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const onCreate = () => {
    dispatch(push(`${location.pathname}/create`));
  };

  return (
    <div className='broadcastsView'>
      <div className='broadcastsView__header'>
        <h1>{t('broadcasts.title')}</h1>
        <WrapperPermission permission='broadcastsCreate' >
          <Button type='button' color='orange' image={<PlusIcon />}
            textType='regular' text={t('broadcasts.create')} onClick={onCreate} />
        </WrapperPermission>
      </div>
      <div className='broadcastsView__content'>
        <BroadcastsPlan />
        <BroadcastsLog />
      </div>
    </div>
  );
}

export default BroadcastsView;
