import './RewardsView.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../store/store';
import { fetchPartnerRewards } from '../../../PartnerCabinetSlice';
import InfiniteScroll from "react-infinite-scroll-component";
import RewardItem from '../RewardItem';


const RewardsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useAppSelector((state) => state.companies.currentCompany);
  const rewards = useAppSelector((state) => state.partners.partnerRewards);

  const onUpload = () => {
    dispatch(fetchPartnerRewards({ company_id: currentCompany.id, offset: rewards.length, limit: 10 }));
  };

  return (
    <div className="rewardsView">
      <div className="rewardsView__header smallText semibold">
        <span>{t('partner_cabinet.finances.rewards.period')}</span>
        <span>{t('partner_cabinet.finances.rewards.client')}</span>
        <span>{t('partner_cabinet.finances.rewards.type_pay')}</span>
        <span>{t('partner_cabinet.finances.rewards.amount_pay')}</span>
        <span>{t('partner_cabinet.finances.rewards.amount_write')}</span>
        <span>{t('partner_cabinet.finances.rewards.reward')}</span>
      </div>
      <div className="rewardsView__list" id="scrollList">
        <InfiniteScroll next={onUpload}
          hasMore={true} inverse={false} loader={null}
          scrollableTarget="scrollList"
          dataLength={rewards.length}
        >
          {rewards.map((reward) => <RewardItem key={reward.id} reward={reward} />)}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default RewardsView;
