import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotesAddIcon } from '../../../../assets/notes-add.svg';
import Button from '../../../../components/Button';
import NoteForm from '../NoteForm';


type AddNoteTypeProps = {
  contactId: number;
};

const AddNote: FC<AddNoteTypeProps> = ({ contactId }) => {
  const { t } = useTranslation();
  const [toggleAddNote, setToggleAddNote] = useState<boolean>(false);

  return (
    <>
      {toggleAddNote && <NoteForm contactId={contactId} togglePopUp={() => setToggleAddNote(!toggleAddNote)} />}
      <Button type="button" color="white" textType="small" text={t('contacts.detailContact.addNote')}
        image={<NotesAddIcon />} disabled={toggleAddNote} id='add-note'
        onClick={() => setToggleAddNote(!toggleAddNote)} data-testid="add-note" />
    </>
  );
};

export default AddNote;
