import './MenuButtons.scss';
import { useDispatch } from 'react-redux';
import { clearOnboardingGuide, setOnboardingSwitchModal } from '../../../../views/Chat/companiesSlice';
import { updateIsOnbourdingModal, useOnbordingMenu } from '../../utils/onboarding';
import { YandexMetrikaCall } from '../../../../utils/utils';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { ReactComponent as MinusIcon } from '../../../../assets/minus.svg';


type MenuButtonsProps = {
  integrator?: string;
};

function MenuButtons({ integrator }: MenuButtonsProps) {
  const dispatch = useDispatch();
  const { closeBoarding, isIntegrator, isCollapse } = useOnbordingMenu();

  const onHide = () => {
    dispatch(setOnboardingSwitchModal(false));
    updateIsOnbourdingModal('isOnbourdingModal', false);
    dispatch(clearOnboardingGuide());
    YandexMetrikaCall('reachGoal', 'onboarding_hide_clicked');
  };

  if (integrator === undefined) return (
    <div className='menuButtons'>
      {isIntegrator && <CloseIcon className="close" onClick={closeBoarding} />}
      {(!isIntegrator && isCollapse) && <MinusIcon className="minusIcon" onClick={onHide} />}
    </div>
  );

  if (integrator === 'integrator') return (
    <div className='menuButtons'>
      <CloseIcon className="close" onClick={closeBoarding} />
    </div>
  );

  if (integrator !== 'integrator') return (
    <div className='menuButtons'>
      {isCollapse && <MinusIcon className="minusIcon" onClick={onHide} />}
    </div>
  );

  return null;
}

export default MenuButtons;
