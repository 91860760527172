import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile, useOutsideClick } from '../../../../components/utils/hooks';
import './PopUpAction.scss';
import Button from '../../../../components/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';


type PopUpActionProps = {
  title: string;
  actionButtonCaption: string;
  children: JSX.Element;
  cancelButton?: () => void;
  reset?: () => void;
  onSubmit: () => void;
  disabled?: boolean;
};

function PopUpAction({ title, children, actionButtonCaption, cancelButton, onSubmit, disabled }: PopUpActionProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<SVGSVGElement>(null);

  const cancel = () => cancelButton && cancelButton();

  useOutsideClick(contentRef, cancel, triggerRef);

  return (
    <div ref={contentRef} className="popUpAction">
      <div className="popUpAction__header">
        <h4>{title}</h4>
        <CloseIcon ref={triggerRef} onClick={cancel} />
      </div>
      <div className="popUpAction__content">{children}</div>
      <div className="popUpAction__footer">
        <Button type="submit" color='orange' textType={isMobile ? 'regular' : 'small'} text={actionButtonCaption}
          disabled={!disabled} onClick={onSubmit} data-testid='submit' />
        {!isMobile &&
          <Button type='button' color='white' textType={isMobile ? 'regular' : 'small'}
            text={t('cancel')} onClick={cancel} data-testid='cancel' />
        }
      </div>
    </div>
  );
};

export default PopUpAction;
