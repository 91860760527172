import './TemplatesList.scss';
import LinkTab from './LinkTab';
import { ReactComponent as MyTemplatesIcon } from '../../../assets/smart.svg';
import { ReactComponent as InteractiveMessagesIcon } from '../../../assets/users.svg';
import { ReactComponent as WabaTemplatesIcon } from '../../../assets/template.svg';


export const templatesTabs = [
  {
    name: 'templates.my_templates',
    path: 'my-templates',
    icon: <MyTemplatesIcon />
  },
  {
    name: 'templates.interactive_messages',
    path: 'interactive-messages',
    icon: <InteractiveMessagesIcon />
  },
  {
    name: 'templates.waba-templates',
    path: 'waba-templates',
    icon: <WabaTemplatesIcon />
  }
];

const TemplatesList = () => (
  <div className="templatesList">
    {templatesTabs.map((tab) => (
      <LinkTab key={tab.name} tabName={tab.name} tabUrl={tab.path} tabIcon={tab.icon} />
    ))}
  </div>
);

export default TemplatesList;
