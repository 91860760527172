import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../utils';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import Button from '../../../../components/Button';
import './ItemChangeAt.scss';


type ItemChangeAtProps = {
  name?: string;
  date: string;
  onEdit: () => void;
  onDelete: () => void;
};

function ItemChangeAt({ name, date, onEdit, onDelete }: ItemChangeAtProps) {
  const { i18n } = useTranslation();

  const onEditHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit();
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <div className='itemChangeAt'>
      <div className='itemChangeAt__content'>
        <p className='itemChangeAt__date'>
          {dateTimeFormat(date, 'shortDate', i18n.language)}, {dateTimeFormat(date, 'shortTime', i18n.language)}
        </p>
        <p className='itemChangeAt__name'>{name}</p>
      </div>
      <div className='itemChangeAt__buttons'>
        <WrapperPermission permission='broadcastsEdit'>
          <Button type='button' color='white' textType='small' image={<EditIcon />} id='edit' onClick={onEditHandler} />
        </WrapperPermission>
        <WrapperPermission permission='broadcastsDelete'>
          <Button type='button' color='white' textType='small' image={<DeleteIcon />}
            id='delete' onClick={onDeleteHandler} />
        </WrapperPermission>
      </div>
    </div>
  );
}

export default React.memo(ItemChangeAt);
