import React, { useEffect, useRef } from 'react';
import './ProfileInvoice.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Invoice } from '../../../../Chat/BillAPI';
import { updateIsVisibleProfileInvoice } from '../../../../Chat/billSlice';
import { getInvoiceDate, getInvoiceStatus, getSymbolCurrency, getTerminalAndLogo } from '../OrderItem/helpers';
import { formatNumberLocale } from "../../../../../utils/utils";
import { terminalsSelector } from '../../../../Chat/billSelector';
import { useOutsideClick } from '../../../../../components/utils/hooks';
import { currentContactSelector } from '../../../../Contacts/contactsSlice/contactsSelector';
import { fetchContact } from '../../../../Contacts/contactsSlice/contactsSlice';
import ProfileInvoiceItem from './ProfileInvoiceItem/ProfileInvoiceItem';


type Props = {
  invoice: Invoice;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileInvoice: React.FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);

  const terminals = useSelector(terminalsSelector);
  const currentContact = useSelector(currentContactSelector);

  useEffect(() => {
    if (invoice.contactId) {
      dispatch(fetchContact({
        contactId: invoice.contactId,
        companyId: Number(url?.params.companyId),
      }));
    }
  }, [invoice.contactId, url?.params.companyId]);

  const onClickContact = () => {
    if (invoice.contactId) {
      history.push(`/companies/${url?.params.companyId}/contacts/${invoice.contactId}`);
    }
  };

  const onCloseDropdown = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(updateIsVisibleProfileInvoice({
      isVisibleProfileInvoice: false,
      profileInvoice: null,
    }));
  };

  useOutsideClick(wrapperRef, onCloseDropdown, triggerButtonRef);

  const buyerText = () => {
    if (!invoice.contactId && (invoice.receipt && !invoice.receipt.clientName)) {
      return t('orders.amo_buyer');
    }
    if (invoice.contactId && currentContact && currentContact.name) {
      return currentContact.name;
    }
    return t('orders.amo_buyer');
  }

  return (
    <div className='profileInvoice'>
      <div className='profileInvoice_order'>
        <h4>{t('orders.id_order_title')}</h4>
        <p className='regularText'>{t('orders.order')} №{invoice.orderId}</p>
      </div>
      <div className='profileInvoice_buyer'>
        <h4>{t('orders.buyer')}</h4>
        <p className='regularText' onClick={onClickContact}
          style={invoice.contactId && currentContact && currentContact.name ? { color: '#FE6D04', cursor: 'pointer' } : {}}>
          {buyerText()}
        </p>
      </div>
      <div className='profileInvoice_container'>
        <div className='profileInvoice_container_left'>
          <div className='profileInvoice_container_left_date'>
            <h4>{t('orders.date_title')}</h4>
            <p className='regularText'>{getInvoiceDate(invoice.createdAt, t, false, true)}</p>
          </div>
          <div className='profileInvoice_container_left_terminal'>
            <h4>{t('orders.terminal')}</h4>
            {getTerminalAndLogo(invoice, terminals)}
          </div>
        </div>
        <div className='profileInvoice_container_right'>
          <div className='profileInvoice_container_right_amount'>
            <h4>{t('orders.amount_title')}</h4>
            <p className="regularText semibold">
              {formatNumberLocale(invoice.amount, 2, i18n.language)} {getSymbolCurrency(invoice)}
            </p>
          </div>
          <div className='profileInvoice_container_right_status'>
            <h4>{t('orders.status_title')}</h4>
            {getInvoiceStatus(invoice, t)}
          </div>
        </div>
      </div>
      <div className='profileInvoice_client'>
        <h4>{t('orders.client_name')}</h4>
        <p className='regularText'>
          {invoice.responsibleMemberId
            ? (invoice.receipt && invoice.receipt.clientName) || ''
            : t('orders.amo_client')}
        </p>
      </div>
      <div className='profileInvoice_url'>
        <h4>{t('orders.link')}</h4>
        <a href={invoice.url} target='_blank' rel='noreferrer'>
          <p>{invoice.url}</p>
        </a>
      </div>
      <div className='profileInvoice_products'>
        <h4>{t('orders.products.title')}</h4>
        <div className='profileInvoice_products_title'>
          <p className='profileInvoice_products_title_product'>{t('orders.products.product')}</p>
          <p className='profileInvoice_products_title_count'>{t('orders.products.count')}</p>
          <p className='profileInvoice_products_title_price'>{t('orders.products.amount')}</p>
        </div>
        <div className='profileInvoice_products_list'>
          {invoice.receipt && invoice.receipt.items.map(item => (
            <ProfileInvoiceItem product={item} forCurrency={invoice} key={item.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileInvoice;
