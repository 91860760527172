import {
  checkItemDialog, checkItemSubscription, invoiceItemTypes, SubscriptionInvoiceDialog, SubscriptionInvoiceItem,
} from '../../../../Settings/Subscriptions/SubscriptionsTypes';
import { formatNumberLocale } from '../../../../../utils/utils';
import { SelectedSubscriptionsType } from '../ClientView/ClientView';
import { settingConnections } from '../../../../Settings/Connections/utils/connections';


export function ClientInvoiceItems(
  sumInvoice: (SubscriptionInvoiceItem | SubscriptionInvoiceDialog)[], selectedSubscriptions: SelectedSubscriptionsType[], lang: string = 'ru') {
  return sumInvoice.map((item) => {
    if (checkItemSubscription(item)) {
      const type = selectedSubscriptions.find((sub) => sub.id === item.subscriptionId);
      if (type) {
        return {
          type: type.type, serviceType: settingConnections[type.type].type,
          itemType: invoiceItemTypes[item.type],
          volume: item.products.length,
          amount: formatNumberLocale(AmountItemPay(item), 2, lang),
          discount: formatNumberLocale(DiscountItemPay(item), 2, lang),
          total: formatNumberLocale(AmountPay(item), 2, lang),
        };
      }
    }
    if (checkItemDialog(item)) {
      return { type: 'waba_dialog', itemType: invoiceItemTypes[item.type],
        serviceType: 'dialog', volume: item.count, amount: formatNumberLocale(item.price, 4, lang),
        total: formatNumberLocale(item.price * item.count, 2, lang) };
    }
    return { type: '', itemType: '', serviceType: '' };
  });
}

const AmountItemPay = (item: SubscriptionInvoiceItem) =>
  item.products.reduce((product, elem) => product + elem.amount, 0);

const DiscountItemPay = (item: SubscriptionInvoiceItem) =>
  item.products.reduce((product, elem) => product + elem.discount, 0);

const AmountPay = (item: SubscriptionInvoiceItem) =>
  (item.products.reduce((product, elem) => product + elem.amount, 0)
    - item.products.reduce((product, elem) => product + elem.discount, 0));
