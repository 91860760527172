import React from "react";
import './NotificationConnect.scss';
import { useTranslation } from 'react-i18next';
import { NotificationChannelTypes } from '../../NotificationsTypes';
import { listLogosNotification } from '../../utils/types';
import { advaidNumbers } from '../../utils/utils';
import Button from '../../../../../components/Button';

type Props = {
  type: NotificationChannelTypes;
  setChannelConnection: React.Dispatch<NotificationChannelTypes | null>;
}

const NotificationConnect = ({ type, setChannelConnection }: Props) => {
  const { t } = useTranslation();
  const maxChannel = advaidNumbers(type);

  const handleConnect = () => {
    if (setChannelConnection !== null) {
      setChannelConnection(type)
    }
  };


  return (
    <div className='notificationConnect'>
      <div className='notificationConnect_logo'>
        {listLogosNotification[type]}
        <h3>{t(`settings.notifications.${type}`)}</h3>
      </div>
      <div className='notificationConnect_create'>
        <p className='smallText'>{t('settings.notifications.connections_title')} {advaidNumbers(type)}</p>
        <Button type="button" color="white" textType="small" text={t('connect')} disabled={maxChannel <= 0}
          onClick={handleConnect} />
      </div>
    </div>
  )
};

export default NotificationConnect;
