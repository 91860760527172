import { useRouteMatch } from 'react-router';
import { getServiceNameByUrl } from './utils/connections';
import OdnoklassnikiCreate from './ConnectionsViews/Odnoklassniki/OdnoklassnikiCreate';
import WhatsAppCreate from './ConnectionsViews/WhatsApp/WhatsAppCreate';
import WabaCreateForm from './ConnectionsViews/Waba/WabaCreateForm';
import TelegramBotForm from './ConnectionsViews/Telegram/TelegramBot/TelegramBotForm';
import TinkoffCreate from './ConnectionsViews/Tinkoff/TinkoffCreate';
import SberCreate from './ConnectionsViews/Sber/SberCreate';
import ModulBankCreate from './ConnectionsViews/ModulBank/ModulBankCreate';
import PayKeeperCreate from './ConnectionsViews/Paykeeper/PayKeeperCreate';
import BePaidCreate from './ConnectionsViews/BePaid/BePaidCreate';
import YookassaCreate from './ConnectionsViews/Yookassa/YookassaCreate';
import TelegramForm from './ConnectionsViews/Telegram/TelegramPersonal/TelegramForm';


type RouteMatchType = {
  companyId: string;
  serviceUrl: string;
};

const ConnectionCreate = () => {
  const params = useRouteMatch<RouteMatchType>('/companies/:companyId/settings/connections/:serviceUrl');
  const service = getServiceNameByUrl(params?.params.serviceUrl);
  if (service) {
      switch (service) {
        case 'waba':
          return (<WabaCreateForm />);
        case 'whatsapp':
          return (<WhatsAppCreate />);
        case 'telegram':
          return (<TelegramForm />);
        case 'telegram_bot':
          return (<TelegramBotForm />);
        case 'odnoklassniki':
          return (<OdnoklassnikiCreate />);
        case 'bepaid':
          return (<BePaidCreate />);
        case 'tinkoff':
          return (<TinkoffCreate />);
        case 'sberbank':
          return (<SberCreate />);
        case 'modulbank':
          return (<ModulBankCreate />);
        case 'paykeeper':
          return (<PayKeeperCreate />);
        case 'yookassa':
          return (<YookassaCreate />);
        default:
          return (<></>);
      }
  }
  return(<></>);
}

export default ConnectionCreate;
