import { useEffect, useState } from 'react';
import './InteractiveMessageSettings.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useMessageTemplate } from '../../utils/message';
import { InteractiveTemplate, useInteractiveTemplate, useInteractiveTemplateSchema } from '../api';
import { InteractiveHeader } from '../../templatesTypes';
import Button from '../../../../components/Button';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import InteractiveSwitchTypes from '../InteractiveSwitchTypes';
import TextAreaResize from '../../Components/TextAreaResize';
import InteractiveMessageContent from './components/InteractiveMessageContent';
import InteractiveMessageSettingsActions from './components/InteractiveMessageSettingsActions';


function InteractiveMessageSettings() {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { templateId } = useParams<{ templateId: string }>();
  const template = useMessageTemplate(templateId, 'waba_interactive')
  const [currentMessageHeadingType, setCurrentMessageHeadingType] = useState<string>('text');
  const [attachedFile, setAttachedFile] = useState<File>();
  const schema = useInteractiveTemplateSchema();
  const { onSubmit: onSubmitTemplate } = useInteractiveTemplate();

  const methods = useForm<InteractiveTemplate>({ mode: 'all', resolver: yupResolver(schema), defaultValues: {
    type: 'button',
    header: { type: 'text', text: '' },
    action: {
      buttons: [{ type: 'reply', reply: { 'title': '' } }],
      sections: { button: '', sections: [{ rows: [{ title: '', description: '' }] }] } }
  } });

  useEffect(() => {
    if (template) {
      let action = {};
      if (template.params.type === 'button') {
        action = { buttons: template.params.action.buttons };
      }
      if (template.params.type === 'list') {
        action = { sections: template.params.action.sections };
      }

      methods.reset({
        name: template.name,
        type: template.params.type,
        header: template.params.header,
        body: template.params.body,
        footer: template.params.footer,
        action
      });
    }
  }, [template]);
      
  useEffect(() => {
    if (templateId && template) {
      setCurrentMessageHeadingType(template.params.header?.type ?? 'withoutAttachment');
    }
  }, [template]);

  const onSelectTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = e.currentTarget.id;
    setCurrentMessageHeadingType(selectedId);
    methods.setValue('header.type', selectedId as InteractiveHeader);
  };

  const onSubmit: SubmitHandler<InteractiveTemplate> = async (data) => {
    const result = await onSubmitTemplate(data, attachedFile, Number(templateId));
    if (result) goBack();
  };

  return (
    <div className="interactiveMessageSettings">
      <GoBackWrapper title={templateId
        ? t('templates.add_interactive_templates.edit_title')
        : t('templates.add_interactive_templates.title')} >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="interactiveMessageSettings__wrapper">
            <TextAreaResize className='regularText' name='name' minRows={1} maxRows={2}
              title={t('templates.add_interactive_templates.template_name')}  />
            <p className='regularText middle'>{t('templates.add_interactive_templates.template_header.title')}</p>
            <InteractiveSwitchTypes tabs={['text', 'image', 'video', 'document', 'no_title']}
              onSelectTab={onSelectTab} selectedTab={currentMessageHeadingType} />
            <InteractiveMessageContent attachedFile={attachedFile} setAttachedFile={setAttachedFile} />
            {((templateId && template) || (!templateId)) && 
              <InteractiveMessageSettingsActions editTemplateData={template}
                currentMessageHeadingType={currentMessageHeadingType} />}
          </div>
          <Button type='submit' color='orange' text={t('save_edit')} textType='regular'
            disabled={(!methods.formState.isDirty || methods.formState.isSubmitting)} />
          </form>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
}

export default InteractiveMessageSettings;
