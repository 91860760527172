import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from "./NotificationsAPI";
import * as typ from "./NotificationsTypes";


export type NotificationsSlice = {
  notification: typ.NotificationChannel | null;
  notifications: typ.NotificationChannel[] | null;
};

const initialState: NotificationsSlice = {
  notification: null,
  notifications: [],
};

export const getNotificationsChannels = createAsyncThunk<typ.GetNotificationChannelsType, typ.NotificationChannelRequest,
  { rejectValue: number }>('notifications/getNotificationsChannels', async (requestOptions, { rejectWithValue }) => {
  try {
    return await api.getNotificationsChannelsAPI(requestOptions);
  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const getNotificationsChannel = createAsyncThunk<typ.NotificationChannel, typ.NotificationChannelUpdateRequest,
  { rejectValue: number }>('notifications/getNotificationsChannel', async (requestOptions, { rejectWithValue }) => {
  try {
    return await api.getNotificationsChannelAPI(requestOptions);
  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const createNotificationsChannel =
  createAsyncThunk<typ.NotificationCreateResponse, typ.NotificationChannelCreate, { rejectValue: number }>(
    'notifications/createNotificationsChannel', async (requestOption, { rejectWithValue }) => {
    try {
      return await api.createNotificationsChannelsAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});

export const updateNotificationsChannel =
  createAsyncThunk<typ.NotificationChannel, typ.NotificationChannelUpdate, { rejectValue: number }>(
    'notifications/updateNotificationsChannel', async (requestOption, { rejectWithValue }) => {
    try {
      return await api.updateNotificationsChannelAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const deleteNotificationChannel =
  createAsyncThunk<void, typ.NotificationChannelUpdateRequest, { rejectValue: number }>(
    'notifications/deleteNotificationChannel', async (requestOption, { rejectWithValue }) => {
    try {
      return await api.deleteNotificationsChannelsAPI({
        companyId: requestOption.companyId,
        channelId: requestOption.channelId,
      });
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});


const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: (state) => {
      state.notification = null;
      state.notifications = null;
    },
    clearNotification: (state) => {
      state.notification = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsChannels.fulfilled, (state, action) => {
        state.notifications = action.payload.data;
      })
      .addCase(getNotificationsChannel.fulfilled, (state, action) => {
        state.notification = action.payload;
        if (state.notifications) {
          state.notifications.push(action.payload);
        }
      })
      .addCase(createNotificationsChannel.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'whatsapp') {
          if (state.notifications) {
            state.notifications.push(action.payload as typ.NotificationChannel);
          } else {
            state.notifications = [action.payload as typ.NotificationChannel];
          }
        }
      })
      .addCase(updateNotificationsChannel.fulfilled, (state, action) => {
        if (state.notifications) {
          state.notifications = state.notifications?.map((i) => (i.id === action.payload.id ? action.payload : i));
        }
      })
      .addCase(deleteNotificationChannel.fulfilled, (state, action) => {
        if (state.notifications && state.notifications.length > 0) {
          state.notifications = state.notifications.filter((item) => item.id !== action.meta.arg.channelId);
        }
      });
  },
});

export const { clearNotifications, clearNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
