import { Anchorme } from 'react-anchorme';


type Props = {
  text: string;
  className?: string;
};

const LinkifyText = ({ text, className }: Props) => (
  <span className={`linkyfyText ${className}`}>
    <Anchorme target="_blank" truncate={50}>{text}</Anchorme>
  </span>
);

export default LinkifyText;
