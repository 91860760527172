import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../../Settings/CompanySettings/currency';
import { WabaAndOrderProduct } from "../../../ChatTypes";
import { ReactComponent as Plug } from '../../../../../assets/image.svg';


type Props = {
  product: WabaAndOrderProduct;
  currency: string;
};

const WabaProduct = ({ product, currency }: Props) => {
  const { i18n } = useTranslation();

  return (
    <div className="wabaProduct">
      <div className={`wabaProduct__image ${!product.imageUrl ? 'plug' : ''}`}>
        {product.imageUrl ? <img alt="image" src={product.imageUrl} /> : <Plug />}
      </div>
      <div className="wabaProduct__content">
        <p className='regularText middle'>{product.name}</p>
        <div className="wabaProduct__content_price">
          <p className='smallText bold'>{product.quantity} шт.</p>
          <p className='monospaceRegText'>{currencyFormat(product.price, currency, i18n.language)}</p>
        </div>
      </div>
    </div>
  );
};

export default WabaProduct;
