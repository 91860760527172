import { useContext } from 'react';
import './ChatMessageTemplate.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMobile } from '../../../../../../components/utils/hooks';
import { MessageTemplate } from '../../../../../Templates/templatesTypes';
import { chatTemplatesContext } from '../../ChatTemplatesProvider/ChatTemplatesProvider';
import { chatTemplatesMobileContext } from '../../ChatTemplatesMobileProvider/ChatTemplatesMobileProvider';
import { sendMessage } from '../../../../chatsSlice';
import { useAppDispatch } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../companiesSelector';
import { ReactComponent as ArrowLeft } from '../../../../../../assets/arrow-left.svg';
import Button from '../../../../../../components/Button';


type ChatMessageTemplateProps = {
  template: MessageTemplate;
};

function ChatMessageTemplate({ template }: ChatMessageTemplateProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const { setMessageTemplate, setCustomTemplate, onClose } = useContext(chatTemplatesContext);
  const { setCurrentStep } = useContext(chatTemplatesMobileContext);
  const isMobile = useMobile();

  const onBack = () => {
    setCustomTemplate(undefined);
    setMessageTemplate(undefined);
    setCurrentStep('selected');
  };

  const onSend = async () => {
    await dispatch(
      sendMessage({
        messageType: template.type === 'document' ? 'file' : template.type,
        caption: template.text,
        url: template.fileUrl!,
        companyId: currentCompany.id,
      })
    )
    onClose();
  }

  return (
    <div className="chatMessageTemplate">
      <div className="chatMessageTemplate__header">
        {isMobile && <ArrowLeft className="arrowBack" onClick={onBack} />}
        {isMobile ? <h3>{template.name}</h3> : <h4>{template.name}</h4>}
      </div>
      <div className="chatMessageTemplate__content">
        <p className='smallText'>{template.text}</p>
        {template.type === 'image' && <img alt="image" src={template.fileUrl ?? undefined}></img>}
        {template.type === 'video' && (
          <video src={template.fileUrl ?? undefined} controls>
            <track src="#" kind="captions" />
          </video>
        )}
        {template.type === 'document' && (
          <a href={template.fileUrl ?? undefined} download>
            {template.fileUrl?.split('/').pop() ?? t('chatTemplates.noFile')}
          </a>
        )}
      </div>
      <Button
        text={t('send')}
        color={isMobile ? 'orange' : 'white'}
        textType={isMobile ? 'regular' : 'small'}
        onClick={onSend}
      />
    </div>
  );
}

export default ChatMessageTemplate;
