import { useTranslation } from 'react-i18next';
import './WabaTemplateCategory.scss';


type WabaTemplateCategoryProps = {
  category: string;
};

function WabaTemplateCategory({ category }: WabaTemplateCategoryProps) {
  const { t } = useTranslation();

  return (
    <div className='wabaTemplateCategory'>
      <p className='regularText'>{t(`templates.waba_list.categories.${category}`)}</p>
    </div>
  );
}

export default WabaTemplateCategory;
