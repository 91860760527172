import { MessageRequest } from '../../ChatTypes';
import { BaseMessageComponent } from '../MessageTypes/types';
import MessageTextType from '../MessageTypes/MesssageTextType';
import MessageWabaTemplate from '../MessageTypes/MessageWabaTemplate';
import MessageImageType from '../MessageTypes/MessageImageType';
import MessageFileType from '../MessageTypes/MessageFileType';
import MessageAudioType from '../MessageTypes/MessageAudioType';
import MessageVideoType from '../MessageTypes/MessageVideoType';
import MessageInteractiveType from '../MessageTypes/MessageInteractiveType';
import MessageWabaCart from "../MessageTypes/MessageWabaCart";
import { ReactComponent as MessageCheckmark } from '../../../../assets/check-double.svg';
import { ReactComponent as SingleCheckmark } from '../../../../assets/check.svg';
import { ReactComponent as MessageDeliveryErrorIcon } from '../../../../assets/warning-circle.svg';
import { ReactComponent as MessageDeliveredIcon } from '../../../../assets/check-double.svg';


export const getMessageStatusIcon = (message: MessageRequest) => {
  if (message.direction === 'inbound') return;
  if (message.errorAt) return <MessageDeliveryErrorIcon className='error' />;
  if (message.readAt) return <MessageCheckmark className='checkmark' />;
  if (message.deliveredAt) return <MessageDeliveredIcon className='delivered' />;
  return <SingleCheckmark className='single' />;
};

export const messageTypeComponents = new Map<string, BaseMessageComponent>([
  ['text', MessageTextType],
  ['waba_template', MessageWabaTemplate],
  ['image', MessageImageType],
  ['file', MessageFileType],
  ['audio', MessageAudioType],
  ['voice', MessageAudioType],
  ['video', MessageVideoType],
  ['waba_interactive', MessageInteractiveType],
  ['radist_order', MessageWabaCart],
  ['waba_order', MessageWabaCart],
]);
