import { createAsyncThunk } from "@reduxjs/toolkit";
import { initTelegram, loginTelegram, logoutTelegram, TelegramInit, TelegramInitRequest, TelegramLoginRequest, TelegramLogoutRequest } from "./api";


export const initTelegramSession = createAsyncThunk<TelegramInit, TelegramInitRequest, { rejectValue: number }>(
  'telegram/init',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await initTelegram(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const sendTelegramLogin = createAsyncThunk<TelegramInit, TelegramLoginRequest, { rejectValue: number }>(
  'telegram/login',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await loginTelegram(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const sendTelegramLogout = createAsyncThunk<unknown, TelegramLogoutRequest, { rejectValue: number }>(
  'telegram/logout',
  async (requestOptions, { rejectWithValue }) => {
    try {
      await logoutTelegram(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);
