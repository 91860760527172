import { useEffect, useState } from 'react';

export const ITEMS_ONE_PAGE = [25, 50, 75, 100];

export const getPageLimit = (pageLimitIndex: string) => {
  const limitState = localStorage.getItem(pageLimitIndex);
  if (limitState !== null) return JSON.parse(limitState);
  return ITEMS_ONE_PAGE[0];
};

export const usePageLimit = (pageLimitIndex: string) => {
  const [limit, setLimit] = useState<number>(getPageLimit(pageLimitIndex));

  useEffect(() => {
    setLimit(getPageLimit(pageLimitIndex));
  }, []);

  useEffect(() => {
    const limitState = JSON.stringify(limit);
    localStorage.setItem(pageLimitIndex, limitState);
  }, [limit]);

  return { limit, setLimit };
};

const range = (start: number, end: number, step: number = 1) =>
  Array.from({ length: (end - start) / step + 1 }, (_, i) => start + i * step);

const NUMBER_DISPLAY_PAGES = 5; // maximum number of pages to display at the one edge including current page and edge
const FIRST_PAGE = 1;

export function paginator(pagesCount: number, currentPage: number) {
  let pages: Array<number | string> = [];
  // check if pagesCount is bigger than NUMBER_DISPLAY_PAGES + two edges (left edge -first page and right edge - last page)
  if (pagesCount > NUMBER_DISPLAY_PAGES + 2) {
    if (currentPage < NUMBER_DISPLAY_PAGES) {
      pages = [...range(1, NUMBER_DISPLAY_PAGES), '...', pagesCount];
    } else if (pagesCount - currentPage + 1 < NUMBER_DISPLAY_PAGES) {
      pages = [FIRST_PAGE, '...', ...range(pagesCount - NUMBER_DISPLAY_PAGES + 1, pagesCount)];
    } else {
      pages = [FIRST_PAGE, '...', ...range(currentPage - 1, currentPage + 1), '...', pagesCount];
    }
  } else {
    pages = range(1, pagesCount);
  }
  return pages;
}
