import { useContext, useEffect } from 'react';
import './WabaTemplateCarousel.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { WabaTemplateCarouselData } from '../../../WabaTemplateProvider/utils';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import { useWabaTemplateCarouselSchema } from '../../schemas';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import WabaTemplateBody from '../WabaTemplateBody';
import WabaTemplateButtons from '../WabaTemplateButtons';
import WabaTemplateCards from '../WabaTemplateCards';


function WabaTemplateCarousel() {
  const { t } = useTranslation();
  const schema = useWabaTemplateCarouselSchema();
  const { wabaTemplateState, updateWabaTemplateState } = useContext(wabaTemplateContext);

  const methods = useForm<WabaTemplateCarouselData>({ mode: 'all', resolver: yupResolver(schema) });

  useEffect(() => {
    methods.reset({ 
      custom_name: wabaTemplateState.custom_name ?? '',
      body: wabaTemplateState.body ?? '',
      header: wabaTemplateState.header ?? { format: 'IMAGE', text: '' },
      cards: wabaTemplateState.cards ?? [{ description: '' }],
      buttons: wabaTemplateState.buttons ?? []
      }, { keepDefaultValues: wabaTemplateState.body !== '' });
  }, []);

  const header = useWatch({ name: 'header', control: methods.control });
  const body = useWatch({ name: 'body', control: methods.control });
  const buttons = useWatch({ name: 'buttons', control: methods.control });
  const cards = useWatch({ name: 'cards', control: methods.control });

  useEffect(() => {
    updateWabaTemplateState({ type: 'preview', payload: methods.getValues() });
  }, [header, header?.format, body, buttons, cards]);


  const onSubmit = (data: WabaTemplateCarouselData) => {
    updateWabaTemplateState({ type: 'carousel', payload: data });
  };

  return (
    <FormProvider {...methods}>
      <Input name='custom_name' label={t('templates.waba_list.form.name')} />
      <WabaTemplateBody />
      <WabaTemplateCards />
      <WabaTemplateButtons category={wabaTemplateState.category} kind={wabaTemplateState.kind} />
      <Button type='submit' color='orange' textType='regular' text={t('save')} onClick={methods.handleSubmit(onSubmit)}
        disabled={methods.formState.isSubmitting || !methods.formState.isDirty} />
    </FormProvider>
  );
}

export default WabaTemplateCarousel;
