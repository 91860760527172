import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AllTagType,
  ContactNotesType,
  ContactType, createContactApi, CreateContactApiType,
  createNewNote, CreateNewNoteType,
  editNoteAPI, EditNoteAPIType,
  getAllTags,
  getContactAPI,
  getContactNotes,
  getContacts,
  GetContactsWithParameters, ParamsContactId,
  removeNoteAPI, RemoveNoteAPIType,
  SearchByCriteriaType,
  TagType,
  UpdateAllTagsContactType,
  updateDateContactEditedAPI, UpdateDateContactEditedAPIType,
  updateTagsContactAPI, UpdateTagsContactType,
} from '../ContactsAPI';


export type ContactsSliceType = {
  contactsTotal: number;
  contacts: ContactType[] | null;
  allTagsContact: TagType[];
  currentContact: ContactType | null;
  selectContactNotes: ContactNotesType[] | null;
  disableRemoveNote: string;
  disableInProgress: number[];
  statusSuccess: boolean;
  statusReject: boolean;
  currentContactTags: number[];
  currentEditNote: ContactNotesType | null;
  statusRequestFetchContactNotes: boolean;
  isDirty: boolean;
  isLoader: boolean;
};

const initialState: ContactsSliceType = {
  contactsTotal: 0,
  contacts: null,
  allTagsContact: [],
  currentContact: null,
  currentContactTags: [],
  selectContactNotes: null,
  disableRemoveNote: 'fulfilled',
  disableInProgress: [],
  statusSuccess: false,
  statusReject: false,
  currentEditNote: null,
  statusRequestFetchContactNotes: false,
  isDirty: false,
  isLoader: false,
};

export const fetchContacts =
createAsyncThunk<GetContactsWithParameters, SearchByCriteriaType, { rejectValue: number; }>(
  'contacts/fetchContacts', async (requestOptions) => {
    try {
      return await getContacts(requestOptions);
    } catch (e) {
      return e.response.data.error_code;
    }
  }
);

export const fetchAllTags =
createAsyncThunk<AllTagType[], { companyId: number; }, { rejectValue: number; }>(
  'tags/fetchAllTags', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getAllTags(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchContact =
createAsyncThunk<ContactType, ParamsContactId, { rejectValue: number; }>(
  'contacts/fetchContactId', async (contactId, { rejectWithValue }) => {
    try {
      return await getContactAPI(contactId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchContactNotes =
createAsyncThunk<ContactNotesType[], ParamsContactId, { rejectValue: number; }>(
  'contacts/fetchContactNotes', async (contactId) => {
    try {
      return await getContactNotes(contactId);
    } catch (e) {
      return e.response.data.error_code;
    }
  }
);

export const addNewNote = createAsyncThunk(
  'contacts/addNewNote', async (requestOptions: CreateNewNoteType) => await createNewNote(requestOptions)
);

export const fetchEditNote = createAsyncThunk(
  'contacts/fetchEditNote',
  async (requestOptions: EditNoteAPIType) => await editNoteAPI(requestOptions)
);

export const removeNote = createAsyncThunk(
  'contacts/removeNote',
  async (requestOptions: RemoveNoteAPIType) => await removeNoteAPI(requestOptions)
);

export const updateTagsContact = createAsyncThunk(
  'contacts/addNewTags',
  async (requestOptions: UpdateTagsContactType) => await updateTagsContactAPI(requestOptions)
);

export const updateContactEdited = 
createAsyncThunk<ContactType, UpdateDateContactEditedAPIType, { rejectValue: number; }>(
  'contacts/updateContactEdited',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateDateContactEditedAPI(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const createContact = createAsyncThunk<ContactType, CreateContactApiType, { rejectValue: number; }>(
  'contacts/createContact',
  async (payload, { rejectWithValue }) => {
    try {
      return await createContactApi(payload);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    clearContacts: (state) => {
      state.contacts = [];
    },
    setCurrentContact: (state, action: PayloadAction<ContactType | null>) => {
      state.currentContact = action.payload;
    },
    setStatusSuccess: (state, action: PayloadAction<boolean>) => {
      state.statusSuccess = action.payload;
    },
    setStatusReject: (state, action: PayloadAction<boolean>) => {
      state.statusReject = action.payload;
    },
    setIsDirtyContacts: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    setCurrentContactTags: (state, action: PayloadAction<number[]>) => {
      state.currentContactTags = action.payload;
    },
    setCurrentContactNote: (state, action: PayloadAction<ContactNotesType | null>) => {
      state.currentEditNote = action.payload;
    },
    setSelectContactNotes: (state) => {
      state.selectContactNotes = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.fulfilled, (state, action: PayloadAction<GetContactsWithParameters>) => {
      state.contactsTotal = action.payload.totalCount;

      const unicObject: ContactType[] = [];
      action.payload.items.forEach((item) => {
        if (!unicObject.find((u) => u.id === item.id)) {
          unicObject.push(item);
        }
      });
      state.isLoader = false;
      state.contacts = unicObject;
    });
    builder.addCase(fetchContacts.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchContacts.rejected, (state) => {
      state.isLoader = false;
    });
    builder.addCase(fetchContactNotes.fulfilled, (state, action: PayloadAction<ContactNotesType[]>) => {
      state.selectContactNotes = action.payload;
    });
    builder.addCase(fetchEditNote.fulfilled, (state, action: PayloadAction<ContactNotesType>) => {
      if (state.selectContactNotes) {
        state.selectContactNotes = state.selectContactNotes.map(i => i.id === action.payload.id ? action.payload : i);
      }
    });
    builder.addCase(removeNote.pending, (state, action) => {
      const data = action.meta.arg;
      state.disableInProgress.push(data.note_id);
      state.disableRemoveNote = action.meta.requestStatus;
    });
    builder.addCase(removeNote.fulfilled, (state, action) => {
      const data = action.meta.arg;
      if (state.selectContactNotes) {
        state.selectContactNotes = state.selectContactNotes.filter((item) => item.id !== data.note_id);
      }
      state.disableRemoveNote = action.meta.requestStatus;
      state.disableInProgress = state.disableInProgress.filter((id) => id !== data.note_id);
    });
    builder.addCase(addNewNote.fulfilled, (state, action: PayloadAction<ContactNotesType>) => {
      if (state.selectContactNotes) state.selectContactNotes.unshift(action.payload);
    });
    builder.addCase(updateTagsContact.fulfilled, (state, action: PayloadAction<UpdateAllTagsContactType[]>) => {
      const arrayTagsId = action.payload;
      const allTagsContact = state.allTagsContact
        .filter((item1) => arrayTagsId.find((item2) => item2.tagId === item1.id))
        .map((item1) => ({ ...item1 }));
      if (allTagsContact && state.currentContact) {
        state.currentContact.tags = allTagsContact;
      }
    });
    builder.addCase(fetchContact.fulfilled, (state, action: PayloadAction<ContactType>) => {
      state.currentContact = action.payload;
    });
    builder.addCase(fetchAllTags.fulfilled, (state, action: PayloadAction<AllTagType[]>) => {
      state.allTagsContact = action.payload;
    });
    builder.addCase(updateContactEdited.fulfilled, (state, action: PayloadAction<ContactType>) => {
      state.currentContact = action.payload;
      state.statusSuccess = true;
      state.isDirty = false;
    });
    builder.addCase(createContact.fulfilled, (state, action: PayloadAction<ContactType>) => {
      if (state.contacts) state.contacts = [...state.contacts, action.payload];
      state.currentContact = action.payload;
      state.statusSuccess = true;
      state.isDirty = false;
    });
    builder.addCase(createContact.pending, (state) => {
      state.isDirty = false;
    });
    builder.addCase(createContact.rejected, (state) => {
      state.statusReject = true;
      state.isDirty = true;
    });
  },
});

export const {
  setCurrentContact,
  setCurrentContactTags,
  setCurrentContactNote,
  setSelectContactNotes,
  setIsDirtyContacts,
  setStatusReject,
  clearContacts
} = contactsSlice.actions;

export default contactsSlice.reducer;
