import { AmoCRMConnection, BitrixConnection } from "../../../api/CompanyAPI";

export const integrationTypes = ['waba_amocrm', 'bepaid_amocrm', 'whatsapp_amocrm', 'retie_whatsappchatapi_amocrm',
  'telegram_amocrm', 'modulbank_amocrm', 'paykeeper_amocrm', 'sberbank_amocrm', 'tinkoff_amocrm',
  'telegram_bitrix', 'whatsapp_bitrix', 'waba_bitrix', 'odnoklassniki_amocrm', 'telegram_bot_amocrm',
  'telegram_bot_bitrix', 'yookassa_amocrm'
] as const;

export type IntegrationsTypesNames = typeof integrationTypes[number];

export const IntegrationsTypesParams: Record<IntegrationsTypesNames, string> = {
  waba_amocrm: 'wabaAmocrm',
  bepaid_amocrm: 'bepaidAmocrm',
  whatsapp_amocrm: 'whatsappAmocrm',
  retie_whatsappchatapi_amocrm: 'retieWhatsAppChatApiAmoCrm',
  telegram_amocrm: 'telegramAmocrm',
  modulbank_amocrm: 'modulbankAmocrm',
  paykeeper_amocrm: 'paykeeperAmocrm',
  sberbank_amocrm: 'sberbankAmocrm',
  tinkoff_amocrm: 'tinkoffAmocrm',
  telegram_bitrix: 'telegramBitrix',
  whatsapp_bitrix: 'whatsappBitrix',
  waba_bitrix: 'wabaBitrix',
  odnoklassniki_amocrm: 'odnoklassnikiAmocrm',
  telegram_bot_amocrm: 'telegramBotAmocrm',
  telegram_bot_bitrix: 'telegramBotBitrix',
  yookassa_amocrm: 'yookassaAmocrm'
};

export enum IntegrationTypeService {
  waba = 'messaging',
  whatsapp = 'messaging',
  telegram = 'messaging',
  odnoklassniki = 'messaging',
  retie_whatsapp = 'messaging',
  retie_whatsappchatapi = 'messaging',
  telegram_bot = 'messaging',
  bepaid = 'banking',
  paypal = 'banking',
  modulbank = 'banking',
  paykeeper = 'banking',
  tinkoff = 'banking',
  sberbank = 'banking',
  yookassa = 'banking'
}

export type IntegrationService = keyof typeof IntegrationTypeService;

export type Areas = 'amocrm' | 'bitrix' | 'api';

export type Named = {
  id: number;
  name: string;
};

export type MessagingOption = {
  pipeID: number | null;
  statusID: number | null;
} & Named;

export type MessagingPipeline = {
  statuses?: Named[];
} & Named;

export type DomainConnection = BitrixConnection | AmoCRMConnection;

export type MessagingSchema = {
  status: boolean;
  writeFirstConn: string;
  checkPhonesEnabled: boolean;
  handleAmoDialogEvents: boolean;
  createInvoices: boolean;
  smsCreateChatEnabled: boolean;
};

export type IntegrationUpdate = {
  id: number;
  name: string;
  type: string;
  options: MessagingOption[];
} & MessagingSchema;

export type BankingSchema = {
  status: boolean;
  createFields: boolean;
  defaultConnection: string;
  extraTax: string;
  extraType: string;
  extraPayment: string;
  amoInvoices: {
    invoiceCatalogId: number;
    statuses: {
      new: number;
      confirmed: number;
      rejected: number;
      expired: number;
      refunded: number;
      partialRefunded: number;
    };
  } | null;
};

export type BankingUpdate = {
  id: number;
  name: string;
  type: string;
  terminals: Named[];
} & BankingSchema;

export const listAPIs = ['connections', 'sales', 'integrations', 'messaging', 'members', 'webhooks', 'partners'];

export type APISchema = {
  status: boolean;
  scopes: string[];
};

export type APIUpdate = {
  id: number;
  name: string;
  type: string;
  resetApiKey?: boolean;
} & APISchema;
