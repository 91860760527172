import { useState } from 'react';
import './TemplateList.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { broadcastsWabaTemplatesSelector } from '../../../BroadcastsSelector';
import { WabaTemplate } from '../../../../Templates/templatesTypes';
import { ErrorStateType } from '../../../api/types';
import Search from '../../../../../components/Search/Search';
import TemplateItem from '../TemplateItem';


type TemplateListProps = {
  setSelectTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | undefined>>;
  errorState?: ErrorStateType;
};

const TemplateList = ({ setSelectTemplate, errorState }: TemplateListProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const wabaTemplates = useSelector(broadcastsWabaTemplatesSelector);

  const isError = errorState?.template;

  return (
    <>
    <div className={`templateForm ${isError ? 'error' : ''}`}>
      <Search onChangeSearchInput={setSearch} value={search} />
      <div className='templateList'>
        {wabaTemplates.map((template) => template.components.body.text.toLowerCase().includes(search.toLowerCase()) &&
        <TemplateItem key={`${template.name}${template.locale}`} templates={template}
          setSelectTemplate={setSelectTemplate} />)}
      </div>
    </div>
    {isError && <p className="errorMessage">{t('broadcasts.error.not_template')}</p>}
    </>
  );
};
export default TemplateList;
