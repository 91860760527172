import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { PermissionsType } from "../../../utils/WrapperPermission/permission-maps";
import {
  WabaTemplateCategory, WabaTemplateComponentBody,
  WabaTemplateComponentButtonsIn, WabaTemplateComponentCarouselIn,
  WabaTemplateComponentFooter, WabaTemplateComponentHeader, WabaTemplateInfo
} from '../templatesTypes';
import { WabaTemplateKind } from "../WabaTemplates/api";


export type SelectPosition = {
  start: number;
  end: number;
};

const variableTemplate = /(\{\{\d+\}\})/g;

export function countVariables(text: string) {
  return text?.match(variableTemplate)?.length ?? 0;
}

export function isValidUrl(link: string) {
  try {
    const linkUrl = new URL(link);
    return linkUrl.protocol
      && linkUrl.host.split('.').length >= 2 && linkUrl.host.split('.').every((item) => item.length > 0);
  } catch (err) {
    return false;
  }
}

export function useVariable(maxVariables: number, text: string) {
  const [variables, setVariables] = useState<number>(0);
  const [content] = useDebounce(text, 400);

  const addVariable = () => {
    if (variables >= maxVariables) return false;
    setVariables((prev) => prev + 1);
    return true;
  };

  useEffect(() => {
    setVariables(countVariables(content));
  }, [content]);

  return { variables, addVariable };
}

export const wabaTemplateButtons = ['QUICK_REPLY', 'PHONE_NUMBER', 'URL'] as const;
export const wabaAuthenticationButtons = ['COPY_CODE', 'ONE_TAP'] as const;

export const wabaTemplateCategoryButtons: Record<WabaTemplateCategory, readonly string[]> = {
  'AUTHENTICATION': wabaAuthenticationButtons,
  'MARKETING': wabaTemplateButtons,
  'UTILITY': wabaTemplateButtons
};

export const wabaTemplateButtonTextLimit: Record<WabaTemplateCategory, number> = {
  'AUTHENTICATION': 20,
  'MARKETING': 20,
  'UTILITY': 20
};

export const wabaTemplateButtonsMaximum: Record<WabaTemplateKind, number> = {
  auth: 1,
  message: 10,
  carousel: 2
};

export const wabaTemplateBodyVariablessMaximum: Partial<Record<WabaTemplateKind, number>> = {
  message: 99,
  carousel: 99
};

export const getPartsComponents = (template?: WabaTemplateInfo) => {
  let header: WabaTemplateComponentHeader | undefined,
    body: WabaTemplateComponentBody | undefined,
    footer: WabaTemplateComponentFooter | undefined,
    buttons: WabaTemplateComponentButtonsIn | undefined,
    carousel: WabaTemplateComponentCarouselIn | undefined;

    template && template.components.map((component) => {
    switch (component.type) {
      case 'HEADER':
        header = component;
        break;
      case 'BODY':
        if ('text' in component) body = component as WabaTemplateComponentBody;
        break;
      case 'FOOTER':
        if ('text' in component) footer = component as WabaTemplateComponentFooter;
        break;
      case 'BUTTONS':
        buttons = component;
        break;
      case 'CAROUSEL':
        carousel = component as WabaTemplateComponentCarouselIn;
        break;
    }

    return { header, body, footer, buttons, carousel };
  });

  return { header, body, footer, buttons, carousel };
};

export const wabaTemplateActions = ['language', 'copy', 'rename', 'delete'] as const;
export type WabaTemplateActions = typeof wabaTemplateActions[number];

export const actionsPermission: Record<WabaTemplateActions, PermissionsType> = {
  copy: 'templatesCreate',
  delete: 'templatesDelete',
  language: 'templatesCreate',
  rename: 'templatesEdit'
};
