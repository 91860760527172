import InteractiveMessageButtons from './components/InteractiveMessageButtons';
import InteractiveMessageList from './components/InteractiveMessageList';


type Actions = Record<string, () => JSX.Element>;

export const interactiveMessageActions: Actions = {
  button: InteractiveMessageButtons,
  list: InteractiveMessageList,
};
