import { createContext } from 'react';
import * as yup from 'yup';
import { useAppDispatch } from '../../../store/store';
import { CompanyRequisite, Organization, PartnerRequisiteUpdate } from '../PartnerCabinetTypes';
import { createPartnerRequisite, fetchPartnerRequisites, updatePartnerRequisite } from '../PartnerCabinetSlice';
import { useTranslation } from 'react-i18next';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../Chat/companiesSelector';


type RequisiteItemContextType = {
  onEdit?: (requisite: CompanyRequisite) => void;
  onDelete?: (requisite: CompanyRequisite) => void;
};

export const requisiteItemContext = createContext<RequisiteItemContextType>({});

export function useRequisiteRequest() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);

  const makeRequisite = async (requisite: PartnerRequisiteUpdate, requisiteId?: number) => {
    let result = false;

    if (requisiteId) {
      await dispatch(updatePartnerRequisite({  company_id: companyId, requisite_id: requisiteId, ...requisite }))
      .then((response) => {if (response.payload) result = response.meta.requestStatus === 'fulfilled'});
    } else {
      await dispatch(createPartnerRequisite({ company_id: companyId, ...requisite }))
      .then((response) => {if (response.payload) result = response.meta.requestStatus === 'fulfilled'});
    }

    let message = t('requisites.actions.error_create');
    if (result) {
      message = t('requisites.actions.success_create');
      if (requisiteId) message = t('requisites.actions.success_edit');
    } else if (requisiteId) {
      message = t('requisites.actions.error_edit');
    }
    dispatch(addAlertWithCustomText({ message, type: result ? 'info' : 'alarm' }));
    dispatch(fetchPartnerRequisites({ company_id: companyId }));
    return result
  };

  return { makeRequisite };
}

const nameRegex = /^\d+$/;

const TIN_LENGTH_LEGAL = 10;

const TIN_LENGTH_PHYSICAL = 12;

export function usePartnerRequisiteSchema() {
  const { t } = useTranslation();

  const validationDigitsSchema = (digits: number) => 
    yup.string().nullable(true).transform((_, val) => (val !== "" ? val : null))
    .matches(nameRegex, t('error.form.digits_only'))
    .min(digits, ({ min }) => t('error.form.min_length', { limit: min }))
    .max(digits, ({ max }) => t('error.form.max_length', { limit: max }));

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    type: yup.string().required(t('error.form.empty_field')),
    tin: yup.string().required(t('error.form.empty_field'))
      .when('type', {
        is: 'company',
        then: validationDigitsSchema(TIN_LENGTH_LEGAL),
        otherwise: validationDigitsSchema(TIN_LENGTH_PHYSICAL)
      }),
      current_account: validationDigitsSchema(20).when('default', {
        is: true,
        then: validationDigitsSchema(20).required(t('error.form.empty_field'))
      }),
      bic: validationDigitsSchema(9).when('default', {
        is: true,
        then: validationDigitsSchema(9).required(t('error.form.empty_field'))
      }),
      kpp: validationDigitsSchema(9).when(['type', 'default'], {
        is: (organization: Organization, primary: boolean) => (organization === 'company' && primary),
        then: validationDigitsSchema(9).required(t('error.form.empty_field'))
      }),
    default: yup.boolean().required(t('partner_cabinet.requisites.main')),
  });

  return schema;
}
