import React, { useEffect, useState } from 'react';
import './ChooseCompanies.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from "react-hook-form";
import Checkbox from '../../../../../../components/Checkbox';


type Props<T> = {
  name: string;
  items: T[];
};

const ChooseCompanies = <T extends { id: number; name: string }>({ name, items }: Props<T>) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [chooseAll, setChooseAll] = useState<boolean>(false);
  const selectedItems: number[] = useWatch({ name, control, defaultValue: [] });

  useEffect(() => {
    if (items.length === selectedItems.length) {
      setChooseAll(true);
    } else setChooseAll(false);
  }, [selectedItems]);

  const onChooseAll = () => {
    if (chooseAll) {
      setValue(name, [], { shouldDirty: true });
    } else {
      setValue(name, items.map((item) => item.id), { shouldDirty: true });
    }
  };

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = Number(e.currentTarget.name);
    if (selectedItems.includes(selected)) {
      setValue(name, selectedItems.filter((item) => item !== selected), { shouldDirty: true });
    } else {
      setValue(name, [...selectedItems, Number(e.currentTarget.name)], { shouldDirty: true });
    }
  };

  return (
    <div className="chooseCompanies">
      <div className="chooseCompanies__header">
        <Checkbox name="" value={chooseAll} onCheckedHandler={onChooseAll} 
          label={t('partner_cabinet.notifications.choose_all')} />
      </div>
      <div className="chooseCompanies__list" id='chooseCompanies'>
        {items.map((item) => (
          <div key={item.id} className="chooseCompanies__list_item">
            <Checkbox name={String(item.id)} value={selectedItems.includes(item.id)} onCheckedHandler={onCheck} />
            <div>
              <p className="regularText">{item.name}</p>
              <p className="smallText">ID {item.id}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseCompanies;
