import './TagsList.scss';
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { updateTagsContact } from "../../contactsSlice/contactsSlice";
import { currentContactSelector } from "../../contactsSlice/contactsSelector";
import { TagType } from "../../ContactsAPI";
import { WrapperPermission } from "../../../../utils/WrapperPermission/WrapperPermission";
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';


const TagsList = () => {
  const dispatch = useAppDispatch();
  const currentContact = useSelector(currentContactSelector);
  const copyTags = currentContact && [...currentContact.tags]?.sort((a, b) => a.id - b.id);

  const activeRemoveSelectTag = (tagId: number) => {
    if (currentContact) {
      const arrayUpdatedTagsContact: number[] = currentContact.tags
        .filter((tag) => tag.id !== tagId)
        .map((item) => item.id);
      dispatch(updateTagsContact({
        contactId: String(currentContact.id),
        idAllTagsContact: arrayUpdatedTagsContact,
        companyId: currentContact.companyId,
      }));
    }
  };

  if (!currentContact || !copyTags) {
    return <></>
  }

  return (
    <div className={`tagsList ${currentContact.tags.length === 0 ? 'empty' : ''}`}>
      {copyTags.map((i: TagType) => (
        <div className="tagsItem" style={{ backgroundColor: `${i.backgroundColor}` }} key={i.id}>
          <p className="smallText">{i.name}</p>
          <WrapperPermission permission="contactsEdit">
            <CloseIcon onClick={() => activeRemoveSelectTag(i.id)} />
          </WrapperPermission>
        </div>
      ))}
    </div>
  );
};

export default TagsList;
