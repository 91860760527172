import './ClientSubscriptionConnection.scss';
import { Connection } from "../../../../PartnerCabinetTypes";


type Props = {
  connection: Connection;
}

const ClientSubscriptionConnection = ({ connection }: Props) => {
  return (
    <div className="clientSubscriptionConnection">
      <p className="monospaceSmText">{connection.id}</p>
      <p className="monospaceRegText">{connection.phone ? connection.phone : connection.name}</p>
    </div>
  );
};

export default ClientSubscriptionConnection;
