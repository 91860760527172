import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getCatalogsAPI,
  ParamsGetCatalogs,
  getShopsAPI,
  ParamsGetShops,
  ParamsCreateShop,
  PromiseGetCatalogs,
  PromiseGetShops,
  ShopType,
  createShopAPI,
  ParamsUpdateShop,
  updateShopAPI,
  getShopOneAPI, ParamsGetShopOne, deleteShopAPI, ParamsDeleteShop,
} from './ShopsAPI';


type ShopsSliceType = {
  shops: PromiseGetShops | null;
  catalogs: PromiseGetCatalogs;
};

const initialState: ShopsSliceType = {
  shops: null,
  catalogs: {
    count: 0,
    items: [],
  }
};

export const getCatalogs = createAsyncThunk<PromiseGetCatalogs,
  ParamsGetCatalogs,
  { rejectValue: number }>('shops/getCatalogs', async (requestOptions) => {
  try {
    return await getCatalogsAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const getShops = createAsyncThunk<PromiseGetShops,
  ParamsGetShops,
  { rejectValue: number }>('shops/getShops', async (requestOptions) => {
  try {
    return await getShopsAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const getShopOne = createAsyncThunk<ShopType,
  ParamsGetShopOne,
  { rejectValue: number }>('shops/getShopOne', async (requestOptions) => {
  try {
    return await getShopOneAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const createShop = createAsyncThunk<ShopType, ParamsCreateShop, { rejectValue: number }>(
  'shops/createShop', async (requestOptions, { dispatch }) => {
  try {
    return await createShopAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const updateShop = createAsyncThunk<ShopType,
  ParamsUpdateShop,
  { rejectValue: number }>('shops/updateShop', async (requestOptions) => {
  try {
    return await updateShopAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const deleteShop = createAsyncThunk<unknown, ParamsDeleteShop, { rejectValue: number }>(
  'shops/deleteShop', async (requestOptions) => {
  try {
    await deleteShopAPI(requestOptions);
  } catch (e) {
    return e.response.data.error_code;
  }
});

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    clearShops: (state) => {state = { ...initialState };},
  },
  extraReducers: (builder) => {
    builder.addCase(getShops.fulfilled, (state, action: PayloadAction<PromiseGetShops>) => {
      state.shops = action.payload;
    });
    builder.addCase(getCatalogs.fulfilled, (state, action: PayloadAction<PromiseGetCatalogs>) => {
      state.catalogs = action.payload;
    });
    builder.addCase(createShop.fulfilled, (state, action: PayloadAction<ShopType>) => {
      state.shops && state.shops.items.push(action.payload);
    });
    builder.addCase(updateShop.fulfilled, (state, action: PayloadAction<ShopType>) => {
      if (state.shops) state.shops.items = state.shops.items.map(i => i.id === action.payload.id ? action.payload : i);
    });
    builder.addCase(deleteShop.fulfilled, (state, { meta: { arg } }) => {
      if (state.shops) state.shops.items = state.shops.items.filter(i => i.id !== arg.shopId);
    });
  },
});

export const { clearShops } = shopsSlice.actions;

export default shopsSlice.reducer;
