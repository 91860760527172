import './OrderItem.scss'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Invoice } from '../../../../Chat/BillAPI';
import { getInvoiceStatus, getInvoiceDate, getSymbolCurrency } from './helpers';
import { updateIsVisibleProfileInvoice } from '../../../../Chat/billSlice';
import { formatNumberLocale } from '../../../../../utils/utils';


type Props = {
  invoice: Invoice;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function OrderItem({ invoice, setIsOpen }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onClickInvoice = () => {
    dispatch(updateIsVisibleProfileInvoice({ isVisibleProfileInvoice: true, profileInvoice: invoice }));
    setIsOpen(true);
  };

  return(
    <div className='invoiceItem' key={invoice.id} onClick={onClickInvoice}>
      <div className='invoiceItem_order'>{t('orders.order')} №{invoice.orderId}</div>
      <div className='invoiceItem_date'>{getInvoiceDate(invoice.createdAt, t, true, true)}</div>
      <div className='invoiceItem_status'>{getInvoiceStatus(invoice, t)}</div>
      <div className='invoiceItem_amount'>
        {formatNumberLocale(invoice.amount, 2, i18n.language)} {getSymbolCurrency(invoice)}
      </div>
    </div>
  )
}

export default OrderItem;
