import { useContext } from 'react';
import { configForChatTemplateMobile, useConfigForChatTemplate } from '../utils';
import { useMobile } from '../../../../../components/utils/hooks';
import { chatTemplatesMobileContext } from "../ChatTemplatesMobileProvider/ChatTemplatesMobileProvider";


const ChatTemplatesWrapper = () => {
  const { currentStep } = useContext(chatTemplatesMobileContext);
  const components = useConfigForChatTemplate();
  const isMobile = useMobile();

  return (<>
   {isMobile ? configForChatTemplateMobile[currentStep] : components}
  </>);
};

export default ChatTemplatesWrapper;
