import React from 'react';
import './ProfileInvoiceItem.scss';
import { useTranslation } from "react-i18next";
import { Invoice, InvoiceItem } from '../../../../../Chat/BillAPI';
import { getSymbolCurrency } from '../../OrderItem/helpers';
import { formatNumberLocale } from "../../../../../../utils/utils";


type Props = {
  product: InvoiceItem;
  forCurrency: Invoice;
};

const ProfileInvoiceItem: React.FC<Props> = ({ product, forCurrency }) => {
  const { i18n } = useTranslation();

  return (
    <div className="profileInvoiceItem">
      <div className="profileInvoiceItem_name">{product.name}</div>
      <div className="profileInvoiceItem_count">{product.quantity}</div>
      <div className="profileInvoiceItem_price">
        {formatNumberLocale(product.price, 2, i18n.language)} {getSymbolCurrency(forCurrency)}
      </div>
    </div>
  );
};

export default ProfileInvoiceItem;
