import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  WabaTemplateComponentBody, WabaTemplateComponentButtonsIn,
  WabaTemplateComponentFooter, WabaTemplateComponentHeader,
  WabaTemplateComponentCarouselButtonsIn, WabaTemplateComponentCarouselHeader, WabaTemplateComponentCarouselIn,
} from "../../templatesTypes";
import { countVariables } from "../../utils/waba";
import { WabaTemplateKind, WabaTemplateSchema } from "../api";


const stages = ['basic', 'form', 'moderation', 'create'] as const;

export type WagaTemplateStage = typeof stages[number];

export type WabaTemplateState = {
  stage: WagaTemplateStage;
  kind: WabaTemplateKind;
} & WabaTemplateSchema;

// make initial state of type WabaTemplateState
const initialState: WabaTemplateState = {
  stage: 'basic',
  kind: 'message',
  custom_name: '',
  category: 'MARKETING',
  template_locale: '',
  add_security_recommendation: undefined,
  allow_category_change: undefined,
  body: '',
  code_expiration_minutes: undefined,
  footer: undefined,
  header: undefined,
  buttons: undefined,
  cards: undefined
};

const wabaTemplateActionKinds = 
['basic', 'message', 'auth', 'preview', 'security', 'expire', 'stage', 'clear', 'copy', 'carousel'] as const;

type WabaTemplateActionKind = typeof wabaTemplateActionKinds[number];

type WabaTemplateActionGeneric<T extends WabaTemplateActionKind, P extends Partial<WabaTemplateState> | undefined> = {
  type: T;
  payload: P;
};

export type WabaTemplateBasicData = 
  Pick<WabaTemplateState, 'kind' | 'custom_name' | 'category' | 'template_locale' | 'allow_category_change'>;
export type WabaTemplateMessageData = Pick<WabaTemplateState, 'custom_name' | 'header' | 'body' | 'footer' | 'buttons'>;
export type WabaTemplateAuthData = Pick<WabaTemplateState, 'custom_name' | 'add_security_recommendation' 
  | 'code_expiration_minutes' | 'footer' | 'buttons'>;
export type WabaTemplateCarouselData = Pick<WabaTemplateState, 'custom_name' | 'header' | 'body' | 'buttons' | 'cards'>;

export type WabaTemplateActionBasic = WabaTemplateActionGeneric<'basic', WabaTemplateBasicData>;
export type WabaTemplateActionMessage = WabaTemplateActionGeneric<'message', WabaTemplateMessageData>;
export type WabaTemplateActionAuth = WabaTemplateActionGeneric<'auth', WabaTemplateAuthData>;
export type WabaTemplateActionSequrity = WabaTemplateActionGeneric<'security', Pick<WabaTemplateState, 'add_security_recommendation'>>;
export type WabaTemplateActionExpire = WabaTemplateActionGeneric<'expire', Pick<WabaTemplateState, 'code_expiration_minutes'>>;
export type WabaTemplateActionStage = WabaTemplateActionGeneric<'stage', Pick<WabaTemplateState, 'stage'>>;
export type WabaTemplateActionPreview = WabaTemplateActionGeneric<'preview', Pick<WabaTemplateState, 'header' | 'body' | 'footer' | 'buttons' | 'cards'>>;
export type WabaTemplateActionClear = WabaTemplateActionGeneric<'clear', undefined>;
export type WabaTemplateActionCopy = WabaTemplateActionGeneric<'copy', WabaTemplateState>;
export type WabaTemplateActionCarousel = WabaTemplateActionGeneric<'carousel', WabaTemplateCarouselData>;

export type WabaTemplateAction = WabaTemplateActionBasic | WabaTemplateActionMessage | WabaTemplateActionAuth
  | WabaTemplateActionPreview | WabaTemplateActionSequrity | WabaTemplateActionExpire | WabaTemplateActionClear
  | WabaTemplateActionStage | WabaTemplateActionCopy | WabaTemplateActionCarousel;

function wabaTemplateReducer (state: WabaTemplateState, action: WabaTemplateAction): WabaTemplateState {
  let newStage = state.stage;

  switch (action.type) {
    case 'basic':
      return {
        ...state,
        stage: 'form',
        kind: action.payload.kind,
        custom_name: action.payload.custom_name ?? state.custom_name,
        category: action.payload.category ?? state.category,
        template_locale: action.payload.template_locale ?? state.template_locale,
        allow_category_change: action.payload.allow_category_change ?? state.allow_category_change
      };
    case 'preview':
      return {
        ...state,
        ...action.payload
      }
    case 'message':    
      if (state.stage === 'form') {
        newStage = (['DOCUMENT', 'IMAGE', 'VIDEO'].includes(action.payload.header?.format ?? '')
          || (action.payload.header?.format === 'TEXT' && countVariables(action.payload.header?.text ?? '') > 0)
          || (countVariables(action.payload.body ?? '') > 0)
          || (action.payload.buttons && action.payload.buttons.length > 0
            && action.payload.buttons?.some((button) => button.type === 'URL' && countVariables(button.url ?? '') > 0))
        ) ? 'moderation' : 'create'
      }
      
      return {
        ...state,
        stage: newStage,
        custom_name: action.payload.custom_name ?? state.custom_name,
        header: action.payload.header ?? state.header,
        body: action.payload.body ?? state.body,
        footer: action.payload.footer ?? state.footer,
        buttons: action.payload.buttons ?? state.buttons
      };
    case 'carousel':
      return {
        ...state,
        ...action.payload,
        stage: 'moderation'
      }
    case 'auth':
      return {
        ...state,
        stage: 'create',
        custom_name: action.payload.custom_name,
        add_security_recommendation: action.payload.add_security_recommendation,
        code_expiration_minutes: action.payload.code_expiration_minutes,
        header: undefined,
        body: undefined,
        footer: action.payload.footer ?? undefined,
        buttons: action.payload.buttons ?? undefined
      };
    case 'clear':
      return initialState;
    case 'stage':
      return {
        ...state,
        stage: action.payload.stage
      };
    case 'expire':
      return {
        ...state,
        code_expiration_minutes: action.payload.code_expiration_minutes
      }
    case 'security':
      return {
        ...state,
        add_security_recommendation: action.payload.add_security_recommendation
      }
    case 'copy':
      return { ...action.payload };
    default:
      return state;
  }
}

export type WabaTemplatePreview = {
  header?: WabaTemplateComponentHeader;
  body?: WabaTemplateComponentBody;
  footer?: WabaTemplateComponentFooter
  buttons?: WabaTemplateComponentButtonsIn
  carousel?: WabaTemplateComponentCarouselIn
};

export function useWabaTemplateCreate() {
  const { t } = useTranslation();
  const [wabaTemplateState, updateWabaTemplateState] = useReducer(wabaTemplateReducer, initialState);
  const [preview, setPreview] = useState<WabaTemplatePreview>();

  const getRecomponstateion = () => {
    const text = t('templates.waba_list.form.security_prompt', { interpolation: { skipOnVariables: true } });
    if (wabaTemplateState.add_security_recommendation) return `${text} ${t('templates.waba_list.form.security_warning')}`;
    return text;
  };

  const getExpiration = () => {
    if (!wabaTemplateState.code_expiration_minutes) return '';
    return [
      t('templates.waba_list.form.code_prompt'),
      `${wabaTemplateState.code_expiration_minutes} `,
      t('templates.waba_list.form.minute.minute', { count: Number(wabaTemplateState.code_expiration_minutes) }),
      '.'
    ].join('');
  }

  const previewWabaTemplate = () => {
    const components: WabaTemplatePreview = { body: { type: 'BODY', text: '' } };

    if (wabaTemplateState.kind === 'message') {
      if ((wabaTemplateState.header) && 
      ((wabaTemplateState.header.text && wabaTemplateState.header.format === 'TEXT') 
      || wabaTemplateState.header.format !== 'TEXT')) { components.header = {
        type: 'HEADER', text: wabaTemplateState.header.text ?? '', format: wabaTemplateState.header.format };
      }
      if (wabaTemplateState.body) components.body = { type: 'BODY', text: wabaTemplateState.body };
      if (wabaTemplateState.footer) components.footer = { type: 'FOOTER', text: wabaTemplateState.footer };
      if (wabaTemplateState.buttons && wabaTemplateState.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtonsIn['buttons'] = [];
        wabaTemplateState.buttons.forEach(button => {
          if (button.type === 'QUICK_REPLY') buttons.push({ type: 'QUICK_REPLY', text: button.text });
          if (button.type === 'PHONE_NUMBER') buttons.push(
            { type: 'PHONE_NUMBER', text: button.text, phoneNumber: button.phone_number });
          if (button.type === 'URL') buttons.push({ type: 'URL', text: button.text, url: button.url });
        });
        components.buttons = { type: 'BUTTONS', buttons };
      }
    }
    if (wabaTemplateState.kind === 'auth') {
      components.body = { type: 'BODY', text: getRecomponstateion() };
      components.footer = { type: 'FOOTER', text: getExpiration() };
      if (wabaTemplateState.buttons && wabaTemplateState.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtonsIn['buttons'] = [];
        wabaTemplateState.buttons.forEach(button => {
          if (button.type === 'COPY_CODE') buttons.push({ type: 'OTP', otpType: button.type, text: button.text });
          if (button.type === 'ONE_TAP') buttons.push({
            type: 'OTP', otpType: button.type, text: button.text,
            autofillText: button.autofill_text, packageName: button.package_name,
            signatureHash: button.signature_hash
          });
        });
        components.buttons = { type: 'BUTTONS', buttons };
      }
    }
    if (wabaTemplateState.kind === 'carousel') {
      if (wabaTemplateState.body) components.body = { type: 'BODY', text: wabaTemplateState.body };
      const buttons: WabaTemplateComponentCarouselButtonsIn['buttons'] = [];
      if (wabaTemplateState.buttons && wabaTemplateState.buttons?.length > 0) {
        wabaTemplateState.buttons.forEach(button => {
          if (button.type === 'QUICK_REPLY') buttons.push({ type: 'QUICK_REPLY', text: button.text });
          if (button.type === 'PHONE_NUMBER') buttons.push(
            { type: 'PHONE_NUMBER', text: button.text, phoneNumber: button.phone_number });
          if (button.type === 'URL') buttons.push({ type: 'URL', text: button.text, url: button.url });
        });
      }
      if (wabaTemplateState.cards && wabaTemplateState.cards?.length > 0) {
        const carousel: WabaTemplateComponentCarouselIn = {
          type: 'CAROUSEL',
          cards: []
        };
        wabaTemplateState.cards.forEach(card => {
          carousel.cards.push({
              components: [
                { type: 'HEADER', format: wabaTemplateState.header?.format } as WabaTemplateComponentCarouselHeader,
                { type: 'BODY', text: card.description },
                { type: 'BUTTONS', buttons }]
          })
        });
        components.carousel = carousel;
      }
    }

    return components;
  };

  useEffect(() => {
    setPreview(previewWabaTemplate());
  }, [wabaTemplateState]);

  return { wabaTemplateState, updateWabaTemplateState, preview };
}
