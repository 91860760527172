import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { fetchListWabaTemplates, fetchWabaConnections } from '../templatesSlice';
import { NewConnection } from '../../../api/CompanyAPI';
import './WabaTemplates.scss';
import Select from '../../../components/Select';
import Loader from '../../../components/Loader/Loader';


function WabaTemplates() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const route = useRouteMatch();
  const history = useHistory();
  const connections = useAppSelector((state) => state.templates.wabaConnections);
  const { id: companyId } = useSelector(currentCompanySelector);
  const loading = useAppSelector((state) => state.templates.wabaListLoading);

  useEffect( () => {
    dispatch(fetchWabaConnections({ companyId }));
  }, []);

  const onSelect = async (connection: NewConnection) => {
    const con = connections.find((c) => c.id === connection.id);
    if (con) {
      let block = true;
      await dispatch(fetchListWabaTemplates({ companyId, connectionId: connection.id }))
        .then((res) => (block = res.meta.requestStatus === 'rejected'));
      history.push({ pathname: `${route.url}/list`, state: { connectionId: connection.id, block } });
    }
  };

  return (
    <div className='wabaTemplates'>
      <h1>{t('templates.waba-templates')}</h1>
      <Select items={connections} labelKey='name' caption={t('templates.waba_list.connection')} onChoose={onSelect} />
      {loading && <Loader />}
    </div>
  );
}

export default WabaTemplates;
