import { useState } from 'react';
import './WabaCarousel.scss';
import { WabaTemplateCarousel } from '../../../../ChatTypes';
import WabaButtons from '../WabaButtons';
import WabaHeader from '../WabaHeader';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/arrow-down.svg'


type WabaCarouselProps = {
  carousel: WabaTemplateCarousel;
};

function WabaCarousel({ carousel }: WabaCarouselProps) {
  const [currentItem, setCurrentItem] = useState(0);

  const onChangeItem = (step: number) => {
    setCurrentItem((prev) => {
      if ((prev + step < carousel.length) && (prev + step >= 0)) {
        return (prev + step);
      }
      return prev;
    });
  };
  
  return (
    <div className='wabaCarousel'>
      <div className='wabaCarousel__selector'>
        <button className={`back ${currentItem === 0 ? 'disabled' : ''}`} onClick={() => onChangeItem(-1)}> 
          <ArrowIcon />
        </button>
        <button className={`forward ${currentItem === carousel.length - 1 ? 'disabled' : ''}`}
          onClick={() => onChangeItem(1)}>
          <ArrowIcon />
        </button>
      </div>
      {carousel[currentItem].header && <WabaHeader header={carousel[currentItem].header} />}
      {carousel[currentItem].body && <div className='wabaCarousel__body'>{carousel[currentItem].body.text}</div>}
      {carousel[currentItem].buttons && carousel[currentItem].buttons.length > 0 &&
        <div className="wabaCarousel__buttons">
          <WabaButtons buttons={carousel[currentItem].buttons} />
        </div>
      }
    </div>
  );
}

export default WabaCarousel;
