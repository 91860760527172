import './AddUser.scss';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postRefreshLink } from '../../../Chat/companiesSlice';
import { RootState } from '../../../../store/store';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import Button from '../../../../components/Button/Button';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import { ReactComponent as ReloadIcon } from '../../../../assets/reload.svg';


const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const inviteLink = useSelector((state: RootState) => state.companies.url);

  const refreshButton = () => {
    if (currentCompanyId?.params.companyId) {
      dispatch(postRefreshLink(Number(currentCompanyId?.params.companyId)));
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    dispatch(addAlertWithCustomText({ message: t('settings.users.copy_link') }));
  };

  return (
    <div className='addUser'>
      <h1>{t('settings.users.title')}</h1>
      <p className='regularText'>{t('settings.users.text_info')}</p>
      <div className='addUser__content'>
        <p className='regularText semibold'>{t('settings.users.unique_link')}</p>
        <div className='addUser__content_iventeLink'>
          <input type='text' value={inviteLink} id='linkInput' readOnly />
          <button type='button' onClick={refreshButton} >
            <ReloadIcon />
            {t('settings.users.refresh_link')}
          </button>
        </div>
      </div>
      <Button type='button' color='orange' textType='regular' text={t('copy')}
        image={<CopyIcon />} onClick={onCopy} /> 
    </div>
  );
};

export default AddUser;
