import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { createConnection } from "../connectionsSlice";
import { ConnectionsCreateRequest } from "../ConnectionsTypes";
import { currentCompanySelector, onboardingGuideSelector, onboardingSelector } from "../../../Chat/companiesSelector";
import { YandexMetrikaCall } from "../../../../utils/utils";
import {
  patchOnboarding, setHadConnections, setOnboardingGuide, setOnboardingSwitchModal
} from "../../../Chat/companiesSlice";
import { CompanyOnboardingUpdate, ConnectionsOnboarding } from "../../../../api/types";


export function useCreateConnection() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentCompany = useSelector(currentCompanySelector);

  const createConnectionSub = (data: ConnectionsCreateRequest) => {
    dispatch(createConnection(data))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          if (currentCompany.hadConnections === false) {
            YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
            dispatch(setHadConnections(true));
          }
          history.goBack();
        }
      });
  };

  return createConnectionSub;
}

export function useGuide() {
  const dispatch = useAppDispatch();
  const onboarding = useSelector(onboardingSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const service = onboardingGuideOn === 'add_connection' &&
    onboarding?.steps.find((step) => step.type === 'add_connection')?.result?.value as ConnectionsOnboarding;

    const updateOnboarding = () => {
      if (service) {
        const data: CompanyOnboardingUpdate = {
          company_id: currentCompany.id,
          steps: [
            {
              is_finished: true,
              type: "add_connection",
              result: { value: service }
            }
          ]
        }
        dispatch(patchOnboarding(data));
      }
    };

    const patch = () => {
      updateOnboarding();
    };

    const update = () => {
      if (service) {
      updateOnboarding();
      dispatch(setOnboardingGuide(null));
      dispatch(setOnboardingSwitchModal(true));
      }
    };

      return { service, update, patch };
}
