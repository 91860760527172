import './NoConnectionsIcon.scss';
import { ConnectionServiceType } from "../../utils/connections";
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ConnectionsType } from "../../ConnectionsTypes";
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import Button from "../../../../../components/Button";


type Props = {
  service: ConnectionsType;
  connection: ConnectionServiceType;
  onAddConnect: () => void;
};

const NoConnectionsIcon = ({ service, connection, onAddConnect }: Props) => {
  const { t } = useTranslation();
  const canCreateConnections = useHasPermission('connectionsCreate');

  return (
    <div className="NoConnectionsIcon">
      <div className="NoConnectionsIcon__iconBlock">
        <div className="NoConnectionsIcon__iconBlock__icon" >
          <connection.bigIcon style={connection.bigIconSize} />
        </div>
      </div>
      <div className="NoConnectionsIcon__text">
        <h1>{t(connection.name)}</h1>
        {canCreateConnections ? (
          <>
            <p className='regularText'>{t(`connections.no-connections.${service}.create`)}</p>
            <div className="NoConnectionsIcon__link">
              <Button type='button' color='orange' textType='regular' text={t('connect')}
                image={<PlusIcon />} onClick={onAddConnect} />
            </div>
          </>
        ) : (
          <p className='regularText'>{t(`connections.no-connections.${service}.no_create`)}</p>
        )}
      </div>
    </div>
  );
};

export default NoConnectionsIcon;
