import {useEffect, useState} from 'react';
import './Onboarding.scss';
import { useDispatch, useSelector } from 'react-redux';
import { currentStepSelector } from './onboardingSelectors';
import { 
  currentCompanySelector, getCurrentCompanyRoleSelector, profileSettingsInfo
} from '../../views/Chat/companiesSelector';
import OnboardingPopup from './OnboardingPopup/OnboardingPopup';


const Onboarding = () => {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const profileSettings = useSelector(profileSettingsInfo);
  const currentStep = useSelector(currentStepSelector);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const isAdministrator = currentRole && currentRole.name === 'ADMINISTRATOR';

  useEffect(() => {
    setIsOwner(currentCompany && profileSettings && profileSettings.userId === currentCompany.ownerId);
  }, [profileSettings, currentCompany]);

  if (!currentStep || (!isOwner && !isAdministrator)) return null;

  return (
    <div className="onboarding">
      <OnboardingPopup />
    </div>
  );
};

export default Onboarding;
