export const convertAudioTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  return `${minutes.toString()}:${Math.floor(time - minutes * 60).toString().padStart(2, '0')}`;
};

export const countWabaProducts = (count: number) => {
  if (count === 1) {
    return 'chats.order.product';
  } else if (count <= 4) {
    return 'chats.order.products_a';
  } else return 'chats.order.products_b';
};
