import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross.svg';
import Button from '../../../../../components/Button';
import './PauseWarn.scss';


type Props = {
  datePause: string;
  handleConfirm: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function PauseWarn({ datePause, handleConfirm, setIsOpen }: Props) {
  const { t } = useTranslation();

  const onCancelClick = () => setIsOpen(false);

  return (
    <div className="pauseWarn__wrapper">
      <div className="pauseWarn">
        <div className="pauseWarn__close">
          <CloseIcon onClick={onCancelClick} />
        </div>
        <div className="pauseWarn__title">{t('subscriptions.pause_warn.head')}</div>
        <div className="pauseWarn__content">
          <p>{t('subscriptions.pause_warn.line_1')}</p>
          <p>{t('subscriptions.pause_warn.line_2')} {datePause}</p>
          <p>{t('subscriptions.pause_warn.line_3')}</p>
          <p>{t('subscriptions.pause_warn.line_4')}</p>
        </div>
        <div className="pauseWarn__buttons">
          <Button color='orange' textType='regular'
            text={t('subscriptions.pause_warn.pause')} onClick={handleConfirm} />
          <Button color='white' textType='regular' text={t('cancel')} onClick={onCancelClick} />
        </div>
      </div>
    </div>
  );
}
