import { ReactElement  } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import './LinkTab.scss';


type LinkTabProps = {
  tabName: string;
  tabUrl: string;
  tabIcon?: ReactElement;
};

function LinkTab({ tabName, tabUrl, tabIcon }: LinkTabProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const path = useRouteMatch<{ tab: string }>('/companies/:companyId/templates/:tab');

  return (
    <Link className={`linkTab ${path?.params.tab === tabUrl && 'active'}`} to={`${url}/${tabUrl}`}>
      <p className='regularText middle'>{tabIcon}{t(tabName)}</p>
    </Link>
  );
}

export default LinkTab;
