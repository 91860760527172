import React, { useEffect, useRef, useState } from 'react';
import './AttachmentVideoPreview.scss';
import { ReactComponent as TrashIcon } from '../../../../assets/trash.svg';
import { ReactComponent as PauseIcon } from '../../../../assets/pause-icon.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/play.svg';
import { AttachmentPreviewComponent } from '../ChatField/WabaTemplateComponent/types';
import Video from '../ChatField/WabaTemplateComponent/Video';

const AttachmentVideoPreview: AttachmentPreviewComponent = ({
  attachedFile,
  setAttachedFiles,
  width,
  height,
  customTemplateEditPayload,
  removeTrashIcon,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [pauseIconIsVisible, setPauseIconIsVisible] = useState(false);

  useEffect(() => {
    if (isPlayed && videoRef.current) {
      videoRef.current.play();
    }
  }, [isPlayed]);

  return (
    <div className="videoPreviewContainer" style={{ width, height }}>
      <div className="videoPreview">
        <Video
          videoRef={videoRef}
          attachedFile={attachedFile}
          attachedFileUrl={customTemplateEditPayload?.fileUrl || ''}
          width={width - 40}
          height={height - 20}
        />
        <div
          className="video-controls"
          onClick={() => {
            setIsPlayed(!isPlayed);
          }}
        >
          {isPlayed ? (
            <div
              style={{
                width: 32,
                height: 32,
              }}
            >
              <PauseIcon
                className={`video-controls__pauseIcon ${pauseIconIsVisible && 'active'}`}
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.pause();
                  }
                }}
                onMouseOver={() => setPauseIconIsVisible(true)}
                onMouseOut={() => setPauseIconIsVisible(false)}
              />
            </div>
          ) : (
            <PlayIcon className='video-controls__playIcon' />
          )}
        </div>
      </div>
      {!removeTrashIcon && <TrashIcon className="documentPreviewContainer__trashIcon" onClick={setAttachedFiles} />}
    </div>
  );
};

export default AttachmentVideoPreview;
