import { useState } from "react";
import { Channels, PartnerNotificationChannel } from "../../../PartnerCabinetTypes";
import { partnerNotifications } from "../../utils";


type NotificationContextProps = {
  children: React.ReactNode;
};

function NotificationContext({ children }: NotificationContextProps) {
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [channel, setChannel] = useState<PartnerNotificationChannel | null>(null);
  const [channelType, setChannelType] = useState<Channels | null>(null);

  return (
    <partnerNotifications.Provider value={
      { openNotification, setOpenNotification, channel, setChannel, channelType, setChannelType }}>
      {children}
    </partnerNotifications.Provider>
  );
}

export default NotificationContext;