import './PopupAPIKey.scss';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../store/store";
import { addAlertWithCustomText } from "../../../../../../components/Alert/alertSlice";
import { IntegrationsItem } from '../../../IntegrationTypes';
import { ReactComponent as CopyIcon } from "../../../../../../assets/copy.svg";
import Button from "../../../../../../components/Button/Button";


type Props = {
  integration: IntegrationsItem;
  isFirstCreateAPI: boolean;
};

function PopupApiKey({ integration, isFirstCreateAPI }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCopy = () => {
    if (integration?.params.apiKey) {
      navigator.clipboard.writeText(integration?.params.apiKey);
      dispatch(addAlertWithCustomText({ message: t('settings.integrations.api.apiCopy') }));
    }
  };

  return (
    <div className='popupApiKey'>
      <h2>{isFirstCreateAPI
        ? t('settings.integrations.api.update.key_create')
        : t('settings.integrations.api.update.key_update')
      }</h2>
      <p className='regularText'>{t('settings.integrations.api.update.apiDescription')}</p>
      <input className='regularText' readOnly value={integration?.params.apiKey} />
      <Button type='button' color='orange' textType='regular' text={t('copy')} image={<CopyIcon />} onClick={onCopy} />
    </div>
  );
}

export default PopupApiKey;
