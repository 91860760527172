import { nanoid } from 'nanoid';
import './WabaTemplatesList.scss';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDebounce } from 'use-debounce';
import { WabaConnection } from '../../../../api/CompanyAPI';
import { useAppSelector } from '../../../../store/store';
import {
  WabaTemplateComponentBody, WabaTemplateComponentButtonsIn,
  WabaTemplateComponentCarouselIn,
  WabaTemplateComponentFooter, WabaTemplateComponentHeader,
} from '../../templatesTypes';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { wabaTemplateContext } from '../WabaTemplateProvider/WabaTemplateProvider';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import Search from '../../../../components/Search/Search';
import WabaTemplateItem from './components/WabaTemplateItem';
import WabaTemplatesPlug from './components/WabaTemplatesPlug';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader/Loader';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';


export function checkComponentText(
  item: WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter
  | WabaTemplateComponentButtonsIn
  | WabaTemplateComponentCarouselIn): item is WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter {
  if (item && ['BODY', 'HEADER', 'FOOTER'].includes(item.type) ) {
    return true;
  }
  return false;
}

function WabaTemplatesList() {
  const { t } = useTranslation();
  const location = useLocation<{connectionId: number; block: boolean}>()
  const history = useHistory();
  const templates = useAppSelector((state) => state.templates.wabaListTemplates);
  const connections = useAppSelector((state) => state.templates.wabaConnections);
  const loading = useAppSelector((state) => state.templates.wabaListLoading);
  const [connection, setConnection] = useState<WabaConnection>();
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [search, setSearch] = useState('');
  const [query] = useDebounce<string>(search, 500);
  const { setConnectionId } = useContext(wabaTemplateContext);

  useEffect(() => {
    if (!location.state) history.push(location.pathname.replace('/list', ''));
  }, []);

  useEffect(() => {
    if (location.state && templates) {
      setConnection(connections.find((c) => c.id === location.state.connectionId));
      setConnectionId(location.state.connectionId);
    }
    if (!query) {
      setFilteredTemplates(templates);
    }
  }, [location.state, templates]);

  useEffect(() => {
    if (query) {
      const filtered = templates.filter((t) => (t.name.toLowerCase().includes(query.toLowerCase())
        || t.customName?.toLowerCase().includes(query.toLocaleLowerCase())
        || t.components.some((c) => checkComponentText(c) && c.text.toLowerCase().includes(query.toLowerCase()))));
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates);
    }
  }, [query, templates]);

  const onCreate = () => {
    setConnectionId(connection?.id ?? 0);
    history.push(location.pathname.replace('list', 'create'));
  };

  if (loading) return <Loader />;

  if (!connection) return null;

  if (connection && templates.length === 0) {
    return (
      <div className='wabaTemplatesList'>
        <GoBackWrapper title=''>
          <WabaTemplatesPlug name={connection.name} phone={connection.params.phone} block={location.state?.block}
            onClick={onCreate} />
        </GoBackWrapper>
      </div>
    );
  }

  return (
    <div className='wabaTemplatesList'>
      <GoBackWrapper title={connection?.name ?? ''} >
        <WrapperPermission permission='templatesCreate'>
          <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
            image={<PlusIcon />} onClick={onCreate} disabled={location.state?.block} />
        </WrapperPermission>
        <div className='wabaTemplatesList__caption regularText'>{connection?.params.phone}</div>
        <Search onChangeSearchInput={setSearch} value={search} />
        <div className='wabaTemplatesList__list'>
          <div className='wabaTemplatesList__list_header smallText semibold'>
            <span>{t('templates.waba_list.name')}</span>
            <span>{t('templates.waba_list.category')}</span>
            <span>{t('templates.waba_list.languages')}</span>
          </div>
          <div className='wabaTemplatesList__list_content'>
            {filteredTemplates.length > 0 && filteredTemplates.map((template) => (
              <WabaTemplateItem key={nanoid()} template={template} />
            ))}
          </div>
        </div>
      </GoBackWrapper>
    </div>
  );
}

export default WabaTemplatesList;
