import { createContext } from 'react';
import { NotificationChannel, NotificationChannelTypes } from '../NotificationsTypes';
import { ReactComponent as WhatsAppIcon } from '../../../../assets/logo-whatsapp-no-fill.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/logo-telegram.svg';


export type NotificationContext = {
  onDelete?: (channel: NotificationChannel) => void;
  onEdit?: (channel: NotificationChannel) => void;
};

export const notificationContext = createContext<NotificationContext>({});

export const listLogosNotification: Record<NotificationChannelTypes, JSX.Element> = {
  whatsapp: <WhatsAppIcon className='whatsappIcon' />,
  telegram: <TelegramIcon />,
};
