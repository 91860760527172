import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { guidedOnboardingSteps } from '../../../modals/OnboardingWizard/utils/guides';
import { currentOnboardingStep } from '../../../modals/OnboardingWizard/utils/getStep';
import { onboardingGuideSelector, onboardingSelector } from '../../../views/Chat/companiesSelector';
import { guideContext } from '../guide';
import './GuideSelector.scss';


const BORDER_SELECTION_PADDING = 8;

type BorderStyle = {
  top: number;
  left: number;
  width: number;
  height: number;
};

function GuideSelector() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setup, style } = useContext(guideContext);
  const onboarding = useSelector(onboardingSelector);
  const guide = useSelector(onboardingGuideSelector);
  const [styleBorder, setStyleBorder] = useState<BorderStyle>();

  const currentStep = onboarding && currentOnboardingStep(onboarding);

  useEffect(() => {
    if (setup) {
      const border = {
        top: style.top - BORDER_SELECTION_PADDING,
        left: style.left - BORDER_SELECTION_PADDING,
        width: style.width + 2 * BORDER_SELECTION_PADDING,
        height: style.height + 2 * BORDER_SELECTION_PADDING
      };
      setStyleBorder(border);
    }
  }, [setup, style]);

  const onSelect = () => {
    if (currentStep && onboarding && guide) {
      const guideConfig = guidedOnboardingSteps[guide];
      if (guideConfig && guideConfig.action) history.push(`${url}${guideConfig.action(onboarding)}`);
    }
  };

  return (
    <div className='guideSelector' style={styleBorder} onClick={onSelect}>
      {}
    </div>
  );
}

export default GuideSelector;
