import { useEffect, useMemo } from 'react';
import './RequisitesFormPartner.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { CompanyRequisite, Organization, organizations, PartnerRequisiteUpdate } from '../../PartnerCabinetTypes';
import { usePartnerRequisiteSchema } from '../utils';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import CheckControl from '../../../../components/CheckControl';
import Dropdown from '../../../Settings/Dropdown/Dropdown';


type RequisiteSchema = {
  name: string;
  type: Organization;
  tin: string;
  current_account: string | null;
  bic: string | null;
  kpp: string | null;
  default: boolean;
};

type RequisitesFormPartnerProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  makeRequisite: (requisite: PartnerRequisiteUpdate, requisiteId?: number ) => Promise<boolean>;
  editData?: CompanyRequisite;
};

const RequisitesFormPartner = ({ setIsOpen, makeRequisite, editData }: RequisitesFormPartnerProps) => {
  const { t } = useTranslation();
  const schema = usePartnerRequisiteSchema();

  const methods = useForm<RequisiteSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '', tin: '', current_account: '', bic: '', kpp: '', default: false },
  });

  useEffect(() => {
    if (editData) {
      methods.reset({ name: editData.name, tin: editData.tin, current_account: editData.currentAccount,
        bic: editData.bic, kpp: editData.kpp, default: editData.default, type: editData.type
       });
    }
  }, []);

  const organizationOptions = useMemo(() => (organizations.map(
    (item) => ({ id: item, name: t(`partner_cabinet.requisites.organization.${item}`) }))), []);

  const organization = methods.watch('type');

  const onSubmit = async (data: RequisiteSchema) => {
    const result = await makeRequisite(data, editData?.id );
    if (result) setIsOpen(false);
  };

  return (
    <div className="requisitesFormPartner" >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h1>{editData ? t('requisites.title_edit') : t('requisites.title_create')}</h1>
          <div className="requisitesFormPartner__content">
            <Dropdown name='type' label={t('partner_cabinet.requisites.organization.label')}
              items={organizationOptions} labelKey='name' valueKey='id' />
            <Input name='name' label={t('partner_cabinet.requisites.name')} />
            <Input name='tin' label={t('partner_cabinet.requisites.tin')} />
            <p className='smallText requisitesFormPartner__content_note'>
              {t('partner_cabinet.requisites.note')}
              {organization === 'company' && t('partner_cabinet.requisites.and_kpp')}
            </p>
            <Input name='current_account' label={t('partner_cabinet.requisites.current_account')} />
            <Input name='bic' label={t('partner_cabinet.requisites.bic')} />
            {organization === 'company' && <Input name='kpp' label={t('partner_cabinet.requisites.kpp')} />}
            <CheckControl name='default' label={t('partner_cabinet.requisites.check')} />
          </div>
          <div className="requisitesFormPartner__buttons">
            <Button color="orange" disabled={!methods.formState.isDirty || methods.formState.isSubmitting}
              textType='regular' text={t('save')} type="submit" />
            <Button color="white" textType='regular' text={t('cancel')}
              onClick={() => setIsOpen(false)} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RequisitesFormPartner;
