import React, { ReactElement } from 'react';
import './ListIcon.scss';


type Props<T> = {
  fields: Array<T>,
  label: string;
  labelKey: string;
  image?: string | ReactElement;
  position?: 'left' | 'right';
  callback?: (value: number) => void;
  termComponent?: () => ReactElement;
};

const ListIcon = <T extends { id: string | number | null; custom?: boolean }>(
  { fields, label, labelKey, image, position = 'right', callback, termComponent }: Props<T>): JSX.Element => {

  const onSelect = (e: React.MouseEvent<HTMLLIElement>, index: number) => {
    e.stopPropagation();
    if (callback) {
      callback(index);
    }
  };

  const getImage = () => {
    if (typeof image === 'string') {
      return (
        <img className='listiconContainer__list__item_image' alt='icon' src={image} />
      );
    } if (typeof image === 'object') {
      return { ...image };
    }
    return <></>;
  };

  return (
    <div className="listiconContainer">
      <p className="listiconContainer__label">
        {label}<span className="listiconContainer__label__length">{fields.length}</span>
      </p>
      {termComponent && termComponent()}
      <ul className="listiconContainer__list">
          {fields.length > 0 && (
            fields.map((item, index) => (
              <li className={`listiconContainer__list__item ${position}`}
                key={item.id} onClick={(e) => onSelect(e, index)}>
                {position === 'left' && <div className='arrowIcon'>{getImage()}</div>}
                <p>
                {// @ts-ignore
                item[labelKey]
                }
                </p>
                {position === 'right' && <div className='arrowIcon'>{getImage()}</div>}
              </li>
            ))
          )}
        </ul>
    </div>
  );
};

export default ListIcon;
