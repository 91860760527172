
export type PartnerInfo = {
  name: string;
  promoCode: string;
  partnerLink: string;
};

export type PartnerCompany = {
	id: number;
	name: string;
	paymentsLocked: boolean;
	partnerPercent: number;
	wabaDialogsBalance: number | null;
  waba360DialogPartnerId: string | null;
	paidUntil: string | null;
	testUntil: string | null;
	pausedAt: string | null;
	partnerNotificationChannelsCount: number;
};

export type Connection = {
	id: number;
	type: string;
	name: string;
	phone: string | null;
};

export type CompanySubscription = {
	id: number;
	connections: Connection[];
	subscriptionType: string;
	currentSize: number;
	paidUntil: string;
	testUntil: string;
	pausedAt: string | null;
};

export type Product = {
  connectionId: number;
  amount: number;
  discount: number;
};

export type SubscriptionItem = 'SUBSCRIPTION';
export type WabaDialogItem = 'WABA_DIALOGS';

export type SubscriptionInvoiceItem = {
  type: SubscriptionItem;
  subscriptionId: number;
  oldSize: number;
  products: Product[];
};

export type SubscriptionInvoiceDialog = {
  type: WabaDialogItem;
  count: number;
  price: number;
};

export type ApprovalStatus = 'APPROVED' | 'PENDING' | 'DECLINED';
export const organizations = ['individual_entrepreneur', 'company'] as const;
export type Organization = typeof organizations[number];

export type CompanyRequisite = {
	id: number;
	type: Organization;
	tin: string;
	name: string;
	approvalStatus: ApprovalStatus;
	default: boolean;
	bic: string | null;
	kpp: string | null;
	currentAccount: string | null;
};

export type PaymentMethod = 'CARD_RU' | 'CARD_OTHER' | 'BANK_TRANSFER'

export type PaymentSummary = {
	paymentMethod: PaymentMethod;
	periodMonths: number;
	items: Array<SubscriptionInvoiceItem | SubscriptionInvoiceDialog>;
	promoCode: string | null;
	totalDiscount: number;
	partnerDiscountPercent: number;
	companyRequisite: CompanyRequisite | null;
};

export type ReceiptItem = {
	name: string;
	quantity: number;
	price: number;
};

export type Receipt = {
	items: ReceiptItem[];
};

export type PaymentStatus = 'new' | 'payment_pending' | 'confirmed' | 'rejected' | 'expired' | 'refunded' | 'partial_refunded';

export type Currency = 'RUB' | 'USD';

export type CompanyPayment = {
	id: number | null;
	preview: boolean;
	status: PaymentStatus;
	lifetime: string;
	summary: PaymentSummary;
	amount: number;
	currency: Currency;
	paymentUrl: string | null;
	previewUrl: string | null;
	actUrl: string | null;
	receipt: Receipt;
	createdAt: string;
	companyId: number;
	companyName: string;
};

export type PartnerCompanyListRequest = {
	company_id: number;
  limit?: number;
  offset?: number;
};

export type PartnerCompaniesRequest = PartnerCompanyListRequest & {
  query?: string;
};

export type PartnerCompanyRequest = {
  partner_company_id: number;
  company_id: number;
};

export type PaymentLockRequest = PartnerCompanyRequest & {
  is_locked: boolean;
};

export type CompaniesPaymentsRequest = PartnerCompanyRequest & {
	limit?: number;
	offset?: number;
};

export type CompanyRequisitesRequest = PartnerCompanyRequest & {
	only_approved?: boolean;
};

export type CompanyInvoiceRequestItem = {
	type: SubscriptionItem;
	id: number;
};

export type CompanyInvoiceRequestDialog = {
	type: WabaDialogItem;
	count: number;
};

export type PartnerCompanyInvoiceRequest = PartnerCompanyRequest & {
	preview: boolean;
	items: Array<CompanyInvoiceRequestItem | CompanyInvoiceRequestDialog>;
	payment_method: PaymentMethod;
	period_months?: number;
	company_requisite_id?: number;
	promo_code?: string;
	partner_email?: string;
};

export type PartnerRequisitesRequest = {
  company_id: number;
  only_approved?: boolean;
};

export type PartnerRequisiteRequest = {
  requisite_id: number;
  company_id: number;
};

export type PartnerRequisiteUpdate = {
  tin: string;
	type: Organization;
  name: string;
	default?: boolean;
	bic?: string | null;
	kpp?: string | null;
	current_account?: string | null;
};

export type PartnerRequisitePostRequest = PartnerRequisiteUpdate & {
	company_id: number;
};

export type UpdatePartnerRequisite = PartnerRequisiteRequest & PartnerRequisiteUpdate;

// Notification Channels
export type NotificationEvents = 'payment_status';

export const channelsTypes = ['telegram', 'whatsapp'] as const;

export type Channels = typeof channelsTypes[number];

export type ChannelWhatsapp = {
	type: 'whatsapp';
	whatsapp: {
		phone: string;
	};
};

export type ChannelTelegram = {
	type: 'telegram';
};
	
type PartnerNotificationGeneric<T> = {
	id: number;
	name: string;
	params: {
		events: NotificationEvents[];
		partnerCompanies: number[];
	};
} & T;

export type PartnerNotificationWhatsapp = PartnerNotificationGeneric<ChannelWhatsapp>;
export type PartnerNotificationTelegram = PartnerNotificationGeneric<ChannelTelegram>;

export type PartnerNotificationChannel = PartnerNotificationWhatsapp | PartnerNotificationTelegram;

export type PartnerNotificationChannelsParams = {
	events: NotificationEvents[];
	partner_companies: number[];
};

type PartnerNotificationChannelCreateGeneric<T> = {
	companyId: number;
	params: PartnerNotificationChannelsParams;
} & T;

export type PartnerNotificationChannelCreate = 
	| PartnerNotificationChannelCreateGeneric<ChannelWhatsapp>
	| PartnerNotificationChannelCreateGeneric<ChannelTelegram>;

export type PartnerNotificationChannelRequest = {
	companyId: number;
	channelId: number;
};

export type PartnerNotificationChannelUpdate = {
	params: PartnerNotificationChannelsParams;
} & PartnerNotificationChannelRequest;

export type PartnerNotificationTelegramResponse = {
	url: string;
	qrCode: string;
};

export function isTelegramLink(item: PartnerNotificationTelegramResponse | PartnerNotificationChannel):
	item is PartnerNotificationTelegramResponse {
	return (item as PartnerNotificationTelegramResponse).url !== undefined;
}

export function isNotificationChannel(item: unknown):
	item is PartnerNotificationChannel {
	return (item as PartnerNotificationChannel).id !== undefined;
}

export type PartnerCurrentBalance = {
	balance: number;
};

export type PartnerRewardType = 'WABA_DIALOGS_REWARD';

export type PartnerReward = {
	id: number;
	period: string;
	companyId: number;
	companyName: string;
	rewardType: PartnerRewardType;
	periodTopupsValue: number;
	periodSpendingsValue: number;
	rewardValue: number;
};

export type PartnerRewards = {
	rewards: PartnerReward[];
};

export type PartnerPayoutStatus = 'CREATED' | 'PENDING' | 'SUCCESS' | 'REJECTED';
export type PartnerPayoutTypes = 'PAYOUT' | 'MANUAL_CHANGE';

export type PartnerPayout = {
	id: number;
	status: PartnerPayoutStatus;
	payoutType: PartnerPayoutTypes;
	value: number;
	createdAt: string;
	comment: string;
};

export type PartnerPayouts = {
	payouts: PartnerPayout[];
};

export type PartnerPayoutRequest = {
	company_id: number;
	requisite_id: number;
};
