import React, { useCallback, useEffect, useState } from 'react';
import './CreateChatPopup.scss';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getContactAPI, Phone } from '../../../Contacts/ContactsAPI';
import { useAppDispatch } from '../../../../store/store';
import { createChat, selectChat, updateErrors, CHAT_ALREADY_EXISTS } from '../../chatsSlice';
import { chatsError, chatsWithContactsSelector } from '../../chatsSelector';
import { allConnectionsForChatSelector } from '../../companiesSelector';
import { ConnectionPopup } from "./utils";
import { useMobile } from "../../../../components/utils/hooks";
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { ReactComponent as TriangleTail } from '../../../../assets/dropdown-after-triangle.svg';
import SwitchTabs from '../../../../components/SwitchTabs';
import Button from '../../../../components/Button/Button';
import DropdownChatPopup from "./DropdownChatPopup/DropdownChatPopup";
import PhoneElement from '../../../../components/PhoneInput/PhoneElement';


type CreateChatProps = {
  position: string;
  setIsVisibleCreateChatPopup: React.Dispatch<React.SetStateAction<boolean>>;
  contactId?: number;
};

type RequestNewChatType = {
  companyId: number;
  connectionId: number;
  phone?: string;
  username?: string;
  contactId?: number;
};

const getUsername = (userName: string) => {
  if (userName?.startsWith('@')) {
    return userName.slice(1);
  }
  return userName;
};

const CreateChatPopup = React.forwardRef<HTMLDivElement, CreateChatProps>(
  ({ contactId, position, setIsVisibleCreateChatPopup }, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
    const dispatch = useAppDispatch();
    const chatsWithContact = useSelector(chatsWithContactsSelector);
    const allConnectionsForChat = useSelector(allConnectionsForChatSelector);
    const errors = useSelector(chatsError);
    const [contactPhones, setContactPhones] = useState<Phone[]>();
    const [selectedPhone, setSelectedPhone] = useState<Phone>();
    const [selectedConnection, setSelectedConnection] = useState<ConnectionPopup>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [switchContactType, setSwitchContactType] = useState<string>('phone');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [usernameValue, setUsernameValue] = useState<string>('@');
    const isMobile = useMobile();

    useEffect(() => {
      // fill list of contact phones
      (async () => {
        if (url?.params.companyId && contactId && selectedConnection) {
          const contact = await getContactAPI(
            { contactId, companyId: Number(url?.params.companyId) });
          const receivers: Phone[] = [];
          if (contact.phones && contact.phones.length > 0 && switchContactType === 'phone') {
            contact.phones.forEach((phone) => {
              receivers.push({
                id: phone.id,
                type: 'phone',
                value: phone.value,
              });
            });
          } else if (selectedConnection.type === 'telegram' && switchContactType === 'username') {
            contact.chats.forEach((chat) => {
              if (chat.profileLink) {
                const urlsList = chat.profileLink.split('/');
                receivers.push(
                  {
                    id: chat.connectionId,
                    type: 'username',
                    value: urlsList[urlsList.length - 1],
                  });
              }
            });
          }
          if (receivers.length > 0) {
            setContactPhones(receivers);
            if (receivers.length === 1) {
              setSelectedPhone(receivers[0]);
            }
          } else {
            setContactPhones(receivers.splice(0, receivers.length));
          }
        }
      })();
    }, [selectedConnection, switchContactType]);

    useEffect(() => {
      if(errors.createNewChat?.errorMessage === 'fulfilled'){
        setIsVisibleCreateChatPopup(false);
      }
    },[errors]);

    const tabs = [
      {
        tabName: t('chats.chats-list.create-chat-popup.switch_phone'),
        tabId: 'phone',
      },
      {
        tabName: t('chats.chats-list.create-chat-popup.switch_name'),
        tabId: 'username',
      },
    ];

    const getListPhones = () => {
      if (contactPhones) {
        let filterPhones: Phone[];
        if (selectedConnection?.type === 'telegram') {
          filterPhones = contactPhones.filter((phone) =>
            phone.value.includes(usernameValue.slice(1)));
        } else {
          filterPhones = contactPhones.filter((phone) => phone.value.includes(phoneNumber));
        }
        return (
          <>
            {filterPhones && filterPhones.length > 0 && (
              <div className='contentList'>
                {filterPhones.map((phone) => (
                  <div className='contentList_item'
                    onClick={(e) => onSelectPhone(phone, e)}
                    key={phone.id}>{phone.value}</div>
                ))}
              </div>
            )}
          </>
        );
      }
      return <></>;
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsernameValue(e.target.value);
    };

    const onSelectPhone = (phone: Phone, e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch(updateErrors({ key: 'createNewChat', value: null }));
      if (selectedConnection?.type === 'telegram') {
        if (switchContactType === 'phone') {
          setPhoneNumber(phone.value);
        } else if (phone.value.startsWith('@')) {
          setUsernameValue(phone.value);
        } else setUsernameValue(`@${phone.value}`);
      } else {
        setPhoneNumber(phone.value);
      }
    };

    const onClickCloseIcon = (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      setIsVisibleCreateChatPopup(false);
    };

    const onSwitchContactType = (e: React.MouseEvent<HTMLButtonElement>) => {
      const switcherValue = e.currentTarget.id;
      setSwitchContactType(switcherValue);
    };

    const errorsMessage = () => {
      let errorMessage = '';
      if (errors.createNewChat) {
        const isWabaConnection = allConnectionsForChat.find((item) => item.connectionId === selectedConnection?.id);
        if ((isWabaConnection?.type === 'waba' || isWabaConnection?.type === 'whatsapp')
          && isWabaConnection.createChat.withUsername && !isWabaConnection.createChat.withPhone) {
          errorMessage = t('error.createChatError');
        } else {
          errorMessage = t(`errors.${errors.createNewChat.errorMessage}`);
          if (errorMessage === `errors.${errors.createNewChat.errorMessage}`) {
            errorMessage = errors.createNewChat.errorMessage;
          }
        }
        return (<p className="createChatPopup__errorMessage">{errorMessage}</p>);
      }
      return (<></>);
    };

    const checkChatErrors = useCallback(() => {
      if (errors.createNewChat && errors.createNewChat.errorMessage === CHAT_ALREADY_EXISTS) {
        const { chatId: chat, contactId: contact } = errors.createNewChat.payload;
        const findContact = chatsWithContact?.data.find((chatG) => chatG.contactId === contact);
        const findChat = findContact?.chats.find((chatR) => chatR.chatId === chat);
        if (findChat) dispatch(selectChat(findChat));
        history.push(`${url!.url}/chats/${contact}/${chat}`);
        setIsVisibleCreateChatPopup(false);
        return true;
      }
      return false;
    }, [errors]);

    const onSubmit = () => {
      // check if errors or chat already exists
      if (checkChatErrors()) {
        return;
      }
      if (selectedConnection && url?.params.companyId) {
        const requestData: RequestNewChatType = {
          companyId: Number(url?.params.companyId),
          contactId,
          connectionId: selectedConnection.id,
        };
        if (position === 'new') {
          // if new chat check phone or user name switcher
          if (switchContactType === 'username') {
            requestData.username = getUsername(usernameValue);
          } else if (phoneNumber) {
            requestData.phone = phoneNumber;
          }
        } else {
          requestData.contactId = contactId;
          if (selectedPhone) {
            if (selectedConnection.type === 'telegram' && switchContactType === 'username') {
                requestData.username = getUsername(usernameValue);
            } else {
              requestData.phone = phoneNumber;
            }
          }
          if (!selectedPhone && phoneNumber) {
            requestData.phone = phoneNumber;
          }
        }
        if (requestData.phone || requestData.username) {
          dispatch(createChat(requestData));
        }
      }
    };

    return (
      <div className={`createChatPopup ${position}`} ref={ref} onClick={(e) => e.stopPropagation()}>
        <div className="createChatPopup__header">
          <p className="createChatPopup__header_title">
            {t(`chats.chats-list.create-chat-popup.title${position === 'new' ? '_new' : ''}`)}
          </p>
          <CloseIcon onClick={onClickCloseIcon} />
        </div>
        <DropdownChatPopup selectedConnection={selectedConnection} setSelectedConnection={setSelectedConnection}
          setSwitchContactType={setSwitchContactType} />
        <div className={`phoneDropdown ${!isValid ? 'invalid' : ''}`}>
          <p className={isMobile ? 'regularText' : 'smallText'}>{t('chats.chats-list.create-chat-popup.phone_label')}</p>
          {selectedConnection?.type === 'telegram' &&
            <SwitchTabs tabs={tabs} selectedTab={switchContactType} onSelectHandler={onSwitchContactType} />}
          {selectedConnection?.type === 'telegram' && switchContactType === 'username'
            ? <input value={usernameValue} onChange={onChangeInput} type='text' className="inputStandard selector" />
            : <PhoneElement phone={phoneNumber} setPhone={setPhoneNumber} setValid={setIsValid} check={true} />
          }
          {position !== 'new' && getListPhones()}
        </div>
        {errorsMessage()}
        <Button type='button' color='orange' textType={isMobile ? 'regular' : 'small'} onClick={onSubmit}
          disabled={!selectedConnection || !isValid}
          text={errors.createNewChat && errors.createNewChat.errorMessage === CHAT_ALREADY_EXISTS
            ? t('chats.chats-list.create-chat-popup.redirect_button')
            : t('chats.chats-list.create-chat-popup.create_button')}
        />
        {!isMobile && <TriangleTail className={`createChatPopup__tail ${position}`} />}
      </div>
    );
  });

export default CreateChatPopup;
