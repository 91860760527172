export function parserWabaErrorMessage(errorMessage: string) {
  const message = '';
  let messageObject;
  if (!errorMessage) return message;
  try {
    messageObject = JSON.parse(errorMessage);
    messageObject = JSON.parse(messageObject?.meta?.error);
    if ('error_user_msg' in messageObject.error) return messageObject?.error.error_user_msg;
    if ('message' in messageObject.error) return messageObject?.error.message;
  } catch (e) {
    return message;
  }
  
  return message;
}
