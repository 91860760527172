import { useEffect, useState } from 'react';
import './WabaCreateForm.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { postConnectionState } from '../../../ConnectionsAPI';
import { bdAnchorsWaba } from '../../../../../../pages/Home/utils/consts';
import { DEFALUT_TARIFF, tarifName } from "../../../../../../utils/dialogs";
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { ReactComponent as QuestionIcon } from '../../../../../../assets/information.svg';
import { ReactComponent as Logo360Dialog } from "../../../../../../assets/logo-360-dialog.svg";
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { useAppDispatch } from "../../../../../../store/store";
import { useGuide } from '../../../api/guide';
import GoBackWrapper from "../../../../../../components/GoBackWrapper";
import ListItems from '../../../../../../components/ListItems';


const PERMISSION_360D_ORIGIN = process.env.REACT_APP_360D_PERMISSION_ORIGIN as string;

const WabaCreateForm = () => {
  const { t, i18n } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const { service, update } = useGuide();
  const currentCompany = useSelector(currentCompanySelector);
  const [popup360D, setPopup360D] = useState<Window | null>(null);

  useEffect(() => {
    const onPopupMessage = (e: MessageEvent) => {
      if (!e.origin.startsWith(PERMISSION_360D_ORIGIN)) {
        return null;
      }
      if (e.data.success) {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        goBack();
        if (service === 'waba') update();
      }
      return undefined;
    }

    if (popup360D) window.addEventListener('message', onPopupMessage, false);

    return () => window.removeEventListener('message', onPopupMessage);

  }, [popup360D]);

  const partnerId = currentCompany.waba360DialogPartnerId || DEFALUT_TARIFF;
  const habTariff = tarifName(partnerId);
  const PERMISSION_360D_LINK = `https://hub.360dialog.com/dashboard/app/${partnerId}/permissions`;

  const showPermissionWindow = (statePermission: string): void => {
    const windowHeight = 900;
    const windowWidth = 600;
    const topOffset = window.innerHeight / 2 + window.screenTop - windowHeight / 2;
    const leftOffset = window.innerWidth / 2 + window.screenLeft - windowWidth / 2;
    const popup = window.open(
     `${PERMISSION_360D_LINK}?state=${statePermission}&plan_selection=basic&hosting_type=cloudapi`, 'Auth360Window',
     `popup, location=0, width=${windowWidth}, height=${windowHeight}, top=${topOffset}, left=${leftOffset}`);
    setPopup360D(popup);
  };

  const getPermissions = async () => {
    const statePermission = await postConnectionState(currentCompany.id);
    showPermissionWindow(statePermission.state);
  };

  return (
    <div className="wabaCreateForm">
      <GoBackWrapper title={`${t('connections.waba.create')} WhatsApp Business API`}>
        <div className='wabaCreateForm__instruction'>
          {habTariff === 'intl'
            ? <p>
              {t('connections.waba.instructions.text_intl')}<br/>
              {t('connections.waba.instructions.sub_text')}
            </p>
            : <p>{t('connections.waba.instructions.sub_text')}</p>
          }
          <ListItems typeList='ol' items={t('connections.waba.instructions.steps').split('\n')} />
        </div>
        <div className="wabaCreateForm__360dialog" onClick={getPermissions}>
          <Logo360Dialog />
          {t('connections.waba.info.access_link')}
        </div>
        <div className="wabaCreateForm__information">
          <QuestionIcon />
          <p>
            <a href={bdAnchorsWaba[i18n.language]} target='_blank' rel='noreferrer'>
              {t('connections.waba.info.guide_link')}
            </a>&nbsp;
            {t('connections.waba.info.guide_text')}
          </p>
        </div>
      </GoBackWrapper>
    </div>
  );
};

export default WabaCreateForm;
