import { useContext, useEffect, useRef } from 'react';
import { guideDispatchContext } from '../../../../../../components/Guide/guide';
import './IntegrationItem.scss';


type IntegrationItemProps = {
  name: string;
  logoUrl: string;
  onChoose: (e: React.MouseEvent<HTMLElement>) => void;
  status?: string;
  guideOn?: boolean;
};

function IntegrationItem({ logoUrl, name, onChoose, status='enabled', guideOn }: IntegrationItemProps) {
  const itemRef = useRef<HTMLDivElement>(null);
  const { setElementRef } = useContext(guideDispatchContext);

  useEffect(() => {
    if (guideOn && itemRef?.current) {
      itemRef.current.scrollIntoView();
      setElementRef(itemRef);
    }
  }, [itemRef.current, guideOn]);

  return (
    <div ref={itemRef} className={`integrationItem ${status}`} onClick={onChoose}>
      <img src={logoUrl} alt="integration img" />
      <p className='smallText middle'>{name}</p>
    </div>
  );
}

export default IntegrationItem;
