import { useEffect, useState } from 'react';
import './BePaidEdit.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useParams, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../../../../components/Input/Input';
import Switch from '../../../../Switch/Switch';
import Button from '../../../../../../components/Button/Button';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { BepaidConnection } from '../../../../../../api/CompanyAPI';
import { BepaidConnectionPatchRequest } from '../../../ConnectionsTypes';
import { updateConnection } from '../../../connectionsSlice';
import { switchItems, MAX_LIFETIME, MIN_LIFETIME } from '../../../utils/terminals';
import LifetimePayment from "../../../components/LifetimePayment";


export type OnSubmitSchema = {
  name: string;
  params?: {
    secret_key?: string;
    is_testing: boolean;
    erip: boolean;
    invoice_lifetime_minutes: number;
  };
};

const BePaidEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { connectionId } = useParams<{ connectionId: string }>();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.bepaid);
  const [connection, setConnection] = useState<BepaidConnection>();

  const schema = yup.object({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object({
      secret_key: yup.string().max(128, t('error.form.max_length', { limit: 128 })),
      is_testing: yup.boolean(),
      erip: yup.boolean(),
      invoice_lifetime_minutes: yup.number()
        .min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    })
      .transform((value) => {
        if (value && Object.values(value).some((v) => v !== '')) {
          return value;
        }
        return undefined;
      })
      .default(undefined),
  });

  const methods = useForm<OnSubmitSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        secret_key: '',
        is_testing: connection?.params.isTesting,
        erip: connection?.params.erip,
        invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes,
      }
    }
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as BepaidConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect?.name,
        params: {
          secret_key: '',
          is_testing: connect?.params.isTesting,
          erip: connect?.params.erip,
          invoice_lifetime_minutes: connect?.params.invoiceLifetimeMinutes
        }
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<OnSubmitSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }
    if (connection) {
      const patchData: BepaidConnectionPatchRequest = {
        companyId: Number(currentCompanyId?.params.companyId),
        itemId: Number(connectionId),
        name: data.name,
        type: 'bepaid',
        params: {
            ...(data.params ? { is_testing: data.params.is_testing } : {} ),
            ...(data.params && data.params.secret_key && data.params.secret_key.length > 0
              ? { secret_key: data.params.secret_key } : {})
        }
      };
      dispatch(updateConnection(patchData)).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/bePaid`));
        }
      });
    }
  };

  return connection ? (
      <div className="bePaidEditContainer">
        <GoBackWrapper title={t('edit')}>
          <p>Номер подключения: #{connection.id}</p>
          <p>ID магазина: {connection.params.shopId}</p>
          <FormProvider {...methods}>
            <div className="bePaidEditContainer_input">
              <Input label="Название подключения" name="name" />
              <Input label="Обновить секретный ключ" name="params.secret_key" placeholder="Изменить секретный ключ" inputType="password" />
              <Switch name="params.is_testing" items={switchItems}  />
            </div>
            <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
          </FormProvider>
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                  onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
        </GoBackWrapper>
      </div>
  ) : null;
};

export default BePaidEdit;
