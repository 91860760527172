import React from 'react';
import ReactDOM from 'react-dom';
import './MobileInfo.scss';
import { OverlayBackgroundType } from '../types';
import { ReactComponent as CloseIcon } from '../../../assets/cross.svg';


type MobileInfoProps = {
  message: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

function MobileInfo({ message, background='none', setIsOpen }: MobileInfoProps) {

  const onCloseClick = () => setIsOpen(false);

  return ReactDOM.createPortal(
    <>
      <div className={`mobileInfo__wrapper ${background}`} onClick={onCloseClick}>{}</div>
      <div className="mobileInfo">
        <div className="mobileInfo__message">{message}</div>
        <CloseIcon onClick={onCloseClick} />
      </div>
    </>,
    document.body
  );
}

export default MobileInfo;
