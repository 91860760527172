import React, {  useRef, useState } from 'react';
import './SelectTags.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShowIcon } from '../../../../../../assets/arrow-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/cross.svg';
import { TagType } from '../../../../ContactsAPI';
import { useOutsideClick } from "../../../../../../components/utils/hooks";
import { useFormContext } from "react-hook-form";


type PropsType = {
  name: string;
  allTags: TagType[];
};

export const SelectTags: React.FC<PropsType> = ({ name, allTags }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const filterTags = allTags.filter(tag => selectedTags.includes(tag.id));
  const diffTags = allTags.filter(tag => !selectedTags.includes(tag.id));

  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(contentRef, () => setIsOpen(false), triggerRef);

  const onSelect = (tagId: number) => {
    selectedTags.push(tagId);
    setSelectedTags((prevState) => {
      return [...prevState];
    });
    setValue(name, selectedTags);
    setIsOpen(!isOpen);
  };

  const onDelete = (e: React.MouseEvent<SVGSVGElement>, item: TagType) => {
    e.stopPropagation();
    const index = selectedTags.findIndex((value) => value === item.id);
    selectedTags.splice(index, 1);
    setSelectedTags((prevState) => {
      return [...prevState];
    });
    setValue(name, selectedTags);
  };

  if (!allTags) {
    return <></>;
  }

  return (
    <div className="selectTags">
      <div className='selectTags__header'>
        <p className='regularText middle'>{t('contacts.contactsList.tags')}</p>
      </div>
      <div className="selectTags__container" onClick={() => setIsOpen(!isOpen)} ref={triggerRef}
        data-testid='open-selector' >
        {t('contacts.contactsList.select_tags')}
        <div className={`closeIcon ${isOpen ? 'open' : ''}`}>
          <ShowIcon />
        </div>
        {isOpen && <div className="selectTags__list" ref={contentRef}>
          {diffTags.map((tag) => (
            <div key={tag.id} className='tagContainer' onClick={() => onSelect(tag.id)} data-testid='select-tag'>
              <div className="tag" style={{ backgroundColor: `${tag.backgroundColor}` }}>
                {tag.name}
              </div>
            </div>
          ))}
        </div>
        }
      </div>
      <div className="selectTags__preview">
        {filterTags.length > 0 && filterTags.map((tag: TagType) => (
          <div key={tag.id} className="tag" style={{ backgroundColor: `${tag.backgroundColor}` }}>
            {tag.name}
            <CloseIcon onClick={(e) => onDelete(e, tag)} data-testid='delete-tag' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectTags;
