
import './OnboardingWizard.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingGuide, setOnboardingSwitchModal } from '../../views/Chat/companiesSlice';
import { configForOnboardingSteps, currentOnboardingStep } from './utils/getStep';
import { onboardingSelector, onboardingSwitchModalSelector } from '../../views/Chat/companiesSelector';
import ProgressSteps from './ProgressSteps';
import FinishStep from './Steps/FinishStep';
import { useEffect } from 'react';


const OnboardingWizard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboarding = useSelector(onboardingSelector);
  const isSwitchModal = useSelector(onboardingSwitchModalSelector);

  useEffect(() => {
    dispatch(setOnboardingGuide(null));
  }, []);

  const onSwitchModal = () => dispatch(setOnboardingSwitchModal(!isSwitchModal));

  if (!onboarding || onboarding.isCancelled || onboarding.isFinished) return <></>;

  const currentStep = currentOnboardingStep(onboarding);

  const Step = currentStep && configForOnboardingSteps[currentStep].isWizardView
    ? configForOnboardingSteps[currentStep].component : null;

  return (
    <div className="onboardingWizard">
      <div className="onboardingWizard__header">
        <h2>{t('company_onboarding.title')}</h2>
        {currentStep && <ProgressSteps />}
      </div>
      {Step ? <Step /> : <FinishStep onSwitchModal={onSwitchModal} />}
    </div>
  );
};

export default OnboardingWizard;
