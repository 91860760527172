import './AnalyticsCode.scss';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';


const AnalyticsCode = () => {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  const onCopy = () => {
    if (textareaRef.current) {
      navigator.clipboard.writeText(textareaRef.current.value);
      dispatch(addAlertWithCustomText({ message: t(`analytics.alert_code`) }));
    }
  };

  return (
    <div className='analyticsCode'>
      <div className='analyticsCode_header'>
        <div className='analyticsCode_title'>
          <p className='smallText'>{t('analytics.code')}</p>
        </div>
        <div className='analyticsCode_code' onClick={onCopy} >
          <p className="smallText" >{t('analytics.copy')}</p>
          <CopyIcon />
        </div>
      </div>
      <textarea ref={textareaRef} readOnly value={`<script>
    !function(r,t,e,s,a,n,c){r[a]=r[a]||function(){(r[a].a=r[a].a||[]).push(arguments)},r[a].l=1
    *new Date;for(var i=0;i<document.scripts.length;i++)if(document.scripts[i].src===s)return
    ;n=t.createElement(e),c=t.getElementsByTagName(e)[0],n.async=1,n.src=s,c.parentNode
    .insertBefore(n,c)}(window,document,"script","https://track.radist.me/tracker.min.js","radist_tracker");

radist_tracker({
      message: ${t('analytics.copy_code')},
      whatsapp_enabled: true,
      tgbot_enabled: true
});
</script>`} />
    </div>
  );
};

export default AnalyticsCode;
