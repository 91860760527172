import React from 'react';
import './EmojiComponent.scss';
import { BaseEmoji, Picker } from "emoji-mart";
import { ReactComponent as EmojiPickerIcon } from "../../assets/smiley.svg";
import { useOutsideClickForEmoji } from "../utils/hooks";


type EmojiProps = {
  picker: boolean,
  setPicker: React.Dispatch<React.SetStateAction<boolean>>
  onSelect: (emoji: BaseEmoji) => void,
  isBlocked?: boolean,
  pickerRef: React.RefObject<SVGSVGElement>,
  onClick: () => void
}

const EmojiComponent = ({ picker, setPicker, onSelect, isBlocked, pickerRef, onClick }: EmojiProps) => {
  useOutsideClickForEmoji(pickerRef, setPicker, picker);

  return (
    <div className='emojiComponent'>
      {picker ? (
        <Picker
          showPreview={false}
          showSkinTones={false}
          native
          onSelect={onSelect}
        />
      ) : null}
      <EmojiPickerIcon
        className={`emojiIcon ${isBlocked ? 'blocked' : ''}`}
        ref={pickerRef}
        onClick={onClick}
      />
    </div>
  )
};

export default EmojiComponent;
