import { ChatsWithContacts, ChatType } from './ChatTypes';
import { useSelector } from 'react-redux';
import { chatsWithContactsSelector } from './chatsSelector';


export const createMessageData = (payload: { fileType: string; caption: string; fileName: string }) => ({
  messageId: Math.random().toString(),
  chatId: Math.random().toString(),
  direction: 'outbound',
  createdAt: new Date().toISOString(),
  deliveredAt: new Date().toISOString(),
  readAt: new Date().toISOString(),
  [payload.fileType]: {
    caption: payload.caption,
    name: payload.fileName,
  },
  ...payload,
});

export const sortChats = (chats: ChatType[] | ChatsWithContacts[]) => {
  chats.sort((chatA, chatB) => {
    if (chatA.sortWeight > chatB.sortWeight) {
      return -1;
    }

    if (chatA.sortWeight < chatB.sortWeight) {
      return 1;
    }
    return 0;
  });
};

export const generateChatSortWeight = (chat: ChatType) => {
  let createdAt = 0;
  let isHaveUnansweredMessages = 0;
  const { unansweredCount, name } = chat;

  if (chat.lastMessage) {
    createdAt = new Date(chat.lastMessage.createdAt).getTime();
  }

  if (unansweredCount > 0) {
    isHaveUnansweredMessages = 1;
  }

  return createdAt + isHaveUnansweredMessages * 100000000000 + name.charCodeAt(0);
};

export const generateChatsWithContactSortWeight = (chatsWithContact: ChatsWithContacts) => {
  let createdAt = 0;
  let isHaveUnansweredMessages = 0;
  const { unansweredCount, contactId } = chatsWithContact;

  if (unansweredCount > 0) {
    isHaveUnansweredMessages = 1;
  }

  if (chatsWithContact.lastChatUpdatedAt) {
    createdAt = new Date(chatsWithContact.lastChatUpdatedAt).getTime();
  }

  return createdAt + isHaveUnansweredMessages * 100000000000 + contactId;
};

export const getConversationsList = () => {
  const chatsWithContacts = useSelector(chatsWithContactsSelector);

  const chats = chatsWithContacts && chatsWithContacts?.data?.length > 0
    && chatsWithContacts.data.map(i => {
      const arraySort = i.chats.filter(m => m.lastMessage).sort((aa, bb) => {
        const b = new Date(aa.lastMessage.createdAt);
        const a = new Date(bb.lastMessage.createdAt);

        if (a < b && aa.unansweredCount) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });


      if (arraySort.length > 0) {
        return {
          ...i,
          chats: i.chats,
          lastChatUpdatedAt: arraySort[0].lastMessage.createdAt,
        };
      }
      return i;
    });

  const unansweredChats = chats && chats.filter(i => i.unansweredCount).sort((aa, bb) => {
    const b = new Date(aa.lastChatUpdatedAt);
    const a = new Date(bb.lastChatUpdatedAt);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  const withAnAnswerChats = chats && chats.filter(i => !i.unansweredCount).sort((aa, bb) => {

    const b = new Date(aa.lastChatUpdatedAt);
    const a = new Date(bb.lastChatUpdatedAt);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  const sortChats = unansweredChats && withAnAnswerChats && [...unansweredChats, ...withAnAnswerChats];

  return { sortChats };
}
