import FirstStep from '../Steps/FirstStep';
import SecondStep from '../Steps/SecondStep';
import ThirdStep from '../Steps/ThirdStep';
import FourthStep from '../Steps/FourthStep';
import FifthStep from '../Steps/FifthStep';
import SixthStep from '../Steps/SixthStep';
import SeventhStep from '../Steps/SeventhStep';
import EighthStep from '../Steps/EighthStep';

type Styles = {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
  width?: number;
  transform?: string;
};

type OnboardingStepsConfig = Record<number, {
    styles: Styles;
    tailPosition: Styles;
    component: () => JSX.Element;
  }>;

export const onboardingStepsConfig: OnboardingStepsConfig = {
  1: {
    styles: {
      top: 30,
      right: 32,
      width: 432,
    },
    tailPosition: {},
    component: FirstStep,
  },
  2: {
    styles: {
      top: 92,
      right: 246,
      width: 432,
    },
    tailPosition: {
      top: -30,
      right: 0,
      transform: 'rotate(180deg)',
    },
    component: SecondStep,
  },
  3: {
    styles: {
      top: 90,
      left: 240,
      width: 390,
    },
    tailPosition: {
      top: -30,
      left: 0,
      transform: 'rotate(180deg)',
    },
    component: ThirdStep,
  },
  4: {
    styles: {
      top: 358,
      left: 240,
      width: 390,
    },
    tailPosition: {
      top: -30,
      left: 0,
      transform: 'rotate(180deg)',
    },
    component: FourthStep,
  },
  5: {
    styles: {
      top: 122,
      left: 15,
      width: 390,
    },
    tailPosition: {
      top: -30,
      left: 0,
      transform: 'rotate(180deg)',
    },
    component: FifthStep,
  },
  6: {
    styles: {
      top: 90,
      left: 585,
      width: 390,
    },
    tailPosition: {
      top: 0,
      left: -40,
      transform: 'rotateZ(90deg)',
    },
    component: SixthStep,
  },
  7: {
    styles: {
      bottom: 115,
      left: 258,
      width: 390,
    },
    tailPosition: {
      bottom: 0,
      left: -40,
      transform: 'rotateZ(90deg)',
    },
    component: SeventhStep,
  },
  8: {
    styles: {
      bottom: 2,
      left: 145,
      width: 390,
    },
    tailPosition: {
      bottom: 0,
      left: -40,
      transform: 'rotateZ(90deg)',
    },
    component: EighthStep,
  },
};
