import React, { useEffect } from 'react';
import './CompanySettings.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentCompanySelector, profileSettingsInfo } from '../../Chat/companiesSelector';
import { timezone } from './timezone';
import { ALL_CURRENCIES } from "./currency";
import { useAppDispatch } from '../../../store/store';
import { updateCompany } from '../../Chat/companiesSlice';
import { useHasPermission, WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../components/Button';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../../../components/Input';


type SubmitSchema = {
  name: string;
  currency: string;
  timezone: string;
};

const CompanySettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const infoProfile = useSelector(profileSettingsInfo);
  const currentCompany = useSelector(currentCompanySelector);
  const hasEdit = useHasPermission('companySettingsEdit');
  const defaultLanguage = 'en';
  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field'))
      .max(128, t('error.form.max_length', { limit: 128 })),
    currency: yup.string(),
    timezone: yup.string(),
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onSubmit',
    defaultValues: {
      name: currentCompany.name,
      currency: currentCompany.paymentCurrency,
      timezone: currentCompany.timezone,
    },
    resolver: yupResolver(schema)
  });

  const itemsTimezone = timezone[infoProfile.language] ? timezone[infoProfile.language] : timezone[defaultLanguage];

  const itemsCurrency = ALL_CURRENCIES.map((currency) => ({ id: currency, name: t(`currency.${currency}`) }));

  const onSubmit = (data: SubmitSchema) => {
    if (currentCompanyId?.params.companyId) {
      dispatch(updateCompany({
        companyId: currentCompanyId?.params.companyId,
        name: data.name,
        payment_currency: data.currency,
        timezone: data.timezone,
      }));
    }
  };

  useEffect(() => {
    methods.reset({
      name: currentCompany.name,
      currency: currentCompany.paymentCurrency,
      timezone: currentCompany.timezone,
    })
  }, [currentCompany]);

  return (
    <div className='settingCompany'>
      <h1>{t('settings.company_settings.title')}</h1>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input disabled={!hasEdit} label={t('settings.company_settings.name_company')} name='name' />
          <Dropdown label={t('settings.company_settings.currency')} items={itemsCurrency} labelKey='name'
            valueKey='id' name='currency' placeholder={t('select')} disabled={!hasEdit} />
          <Dropdown label={t('settings.company_settings.timezone')} items={itemsTimezone} labelKey='name'
            valueKey='id' name='timezone' placeholder={t('select')} disabled={!hasEdit} />
          <WrapperPermission permission='companySettingsEdit'>
            <Button type='submit' text={t('save_edit')} color='orange' textType='regular'
              disabled={!methods.formState.isDirty} />
          </WrapperPermission>
        </form>
      </FormProvider>
    </div>
  );
};

export default CompanySettings;
