import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './ModalWrapper.scss';
import { OverlayBackgroundType } from '../types';
import { ReactComponent as CloseIcon } from "../../../assets/cross.svg";


type ModalWrapperProps = {
  children: ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

function ModalWrapper({ children, setIsOpen, background }: ModalWrapperProps) {

  const onCloseDefault = () => {
    if (setIsOpen) setIsOpen(false);
  };

  return ReactDOM.createPortal(
    <div className="modalWrapper__container">
      <div className={`modalWrapper__wrapper ${background}`} onClick={onCloseDefault}>{}</div>
      {setIsOpen
        ? (
          <div className="modalWrapper">
            <CloseIcon onClick={onCloseDefault} />
            {children }
          </div>
        ) : (<>
          {children}
        </>)
      }
    </div>, document.body
  );
}

export default ModalWrapper;
