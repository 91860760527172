import { useRef } from 'react';
import './TextAreaResize.scss';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getError } from '../../../../components/utils/forms';
import { SelectPosition } from '../../utils/waba';


export type TextAreaResizeProps = {
  name: string;
  title?: string;
  subtitle?: string;
  setPosition?: (value: SelectPosition) => void;
} & TextareaAutosizeProps;

function TextAreaResize({ name, title, subtitle, setPosition, ...props }: TextAreaResizeProps) {
  const { register, formState: { errors } } = useFormContext();
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...rest } = register(name);

  const checkPositions = () => {
    if (!textRef.current || textRef.current.value.length === 0) return;
    if (setPosition) setPosition({
      start: textRef.current.selectionStart ?? textRef.current.value.length,
      end: textRef.current.selectionEnd ?? textRef.current.value.length
    });
  };

  return (
    <div className={`textAreaResize ${getError(errors, name) ? 'error' : ''}`}>
      <div className="textAreaResize__header">
        {title && <p className="regularText middle">{title}</p>}
        {subtitle && <p className="smallText">{subtitle}</p>}
      </div>
      <TextareaAutosize {...props} {...rest} ref={(e) => { ref(e); if (e) textRef.current = e; }} name={name}
        onPointerUp={checkPositions} onKeyUp={checkPositions} onBlur={checkPositions} />
      <ErrorMessage errors={errors} name={name} render={({ message }) => <p className="smallText error">{message}</p>} />
    </div>
  );
}

export default TextAreaResize;
