import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../store/store';
import { editCatalog, insertProductsCatalog, newCatalog, removeProductsCatalog } from '../../../SalesSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { getProductsList } from '../../../SalesAPI';
import Button from '../../../../../components/Button/Button';
import Input from '../../../../../components/Input/Input';
import Search from '../../../../../components/Search/Search';
import CatalogsChooseProductList from '../CatalogsChooseProductList/CatalogsChooseProductList';
import { CatalogType, ProductChooseType, selectContext } from '../../../types';
import UserCatalog from '../../models/UserCatalog';
import loader from '../../../../../assets/grid.svg';
import './CatalogsForm.scss';


const PRODUCTS_LIMIT_NUMBER = 100;

type CatalogsFormProps = {
  setFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalog?: UserCatalog;
};

const CatalogsForm = ({ catalog, setFormOpen }: CatalogsFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [loading, setLoading] = useState<boolean>(true);
  const [clicked, setClicked] = useState<boolean>(false);
  const [productList, setProductList] = useState<ProductChooseType[]>();
  const [include, setInclude] = useState<number[]>([]);
  const [exclude, setExclude] = useState<number[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [hasCatalog, setHasCatalog] = useState<boolean>(!!catalog);
  const [search, setSearch] = useState('');
  const [query] = useDebounce(search, 400);
  const schema = yup.object().shape({
    name: yup.string().required(t('empty_field')).max(30, t('sales.catalogs.form.name_input.max_length')),
  });

  const checkSelected = (productId?: number) => {
    if (productId && productList) {
      const productSelected = productList.find(item => item.id === productId);
      let newIds = [...include];
      let delIds = [...exclude];
      if (productSelected) {
        if (productSelected.selected) {
          if (delIds.includes(productId)) {
            delIds = delIds.filter(item => item !== productId);
          } else if (!catalog || (catalog && !productSelected.catalogIds.includes(catalog.id))) {
            newIds.push(productId);
          }
        } else if (!productSelected.selected) {
          if (newIds.includes(productId)) {
            newIds = newIds.filter(item => item !== productId);
          } else if (catalog && productSelected.catalogIds.includes(catalog.id)) {
            delIds.push(productId);
          }
        }
      }
      setInclude(newIds);
      setExclude(delIds);
      setIsChanged(newIds.length > 0 || delIds.length > 0);
    }
  };

  const setClose = () => setFormOpen(false);

  const loadCatalogProducts = async (newQuery=false)  => {
    const products = await getProductsList({
      companyId: currentCompany.id,
      offset: newQuery ? 0 :  productList?.length,
      limit: PRODUCTS_LIMIT_NUMBER,
      ...(query ? { query } : {}),
      ...(hasCatalog ? { catalogId: catalog?.id } : {})
    });
    if (hasCatalog) {
      setHasCatalog(products.count === PRODUCTS_LIMIT_NUMBER);
    } else {
      setHasMore(products.count === PRODUCTS_LIMIT_NUMBER);
    }
    let productsNew: ProductChooseType[] = [];
    if (catalog) {
      // check products of catalog
      productsNew = products.items.map((product) => (
        { ...product, selected: product.catalogIds.includes(catalog.id) }
      ));
    } else {
      // add all products
      productsNew = products.items.map((product) => ({ ...product, selected: false }));
    }
    if (newQuery) {
      setProductList(productsNew);
    } else {
      setProductList((prevState) => {  
        if (prevState && prevState.length > 0) {
          const prevIds = prevState.map((item) => item.id);
          return [...prevState, ...productsNew.filter((item) => !prevIds.includes(item.id))];
        }
        return productsNew;
       });
    }
  };

  useEffect(() => {
    loadCatalogProducts();
  }, []);

  useEffect(() => {
    setLoading(true);
    loadCatalogProducts();
  }, [hasCatalog]);

  useEffect(() => {
    setLoading(true);
    setProductList(undefined);
    loadCatalogProducts(true);
    if (query.length === 0 && catalog) setHasCatalog(true);
  }, [query]);

  useEffect(() => {
    if (productList) setLoading(false);
  }, [productList]);

  const methods = useForm<CatalogType>({
    mode: 'onChange',
    defaultValues: { name: catalog?.name },
    resolver: yupResolver(schema)
  }); 

  const onSubmit = (data: CatalogType) => {
    setClicked(true);
    if (catalog) {
      dispatch(editCatalog({ 
        companyId: currentCompany.id, catalogId: catalog.id, name: data.name, currency: catalog.currency }));
      if (isChanged) {
        // save product list changes
        if (include.length > 0) {
          dispatch(insertProductsCatalog({ companyId: catalog.companyId, catalogId: catalog.id, products: include }));
        }
        if (exclude.length > 0) {
          dispatch(removeProductsCatalog({ companyId: catalog.companyId, catalogId: catalog.id, products: exclude }));
        }
      }
    } else {
      dispatch(newCatalog({ companyId: currentCompany.id, name: data.name, currency: 'RUB' }))
        .then(result => {
          if (result.meta.requestStatus === 'fulfilled') {
            if (typeof result.payload === 'object' && include.length > 0) {
              dispatch(insertProductsCatalog({ 
                companyId: currentCompany.id, catalogId: result.payload.id, products: include }));
            }
          }
        });
    }
    setClose();
  };

  return (
    <div className="catalogsForm">
      {catalog === undefined ? (
        <h3>{t('sales.catalogs.create_catalog')}</h3>
      ) : (
        <h3>{t('sales.catalogs.edit_catalog')}</h3>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input name='name' label={t('sales.catalogs.form.name_input.label')} inputType='text'
            rightText={t('sales.catalogs.form.name_input.max_length')} />
          <h4>{t('sales.catalogs.form.add_products')}</h4>
          <Search onChangeSearchInput={setSearch} value={search} />
          {loading === true ? (<div className="load"><img src={loader} alt='loading...' /></div>) : (
            <div className="catalogsForm__products">
              {productList && productList.length === 0 ? (
                <p>{search ? t('sales.catalogs.no_products_found') : t('sales.catalogs.no_products')}</p>
              ) : (
                <selectContext.Provider value={{ checkSelected }}>
                  <CatalogsChooseProductList productList={productList || []}
                    onUpload={loadCatalogProducts} more={hasMore} />
                </selectContext.Provider>
              )}
            </div>
          )}
          <div className={`catalogsForm__buttons ${catalog === undefined ? 'right' : ''} ${clicked ? 'clicked': ''}`}>
            {catalog === undefined ? (
              <Button type='submit' textType='regular' color='orange' text={t('sales.catalogs.create_catalog')}
                disabled={!methods.formState.isDirty && !isChanged} />
            ) : (
              <>
                <Button type='submit' textType='regular' color='orange' text={t('save_edit')}
                  disabled={!methods.formState.isDirty && !isChanged} />
                <Button type='button' textType='regular' color='white' text={t('cancel')} onClick={setClose} />
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CatalogsForm;
