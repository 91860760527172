import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { usePhoneSchema } from '../../../../../components/PhoneInput/utils';
import { countVariables, isValidUrl } from '../../../utils/waba';


export function useWabaTemplateCarouselSchema() {
  const { t } = useTranslation();
  const phoneSchema = usePhoneSchema({ fieldName: 'phone', required: true });
  return yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field'))
      .max(256, ({ max }) => t('error.form.max_length', { limit: max })),
    body: yup.string().required(t('error.form.empty_field'))
      .max(1024, ({ max }) => t('error.form.max_length', { limit: max })),
    header: yup.object().shape({
      format: yup.string().required(),
    }),
    cards: yup.array().max(10).min(1, t('templates.waba_list.form.cards.no_card_error')).of(yup.object().shape({
      description: yup.string().required(t('error.form.empty_field'))
      .max(160, ({ max }) => t('error.form.max_length', { limit: max })
    ) })),
    buttons: yup.array().max(2).min(1, t('templates.waba_list.form.cards.no_button_error')).of(yup.object().shape({
      type: yup.string().required(),
      text: yup.string().required(t('error.form.empty_field'))
        .max(25, ({ max }) => t('error.form.max_length', { limit: max })),
      url: yup.string().when('type', {
        is: 'URL',
        then: yup.string().required(t('error.form.empty_field')),
        otherwise: yup.string().nullable() }).test({ name: 'url',
          test: (val, { createError }) => {
            let message = '';
            if (val) {
              if (countVariables(val) > 1) {
                message = t('templates.waba_list.form.variable_limit');
              } else {
                let link = val;
                if (countVariables(val) === 1) {
                  link = val.replace(/(\{\{\d+\}\})?$/g, '');
                }
                if ((countVariables(link) > 0) || !isValidUrl(link)) {
                  message = t('error.form.url');
                }
              }
            }
            return message ? createError({ message }) : true;
          }
      }),
      phone_number: yup.string().when('type', {
        is: 'PHONE_NUMBER',
        then: phoneSchema.phone,
        otherwise: yup.string().nullable()
      })
    })).nullable().test({
      name: 'buttons_number',
      test: (val, { createError }) => {
        if (val) {
          const urlButtons = val.reduce((acc, curr) => acc + (curr.type === 'URL' ? 1 : 0), 0);
          const phoneButtons = val.reduce((acc, curr) => acc + (curr.type === 'PHONE_NUMBER' ? 1 : 0), 0);
          return (urlButtons > 1 || phoneButtons > 1)
            ? createError({ path: 'buttons', message: t('templates.waba_list.form.buttons.buttons_limit') }) : true;
        }
        return true;
      } })
  });
}
