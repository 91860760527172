import { channelsTypes } from '../../../PartnerCabinetTypes';
import NotificationConnect from '../NotificationConnect';
import './NotificationsBanner.scss';


function NotificationsBanner() {
  return (
    <div className='notificationsBanner'>
      {channelsTypes.map((channel) => <NotificationConnect key={channel} channelType={channel} />)}
    </div>
  );
}

export default NotificationsBanner;
