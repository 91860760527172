import './Analytics.scss';
import { useTranslation } from 'react-i18next';
import { bdAnchorsAnalytics } from '../../pages/Home/utils/consts';
import AnalyticsCode from './components/AnalyticsCode/AnalyticsCode';
import AnalyticsCheck from './components/AnaliticsCheck/AnaliticsCheck';


const Analytics = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='analytics'>
      <div className='analytics__content'>
        <h1>{t('analytics.title')}</h1>
        <div className='analytics__content_through'>
          <div className='analytics_title'>
            {t('analytics.description').split('\n').map((line, index) => (
              <p key={index} className="smallText">{line}</p>
            ))}
            <a href={bdAnchorsAnalytics[i18n.language]} target="_blank"
              rel="noreferrer"><p className="smallText" >{t('analytics.instruction')}</p>
            </a>
          </div>
          <AnalyticsCode />
          <AnalyticsCheck />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
