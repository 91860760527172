import { useState } from 'react';
import './MyRequisites.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/store';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { requisiteItemContext, useRequisiteRequest } from './utils';
import { CompanyRequisite } from '../PartnerCabinetTypes';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { removePartnerRequisite } from '../PartnerCabinetSlice';
import Button from '../../../components/Button';
import RequisitesList from './RequisitesList';
import RequisitesFormPartner from './RequisitesFormPartner/RequisitesFormPartner';
import ActionDialog from '../../../components/Modals/ActionDialog';
import SidePanel from '../../../components/Modals/SidePanel';


const MyRequisites = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [editData, setEditData] = useState<CompanyRequisite>();
  const [deleteData, setDeleteData] = useState<CompanyRequisite>();
  const { makeRequisite } = useRequisiteRequest();

  const onAdd = () => {
    setEditData(undefined);
    setIsOpenPopup(true);
  };

  const onEdit = (requisite: CompanyRequisite) => {
    setEditData(requisite);
    setIsOpenPopup(true);
  };

  const onDelete = (requisite: CompanyRequisite) => {
    setIsDelete(true);
    setDeleteData(requisite);
  };

  const onDeleteHandler = () => {
    if (deleteData && isDelete) {
      dispatch(removePartnerRequisite({ company_id: currentCompany.id, requisite_id: deleteData.id })).then((res) => {
        if (res) setIsDelete(false);
      });
    }
  };

  return (
    <div className="myRequisites">
      <div className="myRequisites__header">
        <h1>{t('partner_cabinet.requisites.title')}</h1>
        <WrapperPermission permission="partnersEdit">
          <Button text={t('partner_cabinet.requisites.add')} color="orange" textType="regular" onClick={onAdd} />
        </WrapperPermission>
      </div>
      <requisiteItemContext.Provider value={{ onEdit, onDelete }}>
        <RequisitesList />
      </requisiteItemContext.Provider>
      {isOpenPopup &&
      <SidePanel setIsOpen={setIsOpenPopup} background="light">
      <RequisitesFormPartner setIsOpen={setIsOpenPopup} makeRequisite={makeRequisite} editData={editData} />
      </SidePanel>}
      {isDelete && (
        <ActionDialog
          title={t('requisites.actions.delete_title')}
          message={t('requisites.actions.delete_text')}
          action={t('delete')} cancel={t('cancel')}
          onAction={onDeleteHandler} setIsOpen={setIsDelete} />
      )}
    </div>
  );
};

export default MyRequisites;
