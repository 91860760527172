import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { WabaTemplateAuthData } from '../../../WabaTemplateProvider/utils';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import Input from '../../../../../../components/Input';
import SwitchControl from '../../../../../../components/SwitchControl';
import Switcher from '../../../../../../components/Switcher';
import WabaTemplateButtons from '../WabaTemplateButtons';
import WabaTemplatePlug from '../WabaTemplatePlug';
import Button from '../../../../../../components/Button';
import { ReactComponent as SmallLoader } from '../../../../../../assets/small-loader.svg';


function WabaTemplateAuth() {
  const { t } = useTranslation();
  const { wabaTemplateState, updateWabaTemplateState, previewWabaTemplate: preview } = useContext(wabaTemplateContext);
  const [expiration, setExpiration] = useState<boolean>(false);

  const schema = yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    add_security_recommendation: yup.boolean(),
    code_expiration_minutes: yup.number().nullable(true).transform((_, val) => (val !== "" ? Number(val) : null))
        .min(1, t('error.form.min_value', { limit: 1 })).max(90, t('error.form.max_value', { limit: 90 })),
    buttons: yup.array().of(yup.object().shape({
      type: yup.string().required(),
      text: yup.string().required(t('error.form.empty_field')).when('type', {
        is: 'COPY_CODE' || 'ONE_TAP',
        then: yup.string().max(10, t('error.form.max_length', { limit: 10 })),
        otherwise: yup.string().max(20, t('error.form.max_length', { limit: 20 }))
      })
    })).min(1, t('error.form.empty_field'))
  });

  const methods = useForm<WabaTemplateAuthData>({ mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: {
      custom_name: wabaTemplateState.custom_name,
      add_security_recommendation: wabaTemplateState.add_security_recommendation,
      code_expiration_minutes: wabaTemplateState.code_expiration_minutes,
      buttons: wabaTemplateState.buttons
    } });

  const codeExpiration = useWatch({ name: 'code_expiration_minutes', control: methods.control });
  const securityRecommendation = useWatch({ name: 'add_security_recommendation', control: methods.control });

  useEffect(() => {
    updateWabaTemplateState({ type: 'expire', payload: { code_expiration_minutes: codeExpiration } });
  }, [codeExpiration]);

  useEffect(() => {
    updateWabaTemplateState({ type: 'security', payload: { add_security_recommendation: securityRecommendation } });
  }, [securityRecommendation]);

  const onExpirationChange = () => {
    setExpiration((prev) => !prev);
    if (expiration) {
      methods.setValue('code_expiration_minutes', undefined, { shouldValidate: true });
      updateWabaTemplateState({ type: 'expire', payload: { code_expiration_minutes: undefined } });
    }
  };

  const onSubmit = (payload: WabaTemplateAuthData) => {
    updateWabaTemplateState({ type: 'auth', payload });
  };

  return (
    <FormProvider {...methods}>
      <Input name='custom_name' label={t('templates.waba_list.form.name')} />
      <SwitchControl name='add_security_recommendation'
        label={t('templates.waba_list.form.security_recommendation')} />
      <div className='wabaTemplateForm__switcher'>
        <Switcher lable={t('templates.waba_list.form.code_expiration')}
          toggled={expiration} onToggle={onExpirationChange} />
        <Input name='code_expiration_minutes' label={t('templates.waba_list.form.code_validity')}
          inputType='number' disabled={!expiration} />
        <WabaTemplatePlug header={t('templates.waba_list.form.body.title')} text={preview?.body?.text ?? ''} />
        {expiration && 
          <WabaTemplatePlug header={t('templates.waba_list.form.footer.title')} text={preview?.footer?.text ?? ''} />}
        <WabaTemplateButtons category='AUTHENTICATION' kind={wabaTemplateState.kind} />
      </div>
      <div className='wabaTemplateForm__submit'>
        {wabaTemplateState.stage === 'create' && <SmallLoader />}
        <Button type='submit' color='orange' textType='regular' text={t('save')} onClick={methods.handleSubmit(onSubmit)}
        disabled={methods.formState.isSubmitting || !methods.formState.isDirty} />
      </div>
    </FormProvider>
  );
}

export default WabaTemplateAuth;
