import './OdnoklassnikiCreate.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useAppDispatch } from '../../../../../../store/store';
import { currentCompanySelector } from "../../../../../Chat/companiesSelector";
import { createConnection } from '../../../connectionsSlice';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { useGuide } from '../../../api/guide';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import ListItems from '../../../../../../components/ListItems';


type SubmitType = {
  name: string;
  group: string;
};

const OdnoklassnikiCreate = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { service, update } = useGuide();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, 'Не более 128 символов'),
    group: yup.string().required(t('error.form.empty_field')).max(256, 'Не более 256 символов'),
  });

  const methods = useForm<SubmitType>({
    mode: 'onSubmit',
    defaultValues: { group: '', name: '' },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: SubmitType) => {
    if (currentCompanyId?.params.companyId) {
      dispatch(
        createConnection({
          companyId: Number(currentCompanyId?.params.companyId),
          type: 'odnoklassniki',
          name: data.name,
          params: { ok_api_key: data.group },
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          if (currentCompany.hadConnections === false) {
            YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
            dispatch(setHadConnections(true));
          }
          dispatch(addAlertWithCustomText({
            message: t('connections.odnoklassniki.created'),
            type: 'info',
          }));
          history.push(`${currentCompanyId.url}/settings/connections/odnoklassniki`);
          if (service === 'odnoklassniki') update();
        }
      });
    }
  };

  return (
    <div className="odnoklassnikiCreate">
      <GoBackWrapper title={t('connections.odnoklassniki.add_view.networkGroup')}>
        <FormProvider {...methods}>
          <Input name="name" label={t('connections.odnoklassniki.add_view.name_connections')} />
          <Input name="group" label={t('connections.odnoklassniki.add_view.apiKeyGroup')} inputType="password" />
          <Button type='submit' color="orange" textType="regular"
                  text={t('connections.odnoklassniki.add_view.connectGroup')} onClick={methods.handleSubmit(onSubmit)}
                  disabled={!methods.formState.isDirty} />
          <div className="odnoklassnikiCreate__text">
            <h3>{t('connections.odnoklassniki.add_view.titleManual')}</h3>
            <ListItems typeList='ol' items={t('connections.odnoklassniki.add_view.manual').split('\n')} />
          </div>
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
};

export default OdnoklassnikiCreate;
