import { FC, useState } from 'react';
import './AddTags.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContactType, TagType } from '../../ContactsAPI';
import { allTagsContactSelector } from '../../contactsSlice/contactsSelector';
import { setCurrentContactTags, updateTagsContact } from '../../contactsSlice/contactsSlice';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as ShowIcon } from '../../../../assets/arrow-down.svg';
import { ReactComponent as TagAddIcon } from '../../../../assets/tag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import PopUpAction from '../PopUpAction';
import Button from "../../../../components/Button";


type PropsAddTags = {
  currentContact: ContactType;
  contactId: string;
};

const AddTags: FC<PropsAddTags> = ({ currentContact, contactId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allTagsContact = useSelector(allTagsContactSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [toggleAddTags, setToggleAddTags] = useState<boolean>(false);
  const [previewAddTags, setPreviewAddTags] = useState<TagType[]>([]);

  const idsTags = previewAddTags.map(tag => tag.id);
  const filterTags = allTagsContact.filter(tag => !idsTags.includes(tag.id));

  const activeSetPreviewAddTags = (tagId: number) => {
    const addTag = allTagsContact.find((tag) => tag.id === tagId);
    if (addTag && !previewAddTags) setPreviewAddTags([addTag]);
    if (addTag && previewAddTags) setPreviewAddTags([...previewAddTags, addTag]);
    setIsOpen(!isOpen);
  };

  const activeClearPreviewAddTags = () => {
    setPreviewAddTags([]);
    setToggleAddTags(false);
    setIsOpen(false);
  };

  const activeRemovePreviewAddTags = (tagId: number) => {
    if (previewAddTags) setPreviewAddTags(previewAddTags.filter((tag) => tag.id !== tagId));
  };

  const onSubmit = () => {
    if (previewAddTags && url?.params.companyId) {
      const currentTagsId: number[] =[];
      if (currentContact && currentContact.tags) currentContact.tags.forEach(({ id })=> currentTagsId.push(id))
      const onAddTagsId: number[] = previewAddTags.map(({ id }) => id);
      const idAllTagsContact: number[] = [...currentTagsId, ...onAddTagsId];
      dispatch(updateTagsContact({ contactId, idAllTagsContact,companyId:Number(url?.params.companyId) }));
      dispatch(setCurrentContactTags(idAllTagsContact));
      setPreviewAddTags([]);
      setToggleAddTags(false);
    }
  };

  return (
    <>
      {toggleAddTags && (
        <PopUpAction
          actionButtonCaption={t('add')}
          title={t('contacts.detailContact.addTags')}
          cancelButton={activeClearPreviewAddTags}
          onSubmit={onSubmit} disabled={previewAddTags.length > 0}>
          <div className="addTags">
            <div className='dropDownAddTags' onClick={() => setIsOpen(!isOpen)}>
              <p className='regularText'>{t('contacts.contactsList.select')}</p>
              <ShowIcon className={`arrow ${isOpen ? 'open' : ''}`} />
            </div>
            {isOpen && (
              <div className="dropDownAddTags__list">
                {filterTags.map((tag) =>
                  <div key={tag.id} className='dropDownAddTags__list_item' onClick={() => activeSetPreviewAddTags(tag.id)}>
                    <div className="addTagItem" style={{ backgroundColor: `${tag.backgroundColor}` }}>
                      <p>{tag.name}</p>
                    </div>
                  </div>)}
              </div>
            )}
            <div className="dropDownAddTags__preview">
              {previewAddTags &&
                previewAddTags.map((i: TagType) => (
                  <div key={i.id} className="addTagItem" style={{ backgroundColor: `${i.backgroundColor}` }} >
                    <p>{i.name}</p>
                    <CloseIcon onClick={() => activeRemovePreviewAddTags(i.id)} />
                  </div>
                ))}
            </div>
          </div>
        </PopUpAction>
      )}
      <WrapperPermission permission="contactsEdit">
        <Button type='button' color="white" textType="small" text={t('contacts.detailContact.addTags')}
          disabled={toggleAddTags} image={<TagAddIcon />} onClick={() => setToggleAddTags(true)}
          data-testid='add-tag' id='add-tag' />
      </WrapperPermission>
    </>
  );
};

export default AddTags;
