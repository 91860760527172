import React, { useRef, useState } from 'react';
import './DropdownWithCheckbox.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useOutsideClick } from '../utils/hooks';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';
import { ReactComponent as CloseIcon } from '../../assets/cross.svg';


type PropsType<T> = {
  name: string;
  label: string;
  listArray: T[];
  disabled?: boolean;
};

type Item = {
  id: number;
  name: string;
  backgroundColor?: string;
};

export const DropdownWithCheckbox = <T extends Item>({ name, label, listArray, disabled=false }: PropsType<T>) => {
  const { control, formState, setValue } = useFormContext();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isError = formState.errors[name];
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const selectedItems: number[] = useWatch({ name, control, defaultValue: [] });

  useOutsideClick(contentRef, () => setIsOpen(false), triggerRef);

  const getPlaceholder = () => {
    const filterArray = listArray.filter(item => selectedItems.includes(item.id));

    if (filterArray.length > 0) {
      return filterArray.map((item) => (
        <div key={item.id} className="tagPreview" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : '#FFFFFF' }}>
          {item.name}
          <CloseIcon className="closeIcon" onClick={(e) => onDelete(e, item)} />
        </div>
      ));
    }
    return t('select');
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement>, item: Item) => {
    e.stopPropagation();
    if (selectedItems.includes(item.id)) {
      const index = selectedItems.findIndex((value) => value === item.id);
      selectedItems.splice(index, 1);
      setValue(name, selectedItems, { shouldDirty: true });
    } else {
      selectedItems.push(item.id);
      setValue(name, selectedItems, { shouldDirty: true });
    }
  };

  const onCheckbox = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => e.preventDefault();

  const onDelete = (e: React.MouseEvent<SVGSVGElement>, item: Item) => {
    e.stopPropagation();
    const index = selectedItems.findIndex((value) => value === item.id);
    selectedItems.splice(index, 1);
    setValue(name, selectedItems, { shouldDirty: true });
  };

  const getItem = (item: Item) => {
    if (item.backgroundColor) {
      return <div className="tag" style={{ backgroundColor: item.backgroundColor }}>
        {item.name}
      </div>
    } else {
      return <div className="dropdownWithCheckbox__content_item">
        {item.name}
      </div>
    }
  };

  return (
    <div className="dropdownWithCheckbox">
      <p className="dropdownWithCheckbox_label">{label}</p>
      <div className={`dropdownWithCheckbox__dropdown ${isError ? 'error' : ''}`}
        onClick={() => setIsOpen(!isOpen && !disabled)} ref={triggerRef}>
        <div className="previewTags">{getPlaceholder()}</div>
        <div className={`arrow ${isOpen ? 'open' : ''}`}>
          <ArrowIcon />
        </div>
        {isOpen && (
          <div className="dropdownWithCheckbox__content" ref={contentRef}>
            {listArray.map((item) => (
              <div key={item.id} className={`dropTag ${selectedItems.includes(item.id) ? 'active' : ''}`}
                   onClick={(e) => onSelect(e, item)}>
                <label className="checkbox" onClick={(e) => onCheckbox(e)}>
                  <input type="checkbox" checked={selectedItems.includes(item.id)} readOnly />
                  <span className="checkmark" />
                </label>
                {getItem(item)}
              </div>
            ))}
          </div>
        )}
      </div>
      {isError &&
        <ErrorMessage errors={formState.errors} name={name}
          render={({ message }) => <p className='dropdownWithCheckbox__error'>{message}</p>}
        />
      }
    </div>
  );
};

export default DropdownWithCheckbox;
