import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TelegramData } from '../../../shemas';
import { TelegramActions, TelegramState } from '../utils';
import Button from '../../../../../../../components/Button';
import ListItems from '../../../../../../../components/ListItems';
import qrCodePlug from '../../../../../../../assets/qrCode-mask.svg';
import Password from '../Password/Password';
import './AuthQr.scss';


type AuthQrProps = {
  init: (data: TelegramData) => Promise<void>;
  update: Dispatch<TelegramActions>;
  state: TelegramState;
};

function AuthQr({ init, update, state }: AuthQrProps) {
  const { t } = useTranslation();
  const { handleSubmit, formState: { isSubmitting } } = useFormContext<TelegramData>();

  const onClickHandler = () => update({ type: 'authType', payload: { authType: 'code' } });

  const onInitHandler = async (data: TelegramData) => await init(data);

  return (
    <div className='authQr'>
      {(state.status === null || state.status === 'waiting_for_qr') && (<>
        <div className={`authQr__code ${state.status === null && 'init'}`}>
          <img alt='qr' src={state.qrLogin ? state.qrLogin.qrCode : qrCodePlug} />
          {state.status === null &&
            <Button type='button' color='orange' textType='small' text={t('connections.telegram.auth.qr_load')}
              onClick={handleSubmit(onInitHandler)} disabled={isSubmitting} />
          }
        </div>
        <div className='authQr__instructions'>
          <p className='regularText semibold'>{t('connections.telegram.auth.qr_instruction')}</p>
          <ListItems items={t('connections.telegram.auth.qr_instructions').split('\n')} typeList='ol' />
          <Button type='submit' color='white' textType='small' text={t('connections.telegram.auth.auth_code')}
            onClick={onClickHandler} />
        </div>
      </>)}
      {state.status === 'waiting_for_password' && <Password state={state} update={update} />}
    </div>
  );
}

export default AuthQr;
