import axiosInstance from "../../api/AxiosInstance";

export type Analytics = {
  success: true,
  url: string,
};

export const getAnalytics = async (companyId: number, url: string): Promise<Analytics> => {
  return await axiosInstance.post(`/companies/${companyId}/analytics/actions/check_site`, { url });
};
