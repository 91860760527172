import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import ru from 'date-fns/locale/ru';
import { getDateTime } from '../../../../../utils/getDateTime/getDateTime';
import { getWabaStats, WabaConnection, WabaStats } from '../../../../../api/CompanyAPI';
import { connectionItemContext } from '../../utils/connections';
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendar.svg'
import './Statistics.scss';


const formatDate: Record<string, string> = { ru: 'dd.MM.yyyy', en: 'MM/dd/yyyy', es: 'dd/MM/yyyy' };
registerLocale("es", es);
registerLocale("ru", ru);

const Statistics = () => {
  const { t, i18n } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const { connection: connectionItem } = useContext(connectionItemContext);
  const connection = connectionItem as WabaConnection;
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [wabaStats, setWabaStats] = useState<WabaStats>();

  useEffect(() => {
    if (currentCompanyId?.params.companyId && startDate && endDate) {
      (async () => {
        const stats = await getWabaStats({
          companyId: Number(currentCompanyId?.params.companyId),
          connection_id: connection.id,
          since: getDateTime().moment(startDate).startOf('day').format(),
          until: getDateTime().moment(endDate).endOf('day').format(),
        });
        setWabaStats(stats);
      })();
    }
  }, [startDate, endDate, currentCompanyId?.params.companyId]);

  return (
    <div className="statistics">
      <h4>{t('settings.number_phone.added.info.statistics_title')}</h4>
      <div className="statistics__dates">
        <div className="statistics__dates_pick">
          <p>{t('settings.number_phone.added.info.statistics_date1')}</p>
          <DatePicker
            dateFormat={formatDate[i18n.language]}
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            locale={i18n.language}
            fixedHeight
          />
          <CalendarIcon />
        </div>
        <div className="statistics__dates_pick">
          <p>{t('settings.number_phone.added.info.statistics_date2')}</p>
          <DatePicker
            dateFormat={formatDate[i18n.language]}
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            locale={i18n.language}
            fixedHeight
          />
          <CalendarIcon />
        </div>
      </div>
      <div className="statistics__counter">
        <div className="statistics__counter_value">{wabaStats && wabaStats.stats.totalOutbound}</div>
        <p>{t('settings.number_phone.added.info.outgoing_messages')}</p>
        <div className="statistics__counter_value">{wabaStats && wabaStats.stats.totalInbound}</div>
        <p>{t('settings.number_phone.added.info.incoming_messages')}</p>
      </div>
    </div>
  );
};

export default Statistics;
