import { WabaTemplateHeader } from '../../../../ChatTypes';
import './WabaHeader.scss';


type WabaHeaderProps = {
  header: WabaTemplateHeader;
};

function WabaHeader({ header }: WabaHeaderProps) {
  return (
    <div className="wabaHeader">
      {header.format === 'image' && 
        <img src={header.fileUrl} alt="message media" />
      }
      {header.format === 'video' && (
        <video src={header.fileUrl} controls>
          <track src="#" kind="captions" />
        </video>)
      }
      {header.format === 'document' && (
        <a href={header.fileUrl} target="_blank" rel="noreferrer">
          {header.fileName}
        </a>
      )}
    </div>
  );
}

export default WabaHeader;
