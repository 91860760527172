import moment from 'moment';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';
import { CompanySubscription, PartnerCompany, PartnerNotificationChannel } from '../../PartnerCabinetTypes';
import { PartnerCompanySelector, PartnerNotifications } from '../../PartnerCabinetSelector';


const DAYS_LEFT_TO_WARN = 1;
const DAYS_END_TEST = 1;

type ClientSubscriptionType = {
  pausedAt: string | null;
  testUntil: string | null;
  paidUntil: string | null;
}

export function getCompanySubscriptionStatus<T extends ClientSubscriptionType>(subscription: T) {
  if (subscription.pausedAt) {
    return 'paused';
  }

  const now = getDateTime().moment();

  if (now.isBefore(subscription.testUntil)) {
    if (subscription.testUntil && getDateTime().moment(subscription.testUntil).diff(now, 'days') <= DAYS_END_TEST) {
      return 'testEnd';
    }
    return 'test';
  }

  if (!subscription.paidUntil) {
    return 'unpaid';
  }

  if (getDateTime().moment(subscription.paidUntil).isBefore(now)) {
    return 'expired';
  }

  if (getDateTime().moment(subscription.paidUntil).diff(now, 'days') <= DAYS_LEFT_TO_WARN) {
    return 'paid';
  }

  return 'paid';
}

export function formatCompanySubscriptionDateStatus(locale: string, subscription: CompanySubscription) {
  let formatDate = '';
  moment.locale(locale);
  switch (getCompanySubscriptionStatus(subscription)) {
    case 'test':
    case 'testEnd':
      formatDate = `${moment(subscription.testUntil).format('L')}`;
      break;
    case 'paid':
    case 'expired':
      formatDate = `${moment(subscription.paidUntil).format('L')}`;
      break;
    case 'paused':
      formatDate = `${moment(subscription.paidUntil).diff(moment(subscription.pausedAt), 'days')}`;
      break;
  }
  return formatDate;
}

export function getFormatClientSubscriptionDateStatus(locale: string, client: PartnerCompany) {
  const { t } = useTranslation();

  let formatDate = '';
  moment.locale(locale);
  switch (getCompanySubscriptionStatus(client)) {
    case 'test':
    case 'testEnd':
      formatDate = `${moment(client.testUntil).format('L')}`;
      break;
    case 'paid':
    case 'expired':
      formatDate = `${moment(client.paidUntil).format('L')}`;
      break;
    case 'paused':
      formatDate = `${moment(client.paidUntil).format('L')}`;
      break;
  }
  return formatDate && t(`partner_cabinet.clients.date.${getCompanySubscriptionStatus(client)}`) + ' ' + formatDate;
}

export type ClientPayerType = {
  id: string;
  name: string;
  value: boolean;
};

export const getArrayPayer = () => {
  const { t } = useTranslation();

  const array: ClientPayerType[] = [
    { id: 'client', name: t('partner_cabinet.clients.client'), value: false },
    { id: 'partner', name: t('partner_cabinet.clients.partner'), value: true },
  ];

  return array;
};

export const getTitlePaymentsLocked = (t: TFunction<"translation">, paymentsLocked: boolean) => {
  return paymentsLocked ? t('partner_cabinet.clients.partner') : t('partner_cabinet.clients.client');
};

export const useClientsChannels = () => {
  const partnerCompany = useSelector(PartnerCompanySelector);
  const partnerNotifications = useSelector(PartnerNotifications);
  let channels: PartnerNotificationChannel[] = [];

  if (partnerCompany) {
    channels = partnerNotifications.filter((channel) => channel.params.partnerCompanies.includes(partnerCompany.id));
  }

  return channels;
};
