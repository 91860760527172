import './CardButtons.scss';
import { useTranslation } from 'react-i18next';
import { PermissionsType } from '../../utils/WrapperPermission/permission-maps';
import { WrapperPermission } from '../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as PenIcon } from '../../assets/edit.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';


type CardButtonsProps = {
  onDelete?: () => void;
  onEdit?: () => void;
  deletePermission?: PermissionsType;
  editPermission?: PermissionsType;
};

function CardButtons({ onDelete, onEdit, deletePermission, editPermission }: CardButtonsProps) {
  const { t } = useTranslation();

  return (
    <div className='cardButtons'>
      <WrapperPermission permission={editPermission}>
        <button className='cardButtons_editButton' type='button' onClick={onEdit}>
          <PenIcon />
          <span className='smallText'>{t('edit')}</span>
        </button>
      </WrapperPermission>
      <WrapperPermission permission={deletePermission}>
        <button className='cardButtons_deleteButton' type='button' onClick={onDelete}>
          <TrashIcon />
          <span className='smallText'>{t('delete')}</span>
        </button>
      </WrapperPermission>
    </div>
  );
}

export default CardButtons;
