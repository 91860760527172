import './WabaTemplateBody.scss';
import { BaseEmoji } from 'emoji-mart';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectPosition, useVariable, wabaTemplateBodyVariablessMaximum } from '../../../../utils/waba';
import { wabaTemplateContext } from '../../../WabaTemplateProvider/WabaTemplateProvider';
import { WabaTemplateMessageData } from '../../../WabaTemplateProvider/utils';
import Button from '../../../../../../components/Button';
import Textarea from '../Textarea';
import Counter from '../Counter';
import EmojiComponent from '../../../../../../components/EmojiComponent/EmojiComponent';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';
import { ReactComponent as BoldIcon } from '../../../../../../assets/bold.svg';
import { ReactComponent as ItalicIcon } from '../../../../../../assets/italic.svg';
import { ReactComponent as StrikeIcon } from '../../../../../../assets/strikethrough.svg';
import { ReactComponent as CodeIcon } from '../../../../../../assets/code-view.svg';


const BODY_MAX_LENGTH = 1024;

const toolsFormattings: Record<string, string> = {
  'bold': '*',
  'italic': '_',
  'strike': '~',
  'code': '```',
};

function WabaTemplateBody() {
  const { t } = useTranslation();
  const [position, setPosition] = useState<SelectPosition>();
  const [variablesMax, setVariablesMax] = useState<number>(0);
  const [picker, setPicker] = useState<boolean>(false);
  const pickerRef = useRef<SVGSVGElement>(null);
  const { wabaTemplateState } = useContext(wabaTemplateContext);
  const { kind } = wabaTemplateState;
  const { setValue } = useFormContext<WabaTemplateMessageData>();

  useEffect(() => {
    setVariablesMax(wabaTemplateBodyVariablessMaximum[kind] ?? 0);
  }, [kind])

  const text = useWatch({ name: 'body' });
  const { variables, addVariable } = useVariable(variablesMax, text);

  const onVariableClick = () => {
    if (!addVariable()) return;
    setValue('body', [text.slice(0, position?.end), `{{${variables + 1}}}`, text.slice(position?.end)].join(''),
      { shouldDirty: true, shouldValidate: true });
  };

  const onEmojiPick = (emoji: BaseEmoji) => {
    setValue('body', [text.slice(0, position?.end), emoji.native, text.slice(position?.end)].join(''),
      { shouldDirty: true, shouldValidate: true });
  };

  const onToolClick = (format: string) => {
    return () => {
      const formatting = toolsFormattings[format];
      setValue('body', [
        text.slice(0, position?.start), formatting,
        text.slice(position?.start, position?.end ?? text.length), formatting,
        text.slice(position?.end ?? text.length)
      ].join(''));
    }
  };

  return (
    <div className='wabaTemplateBody'>
      <div className='wabaTemplateBody__header'>
        <h3>{t('templates.waba_list.form.body.title')}</h3>
        <Button type='button' color='white' textType='small' text={t('templates.waba_list.form.variable')}
          image={<PlusIcon />} onClick={onVariableClick} disabled={variables >= variablesMax} />
      </div>
      <Textarea name='body' className='regularText' autoComplete='off' setPosition={setPosition}
        placeholder={t('templates.waba_list.form.body.placeholder')} maxLength={BODY_MAX_LENGTH} />
      <div className='wabaTemplateBody__tools'>     
        <EmojiComponent pickerRef={pickerRef} picker={picker} setPicker={setPicker}
        onClick={() => setPicker(!picker)} onSelect={onEmojiPick} />
        <button type='button' onClick={onToolClick('bold')}><BoldIcon /></button>
        <button type='button' onClick={onToolClick('italic')}><ItalicIcon /></button>
        <button type='button' onClick={onToolClick('strike')}><StrikeIcon /></button>
        <button type='button' onClick={onToolClick('code')}><CodeIcon /></button>
        <Counter counter={text?.length || 0} maximum={BODY_MAX_LENGTH}
          text={'templates.waba_list.form.character.character'} />
      </div>
    </div>
  );
}

export default WabaTemplateBody;
