import axiosInstance from "../../../api/AxiosInstance";
import { Connections, NewConnection } from "../../../api/CompanyAPI";
import * as con from './ConnectionsTypes';


export const postConnectionState = async (companyId: number): Promise<{state: string}> =>
  await axiosInstance.post(`companies/${companyId}/connections/state`);

export const patchConnection = async (requestOptions: con.ConnectionsUpdateRequest): Promise<NewConnection> => {
  const { companyId, itemId, ...rest } = requestOptions;
  return await axiosInstance.patch(`companies/${companyId}/connections/${itemId}`, { ...rest });
};

export const postConnection = async (requestOptions: con.ConnectionsCreateRequest): Promise<NewConnection> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/connections/`, { ...rest });
};

export const deleteConnection = async (requestOptions: con.CompanyConnectionType): Promise<void> =>
  await axiosInstance.delete(`companies/${requestOptions.companyId}/connections/${requestOptions.connectionId}`);

export const getConnectionsList = async (requestOptions: con.ConnectionsListRequest): Promise<Connections> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/connections/`, {
    params: {
      offset: requestOptions.offset,
      limit: requestOptions.limit,
      types: requestOptions.types
    }
  });

export const getConnectionById = async (requestOption: con.CompanyConnectionType ): Promise<NewConnection> =>
  await axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connectionId}`);

export const getConnectionStatus = async (
  requestOption: {companyId: number, connectionId: number}): Promise<con.ConnectionStatusType> =>
  await axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connectionId}/instance/status`);

export const getChatsBlackList = async (requestOptions: con.BlackListChatsRequest): Promise<con.BlockedChatType[]> =>
  await axiosInstance.get(
    `/companies/${requestOptions.companyId}/connections/${requestOptions.connectionId}/chats_blacklist/`,
    { params: { offset: requestOptions.offset, limit: requestOptions.limit } });

export const postChatToBlackList = async (requestOptions: con.BlackListChatAddRequest): Promise<con.BlockedChatType> =>
  await axiosInstance.post(
    `companies/${requestOptions.companyId}/connections/${requestOptions.connectionId}/chats_blacklist/`,
    { phone: requestOptions.phone });

export const deleteChatFromBlackList = async (requestOptions: con.BlackListChatDeleteRequest): Promise<void> => {
  const { companyId, connectionId, itemId } = requestOptions;
  await axiosInstance.delete(`companies/${companyId}/connections/${connectionId}/chats_blacklist/${itemId}`);
};

export const linkFacebook = async (requestOptions: con.linkFacebookRequest): Promise<void> => {
  const {  company_id: companyId, ...rest } = requestOptions;
  await axiosInstance.post(`/companies/${companyId}/facebook/link_facebook`, { ...rest });
};

export const getFacebookCatalog = async (requestOptions: con.WabaConnectionRequest): Promise<con.FacebookCatalog> =>
  await axiosInstance.get(`/companies/${requestOptions.company_id}/facebook/catalog/${requestOptions.waba_connection_id}`);

export const disableFacebookCatalog = async (requestOptions: con.WabaConnectionRequest): Promise<void> =>
  await axiosInstance.post(
    `/companies/${requestOptions.company_id}/facebook/catalog/${requestOptions.waba_connection_id}/disable`);

export const getWabaProfile = async (requestOption: con.CompanyConnectionType): Promise<con.WabaProfile> => {
  const { companyId, connectionId } = requestOption;
  return await axiosInstance.get(`/companies/${companyId}/waba/${connectionId}/whatsapp_business_profile`);
};

export const patchWabaProfile = async (requestOption: con.WabaProfileRequest): Promise<con.WabaProfile> => {
  const { companyId, connectionId, ...rest } = requestOption;
  return await axiosInstance.post(`/companies/${companyId}/waba/${connectionId}/whatsapp_business_profile`, { ...rest });
};

export const postWabaProfilePicture = async (companyId: number, file: File ): Promise<con.WabaProfilePicture> => {
  const formData = new FormData();
  formData.append('file', file);
  return await axiosInstance.post(`companies/${companyId}/files/waba_profile_picture/`, formData);
};

export const getWabaIceBreakers = async (requestOptions: con.WabaConnectionRequest): Promise<con.WabaIceBreakers> =>
  await axiosInstance.get(
    `/companies/${requestOptions.company_id}/waba/${requestOptions.waba_connection_id}/conversational_automation`
  );

export const postWabaIceBreakers = async (requestOptions: con.WabaIceBreakersRequest): Promise<con.WabaIceBreakers> =>
  await axiosInstance.post(
    `/companies/${requestOptions.company_id}/waba/${requestOptions.waba_connection_id}/conversational_automation`,
    { ice_breakers: requestOptions.ice_breakers }
  );
