import React from 'react';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { Tag } from './TagsAPI';
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
import Button from '../../../components/Button/Button';


type Props = {
  tag: Tag;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIdEditTag: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagsItem: React.FC<Props> = ({ tag, setIsEdit, setIdEditTag, setIsDisable }) => {

 const onEdit = () => {
    setIdEditTag(tag.id);
    setIsDisable(true);
    setIsEdit(true);
  };

  return (
    <div className="tagsContainer__table_container">
      <div className="tagsContainer__table_item">
        <div className="tagsContainer__table_name" style={{ background: tag.backgroundColor }}>
          <p>{tag.name}</p>
        </div>
      </div>
      <div className="tagsContainer__table_item">
        <div className="tagsContainer__table_description">
          <p>{tag.description}</p>
        </div>
        <WrapperPermission permission='tagsEdit'>
          <Button type='button' color='white' textType='small' onClick={onEdit} image={<EditIcon />} />
        </WrapperPermission>
      </div>
    </div>
  );
};

export default TagsItem;
