import React, { FC, useEffect, useState } from 'react';
import './PreviewProducts.scss';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from "react-slideshow-image";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-left.svg'
import { ReactComponent as CatalogsIcon } from '../../../../../assets/catalogs.svg'
import { ICatalog, Product } from '../../../SalesAPI';
import { SalesCatalogsSelector } from "../../../SalesSelector";

type Props = {
  product?: Product,
  setProductSelected?: React.Dispatch<React.SetStateAction<Product | undefined>>;
}

const PreviewProducts: FC<Props> = ({ product, setProductSelected }) => {
  const { t } = useTranslation();

  const catalogsNames = useSelector(SalesCatalogsSelector);

  const [listCatalogs, setListCatalogs] = useState<Array<ICatalog>>([]);

  useEffect(() => () => {
    if (setProductSelected) {
      setProductSelected(undefined);
    }
  }, [])

  useEffect(() => {
    if (product && catalogsNames) {
      const idList: ICatalog[] = [];
      for (let i = 0; i < product.catalogIds.length; i++) {
        const findCatalog = catalogsNames.find((id) => id.id === product.catalogIds[i]);
        if (findCatalog) {
          idList.push(findCatalog);
        }
      }
      setListCatalogs(idList)
    }
  }, [])

  if (!product) return null;

  const arrows = {
    prevArrow: <div className={`arrowSlide ${product.images.length === 1 && 'none'}`}><ArrowIcon /></div>,
    nextArrow: <div className={`arrowSlide ${product.images.length === 1 && 'none'}`}><ArrowIcon /></div>
  }

  const slide = () => (
    <Slide {...arrows} infinite={false} transitionDuration={10}>
      {product.images.map((slideImage) => (
        <div className="each-slide" key={slideImage}>
          <img alt='Фото товара' src={slideImage} />
        </div>
      ))}
    </Slide>
  )

  return (
    <div className='previewProducts'>
      <div className='previewProducts_header'>
        {product.sku && <p>{t('sales.products.add.sku')}: {product.sku}</p>}
        <h1>{product.name}</h1>
        <h3>{product.prices[0].price} ₽</h3>
      </div>
      {listCatalogs.length > 0 &&
        <div className='previewProducts_catalogTitle'>
          <CatalogsIcon />
          <p className="smallText">
            {listCatalogs.map((name, index) => `${name.name}${index === listCatalogs.length - 1 ? '' : ', '}`)}
          </p>
        </div>}
      <div className='previewProducts_sliderImage'>
        {product.images.length > 0 ? slide() : null}
      </div>
      <div className='previewProducts_description'>
        {product.description}
      </div>
    </div>
  );
}

export default PreviewProducts;
