import { WabaTemplateButton } from "../../../../../Templates/templatesTypes";


export type ParamsType = {
  id: number;
  value: string;
  label: string;
};

export type IndexedParams = {
  id: number;
  params: ParamsType[];
};

export const getTextParams = (text: string): ParamsType[] => {
  const findParams = text.split(/{{(\d+)}}/gm);
  const numbers: number[] = [];
  const params: ParamsType[] = [];

  findParams.forEach((param, index) => {
    if (index % 2 === 1 && !numbers.includes(Number(param))) {
      numbers.push(Number(param));
      params.push({ id: Number(param), label: param, value: '' });
    }
  });

  return params;
};

export const getButtonsUrlParams = (buttons: WabaTemplateButton[]) => {
  const params: ParamsType[] = [];
  buttons.forEach((item, index) => {
    const findParams = item.url.split(/{{(\d+)}}/gm);
    if (findParams[1]) params.push({ id: index, label: findParams[1], value: '' });
  });

  return params;
};
