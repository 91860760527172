import { useContext, useEffect } from 'react';
import './ChannelCompanies.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../store/store';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { updatePartnerNotificationChannel } from '../../../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { partnerNotifications } from '../../../utils';
import { usePartnerCompanies } from '../../../api';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../../../../../../components/Button';
import Search from '../../../../../../components/Search/Search';
import ChooseCompanies from '../ChooseCompanies';
import ChannelIcon from '../../ChannelIcon';


type CompaniesSchema = {
  company_ids: number[];
};

const ChannelCompanies = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const { channel, setChannel, setOpenNotification } = useContext(partnerNotifications);
  const { partnerCompanies, hasMore, onUpload, setSearch } = usePartnerCompanies();

  const schema = yup.object().shape({
    company_ids: yup.array().of(yup.number()).required(t('empty_field'))
  });

  const methods = useForm<CompaniesSchema>({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    methods.reset({ company_ids: channel?.params.partnerCompanies });
  }, []);

  useEffect(() => () => {
    setChannel(null);
  }, []);

  const close = () => {
    setOpenNotification(false);
  };

  const onSubmit = (data: CompaniesSchema) => {
    if (channel) {
      dispatch(updatePartnerNotificationChannel({
        companyId: currentCompany.id,
        channelId: channel.id,
        params: {
          events: channel.params.events,
          partner_companies: data.company_ids,
        },
      })).then(() => {
        dispatch(addAlertWithCustomText({ message: t('partner_cabinet.notifications.success'), type: 'info' }));
        close();
      });
    }
  };

  if (channel === null) {
    return null;
  }

  return (
    <div className="channelCompanies">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h1>{t('partner_cabinet.notifications.add_company')}</h1>
          <div className="channelCompanies__channel">
            <p className="regularText">{t(`partner_cabinet.notifications.${channel.type}.channel`)}</p>
            <p className="regularText">
              <ChannelIcon channel={channel.type} />
              {channel.type === 'whatsapp' 
                ? parsePhoneNumberFromString(channel.name)?.formatInternational()
                : channel.name}
            </p>
          </div>
          <div className='channelCompanies__companies'>
            <h3>{t('partner_cabinet.notifications.companies')}</h3>
            <p className='regularText'>{t('partner_cabinet.notifications.select_company')}</p>
            <Search onChangeSearchInput={setSearch} />
            <InfiniteScroll next={onUpload} hasMore={hasMore} loader={null} dataLength={partnerCompanies.length}
              scrollableTarget="chooseCompanies">
              <ChooseCompanies name='company_ids' items={partnerCompanies} />
            </InfiniteScroll>
          </div>
          <div className="channelCompanies__footer">
            <Button color="orange" textType="regular" text={t('save_edit')} type="submit" disabled={!methods.formState.isDirty} />
            <Button color="white" textType="regular" text={t('cancel')} type="button" onClick={close} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ChannelCompanies;
