import { useKeycloak } from "@react-keycloak/web";
import { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";


type NotificationsProviderProps = {
  children: React.ReactNode;
};

const SOCKET_URL: string = process.env.REACT_APP_BASE_WS_URL as string;
const NOTIFICATION_PATH = '/ws/notifications/';

const notificationsSocketContext = createContext<Socket | undefined>(undefined);

function NotificationsProvider({ children }: NotificationsProviderProps) {
  const [socket, setSocket] = useState<Socket | undefined>();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak) {
      const newSocket = io(SOCKET_URL, {
            path: NOTIFICATION_PATH,
            transports: ['websocket', 'polling'],
            auth: (cb) => {
              cb({ token: keycloak.token })
            },
          });
      setSocket(newSocket);
    }
  }, [keycloak]);

  if (!socket) return null;

  return (
    <notificationsSocketContext.Provider value={socket}>
      {children}
    </notificationsSocketContext.Provider>
  );
}


export const useNotificationsSocket = () => {
  const sockets = useContext(notificationsSocketContext);
  if (sockets === undefined) {
    throw new Error('useNotificationsSocket must be used within a notificationsSocketContext');
  }
  return sockets;
}

export default NotificationsProvider;
