import './TelegramForm.scss';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { TelegramData } from '../../../shemas';
import { useTelegramLogin } from '../utils';
import { usePhoneSchema } from '../../../../../../../components/PhoneInput/utils';
import { surePreffix } from '../../../../utils/terminals';
import GoBackWrapper from '../../../../../../../components/GoBackWrapper';
import Input from '../../../../../../../components/Input';
import AuthCode from '../AuthCode';
import AuthQr from '../AuthQr';


type LocationState = {
  name: string;
  phone: string
  connectionId: number;
} | undefined;

function TelegramForm() {
  const { t } = useTranslation();
  const { state } = useLocation<LocationState>();
  const { initTelegram, telegramState, updateTelegramState } = useTelegramLogin();
  const phoneSchema = usePhoneSchema({ required: true, nonGeo: true });

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')),
    phone: yup.string().when({
      is: () => telegramState.authType === 'code',
      then: phoneSchema.phone,
      otherwise: yup.string().nullable(true)
    })
  });

  const methods = useForm<TelegramData>({
    mode: 'onSubmit', reValidateMode: 'onChange', resolver: yupResolver(schema),
    defaultValues: { name: '', phone: '' }
  });

  useEffect(() => {
    const isReauthUrl = window.location.href.includes('/settings/connections/telegram/auth');
    
    let data: LocationState;
    
    if (isReauthUrl) {
      if (state) {
        data = { ...state };
        localStorage.setItem('telegramFormData', JSON.stringify(data));
      } else {
        const savedData = localStorage.getItem('telegramFormData');
        if (savedData) data = JSON.parse(savedData);
      }
    }
    if (data) {
      updateTelegramState({
        type: 'data',
        payload: { ...data, phone: data.phone ?? null }
      });
      methods.reset({ ...data, phone: data.phone ? surePreffix(data.phone, '+') : '' }, { keepDefaultValues: true });
    }
  }, [state, methods, updateTelegramState]);

  const onInit = async (data?: TelegramData) => {
    await initTelegram(data);
    localStorage.removeItem('telegramFormData');
  };

  return (
    <div className='telegramForm'>
      <GoBackWrapper title={t('connections.telegram.add')}>
        <FormProvider {...methods}>
          <Input name='name' label={t('connections.connection_name')}
            readOnly={telegramState.name !== null || state?.name !== undefined} />
          {telegramState.authType === 'code'
            ? <AuthCode init={onInit} update={updateTelegramState} state={telegramState} />
            : <AuthQr init={onInit} update={updateTelegramState} state={telegramState} />
          }
        </FormProvider>
      </GoBackWrapper>
    </div>
  );
}

export default TelegramForm;
