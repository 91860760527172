import './PayoutsView.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../store/store';
import { fetchPartnerPayouts } from '../../../PartnerCabinetSlice';
import InfiniteScroll from "react-infinite-scroll-component";
import PayoutItem from '../PayoutItem';


const PayoutsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useAppSelector((state) => state.companies.currentCompany);
  const payouts = useAppSelector((state) => state.partners.partnerPayouts);

  const onUpload = () => {
    dispatch(fetchPartnerPayouts({ company_id: currentCompany.id, offset: payouts.length, limit: 10 }));
  };

  return (
    <div className="payoutsView">
      <div className="payoutsView__header smallText semibold">
        <span>{t('partner_cabinet.finances.payouts.name')}</span>
        <span>{t('partner_cabinet.finances.payouts.amount')}</span>
        <span>{t('partner_cabinet.finances.payouts.status')}</span>
      </div>
      <div className="payoutsView__list" id="scrollList">
        <InfiniteScroll next={onUpload}
          hasMore={true} inverse={false} loader={null}
          scrollableTarget="scrollList"
          dataLength={payouts.length}
        >
          {payouts.map((payout) => <PayoutItem key={payout.id} payout={payout} />)}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PayoutsView;
