import { TFunction } from 'react-i18next';
import { MessageRequest } from '../../ChatTypes';
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';
import { getCustomMonth } from '../../../../utils/getCustomMonth';
import { todayDate } from "../../../../utils/getDateTime/todayDay";


export const getLastMessage = (lastMessage: MessageRequest, t: TFunction<'translation'>) => {
  const conversationMessageType = lastMessage.messageType;
  let message;

  switch (conversationMessageType) {
    case 'text':
      message = lastMessage.text.text;
      break;
    case 'waba_template':
      message = lastMessage.wabaTemplate.text;
      break;
    case 'waba_interactive':
      message = lastMessage.wabaInteractive.body.text;
      break;
    case 'radist_order':
      message = `🛍️ ${t('chats.order.cart')}`;
      break;
    case 'waba_order':
      message = t('chats.order.cart');
      break;
    default:
      message = lastMessage[conversationMessageType].name;
      break;
  }
  if (message === undefined) {
    throw new Error(`${conversationMessageType} doesn't have message`);
  }
  return message;
};

export const getLastMessageDate = (date: string, t: TFunction<'translation'>) => {
  const { months, month } = getCustomMonth(date, t);
  const isTodayDate = todayDate(date);

  return isTodayDate
    ? getDateTime().format(date, 'hourMinutes')
    : getDateTime().format(date, 'withCustomFormat', `DD [${months[month]}]`);
};
