import { useEffect, useState } from 'react';
import './Shops.scss';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../store/store';
import { deleteShop, getCatalogs, getShops } from './shopsSlice';
import { shopsSelector } from './shopsSelector';
import { profileSettingsInfo } from '../../Chat/companiesSelector';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { ShopType } from './ShopsAPI';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as InformationIcon } from '../../../assets/information.svg';
import Button from '../../../components/Button';
import Shop from './components/Shop';
import NotItems from '../components/NotItems/NotItems';
import ActionDialog from '../../../components/Modals/ActionDialog';
import BigIcon from '../../../assets/no-shops-icon.png';
import ModalWrapper from '../../../components/Modals/ModalWrapper';
import CopyLink from './components/CopyLink';
import Loader from "../../../components/Loader/Loader";


type RefsInstructionKeys = Record<'ru' | 'en' | 'es', string>
const refsInstruction: RefsInstructionKeys = {
  'ru': 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/modul-prodazhi/magaziny/podklyuchenie-magazina-k-telegram-bot',
  'en': 'https://docs.radist.online/docs/v/english-2/our-products/radist-web/the-sales-module/the-shops/connecting-the-store-to-telegram-bot',
  'es': 'https://docs.radist.online/docs/productos-1/radist-web/modulo-de-ventas/comercio/conexion-de-la-tienda-a-telegram-bot',
};

const Shops = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const location = useLocation<string>();
  const { state } = location;
  const infoProfile = useSelector(profileSettingsInfo);
  const shops = useSelector(shopsSelector);
  const currentLang = infoProfile.language as 'ru' | 'en' | 'es';
  const currentRefInstruction = refsInstruction[currentLang];
  const [deleteShopId, setDeleteShopId] = useState<number | null>(null);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isVisibleModalCopyRef, setIsVisibleModalCopyRef] = useState<boolean>(false);

  useEffect(() => {
    if (state) {
      setIsVisibleModalCopyRef(true);
    }
  }, [state]);

  const handlerAddShop = () => {
    dispatch(push(`${params?.url}/shops/create`));
  };

 const handlerModeCreateEdit = (editShop?: ShopType) => {
    if (editShop) {
      dispatch(push(`${params?.url}/shops/edit`));
      localStorage.setItem('editShop', JSON.stringify(editShop));
    } else {
      dispatch(push(`${params?.url}/shops/create`));
    }
  };

  const handlerDeleteShop = (shopId: number) => {
    setDeleteShopId(shopId);
    setDeleteOpen(true);
  };

  const currentDeleteShop = shops?.items?.find(i=>i.id === deleteShopId);

  const onDeleteShopHandler = () => {
    if (deleteShopId && params?.params?.companyId) {
      dispatch(deleteShop({
        shopId: deleteShopId,
        companyId: params.params.companyId,
      })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(addAlertWithCustomText({
            type: 'info',
            message: t('sales.shops.deletedSuccessShop'),
          }));
          setDeleteShopId(null);
          setDeleteOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(getShops({
        companyId: params?.params.companyId,
      }));
      dispatch(getCatalogs({
        companyId: params?.params.companyId,
      }));
    }
    return () => {
      setDeleteShopId(null);
    };
  }, [params?.params.companyId]);

  if (shops === null) {
    return (
      <div className='shops'>
        <Loader />
      </div>
    );
  }

  if (shops && shops.items.length === 0) {
    return <NotItems
      image={BigIcon}
      title='sales.shops.name'
      subsection='shop'
      cb={handlerAddShop}
      buttonName='sales.shops.createShop'
    />;
  }

  const getDeleteConfimMessage = () => t('sales.shops.delete_confirm', { shop: currentDeleteShop?.name });

  const hideModalCopy = () => setIsVisibleModalCopyRef(false);

  return (
    <div className='shops'>
      <div className='shops__header'>
        <h1>{t('sales.shops.name')}</h1>
        <WrapperPermission permission='salesShopCreate'>
          <Button type='button' color='orange' textType='regular' text={t('sales.shops.addShop')}
            onClick={handlerAddShop} />
        </WrapperPermission>
      </div>
      <div className='shops__content'>
        {shops?.items?.map(item =>
            <Shop key={item.id} item={item}
              handlerModeCreateEdit={handlerModeCreateEdit} handlerDeleteShop={handlerDeleteShop} />
          )}
      </div>
      <div className='shops__information'>
        <InformationIcon />
        <a target='_blank' href={currentRefInstruction} rel='noreferrer'>
          {t('sales.shops.instructionRef')}
        </a>
      </div>
      {isVisibleModalCopyRef &&
        <ModalWrapper setIsOpen={setIsVisibleModalCopyRef} background='light'>
          <CopyLink hide={hideModalCopy} shopLink={state} />
        </ModalWrapper>
      }
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          title={t('sales.shops.deleteShop')} message={getDeleteConfimMessage()}
          onAction={onDeleteShopHandler} setIsOpen={setDeleteOpen} />}
    </div>
  );
};

export default Shops;
