import './ClientInvoicesView.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../../../store/store';
import { PartnerPaymentsSelector } from '../../../PartnerCabinetSelector';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { uploadPartnerCompanyPayments } from '../../../PartnerCabinetSlice';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-down.svg';
import InvoicesList from '../../../components/InvoicesList';


type ClientInvoicesViewProps = {
  partnerId: number;
};

const ClientInvoicesView = ({ partnerId }: ClientInvoicesViewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const partnerPayments = useSelector(PartnerPaymentsSelector);
  const hasMore = useAppSelector((state) => state.partners.partnerHasPayments);

  const getMore = () => dispatch(uploadPartnerCompanyPayments({
    company_id: company?.id,
    partner_company_id: partnerId,
    limit: 10,
    offset: partnerPayments?.length })
  );

  if (partnerPayments.length === 0) return null;

  return (
    <>
    <div className="clientInvoicesList">
      <h3>{t('partner_cabinet.clients.invoices')}</h3>
      <div className="clientInvoicesTable">
        <div className="clientInvoicesTable__header smallText semibold">
          <span>{t('partner_cabinet.payments.number')}</span>
          <span>{t('partner_cabinet.payments.amount')}</span>
          <span>{t('partner_cabinet.payments.status')}</span>
        </div>
        <div>
          <InvoicesList invoices={partnerPayments} />
        </div>
      </div>
    </div>
    {hasMore &&
      <div className="showMore regularText" onClick={getMore}>{t('subscriptions.bills.show_more')}<ArrowIcon /></div>}
    </>
  );
};

export default ClientInvoicesView;
