import React, { useState } from 'react';
import './Paginator.scss';
import { useTranslation } from 'react-i18next';
import { ITEMS_ONE_PAGE, paginator, usePageLimit } from "./utils";
import { ReactComponent as SideIcon } from '../../assets/arrow-left.svg';
import { ReactComponent as DownArrow } from '../../assets/arrow-down.svg';


type PaginatorProps = {
  totalCount: number;
  pageLimitStoreIndex: string;
  pageSelect: (page: number, limit: number) => void;
};

const Paginator: React.FC<PaginatorProps> = ({ totalCount, pageSelect, pageLimitStoreIndex }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { limit, setLimit } = usePageLimit(pageLimitStoreIndex);
  const [openListVolume, setopenListVolume] = useState<boolean>(false);
  const pagesCount = Math.ceil(totalCount / limit);
  const arrayPages = paginator(pagesCount, currentPage);

  const onChangePage = (page: number) => {
    if (page >= 1 && page <= pagesCount) {
      setCurrentPage(page);
      pageSelect(page, limit);
    }
  };

  const onOpenSelect = () => {
    setopenListVolume(!openListVolume);
  };

  const onPageVolume = (pageVolume: number) => {
    setCurrentPage(1);
    pageSelect(1, pageVolume);
    setLimit(pageVolume);
    onOpenSelect();
  };

if (!totalCount || totalCount <= ITEMS_ONE_PAGE[0]) return null;

  return (
    <div className='paginator'>
      <div className="paginator__pages">
        <button className={`arrow ${currentPage === 1 ? "disabled" : ""}`}
          onClick={() => onChangePage(currentPage - 1)}>
          <SideIcon />
        </button>
        {arrayPages.map((p: number | string, i) => (
          typeof p === "number"
            ? <button className={`page ${currentPage === p ? 'active' : ''}`} key={i} onClick={() => onChangePage(p)}>
                {p}
              </button>
            : <span className='page spread' key={i}>{p}</span>
        ))}
        <button className={`arrow right ${currentPage === pagesCount ? "disabled" : ""}`}
          onClick={() => onChangePage(currentPage + 1)}>
          <SideIcon />
        </button>
      </div>
      <div className="paginator__settings">
        <div className="paginator__settings_text">{t('showOnPageBy')}</div>
          {openListVolume && (
            <div className="paginator__settings_list">
              {ITEMS_ONE_PAGE.map((item) => (
                <div className="paginator__settings_item" key={item} onClick={() => onPageVolume(item)}>
                  {item}
                </div>
              ))}
            </div>
          )}
        <div className="paginator__settings_field" onClick={onOpenSelect}>
          {limit}<DownArrow className={`${openListVolume ? '' : "up"}`} />
        </div>
      </div>
    </div>
  );

}

export default Paginator;
