import { useEffect } from 'react';
import './PartnerProfile.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnerInfo } from '../PartnerCabinetSlice';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { PartnerInfoSelector } from '../PartnerCabinetSelector';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';


const PartnerProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const partnerInfo = useSelector(PartnerInfoSelector);

  useEffect(() => {
    dispatch(fetchPartnerInfo(currentCompany.id));
  }, [currentCompany.id]);

  const onCopy = (text: string, isPromocode: boolean) => {
    navigator.clipboard.writeText(text);
    dispatch(
      addAlertWithCustomText({ message: t(`partner_cabinet.profile.${isPromocode ? 'copy_promocode' : 'copy_link'}`) })
    );
  };

  if (!partnerInfo) return <></>;

  const partnerLink = window.location.origin.toString() + '/registration/?promo=' + partnerInfo.promoCode;

  return (
    <div className='partnerProfile'>
      <h1>{t('partner_cabinet.profile.title')}</h1>
      <div className='partnerProfile__name'>
        <p className='regularText semibold'>{t('partner_cabinet.profile.name_partner')}</p>
        <p className='regularText'>{partnerInfo.name}</p>
      </div>
      <div className='partnerProfile__link'>
        <p className='regularText semibold'>{t('partner_cabinet.profile.partner_link')}</p>
        <p className='regularText' onClick={() => onCopy(partnerLink, false)}>
          {partnerLink}
          <CopyIcon />
        </p>
      </div>
      <div className='partnerProfile__promocode'>
        <p className='regularText semibold'>{t('partner_cabinet.profile.promocode')}</p>
        <p className='regularText' onClick={() => onCopy(partnerInfo.promoCode, true)}>
          {partnerInfo.promoCode}
          <CopyIcon />
        </p>
      </div>
    </div>
  );
};

export default PartnerProfile;
