import React, { FC } from 'react';
import './ComeBackList.scss'
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBack } from "../../../../assets/arrow-back.svg";
import Button from '../../../../components/Button';

type ComeBackListType={
  cb:()=>void
  title:string
}
const ComeBackList:FC<ComeBackListType> = ({ cb,title,children }) => {
  const { t } = useTranslation();
  return (
    <div className='comeBackList'>
      <div className='comeBackList__header'>
        <Button text={t('back')} color='white' textType='small' image={<ArrowBack />} type='reset' onClick={cb} />
        <div><h2>{title}</h2></div>
      </div>
        {children}
    </div>
  );
};

export default ComeBackList;
