import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { getCodeCountry, getLabelsLocalization, validatePhone } from '../utils';
import { getError } from '../../utils/forms';
import CountrySelector from '../CountrySelector';
import '../PhoneInput.scss';


type PhoneFieldProps = {
  name: string;
  required?: boolean;
  label?: string;
  nonGeo?: boolean;  // allow non geographic numbers as valid
} & React.InputHTMLAttributes<HTMLInputElement>;

const PhoneField = ({ name, required=false, label, nonGeo=false, ...rest }: PhoneFieldProps) => {
  const { t, i18n } = useTranslation();
  const { control, formState: { errors } } = useFormContext();
  const country = getCodeCountry(i18n.language);
  const error = getError(errors, name);

  const rules = {
    required: {
      value: required,
      message: t('error.form.empty_field'),
    },
    validate: (value: string) => {
      const isValid = validatePhone(value, nonGeo, country);
        return (typeof isValid === 'string') ? t(isValid) : isValid;
    }
  };

  return (
    <div className="phoneField">
      {label && <p>{label}</p>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          <PhoneInput
            {...field}
            {...rest}
            name={name}
            international={true}
            limitMaxLength={true}
            onChange={(value) => field.onChange(value !== undefined ? value : '')}
            labels={getLabelsLocalization(i18n.language)}
            defaultCountry={country}
            countrySelectComponent={CountrySelector}
            className={error ? 'error' : ''}
          />
        }
      />
      {error && <span className="errorMessage">{error.message}</span>}
    </div>
  );
};

export default PhoneField;
