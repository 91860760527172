import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store/store";
import { currentCompanySelector, onboardingGuideSelector } from "../../../Chat/companiesSelector";
import { patchOnboarding, setOnboardingGuide, setOnboardingSwitchModal } from "../../../Chat/companiesSlice";
import { CompanyOnboardingUpdate } from "../../../../api/types";


export function useGuide() {
  const dispatch = useAppDispatch();
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const guided = onboardingGuideOn === 'setup_notifications';

  const update = () => {
    if (guided) {
      const data: CompanyOnboardingUpdate = {
        company_id: currentCompany.id,
        steps: [
          {
            is_finished: true,
            type: "setup_notifications"
          }
        ]
      }
      dispatch(patchOnboarding(data));
      dispatch(setOnboardingGuide(null));
      dispatch(setOnboardingSwitchModal(true));
    }
  };

  return { guided, update };
}
