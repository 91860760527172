import './InvoiceItem.scss';
import InvoiceInfo from '../InvoiceInfo';
import InvoiceAmount from '../InvoiceAmount';
import InvoiceStatus from '../InvoiceStatus';
import { CompanyPayment } from '../../../../PartnerCabinetTypes';


type InvoiceItemProps = {
  invoiceItem: CompanyPayment;
  showCompany?: boolean;
};

function InvoiceItem({ invoiceItem, showCompany }: InvoiceItemProps) {
  return (
    <div className={`invoicesView__list_item ${invoiceItem.status}`}>
      <InvoiceInfo number={invoiceItem.id} date={invoiceItem.createdAt} status={invoiceItem.status}
        companyId={showCompany ? invoiceItem.companyId : undefined}
        companyName={showCompany? invoiceItem.companyName : undefined} />
      <InvoiceAmount amount={invoiceItem.amount} currency={invoiceItem.currency} status={invoiceItem.status}
        discount={invoiceItem.summary.partnerDiscountPercent > 0} />
      <InvoiceStatus actual={invoiceItem.lifetime} status={invoiceItem.status}
        paymentUrl={invoiceItem.previewUrl} actUrl={invoiceItem.actUrl} />
    </div>
  );
}

export default InvoiceItem;
