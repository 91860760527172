import './PreviewBuble.scss';
import { ReactComponent as Tail } from '../../../../../assets/message-tail.svg';
import { ReactComponent as MessageCheckmark } from '../../../../../assets/message-checkmark.svg';


type PreviewBubleProps = {
  children?: React.ReactNode;
};

function PreviewBuble({ children }: PreviewBubleProps) {
  return (
    <div className="wabaPreview__body">
      {children}
      <div className="messageDate">{'12:25'}<MessageCheckmark /></div>
      <Tail className="wabaPreview__body_tail" />
    </div>
  );
}

export default PreviewBuble;
