import axiosInstance from "../../../../../../api/AxiosInstance";

export type AuthType = 'qr' | 'code';

export type TelegramInitStatus = | 'authenticated' | 'waiting_for_qr' | 'waiting_for_code' | 'waiting_for_password';

type CompanyRequest = {
	company_id: number;
};

export type TelegramInitRequest = {
	auth_type: AuthType;
	phone: string | null;
	connection_id: number | null;
} & CompanyRequest;

export type QrLogin = {
	url: string;
	expires: string;
	qrCode: string;
};

export type TelegramInit = {
	sessionId: string;
	status: TelegramInitStatus;
	qrLogin: QrLogin | null;
};

export type TelegramLoginRequest = {
	session_id: string;
	code: number | null;
	password: string | null;
} & CompanyRequest;

export type TelegramLogoutRequest = {
  connection_id: number;
} & CompanyRequest;

export const initTelegram = async (requestOptions: TelegramInitRequest): Promise<TelegramInit> => {
	const { company_id: companyId, ...rest } = requestOptions;
	return await axiosInstance.post(`/companies/${companyId}/telegram/init`, { ...rest });
};

export const loginTelegram = async (requestOptions: TelegramLoginRequest): Promise<TelegramInit> => {
	const { company_id: companyId, ...rest } = requestOptions;
	return await axiosInstance.post(`/companies/${companyId}/telegram/login`, { ...rest });
};

export const logoutTelegram = async (requestOptions: TelegramLogoutRequest): Promise<void> => {
	const { company_id: companyId, ...rest } = requestOptions;
	await axiosInstance.post(`/companies/${companyId}/telegram/logout`, { ...rest });
};
