import {
  WabaTemplateComponentButtonsButtonOTP, WabaTemplateComponentButtonsButtonOTPIn,
  WabaTemplateComponentButtonsButtonPhoneNumber, WabaTemplateComponentButtonsButtonPhoneNumberIn,
  WabaTemplateComponentButtonsButtonQuickReply, WabaTemplateComponentButtonsButtonUrl
} from '../../../templatesTypes';
import { configWabaTemplateButton } from '../../WabaTemplatesModeration/utils';
import './PreviewButtons.scss';


type PreviewButtonsProps = {
  buttons: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumber
    | WabaTemplateComponentButtonsButtonPhoneNumberIn
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateComponentButtonsButtonOTP
    | WabaTemplateComponentButtonsButtonOTPIn
  >;
};

function PreviewButtons({ buttons }: PreviewButtonsProps) {
  return (<>
    {buttons.map((button, index) => {
      return (
        <button key={`${button.type}${index}`} className="wabaPreview__buttons_button" type="button">
          {configWabaTemplateButton[button.type].icon && configWabaTemplateButton[button.type].icon}
          {configWabaTemplateButton[button.type].text(button)}
        </button>
      );
    })}
  </>);
}

export default PreviewButtons;
