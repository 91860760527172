import './ProgressSteps.scss';
import { currentOnboardingStep, getProgressSteps, guideSteps } from '../utils/getStep';
import { CompanyOnboardingSteps, companyOnboardingSteps } from '../../../api/types';
import { useSelector } from 'react-redux';
import { onboardingSelector } from '../../../views/Chat/companiesSelector';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';


const ProgressSteps = () => {
  const onboarding = useSelector(onboardingSelector);
  const progressSteps = getProgressSteps();

  const currentStep = onboarding && currentOnboardingStep(onboarding);

  if (!onboarding || !currentStep) {
    return <></>;
  }

  const statusStep = (step: CompanyOnboardingSteps) => {
    const indexStep = companyOnboardingSteps.indexOf(currentStep);

    if (currentStep === step) {
      return 'active';
    } else if (!guideSteps.includes(step) && indexStep >= companyOnboardingSteps.indexOf(step)) {
      return 'success';
    } else if (guideSteps.includes(currentStep)) {
      return 'active';
    }
    return '';
  };

  return (
    <div className="progressSteps">
      {progressSteps.map((step, index) => (
        <div key={step.type} className="stepContainer">
          <div className={`step ${statusStep(step.type)}`}>
            {index + 1}
            {statusStep(step.type) === 'success' && <CheckIcon />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressSteps;
