import { useContext } from "react";
import { useSelector } from "react-redux";
import { addAlertWithCustomText } from "../../../../components/Alert/alertSlice";
import { useAppDispatch } from "../../../../store/store";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { integrationsUpdate } from "../integrationSlice";
import { IntegrationsTypesParams, IntegrationUpdate, MessagingOption } from "../types";
import { integrationContext } from "../utils";
import { useGuide } from "./guide";


const checkOpenLinesUnique = (options: MessagingOption[]): boolean => {
  const setLines = new Set(options.map(option => option.pipeID));
  return setLines.size === options.length;
};

export function useMessagingUpdate() {
  const dispatch = useAppDispatch();
  const company = useSelector(currentCompanySelector);
  const { area, setIntegration } = useContext(integrationContext);
  const { guided, update } = useGuide();

  const showOpenLinesLimitError = () => {
    dispatch(addAlertWithCustomText({
      message: 'settings.integrations.bitrix.open_line_limit',
      type: 'alarm'
    }));
  };

  const integrationUpdate = async (data: IntegrationUpdate) => {
    if (area === 'bitrix' && data.options) {
      if (!checkOpenLinesUnique(data.options)) {
        showOpenLinesLimitError();
        return;
      }
    }

    let response: boolean = false;

    if (company) {
      await dispatch(integrationsUpdate({
        companyId: company.id,
        integration_id: data.id,
        requestOption: {
          name: data.name,
          type: data.type,
          status: data.status ? 'enabled' : 'disabled',
          ...(data.checkPhonesEnabled !== undefined ? { checkPhonesEnabled: data.checkPhonesEnabled } : {}),
          ...(data.handleAmoDialogEvents !== undefined ? { handleAmoDialogEvents: data.handleAmoDialogEvents } : {}),
          ...(data.writeFirstConn !== undefined ? { writeFirstConn: Number(data.writeFirstConn) } : {}),
          ...(data.createInvoices !== undefined ? { createInvoices: data.createInvoices } : {}),
          ...(data.smsCreateChatEnabled !== undefined ? { smsCreateChatEnabled: data.smsCreateChatEnabled } : {}),
          [IntegrationsTypesParams[data.type]]: area === 'bitrix'
            ? data.options.map(item => ({ id: item.id, open_line_id: item.pipeID }))
            : data.options.map(item => ({
              id: item.id, pipeline_id: item.pipeID, status_id: item.statusID === 0 ? null : item.statusID }))
        },
      }))
        .then((result) => {
          response = result.meta.requestStatus === 'fulfilled';
          if (response && typeof result.payload === 'object') {
            if (guided) {
              update();
            } else {
              setIntegration(result.payload);
            }
          }
      });
    }

    return response;
  };

  return integrationUpdate;
}
