import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductChooseType, selectContext } from '../../../types';
import { formatNumberLocale } from '../../../../../utils/utils';
import { ReactComponent as ProductIcon } from '../../../../../assets/image.svg';
import Checkbox from '../../../../../components/Checkbox';
import './CatalogsChooseProductCard.scss';


type CatalogsChooseProductCardProps = {
  product: ProductChooseType;
  onCheck?: (product: ProductChooseType) => void;
};

const CatalogsChooseProductCard = ({ product, onCheck }: CatalogsChooseProductCardProps) => {
  const { i18n } = useTranslation();
  const { checkSelected } = useContext(selectContext);

  const onSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    product.selected = e.currentTarget.checked;
    checkSelected(product.id);
    if (onCheck) onCheck(product);
  };

  return (
    <div className="catalogsChooseProductCard">
      <div className="catalogsChooseProductCard__select">
        <Checkbox name={String(product.id)} value={product.selected} onCheckedHandler={onSelectHandler} />
      </div>
      <div className="catalogsChooseProductCard__image">
        {product.images.length > 0 ? <img src={product.images[0]} alt="product" /> : <ProductIcon />}
      </div>
      <div className="catalogsChooseProductCard__about">
        <div className="catalogsChooseProductCard__name">{product.name}</div>
        <div className="catalogsChooseProductCard__price">
          {formatNumberLocale(product.prices.find(item => item.currency === 'RUB')?.price!, 2, i18n.language)} ₽
        </div>
      </div>
    </div>
  );
};

export default CatalogsChooseProductCard;
