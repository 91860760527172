import { useContext, useEffect, useState } from 'react';
import './ChannelTelegram.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { setPartnerNotificationNew } from '../../../../PartnerCabinetSlice';
import { postPartnerNotificationChannel } from '../../../../PartnerCabinetAPI';
import { isTelegramLink, PartnerNotificationTelegramResponse } from '../../../../PartnerCabinetTypes';
import { partnerNotifications } from '../../../utils';
import ListItems from '../../../../../../components/ListItems';
import qrCodePlug from '../../../../../../assets/qrCode-mask.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/copy.svg';
import { PartnerNotifications } from '../../../../PartnerCabinetSelector';


function ChannelTelegram() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const newChannelStatus = useSelector((stare: RootState) => stare.partners.partnerNotificationNew);
  const newChannel = useSelector((state: RootState) => state.partners.partnerTelegramChannelNew);
  const channels = useSelector(PartnerNotifications);
  const [response, setResponse] = useState<PartnerNotificationTelegramResponse | null>(null);
  const { setChannelType, setChannel } = useContext(partnerNotifications);
  const channelType = 'telegram';

  useEffect(() => {
    let isFetch = true;

    const fetchLink = async () => {
      const result = await postPartnerNotificationChannel({
        companyId, type: channelType, params: { events: ['payment_status'], partner_companies: [] } });
      
      if (isFetch && isTelegramLink(result)) {
        setResponse(result);
      }
    }

    fetchLink().catch((e) => dispatch(addAlertWithCustomText({ type: 'alarm', message: e.message })));

    return () => { isFetch = false };
  }, []);

  useEffect(() => {
    dispatch(setPartnerNotificationNew('pending'));
  }, []);

  useEffect(() => () => {
    setChannelType(null);
    dispatch(setPartnerNotificationNew('idle'));
  }, []);

  useEffect(() => {
    if (newChannelStatus === 'fulfilled') {
      const channel = channels.find((ch) => ch.id === newChannel);
      if (channel) {
        setChannel(channel);
        setPartnerNotificationNew('idle');
      }
    }
  }, [newChannelStatus, channels]);

  const onCopy = () => {
    if (response?.url) navigator.clipboard.writeText(response.url).then(() => {
      dispatch(addAlertWithCustomText({ message: t('partner_cabinet.notifications.telegram.copied') }));
    });
  };

  return (
    <div className="channelTelegram">
      <h1>{t(`partner_cabinet.notifications.${channelType}.title`)}</h1>
      <p className="regularText">{t(`partner_cabinet.notifications.${channelType}.info`)}</p>
      <h3>{t(`partner_cabinet.notifications.${channelType}.add`)}</h3>
      <div className={`channelTelegram__qr  ${!response && 'init'}`}>
        <img alt='qr' src={response ? response.qrCode : qrCodePlug} />
      </div>
      <ListItems typeList='ol' items={t('partner_cabinet.notifications.telegram.steps').split('\n')} />
      <div className='channelTelegram__link'>
        <input className='smallText' type="text" readOnly={true} value={response ? response.url : ''} />
        <CopyIcon onClick={onCopy} />
      </div>
    </div>
  );
}

export default ChannelTelegram;
