import { memo } from 'react';
import './PeriodSelector.scss';
import { useTranslation } from 'react-i18next';
import RadioControl from '../../../../components/RadioControl';


type PeriodSelectorProps = {
  label: string;
  name: string;
};

function PeriodSelector({ label, name }: PeriodSelectorProps) {
  const { t } = useTranslation();
  
  return (
    <div className='periodSelector'>
      <h3>{label}</h3>
      {[1, 3, 6, 12].map((month, index) =>
        <RadioControl key={month} name={name} value={month} defaultChecked={index === 0 && true}
          label={`${month} ${t('month.month', { count: month })}`} />
      )}
    </div>
  );
}

export default memo(PeriodSelector);
