import { FC, useEffect } from 'react';
import './ContactCardEdit.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { currentContactSelector } from '../../contactsSlice/contactsSelector';
import {
  clearContacts, fetchContact, setSelectContactNotes, updateContactEdited
} from '../../contactsSlice/contactsSlice';
import { UpdateContactPayload } from '../../ContactsAPI';
import { usePhoneSchema } from '../../../../components/PhoneInput/utils';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input';
import InputsPhones from '../../components/InputsPhones';
import InputsEmails from '../../components/InputsEmails';
import loader from '../../../../assets/grid.svg';


type DefaultValueStateType = {
  name: string;
  phones: Array<{ value: string }> | null;
  emails: Array<{ value: string }> | null;
};

const ContactCardEdit: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string, contactId: string }>('/companies/:companyId/contacts/:contactId');
  const currentContact = useSelector(currentContactSelector);
  const nameRegex = new RegExp('^(?!\\s*$)[-\\/\'" а-яА-ЯёЁa-zA-Z0-9]+$');

  const emailSchema = {
    value: yup.string().email(t('contacts.errorMessage.incorrectEmail'))
      .required(t('error.form.empty_field'))
      .max(64, `${t('error.form.max_length', { limit: 64 })}`),
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field'))
      .matches(nameRegex, t('contacts.errorMessage.incorrectData'))
      .max(256, `${t('error.form.max_length', { limit: 256 })}`),
    phones: yup.array().of(yup.object().shape(usePhoneSchema({ fieldName: 'value' }))),
    emails: yup.array().of(yup.object().shape(emailSchema)),
  });

  const methods = useForm<DefaultValueStateType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: currentContact?.name || '',
      phones: currentContact?.phones.map((item) => ({ value: item.value })) || [],
      emails: currentContact?.emails.map((item) => ({ value: item.value })) || [],
    },
  });

  const onSubmit = (data: DefaultValueStateType) => {
    if (url?.params.companyId && url?.params.contactId) {
      const { name, emails } = data;

      const payload: UpdateContactPayload = {
        name,
        phones: data.phones,
        emails,
        tags: currentContact && currentContact?.tags && currentContact.tags.length > 0
          ? currentContact.tags.map(({ id }) => id)
          : [],
      };
      dispatch(updateContactEdited({
        contactId: url?.params.contactId,
        companyId: Number(url?.params.companyId),
        payload,
      }));
      methods.reset(data);
    }
  };

  useEffect(() => () => {
    dispatch(clearContacts());
  }, []);

  useEffect(() => {
    if (url?.params.companyId && !currentContact ) {
      dispatch(push(`/companies/${url?.params.companyId}/contacts`));
    }
  }, [currentContact]);

  useEffect(() => {
    if (currentContact && url?.params.contactId ) {
      methods.reset({
        name: currentContact.name,
        phones: currentContact.phones.map((item) => ({ value: item.value })),
        emails: currentContact.emails.map((item) => ({ value: item.value })),
      });
    }
  }, [url?.params.contactId, currentContact]);


  useEffect(() => {
    if (url?.params.companyId && url?.params.contactId) {
      dispatch(fetchContact({
        contactId: Number(url?.params.contactId),
        companyId: Number(url?.params.companyId),
      }));
    }

    return () => {
      dispatch(setSelectContactNotes());
    };
  }, [url?.params.contactId, url?.params.companyId]);

  if (!currentContact ) {
    return (
      <div className='loaderContainer'>
        <img src={loader} alt='' />
      </div>
    );
  }

  return (
    <div className='ContactDetailEdit__wrapper'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='ContactDetailEdit'>
            <h1>{t('contacts.editContact.editContact')}</h1>
            <div className='ContactDetailEdit__content'>
              <Input name='name' label={t('contacts.contactsList.nameSurname')} data-testid='name' />
              <InputsPhones />
              <InputsEmails />
            </div>
            <div className='ContactDetailEdit__footer'>
              <Button text={t('save_edit')} textType='regular' color='orange' 
                disabled={!methods.formState.isDirty} type='submit' data-testid='save' />
              <Button text={t('cancel')} textType='regular' color='white' onClick={() => history.goBack()}
                data-testid='cancel' />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ContactCardEdit;
