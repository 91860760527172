import React, { useState } from 'react';
import './ConditionsForm.scss';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { ConditionsLogicOperations, ICondition } from '../../BroadcastsTypes';
import { BroadcastsServicesType } from '../../utils';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ErrorStateType } from '../../api/types';
import { useShowServiceInfo } from '../../api/source';
import Button from '../../../../components/Button';
import ConditionItem from './ConditionItem';
import ChooseModal from './ChooseModal';
import SelectModal from './SelectModal';
import Informing from '../Informing/Informing';


type ConditionsFormProps = {
  conditions: ICondition[];
  onInsert?: (condition: ICondition) => void;
  onDelete?: (condition: ICondition) => void;
  operation: ConditionsLogicOperations;
  onOperation?: (operation: ConditionsLogicOperations) => void;
  recipientsNumber?: number;
  viewOnly?: boolean;
  service?: BroadcastsServicesType;
  errorState?: ErrorStateType;
};

function ConditionsForm( props: ConditionsFormProps) {
  const { conditions, onInsert, onDelete, operation, onOperation, recipientsNumber=0, viewOnly=false, service } = props;
  const { t } = useTranslation();
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isError = recipientsNumber <= 0 && props.errorState?.recipientsRadist;
  const showInfo = useShowServiceInfo(service);

  const onConditionChoose = () => setIsOpen(!isOpen);

  const onSelectToggle = () => setIsSelect(!isSelect);

  const onSelectHandler = (item: ConditionsLogicOperations) => {
    if (onOperation) {
      onOperation(item);
      setIsSelect(false);
    }
  };

  return (
    <div className='conditionsForm'>
      {conditions && conditions.length > 1 && (
        <div className={`conditionsForm__select ${viewOnly? 'view' : ''}`} onClick={onSelectToggle}>
          {t(`broadcasts.form.condition_${operation}`)}:
        </div>
      )}
      {isSelect && <SelectModal selected={operation} onSelect={onSelectHandler} setIsOpen={setIsSelect} />}
      {conditions &&
        conditions.map((item) =>
          <ConditionItem key={nanoid()} condition={item} onDelete={onDelete} viewOnly={viewOnly} />)}
      <div className={`conditionsForm__button ${conditions && conditions.length > 0 ? '' : 'empty'}`}>
        {isOpen && <ChooseModal setIsOpen={setIsOpen} addCondition={onInsert} conditionType='tag' />}
        {!viewOnly &&
          <Button type='button' color='white' textType='regular' text={t('broadcasts.form.condition_add')}
            image={<PlusIcon />} onClick={onConditionChoose} />}
      </div>
      {!viewOnly &&
        <div className={`conditionsForm__recipients ${isError ? 'error' : ''}`}>
          <span>{t('broadcasts.params.recievers')}</span>
          <h4>{recipientsNumber}</h4>
        </div>}
      {!viewOnly && service && showInfo &&
        <div className='conditionsForm__exclaim'>
          <Informing text={t(`broadcasts.form.info.${service}`)} />
        </div>
      }
    </div>
  );
}

export default ConditionsForm;
