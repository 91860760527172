import './WatchVideo.scss';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useUpdateStep } from '../../utils/onboarding';
import { CompanyOnboardingUpdate } from '../../../../api/types';
import { onboardingSelector } from '../../../../views/Chat/companiesSelector';
import VideoPlayer from '../../../../components/VideoPlayer';
import Button from '../../../../components/Button';
import { bdAnchorsOnboarding } from '../../../../pages/Home/utils/consts';
import { YandexMetrikaCall } from "../../../../utils/utils";


const WatchVideo = () => {
  const { t, i18n } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const onboarding = useSelector(onboardingSelector);
  const onSubmitOnboardingStep = useUpdateStep();
  const videoSource = 'https://radist.cdn.radist.online/static/video/onboarding_ru.mp4';


  const onNextStep = () => {
    const data: CompanyOnboardingUpdate = {
      company_id: Number(url?.params.companyId),
      steps: [
        {
          is_finished: true,
          type: 'watch_intro_video',
        }
      ]
    }
    YandexMetrikaCall('reachGoal', 'onb-step-watch-video');
    onSubmitOnboardingStep(data);
  };

  const onPlayYaMetrika = () => YandexMetrikaCall('reachGoal', 'onb-video-clck');

  if (!onboarding) return <></>;

  return (
    <div className="watchVideo">
      {i18n.language === 'ru' ? (
        <>
          <p className="regularText">
            <Trans i18nKey="company_onboarding.watchVideo.instructions">plain
              <a target="_blank" rel="noreferrer" href={bdAnchorsOnboarding[i18n.language]}>link</a>
            </Trans>
          </p>
          <VideoPlayer videoSource={videoSource} onPlayYaMetrika={onPlayYaMetrika} />
        </>
      ) : (
        <>
          <p className="regularText">{t('company_onboarding.watchVideo.welcome1')}</p>
          <p className="regularText">{t('company_onboarding.watchVideo.welcome2')}</p>
          <p className="regularText">
            <Trans i18nKey="company_onboarding.watchVideo.welcome3">plain
              <a target="_blank" rel="noreferrer" href={bdAnchorsOnboarding[i18n.language]}>link</a>
            </Trans>
          </p>
        </>
      )}
      <div className="watchVideo__buttons">
        <Button color="orange" textType="small" text={t('next')} onClick={onNextStep} />
      </div>
    </div>
  );
};

export default WatchVideo;
