import { useContext } from 'react';
import './WabaParams.scss';
import { useTranslation } from 'react-i18next';
import { wabaModeration } from '../context';
import { ParamsType } from '../utils';
import ParamsList from '../ParamsList';


const WabaParams = () => {
  const { t } = useTranslation();
  const {
    arrayHeaderParams, setArrayHeaderParams,
    arrayBodyParams, setArrayBodyParams,
    arrayButtonParams, setArrayButtonParams,
    arrayCardParams, setArrayCardParams,
    isError
  } = useContext(wabaModeration);

  const onCardParamUpdate = (id: number) => {
    
    return (arrays: ParamsType[]) => {
      setArrayCardParams((prev) => {
        return prev.map((card) => {
          if (card.id === id) {
            return { ...card, params: arrays };
          }
          return card;
        });
      });  
    }
  };

  return (
    <div className="wabaParams">
      <ParamsList caption={t('templates.waba_moderation.header_param')} arrayParams={arrayHeaderParams}
        hasError={isError} setArrayParams={setArrayHeaderParams} />
      <ParamsList caption={t('templates.waba_moderation.body_param')} arrayParams={arrayBodyParams}
        hasError={isError} setArrayParams={setArrayBodyParams} />
      <ParamsList caption={t('templates.waba_moderation.url_param')} arrayParams={arrayButtonParams}
        hasError={isError} setArrayParams={setArrayButtonParams} />
      {arrayCardParams.map((card) => (
        <ParamsList key={card.id} caption={`${t('templates.waba_moderation.card-param')} ${card.id + 1}`}
          arrayParams={card.params} hasError={isError} setArrayParams={onCardParamUpdate(card.id)} />
      ))}
    </div>
  );
};

export default WabaParams;
