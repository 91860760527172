import './PayoutItem.scss';
import { useTranslation } from 'react-i18next';
import { PartnerPayout } from '../../../PartnerCabinetTypes';
import { dateTimeFormat } from '../../../../Broadcasts/utils';

type Props = {
  payout: PartnerPayout;
};

const PayoutItem = ({ payout }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="payoutItem">
      <p className="regularText">
        {payout.payoutType === 'PAYOUT' &&
          t('partner_cabinet.finances.withdrawal_request', {
            id: payout.id, date: dateTimeFormat(payout.createdAt, 'shortDate', i18n.language),
        })}
        {payout.payoutType === 'MANUAL_CHANGE' &&
          `${payout.comment} ${t('partner_cabinet.finances.payouts.date_from', { date: dateTimeFormat(payout.createdAt, 'shortDate', i18n.language) })}`
        }
      </p>
      <p className="regularText">{payout.value}</p>
      <p className={`smallText semibold ${payout.status.toLowerCase()}`}>
        {t(`partner_cabinet.finances.status.${payout.status.toLowerCase()}`)}
      </p>
    </div>
  );
};

export default PayoutItem;
