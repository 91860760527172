import { useTranslation } from 'react-i18next';
import './Counter.scss';


type CounterProps = {
  counter: number;
  maximum: number;
  text: string;
};

function Counter({ counter, maximum, text }: CounterProps) {
  const { t } = useTranslation();

  return (
    <div className='counter'>
      <p className='smallText'>{counter}/{maximum} {t(text, { count: maximum })}</p>
    </div>
  );
}

export default Counter;
