export const ALL_CHANNEL_TYPES = ['telegram', 'whatsapp'] as const;

export type NotificationChannelTypes = typeof ALL_CHANNEL_TYPES[number];

export const ALL_CHANNEL_EVENTS = ['integration_status', 'payment_status', 'broadcast_status'] as const;

export type NotificationChannelEvents = typeof ALL_CHANNEL_EVENTS[number];

export type NotificationChannel = {
  id: number;
  companyId: number;
  name: string;
  type: NotificationChannelTypes;
  whatsapp: {
    phone: string;
  };
  params: {
    events: NotificationChannelEvents[];
  };
};

export type TelegramNotificationChannel = {
  url: string;
  qrCode: string;
};

export type NotificationChannelUpdate = {
  companyId: number;
  channelId: number;
  name: string;
  type: NotificationChannelTypes;
  whatsapp?: { phone: string };
  params?: { events: NotificationChannelEvents[] };
};

export type GetNotificationChannelsType = {
  data: NotificationChannel[];
};

export type NotificationCreateResponse = NotificationChannel | TelegramNotificationChannel;

export type NotificationChannelCreate = {
  companyId: number;
  type: NotificationChannelTypes;
  whatsapp?: { phone: string };
  params?: {
    events: NotificationChannelEvents[];
  };
};

export type NotificationChannelRequest = {
  companyId: number;
};

export type NotificationChannelUpdateRequest = NotificationChannelRequest & {
  channelId: number;
};
