import moment from 'moment';
import { useTranslation } from 'react-i18next';


export const getFinancesSwitch = () => {
  const { t } = useTranslation();

  return [
    { tabId: 'rewards', tabName: t('partner_cabinet.finances.switch.rewards') },
    { tabId: 'payouts', tabName: t('partner_cabinet.finances.switch.payouts') },
  ];
};

export function dateFormatReward(date: string) {
  const result = moment(date,'YYYY-MM').format('MMMM YYYY');
  return result.charAt(0).toUpperCase() + result.slice(1)
};