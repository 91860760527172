import React, { useContext, useState } from 'react';
import './ClientSubscriptionCard.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-down.svg';
import { subscriptionServiceMap } from '../../../../Settings/Subscriptions/SubscriptionsTypes';
import { CompanySubscription } from '../../../PartnerCabinetTypes';
import { PartnerCompanySelector } from '../../../PartnerCabinetSelector';
import { formatCompanySubscriptionDateStatus, getCompanySubscriptionStatus } from '../../ulils/utils';
import { clientViewContext } from '../../ulils/Context';
import Checkbox from '../../../../../components/Checkbox';
import ClientSubscriptionConnection from './ClientSubscriptionConnection';
import Button from '../../../../../components/Button';


const frozenStyle: React.CSSProperties = {
  backgroundImage: "url(/frozen.jpg)",
  backgroundSize: "cover",
  backgroundOrigin: "border-box"
};

type Props = {
  subscription: CompanySubscription;
};

const ClientSubscriptionCard = ({ subscription }: Props) => {
  const { t, i18n } = useTranslation();
  const partnerCompany = useSelector(PartnerCompanySelector);
  const { selectedSubscriptions, setSelectedSubscriptions, setOnPayBalance } = useContext(clientViewContext);
  const [isShowCard, setIsShowCard] = useState<boolean>(false);

  const subscriptionWithCheckbox = { ...subscription, select: true };

  const onSwitchCard = () => {
    setIsShowCard(!isShowCard);
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = selectedSubscriptions.findIndex((item) => item.id === Number(e.target.name));
    if (index !== -1) {
      selectedSubscriptions.splice(index, 1);
      setSelectedSubscriptions([...selectedSubscriptions]);
    } else {
      setSelectedSubscriptions([...selectedSubscriptions, {
        id: Number(e.target.name),
        type: subscriptionServiceMap[subscription.subscriptionType],
        connections: subscription.connections.length,
      }]);
    }
  };

  const isPausedStatus = () => getCompanySubscriptionStatus(subscription) === 'paused';

  return (
    <div className="clientSubscriptionCard" style={isPausedStatus() ? frozenStyle : undefined}>
      <ArrowIcon className={`clientSubscriptionCard__arrow ${isShowCard ? 'open' : ''}`} onClick={onSwitchCard} />
      <div className="clientSubscriptionCard__checkbox">
        <Checkbox name={String(subscription.id)} value={!isPausedStatus() && subscriptionWithCheckbox.select}
          onCheckedHandler={onChecked} disabled={isPausedStatus()} />
      </div>
      <div className="clientSubscriptionCard__content">
        <p className="regularText semibold">
          {t(`subscriptions.types.${subscriptionServiceMap[subscription.subscriptionType]}`)}
        </p>
        <div className={`clientSubscriptionCard__content_info ${isShowCard ? 'open' : ''}`}>
          <div className="clientSubscriptionCard__content_info_value">
            <p className="regularText">
              {t('partner_cabinet.clients.subscriptions.numbers')}: {subscription.currentSize}
            </p>
            {partnerCompany?.waba360DialogPartnerId &&
              subscriptionServiceMap[subscription.subscriptionType] === 'waba' && (
                <div className="wabaBalance">
                  <p className="regularText">
                    {t('subscriptions.card.waba.balance')}: {partnerCompany?.wabaDialogsBalance}
                  </p>
                  <Button color="white" textType="small" text={t('subscriptions.card.waba.extra')}
                    onClick={() => setOnPayBalance(true)} />
                </div>
              )}
          </div>
          <p className={`${isShowCard ? 'regularText' : 'smallText'} ${getCompanySubscriptionStatus(subscription)}`}>
            {t(`subscriptions.card.status.${getCompanySubscriptionStatus(subscription)}`)}&nbsp;
            {formatCompanySubscriptionDateStatus(i18n.language, subscription)}
          </p>
        </div>
        {isShowCard && (
          <div className="clientSubscriptionCard__content_connections">
            {subscription.connections.map((connection) => (
              <ClientSubscriptionConnection key={connection.id} connection={connection} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientSubscriptionCard;
