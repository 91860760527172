import { ChatConnectionsType } from '../../../BroadcastsTypes';

type BdAnchors = {
 telegram: Record<string, string>;
 whatsapp: Record<string, string>;
};
export const bdAnchorsBroadcast: BdAnchors = {
 telegram:{
   ru: 'https://radist.online/article/telegram/ban',
   en: 'https://radist.online/article/en/telegram/ban',
   es: 'https://radist.online/article/esp/telegram/ban',
 },
  whatsapp:{
    ru: 'https://radist.online/article/no-ban-whatsapp',
    en: 'https://radist.online/article/en/no-ban-whatsapp',
    es: 'https://radist.online/article/esp/no-ban-whatsapp',
  }
};

export const necessaryResourcesAlertWarn = {
  telegram: 'Telegram',
  whatsapp: 'WhatsApp'
}

type NecessaryTypesResourcesAlertWarnType = ["whatsapp" , "telegram" ]
export const necessaryTypesResourcesAlertWarn:NecessaryTypesResourcesAlertWarnType = ['whatsapp', 'telegram']

export const getResourceForAlertWarning = (type: ChatConnectionsType) =>
  necessaryTypesResourcesAlertWarn.find(t => t === type );
