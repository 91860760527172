import { useCallback, useContext, useState } from 'react';
import './NotificationCard.scss';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { PartnerNotificationChannel } from '../../../PartnerCabinetTypes';
import { partnerNotifications } from '../../utils';
import { usePartnerNotificationChannel } from '../../api';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import Button from '../../../../../components/Button';
import CompanyItem from '../CompanyItem';
import ChannelIcon from '../ChannelIcon';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';


type NotificationCardProps = {
  channel: PartnerNotificationChannel;
};

function NotificationCard({ channel }: NotificationCardProps) {
  const { t } = useTranslation();
  const { id: companyId } = useSelector(currentCompanySelector);
  const { deleteChannel, updateChannel } = usePartnerNotificationChannel();
  const { setOpenNotification, setChannel } = useContext(partnerNotifications);
  const [ deleteOpen, setDeleteOpen ] = useState<boolean>(false);

  const onDeleteChannel = () => {
    if (channel.id !== 0) {
      deleteChannel({ companyId, channelId: channel.id });
      setDeleteOpen(false);
    }
  };

  const onDelete = () => setDeleteOpen(true);

  const removePartnerCompany = useCallback((partnerCompany: number) => {
    updateChannel({ companyId, channelId: channel.id, params: {
      events: [...channel.params.events],
      partner_companies: channel.params.partnerCompanies.filter((id) => id !== partnerCompany) }
    });
  }, [ companyId, channel ]);

  const onAddCompany = () => {
    setChannel(channel);
    setOpenNotification(true);
  };

  return (
    <div className='notificationCard'>
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          title={t('partner_cabinet.notifications.delete_title')}
          message={t('partner_cabinet.notifications.delete_message')}
          onAction={onDeleteChannel} setIsOpen={setDeleteOpen} />
      }
      <div className='notificationCard__header'>
        <ChannelIcon channel={channel.type} />
        {channel.type === 'whatsapp' && 
          <p className="regularText">{parsePhoneNumberFromString(channel.name)?.formatInternational()}</p>
        }
        {channel.type !== 'whatsapp' && <p className='regularText'>{channel.name}</p>}
        <WrapperPermission permission="partnersEdit">
          <Button type="button" color="white" textType="small" image={<DeleteIcon />} onClick={onDelete} />
        </WrapperPermission>
      </div>
      {channel.params.partnerCompanies.length > 0 && <>
        <h3>{t('partner_cabinet.notifications.companies')}</h3>
        <div className='notificationCard__companies'>
          {channel.params.partnerCompanies.map((id) =>
            <CompanyItem key={id} partertnerCompany={id} onRemovePartnerCompany={removePartnerCompany} />
          )}
        </div>
      </>}
      <WrapperPermission permission="partnersEdit">
        <Button type='button' color='white' textType='small' text={t('partner_cabinet.notifications.add_company')}
          image={<PlusIcon />} onClick={onAddCompany} id='add' />
      </WrapperPermission>
    </div>
  );
}

export default NotificationCard;
