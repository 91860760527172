import React from 'react';
import './ClientName.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { ReactComponent as CopyIcon } from '../../../../../../assets/copy.svg';



type Props = {
  name: string;
  id: number;
};

const ClientName = ({ name, id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCopy = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(String(id));
    dispatch(addAlertWithCustomText({ message: t(`partner_cabinet.clients.copy`) }));
  };

  return (
    <div className="clientName">
      <p className="regularText middle">{name}</p>
      <p className="smallText" onClick={(e) => onCopy(e)}>
        ID {id}
        <CopyIcon />
      </p>
    </div>
  );
};

export default ClientName;
