import { createRef, useEffect, useRef, useState } from 'react';
import './CountrySelector.scss';
import 'react-phone-number-input/style.css'
import { getCountryCallingCode } from "react-phone-number-input";
import { CountryCode } from 'libphonenumber-js';
import { useOutsideClick } from '../../utils/hooks';


type CountrySelectorProps = {
  value?: string;
  onChange: (value: string) => void;
  options: Array<{ value?: string, label: string }>;
  disabled?: boolean;
};

const CountrySelector = ({ value, onChange, options, disabled=false, ...rest }: CountrySelectorProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const listRef = useRef<HTMLUListElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const refs = options.reduce((acc, option) => {
    if (option.value) acc[option.value] = createRef();
    return acc;
  }, {});

  const onOuterClick = () => {
    setIsOpen(false);
  };

  const onTrigger = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && value) {
      refs[value].current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [isOpen]);

  useOutsideClick(listRef, onOuterClick, triggerRef);

  const onChangeHanderl = (code?: string) => {
    if (onChange) onChange(code || '');
    setIsOpen(false);
  };

  const chooseFlagIcon = (value: string | undefined)  => {
    if (value) {
      return (
        <img className="PhoneInputCountryIcon" alt={value}
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} />
      );
    }
    return null;
  };

  return (
    <div className="PhoneInputCountrySelector" onClick={onTrigger} ref={triggerRef}>
      {isOpen &&
        <ul ref={listRef} className="countrySelector" {...rest}>
          {options && options.map(({ value: val, label }) => (
              <li key={val} value={val} onClick={() => onChangeHanderl(val)}
                className={value && value === val ? 'highlight' : ''}
                ref={val ? refs[val] : null}
              >
                {chooseFlagIcon(val)} {label} {val && `+${getCountryCallingCode(val as CountryCode)}`}
              </li>
          ))}
        </ul>
      }
      <div className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
        {chooseFlagIcon(value)}
      </div>
      <div className="PhoneInputCountrySelectArrow" onClick={onTrigger}/>
    </div>
  );
};

export default CountrySelector;
