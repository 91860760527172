import { useContext } from 'react';
import './FillCardParams.scss';
import { useTranslation } from 'react-i18next';
import { ParamsType } from '../../utils';
import { useMobile } from '../../../../../../../../components/utils/hooks';
import { chatTemplatesFillContext } from '../../../../ChatTemplatesFillProvider/ChatTemplatesFillProvider';
import { WabaTemplateHeaderFormat } from '../../../../../../../Templates/templatesTypes';
import FillParams from '../FillParams';
import ChatTemplateFillHeader from '../ChatTemplateFillHeader';


type Props = {
  cardIndex: number;
  format: WabaTemplateHeaderFormat;
  isError: boolean;
};

const FillCardParams = ({ cardIndex, format, isError }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { arrayCardParams, setArrayCardParams,
    arrayCardButtonsParams, setArrayCardButtonsParams,
    cardFiles, setCardFiles
  } = useContext(chatTemplatesFillContext);
  const cards = arrayCardParams[cardIndex];
  const buttons = arrayCardButtonsParams[cardIndex];
  const file = cardFiles && cardFiles[cardIndex];
  
  const onCardParamUpdate = (id: number) => {
    return (arrays: ParamsType[]) => {
      setArrayCardParams((prev) => {
        return prev.map((card) => {
          if (card.id === id) {
            return { ...card, params: arrays };
          }
          return card;
        });
      });
    };
  };

  const onButtonParamUpdate = (id: number) => {
    return (arrays: ParamsType[]) => {
      setArrayCardButtonsParams((prev) => {
        return prev.map((button) => {
          if (button.id === id) {
            return { ...button, params: arrays };
          }
          return button;
        });
      });
    };
  };

  const setFile = (file: File | undefined) => {
    setCardFiles((prev) => {
      if (!prev) return [file];
      return [...prev.slice(0, cardIndex), file, ...prev.slice(cardIndex + 1)];
    });
  };

  return (
    <div className="fillCardParams">
      {isMobile 
        ? <h3>{`${t(`chats.templates.fill.card_title`)} ${cardIndex + 1}`}</h3>
        : <h4>{`${t(`chats.templates.fill.card_title`)} ${cardIndex + 1}`}</h4>
      }
      <ChatTemplateFillHeader file={file} format={format} setFile={setFile} isError={isError} />
      {cards && <FillParams titleKey="card_param" array={cards.params} isError={isError}
        setArray={onCardParamUpdate(cardIndex)} />}
      {buttons && <FillParams titleKey="url_param" array={buttons.params} isError={isError}
        setArray={onButtonParamUpdate(cardIndex)} />}
    </div>
  );
};

export default FillCardParams;
