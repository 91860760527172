import { useState } from 'react';
import './MessageWabaCart.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { ReactComponent as Cart } from '../../../../../assets/shops.svg';
import { ReactComponent as Arrow } from '../../../../../assets/arrow-left.svg';
import { ReactComponent as Receipt } from '../../../../../assets/receipt.svg';
import { BaseMessageComponent } from '../types';
import { OrderWabaType, RadistOrderType, WabaAndOrderProduct } from '../../../ChatTypes';
import { currencyFormat } from '../../../../Settings/CompanySettings/currency';
import { updateIsPageBill } from '../../../billSlice';
import { terminalsSelector } from "../../../billSelector";
import { countWabaProducts } from '../helpers';
import { useMobile } from "../../../../../components/utils/hooks";
import Button from '../../../../../components/Button';
import WabaProduct from './WabaProduct';
import Comment from '../../Comment';


const MessageWabaCart: BaseMessageComponent = ({ message }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const url = useRouteMatch('/companies/:companyId/chats/:contactId/:chatId/');
  const terminals = useSelector(terminalsSelector);
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);
  const isMobile = useMobile();

  const cartList: Record<'waba_order' | 'radist_order', RadistOrderType | OrderWabaType> = {
    waba_order: message.wabaOrder,
    radist_order: message.radistOrder,
  };

  const cart = cartList[message.messageType];

  const onSnowCart = () => {
    setIsOpenCart(!isOpenCart);
  };

  const onPay = () => {
    const itemsForBill: WabaAndOrderProduct[] = [];
    const cartPay = cartList[message.messageType];

    for (let i = 0; i < cartPay.items.length; i++) {
      itemsForBill.push({
        name: cartPay.items[i].name,
        price: cartPay.items[i].price,
        quantity: cartPay.items[i].quantity,
        productId: cartPay.items[i].productId,
      });
    }
    history.push({
      pathname: `${url?.url}/bill`,
      state: {
        amount: cartPay.amount,
        currency: cartPay.currency,
        items: itemsForBill,
        comment: cartPay.comment,
      },
    });
    dispatch(updateIsPageBill(true));
  };

  return (
    <div className="wabaCartContent">
      <Cart className="cart" />
      <div className={`wabaCartContent__container ${isOpenCart ? 'open' : ''}`}>
        <div className="wabaCartContent__content">
          <p className='regularText bold'>{t('chats.order.cart')}</p>
          <p className='smallText'>{cart.items.length}{t(countWabaProducts(cart.items.length))}</p>
          {isOpenCart ?
            <div className="listProducts">
              {cart.items.map((product: WabaAndOrderProduct) => (
                <WabaProduct key={product.productId || product.retailerId}
                  product={product} currency={cart.currency} />
              ))}
              <div className="total">
                <div className="total_text">{t('chats.bill.amount')}:</div>
                {currencyFormat(cart.amount, cart.currency, i18n.language)}
              </div>
            </div> :
            <div className='rolledAmount'>
              <p className='monospaceRegText semibold'>{currencyFormat(cart.amount, cart.currency, i18n.language)}</p>
              {!isOpenCart &&
                <Button color="white" textType="small" text={t('show')} onClick={onSnowCart} image={<Arrow />} />}
            </div>
          }
        </div>
         {cart.comment && <Comment text={cart.comment} />}
        <div className={`wabaCartContent__buttons ${terminals.length === 0 ? 'oneButton' : ''}`}>
          {isOpenCart && (
            <>
              {isMobile ||
                (terminals.length !== 0 && (
                  <Button color="orange" textType="small" text={t('chats.bill.title_bill')}
                    image={<Receipt />} onClick={onPay} />
                ))}
              <Button color="white" textType="small"
                text={isOpenCart ? t('hide') : t('show')} onClick={onSnowCart}
                image={<Arrow />} className={`GlobalButton white small isImage ${isOpenCart ? 'open' : ''}`}/>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageWabaCart;
