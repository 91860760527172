import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';


type SwitchTimerProps = {
  timeLimit: number;
  switchAction: Dispatch<SetStateAction<boolean>>;
};

const formatMinuteSeconds = (seconds: number) =>
  `${Math.floor(seconds / 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

function SwitchTimer({ timeLimit, switchAction }: SwitchTimerProps) {
  const [elapsed, setElapsed] = useState<number>(timeLimit);
  const timerId = useRef<NodeJS.Timer>();

  useEffect(() => {
    switchAction(prev => {
      if (elapsed === 0) return !prev;
      return prev});
  }, [elapsed]);

  useEffect(() => {
    if (timeLimit > 0) {
      setElapsed(prev => timeLimit);

      const testFunc = () => {
        setElapsed(prev => {
          if (prev > 0) return prev -1;
          return prev;
        });
      };

      testFunc();
      timerId.current = setInterval(testFunc, 1000);
    }

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [timeLimit]);

  return (
    <span className='switchTimer'>{formatMinuteSeconds(elapsed)}</span>
  );
};

export default SwitchTimer;
