import { FC, useEffect, useState } from 'react';
import './ProfileContact.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { addNewNote, fetchAllTags, fetchContact, fetchContactNotes } from '../../contactsSlice/contactsSlice';
import { currentContactSelector, selectContactNotesSelector } from '../../contactsSlice/contactsSelector';
import { fetchInvoices } from '../../../Chat/billSlice';
import { invoicesSelector } from '../../../Chat/billSelector';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/mail.svg';
import AddTags from '../AddTags';
import AddNote from "../AddNote";
import TagsList from "../TagsList";
import Notes from '../Notes';
import InvoiceChat from "./components/InvoiceChat";
import Loader from '../../../../components/Loader/Loader';
import Button from "../../../../components/Button";
import Avatar from "../../../../components/Avatar";


type TypeProps = {
  toggleVisibleProfile: () => void;
};

const ProfileContact: FC<TypeProps> = ({ toggleVisibleProfile }) => {
  const url = useRouteMatch<{ companyId: string, contactId: string }>('/companies/:companyId/chats/:contactId');
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { handleSubmit, reset } = useForm<{ createNote: string }>();
  const currentContact = useSelector(currentContactSelector);
  const selectContactNotes = useSelector(selectContactNotesSelector);
  const listInvoice = useSelector(invoicesSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchContact({
        contactId: Number(url?.params.contactId),
        companyId: Number(url?.params.companyId),
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        await dispatch(fetchAllTags({ companyId: Number(url?.params.companyId) }));
        await dispatch(fetchInvoices({
            companyId: Number(url?.params.companyId),
            contactId: Number(url?.params.contactId),
            offset: 0,
            limit: 2,
          })
        );
        await dispatch(fetchContactNotes({
          contactId: Number(url?.params.contactId),
          companyId: Number(url?.params.companyId),
        }));
        setIsLoading(false);
      }
      if (res.meta.requestStatus === 'rejected') setIsLoading(false);
    });
  }, [url?.params.companyId, url?.params.contactId]);

  const onSubmitCreateNote = (data: { createNote: string }) => {
    if (data && currentContact) {
      dispatch(addNewNote({
        text: data.createNote,
        contactId: currentContact.id,
        companyId: Number(url?.params.companyId),
      }));
      reset({});
    }
  };

  const onOpenContact = () => {
    if (currentContact) {
      history.push(`/companies/${currentContact.companyId}/contacts/${currentContact.id}`);
    }
  };

  if (!currentContact || isLoading) {
    return <div className="profileContact"><Loader /></div>;
  }

  return (
    <div className="profileContact">
      <div className="profileContact__header">
        <p className="regularText semibold">{t('chats.chat.profile')}</p>
        <CloseIcon onClick={toggleVisibleProfile} />
      </div>
      <Avatar image={currentContact.avatarUrl} name={currentContact.name} />
      <div className="profileContact__detail">
        <p className="regularText semibold">{currentContact.name}</p>
        <div className="profileContact__detail_phoneEmail">
          {currentContact.phones.length > 0 && (
            <div className='phoneEmail'>
              <div className="phoneEmail__icon">
                <PhoneIcon />
              </div>
              <div className="phoneEmail__phoneList">
                {currentContact.phones.map((p) => (
                  <div className="phoneEmail__phoneList_item" key={p.id}>
                    <p className="regularText">{p.value}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentContact.emails.length > 0 && (
            <div className='phoneEmail'>
              <div className="phoneEmail__icon">
                <EmailIcon />
              </div>
              <div className="phoneEmail__phoneList">
                {currentContact.emails.map((p) => (
                  <div className="phoneEmail__phoneList_item" key={p.id}>
                    <p className="regularText">{p.value}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="profileContact__detail_tags">
          {currentContact.tags.length > 0 && <h4>{t('contacts.contactsList.tags')}</h4>}
          <TagsList />
          <AddTags currentContact={currentContact} contactId={String(currentContact.id)} />
        </div>
        <div className="profileContact__detail_notes">
          {selectContactNotes && selectContactNotes.length > 0 && (
            <>
              <h4>{t('contacts.detailContact.note')}</h4>
              <Notes />
            </>
          )}
          <form onSubmit={handleSubmit(onSubmitCreateNote)}>
            <WrapperPermission permission="contactsEdit">
              <AddNote contactId={Number(url?.params.contactId) || 0} />
            </WrapperPermission>
          </form>
        </div>
        {listInvoice && listInvoice.length > 0 && (
          <WrapperPermission permission="banksInvoiceView">
            <div className="profileContact__detail__invoices">
              <h4>{t('contacts.detailContact.history_orders')}</h4>
              <InvoiceChat />
            </div>
          </WrapperPermission>
        )}
      </div>
      <div className="profileContact__footer">
        <Button text={t('chats.chat.open_full_profile')} color="white" textType="regular" onClick={onOpenContact} />
      </div>
    </div>
  );
};

export default ProfileContact;
