import { useContext } from 'react';
import { partnerNotifications } from '../../../utils';
import ChannelTelegram from '../ChannelTelegram';
import ChannelWhatsapp from '../ChannelWhatsapp';


function ChannelCreate() {
  const { channelType } = useContext(partnerNotifications);
  if (channelType === 'whatsapp') {
    return (
      <ChannelWhatsapp />
    )
  }

  if (channelType === 'telegram') {
    return (
      <ChannelTelegram />
    )
  }

  return null;
}

export default ChannelCreate;
