import { useContext } from 'react';
import './ChannelWhatsapp.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { createPartnerNotificationChannel } from '../../../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { usePhoneSchema } from '../../../../../../components/PhoneInput/utils';
import { useAppDispatch } from "../../../../../../store/store";
import { partnerNotifications } from '../../../utils';
import { Channels, isNotificationChannel } from '../../../../PartnerCabinetTypes';
import PhoneField from '../../../../../../components/PhoneInput/PhoneField';
import Button from '../../../../../../components/Button';


type NotificationSchema = {
  phone: string;
};

const ChannelWhatsapp = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const phoneSchema = usePhoneSchema({ required: true });
  const { setChannel } = useContext(partnerNotifications);
  const channelType: Channels = 'whatsapp';

  const schema = yup.object().shape({
    ...phoneSchema,
  });

  const methods = useForm<NotificationSchema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: NotificationSchema) => {
    dispatch(createPartnerNotificationChannel({
      companyId: currentCompany.id,
      type: 'whatsapp',
      params: {
        events: ['payment_status'],
        partner_companies: []
      },
      whatsapp: {
        phone: data.phone,
      },
    })).then((res) => {
      if (typeof res.payload === 'object') {
        dispatch(addAlertWithCustomText({ message: t('partner_cabinet.notifications.success'), type: 'info' }));
        if (res.payload && isNotificationChannel(res.payload)) setChannel(res.payload);
      }
    });
  };

  return (
    <div className="channelWhatsapp">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h1>{t(`partner_cabinet.notifications.${channelType}.title`)}</h1>
          <p className="regularText">
            {t(`partner_cabinet.notifications.${channelType}.info`, { number: '+7 904 676-11-46' })}
          </p>
          <PhoneField name="phone" />
          <Button color="orange" textType="regular" text={t('next')} type="submit"
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
};

export default ChannelWhatsapp;
