import React from 'react';
import regexifyString from 'regexify-string';
import { MessageTemplate, WabaTemplate } from '../../../Templates/templatesTypes';


export const getTemplatesFromActiveTab = <T extends MessageTemplate | WabaTemplate>(
  templates: T[],
  getTemplateTextContent: (template: T) => string,
  onSelectTemplate: (template: T, e: React.MouseEvent<HTMLDivElement>) => void,
  getKey: (template: T) => string
) =>
  templates.map((template) => (
    <div className="template__content" key={getKey(template)} onClick={(e) => onSelectTemplate(template, e)}>
      <p>{getTemplateTextContent(template)}</p>
    </div>
  ));

export const getSearchedTemplate = <T extends MessageTemplate | WabaTemplate>(
  templates: T[],
  predicate: (template: T) => boolean,
  getTemplateTextContent: (template: T) => string,
  onSelectTemplate: (template: T, e: React.MouseEvent<HTMLDivElement>) => void,
  getKey: (template: T) => string
): React.ReactNodeArray => {
  const searchedTemplates = templates.filter((template) => predicate(template));

  return searchedTemplates.map((template) => (
    <div className="template__content" key={getKey(template)} onClick={(e) => onSelectTemplate(template, e)}>
      <p>{getTemplateTextContent(template)}</p>
    </div>
  ));
};


export const getTemplatesChat = (
  wabaTemplates:WabaTemplate[],
  searchedValueOfTemplate:string,
  activeTab:string,
  setSelectedWabaTemplate:React.Dispatch<React.SetStateAction<WabaTemplate | null | undefined>>,
  customTemplates:MessageTemplate[],
  onCloseDropdown:(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>void,
  interactiveTemplates:MessageTemplate[],
  setSelectedInteractiveTemplate:React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>,
  setSelectedCustomTemplate:React.Dispatch<React.SetStateAction<MessageTemplate | undefined>>
                                                   )=> {
  switch (activeTab) {
    case 'whatsApp':
      if (searchedValueOfTemplate) {
        return getSearchedTemplate<WabaTemplate>(
          wabaTemplates,
          (template) => template.components.body.text.toLowerCase().includes(searchedValueOfTemplate.toLowerCase()),
          (template) => template.components.body.text,
          setSelectedWabaTemplate,
          (template) => `${template.locale}${template.name}`
        );
      }
      return getTemplatesFromActiveTab<WabaTemplate>(
        wabaTemplates,
        (template) => template.components.body.text,
        setSelectedWabaTemplate,
        (template) => `${template.locale}${template.name}`
      )

    case 'my':
      if (searchedValueOfTemplate) {
        return getSearchedTemplate<MessageTemplate>(
          customTemplates,
          (template) => template.text.toLowerCase().includes(searchedValueOfTemplate.toLowerCase()),
          (template) => template.text,
          (template, e) => {
            setSelectedCustomTemplate(template);
            onCloseDropdown(e);
          },
          (template) => `${template.id}`
        );
      }
      return getTemplatesFromActiveTab<MessageTemplate>(
        customTemplates,
        (template) => template.text,
        (template, e) => {
          setSelectedCustomTemplate(template);
          onCloseDropdown(e);
        },
        (template) => `${template.id}`
      );

    case 'interactive':
      if (searchedValueOfTemplate) {
        return getSearchedTemplate<MessageTemplate>(
          interactiveTemplates,
          (template) => template.params.body.toLowerCase().includes(searchedValueOfTemplate.toLowerCase()),
          (template) => template.params.body,
          setSelectedInteractiveTemplate,
          (template) => `${template.id}`
        );
      }
      return getTemplatesFromActiveTab<MessageTemplate>(
        interactiveTemplates,
        (template) => template.params.body,
        setSelectedInteractiveTemplate,
        (template) => `${template.id}`
      );

    default:
      return null;
  }
};

export const parseWabaText = (text: string, onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void,
                              inputCollectionEdit?: string[]) =>
  regexifyString({
    pattern: /{{(\d+)}}/gm,
    decorator: (match, index) => <input id={index.toString()} onChange={onChangeInput}
                                        defaultValue={inputCollectionEdit && inputCollectionEdit[index]} key={index}/>,
    input: text,
  });

export const getWabaTemplateButtons = (template: WabaTemplate) => {
  if (template.components.buttons) {
    return template.components.buttons.buttons.map((button) => {
      if (button.type === 'url') {
        return (
          <a href={button.url} target="_blank" rel="noreferrer" key={button.text}>
            {button.text}
          </a>
        );
      }
      return (
        <button type="button" key={button.text}>
          {button.text}
        </button>
      );
    });
  }
  return null;
};

export const getAttachmentsTypes = (templateType: string) => {
  switch (templateType) {
    case 'document':
      return '.pdf';
    case 'image':
      return '.jpeg,.png';
    case 'video':
      return 'video/mp4,video/3gpp';
    default:
      return '*/';
  }
};

export const validateAttachmentFileType = (filetype: string) => {
  const fileTypes = [
    'audio/aac',
    'audio/mp4',
    'audio/amr',
    'audio/mpeg',
    'audio/ogg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/3gpp',
  ];
  return fileTypes.some((type) => type === filetype);
};
