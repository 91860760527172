import { useEffect, useState } from 'react';
import './WhatsAppCreate.scss';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../../../../Chat/companiesSelector";
import { useAppDispatch } from '../../../../../../store/store';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { createConnection } from '../../../connectionsSlice';
import { ConnectionStatus, WhatsAppConnection, WhatsAppSchema } from '../../../../../../api/CompanyAPI';
import { getConnectionStatus } from '../../../ConnectionsAPI';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { useGuide } from '../../../api/guide';
import Input from '../../../../../../components/Input';
import WhatsAppCopyQr from '../WhatsAppCopyQr';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import ListItems from "../../../../../../components/ListItems";
import qrCodePlug from '../../../../../../assets/qrCode-mask.svg';
import loader from '../../../../../../assets/grid.svg';


type Statuses = Record<string, {
    name: string;
    color: string;
    textColor: string;
  }>;

const statuses: Statuses = {
  loading: {
    color: '#FFFFFF',
    name: 'connections_statuses.loading',
    textColor: '#FE6D04',
  },
  not_created: {
    color: '#FE6D04',
    name: 'connections_statuses.not_created',
    textColor: '#FFFFFF',
  },
  ok: {
    color: '#2CBA5F',
    name: 'connections_statuses.ok',
    textColor: '#FFFFFF',
  },
  not_paid: {
    color: '#2CBA5F',
    name: 'connections_statuses.not_paid',
    textColor: '#FFFFFF',
  },
  conflict: {
    color: '#2CBA5F',
    name: 'connections_statuses.conflict',
    textColor: '#FFFFFF',
  },
};

const whatsAppCreate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentCompany = useSelector(currentCompanySelector);
  const { service, update, patch } = useGuide();
  const [status, setStatus] = useState<ConnectionStatus>({ status: 'not_created' });
  const [connection, setConnection] = useState<WhatsAppConnection>();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(2083, t('error.form.max_length', { limit: 2083 })),
  });

  const methods = useForm({ defaultValues: { name: '' }, resolver: yupResolver(schema) });

  useEffect(() => {
    if (status.status === 'ok') {
      if (currentCompany.hadConnections === false) {
        YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
        dispatch(setHadConnections(true));
      }
      dispatch(addAlertWithCustomText({
        message: t('connections.whatsapp.add-view.connected', { number: connection?.params.phone || '' })
      }));
      history.goBack();
      if (service === 'whatsapp') update();
    }
  }, [status]);

  useEffect(
    () => () => {
      if (intervalId) clearInterval(intervalId);
    },
    [intervalId]
  );

  const onCreateConnection: SubmitHandler<WhatsAppSchema> = async (data) => {
    if (status.status === 'not_created') {
      setStatus({ status: 'loading' });
      const fetchedConnection = await dispatch(createConnection({
        companyId: currentCompany.id,
        name: data.name,
        type: 'whatsapp'
      })).unwrap();
      if (fetchedConnection && currentCompany.id) {
        if (service === 'whatsapp') patch();
        const checkConnectionStatus = async () => {
          const connectionStatus = await getConnectionStatus({
            connectionId: fetchedConnection.id,
            companyId: currentCompany.id
          });
          setStatus(connectionStatus);
          if (connectionStatus.status === 'ok') {
            clearInterval(id);
          }
        };
        checkConnectionStatus();
        const id = setInterval(checkConnectionStatus, 15000);  // repeat checking with interval
        setIntervalId(id);
      }
      setConnection(fetchedConnection as WhatsAppConnection);
    }
  };

  return (
    <div className="whatsAppCreate">
      <GoBackWrapper title={t('connections.whatsapp.add-view.title')}>
        <FormProvider {...methods}>
          <Input label={t('connections.whatsapp.add-view.input_title')} name="name" />
        </FormProvider>
        <div className="whatsAppCreate__description">
          <Trans i18nKey="connections.whatsapp.add-view.description.text1">
            plane<strong>strong1</strong><strong>strong2</strong>
          </Trans>
          <p>
            <Trans i18nKey="connections.whatsapp.add-view.description.text2">
              plane<strong>strong1</strong>
            </Trans>
          </p>
        </div>
        <div className="whatsAppCreate__recommendation">
          <h4>{t('connections.whatsapp.add-view.description.recommendation')}</h4>
          <ListItems typeList='ol' items={t('connections.whatsapp.add-view.description.steps').split('\n')} />
        </div>
        <div className="whatsAppCreate__qrCodeWrapper">
          <div className="whatsAppCreate__qrCodeWrapper_qrcode">
            <img
              alt="qr code"
              src={status.status !== 'qr' ? qrCodePlug : status.qrCode}
              style={{ opacity: status.status === 'qr' ? 1 : 0.1 }}
            />
            {status.status !== 'qr' && (
              <button
                onClick={status.status === 'not_created' ? methods.handleSubmit(onCreateConnection) : undefined}
                style={{
                  backgroundColor: statuses[status.status].color,
                  color: statuses[status.status].textColor,
                }}
              >
                {t(statuses[status.status].name)}
                {status.status === 'loading' && (
                  <>
                    <br />
                    <img width={20} height={20} src={loader} alt="" style={{ marginTop: 4 }} />
                  </>
                )}
              </button>
            )}
            {status.status === 'qr' && (
              <div className="copyQR">
                <WhatsAppCopyQr codeQr={connection?.params.qrUrl || ''} />
              </div>
            )}
          </div>
          <div className="whatsAppCreate__qrCodeWrapper_description">
            <p className="regularText semibold">
              {t('connections.whatsapp.add-view.description.qr_code.description_title')}
            </p>
            <p className="smallText">
              {t('connections.whatsapp.add-view.description.qr_code.description_subtitle')}
            </p>
          </div>
        </div>
      </GoBackWrapper>
    </div>
  );
};

export default whatsAppCreate;
