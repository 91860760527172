import './WabaImageProfile.scss';
import { nanoid } from 'nanoid';
import { ReactComponent as DeleteIcon } from '../../../../../../../../../assets/trash.svg';


export type WabaProfilePicture = React.FC<{
  profileFile?: File;
  deleteImage: () => void;
  imageData?: {
    fileUrl: string | null;
  };
  removeTrashIcon?: boolean;
}>;

const WabaImageProfile: WabaProfilePicture = ({ profileFile, deleteImage, imageData, removeTrashIcon }) => (
  <div className="wabaImageProfile" >
    <div className="wabaImageProfile__image">
      <img
        src={profileFile?.name
          ? URL.createObjectURL(profileFile)
          : imageData?.fileUrl ? imageData.fileUrl : ''}
        key={nanoid()}
        alt="preview img"
      />
    </div>
    {removeTrashIcon && <span className='wabaImageProfile__delete' onClick={deleteImage}><DeleteIcon /></span>}
  </div>
);

export default WabaImageProfile;
