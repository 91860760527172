import { useEffect, useState } from 'react';
import './ClientView.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from "../../../../../store/store";
import {
  clearPartnerCompany, fetchPartnerCompany,
  uploadPartnerCompanyPayments, fetchPartnerCompanySubscriptions
} from '../../../PartnerCabinetSlice';
import { PartnerCompanySelector } from '../../../PartnerCabinetSelector';
import { clientViewContext, ClientViewContext } from '../../ulils/Context';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import GoBackWrapper from '../../../../../components/GoBackWrapper';
import Button from '../../../../../components/Button';
import ClientSubscriptionList from '../ClientSubscriptionList';
import ClientInvoicesView from '../ClientInvoicesView';
import Loader from '../../../../../components/Loader/Loader';
import ClientNotifications from "../ClientNotifications";
import SidePanel from "../../../../../components/Modals/SidePanel";
import ClientInvoicesCreate from "../ClientInvoicesCreate";
import ClientBalanceCreate from '../ClientBalanceCreate';
import ClientPayInfo from '../ClientPayInfo';


export type SelectedSubscriptionsType = {
  id: number;
  type: string;
  connections: number;
};

const ClientView = () => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; partnerCompanyId: string; }>(
    '/companies/:companyId/partners/clients/:partnerCompanyId'
  );
  const dispatch = useAppDispatch();
  const partnerCompany = useSelector(PartnerCompanySelector);
  const [onPay, setOnPay] = useState<boolean>(false);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<SelectedSubscriptionsType[]>([]);
  const [onPayBalance, setOnPayBalance] = useState<boolean>(false);
  const [payDisabled, setPayDisabled] = useState<boolean>(false);

  useEffect(() => () => {
    dispatch(clearPartnerCompany());
  }, []);

  useEffect(() => {
    const companyId = Number(url?.params.companyId);
    const partnerCompanyId = Number(url?.params.partnerCompanyId);

    dispatch(fetchPartnerCompany({ company_id: companyId, partner_company_id: partnerCompanyId }));
    dispatch(fetchPartnerCompanySubscriptions({ company_id: companyId, partner_company_id: partnerCompanyId }));
    dispatch(uploadPartnerCompanyPayments({ company_id: companyId, partner_company_id: partnerCompanyId, limit: 3 }));
  }, [url?.params.partnerCompanyId]);

  useEffect(() => {
    if (selectedSubscriptions.length > 0) {
      setPayDisabled(!selectedSubscriptions.some((item) => item.connections > 0));
    } else {
      setPayDisabled(true);
    }
  }, [selectedSubscriptions]);

  const onCreateInvoices = () => setOnPay(true);

  const contextValue: ClientViewContext = {
    selectedSubscriptions,
    setSelectedSubscriptions,
    setOnPayBalance
  };

  if (!partnerCompany) {
    return (
      <div className="clientView">
        <Loader />
      </div>
    );
  }

  return (
    <div className="clientView">
      <GoBackWrapper title={partnerCompany.name}>
        <clientViewContext.Provider value={contextValue}>
          {onPay && (
            <SidePanel setIsOpen={setOnPay} background="light">
              <ClientInvoicesCreate setOnPay={setOnPay} partnerCompany={partnerCompany} />
            </SidePanel>
          )}
          {onPayBalance && (
            <SidePanel setIsOpen={setOnPayBalance} background="light">
              <ClientBalanceCreate setOnPay={setOnPayBalance} partnerCompany={partnerCompany} />
            </SidePanel>
          )}
          <div className="clientView__header">
            <WrapperPermission permission="partnersEdit">
              <Button color="orange" textType="regular" text={t('partner_cabinet.clients.create.title')}
                image={<PlusIcon />} onClick={onCreateInvoices} disabled={payDisabled} />
            </WrapperPermission>
          </div>
          <div className='clientView__content'>
            <p className="regularText">ID {partnerCompany.id}</p>
            <div className="clientView__content_info">
              <ClientPayInfo companyId={Number(url?.params.companyId)}
                partnerCompanyId={Number(url?.params.partnerCompanyId)} partnerCompany={partnerCompany} />
              <ClientNotifications />
            </div>
            <ClientSubscriptionList />
            <ClientInvoicesView partnerId={partnerCompany.id} />
          </div>
        </clientViewContext.Provider>
      </GoBackWrapper>
    </div>
  );
};

export default ClientView;
