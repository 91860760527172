import './InteractiveMessageList.scss';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InteractiveTemplate } from '../../../api';
import Button from '../../../../../../components/Button';
import TextAreaResize from '../../../../Components/TextAreaResize';
import ListAction from '../ListAction';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


const MAX_SECTIONS = 10;

const InteractiveMessageList = () => {
  const { t } = useTranslation();
  const methods = useFormContext<InteractiveTemplate>();
  const fieldArrayMethods = useFieldArray<InteractiveTemplate>({
    name: 'action.sections.sections.0.rows',
    control: methods.control,
  });

  const onAddListAction = () => {
    if (fieldArrayMethods.fields.length < MAX_SECTIONS) {
      fieldArrayMethods.append({
        title: '',
        description: '',
      });
    }
  };

  return (
    <div className="interactiveMessageList">
      <TextAreaResize className='regularText' name='action.sections.button'
        title={t('templates.add_interactive_templates.template_actions.list.title')}
        subtitle={t('templates.add_interactive_templates.template_actions.list.subtitle')}
        minRows={2} maxRows={2} maxLength={20} />
      {fieldArrayMethods.fields.map((listAction, id) => (
        <ListAction id={id} key={listAction.id} {...methods} {...fieldArrayMethods} />
      ))}
      {fieldArrayMethods.fields.length < MAX_SECTIONS && (
        <Button type='button' color='white' textType='small' image={<PlusIcon />}
          text={t('templates.add_interactive_templates.template_actions.list.add_paragraph')}
          onClick={onAddListAction}
        />
      )}
    </div>
  );
};

export default InteractiveMessageList;
