import { useTranslation } from 'react-i18next';
import './InvoiceStatus.scss';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { dateTimeFormat } from '../../../../../Broadcasts/utils';
import { ReactComponent as CopyIcon } from '../../../../../../assets/copy.svg';
import { ReactComponent as FileDownload } from "../../../../../../assets/file-arrow-down.svg";
import Button from '../../../../../../components/Button';


type InvoiceStatusProps = {
  status: string;
  actual: string;
  paymentUrl: string | null;
  actUrl: string | null;
};

function InvoiceStatus({ status, actual, paymentUrl, actUrl }: InvoiceStatusProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onCopy = () => {
    if (paymentUrl) navigator.clipboard.writeText(paymentUrl);
    dispatch(addAlertWithCustomText({ message: t('partner_cabinet.payments.copy_link') }));
  };

  const onPay = () => {
    if (paymentUrl) window.open(paymentUrl, '_blank');
  };

  const onAct = () => {
    if (actUrl) window.open(actUrl, '_blank');
  };

  return (
    <div className={`invoiceStatus ${status}`}>
      <div className='invoiceStatus__status'>
        <p className='regularTex'>
          {t(`subscriptions.invoice.status.${status}`)}
          {status === 'new' && actual &&
            <span>
              ,&nbsp;{t('partner_cabinet.payments.statuses.actual')}
              &nbsp;{dateTimeFormat(actual, 'shortDate', i18n.language)}
            </span>
          }
        </p>
      </div>
      {status === 'new' &&
        <div className='invoiceStatus__buttons'>
          <Button type='button' color='white' textType='small' text={t('partner_cabinet.payments.pay')}
            onClick={onPay} />
          <button type='button' className='invoiceStatus__buttons_copy' onClick={onCopy}>
            <p className='smallText'>{t('copy')}&nbsp;<CopyIcon /></p>
          </button>
        </div>
      }
      {status === 'confirmed' && actUrl ?
        <p className='invoiceStatus__act' onClick={onAct}>
          <FileDownload /> {t('partner_cabinet.payments.act')}</p> : null
      }
    </div>
  );
}

export default InvoiceStatus;
