import { useEffect, useState } from 'react';
import './SberEdit.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { SberbankConnection } from '../../../../../../api/CompanyAPI';
import { RuTaxation } from '../../../ConnectionsTypes';
import { MAX_LIFETIME, MIN_LIFETIME, surePreffix, switchItems, taxationTypes } from "../../../utils/terminals";
import { updateConnection } from '../../../connectionsSlice';
import { transformEmptyStringToUndefined } from '../../../utils/connections';
import Dropdown from '../../../../Dropdown/Dropdown';
import Switch from '../../../../Switch/Switch';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import LifetimePayment from "../../../components/LifetimePayment";


type SubmitSchema = {
  name: string;
  params: {
    password: string;
    success_url: string;
    fail_url: string;
    is_testing?: boolean;
    taxation: RuTaxation;
    invoice_lifetime_minutes: number;
  };
};

const SberEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { connectionId } = useParams<{ connectionId: string }>();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.sberbank);
  const [connection, setConnection] = useState<SberbankConnection>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      password: yup.string().transform(transformEmptyStringToUndefined),
      success_url: yup.string().required(t('error.form.empty_field'))
        .max(2083, t('error.form.max_length', { limit: 2083 })),
      fail_url: yup.string().required(t('error.form.empty_field')).max(2083, t('error.form.max_length', { limit: 2083 })),
      is_testing: yup.boolean(),
      taxation: yup.string(),
      invoice_lifetime_minutes: yup.number()
        .min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<SubmitSchema >({
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        password: '',
        fail_url: connection?.params.failUrl,
        success_url: connection?.params.successUrl,
        is_testing: connection?.params.isTesting,
        taxation: connection?.params.taxation,
        invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes
      }
    }
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as SberbankConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect?.name,
        params: {
          password: '',
          fail_url: connect?.params.failUrl,
          success_url: connect?.params.successUrl,
          is_testing: connect?.params.isTesting,
          taxation: connect?.params.taxation,
          invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes
        }
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<SubmitSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }

    if (connection && currentCompanyId?.params.companyId) {
      if (data.params.is_testing === connection.params.isTesting) {
        data.params.is_testing = undefined;
      }
      const urlPrefix = 'https://';
      data.params.fail_url = surePreffix(data.params.fail_url, urlPrefix);
      data.params.success_url = surePreffix(data.params.success_url, urlPrefix);
      dispatch(updateConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        itemId: Number(connectionId),
        name: data.name,
        type: 'sberbank',
        params: data.params,
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/sber`));
        }
      });
    }
  };

  return connection ? (
      <div className="sberEditPage">
        <GoBackWrapper title={t('edit')}>
          <p className="sberEditPage__connectionNumber">Номер подключения: #{connection.id}</p>
          <p className="sberEditPage__terminalKey">Имя пользователя: {connection.params.login} </p>
          <FormProvider {...methods}>
            <Input label="Название подключения" name="name" />
            <Dropdown
              items={taxationTypes}
              label="Система налогообложения"
              name="params.taxation"
              labelKey="option"
              valueKey="id"
            />
            <Input label="Обновить пароль" name="params.password" inputType="password" />
            <Input label="Страница для перенаправления после успешной оплаты" name="params.success_url"
                   placeholder="https://" width={560} />
            <Input label="Страница для перенаправления после неуспешной оплаты" name="params.fail_url"
                   placeholder="https://" width={560} />
            <Switch items={switchItems} name="params.is_testing" />
            <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
          </FormProvider>
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                  onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
        </GoBackWrapper>
      </div>
  ) : null;
};

export default SberEdit;
