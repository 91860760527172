import React, { FunctionComponent, SVGProps } from 'react';
import './InteractiveMessageTemplateCardBadges.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TextTypeIcon } from '../../../../assets/article.svg';
import { ReactComponent as ImageTypeIcon } from '../../../../assets/image.svg';
import { ReactComponent as VideoTypeIcon } from '../../../../assets/video.svg';
import { ReactComponent as DocumentTypeIcon } from '../../../../assets/template.svg';

type Props = {
  type: string;
};

type AttachmentTypes = {
  [key: string]: {
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    text: string;
  };
};

const InteractiveMessageTemplateCardBadges: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  const attachmentTypes: AttachmentTypes = {
    text: {
      icon: TextTypeIcon,
      text: t('templates.interactive_attachments_types.text'),
    },
    image: {
      icon: ImageTypeIcon,
      text: t('templates.interactive_attachments_types.image'),
    },
    video: {
      icon: VideoTypeIcon,
      text: t('templates.interactive_attachments_types.video'),
    },
    document: {
      icon: DocumentTypeIcon,
      text: t('templates.interactive_attachments_types.document'),
    },
  };

  const IconType = attachmentTypes[type].icon;

  return (
    <div className="interactiveMessageTemplateCardBadges">
      <IconType />
      <p className="interactiveMessageTemplateCardBadges__text">{attachmentTypes[type].text}</p>
    </div>
  );
};

export default InteractiveMessageTemplateCardBadges;
