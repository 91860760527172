import React from 'react';
import './ItemsList.scss';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../../CompanySettings/currency';
import { SubscriptionInvoice } from '../../../SubscriptionsTypes';
import { InvoiceItems } from '../helper';
import UserSubscription from '../../../models/UserSubscription';
import InvoiceItem from '../InvoiceItem';


type ItemsListProps = {
  invoice: SubscriptionInvoice;
  subscriptions?: UserSubscription[];
};

function ItemsList({ invoice, subscriptions }: ItemsListProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className='subscriptionsInvoicePay__subscriptions'>
      <h3>{t('selected')}</h3>
      <table className='subscriptionsInvoicePay__subscriptions_container'>
        <thead>
          <tr className='subscriptionsInvoicePay__subscriptions_container_title'>
            <th>{t('subscriptions.invoice.sub_list.name')}</th>
            <th>{t('subscriptions.invoice.sub_list.price')}</th>
            <th>{t('subscriptions.invoice.sub_list.discount')}</th>
            <th>{t('subscriptions.invoice.sub_list.amount')}</th>
          </tr>
        </thead>
        <tbody className='subscriptionsInvoicePay__subscriptions_container_content'>
          {InvoiceItems(invoice.summary.items, subscriptions, i18n.language).map((item) =>
            <InvoiceItem item={item} key={nanoid()} />
          )}
        </tbody>
      </table>
      <div className='subscriptionsInvoicePay__subscriptions_container_bottom'>
        <h2>{t('subscriptions.invoice.total_amount')}:</h2>
        <div className='amountStyle'>
          {currencyFormat(invoice.amount, invoice.currency, i18n.language)}
        </div>
      </div>
    </div>
  );
}

export default ItemsList;
