import './ButtonAction.scss';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { InteractiveTemplate } from '../../../api';
import RemoveActionButton from '../RemoveActionButton/RemoveActionButton';
import TextAreaResize from '../../../../Components/TextAreaResize';


type Props = {
  id: number;
} & UseFormReturn<InteractiveTemplate> & UseFieldArrayReturn<InteractiveTemplate>;

const ButtonAction: React.FC<Props> = ({ id, remove }) => {
  const { t } = useTranslation();

  const onRemoveButtonAction = () => {
    remove(id);
  };

  return (
    <div className="buttonAction">
      <TextAreaResize className='regularText' name={`action.buttons.${id}.reply.title`} minRows={1} maxRows={2}
        title={`${t('templates.add_interactive_templates.template_actions.buttons.button_title')} ${id + 1}`}
        subtitle={t('templates.add_interactive_templates.template_actions.buttons.button_subtitle')} />
      {id > 0 && <RemoveActionButton onRemoveAction={onRemoveButtonAction} />}
    </div>
  );
};

export default ButtonAction;
