import { useSelector } from 'react-redux';
import { useHasPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { PartnerNotifications } from '../../../PartnerCabinetSelector';
import NotificationCard from '../NotificationCard';
import './NotificationsList.scss';


function NotificationsList() {
  const channels = useSelector(PartnerNotifications);
  const hasPermissionEdit = useHasPermission('partnersEdit');

  return (
    <div className={`notificationChannels ${hasPermissionEdit ? 'banned' : ''}`}>
      {channels.map((channel) => (
        <NotificationCard key={channel.id} channel={channel} />
      ))}
    </div>
  );
}

export default NotificationsList;
