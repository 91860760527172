import React from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../../../../Chat/BillAPI';
import { getInvoiceDate, getInvoiceStatus, getSymbolCurrency } from '../../../../../Sales/Orders/components/OrderItem/helpers';

type Props = {
  invoice: Invoice
}

const InvoiceItem: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation();

  return (
    <div className='invoiceList_list_item'>
      <div>{getInvoiceDate(invoice.createdAt, t)}</div>
      {getInvoiceStatus(invoice, t)}
      <div>{invoice.amount} {getSymbolCurrency(invoice)}</div>
      <div>{invoice.receipt.clientName}</div>
    </div>
  );
};

export default InvoiceItem;
