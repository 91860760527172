import React, { useEffect, useRef } from 'react';
import UploadProgressBar from '../UploadProgressBar/UploadProgressBar';
import sizeConvertor from './sizeConvertor';
import { getTruncateString } from '../../../../utils/utils';

type Props = {
  uploadStatusData: {
    loaded: number;
    total: number | undefined;
  };
  fileName: string;
};

const UploadProgressCard: React.FC<Props> = ({ uploadStatusData, fileName}) => {
  const uploadProgressBarRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={uploadProgressBarRef}>
      <p>{getTruncateString(fileName,30)}</p>
      <div>
        <span>{sizeConvertor(uploadStatusData.loaded)} /</span>
        <span>{sizeConvertor(uploadStatusData.total || 0)}</span>
      </div>
      <UploadProgressBar />
    </div>
  );
};

export default UploadProgressCard;
