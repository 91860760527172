import React, { useEffect, useState } from 'react';
import './ClientBalanceCreate.scss';
import * as yup from 'yup';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../store/store';
import { CompanyPayment, PartnerCompany } from '../../../PartnerCabinetTypes';
import {getPartnerCompanyRequisites, getPartnerRequisites} from '../../../PartnerCabinetAPI';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { PartnerInvoiceSchema, useInvoice } from "../../../api/invoices";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import Input from '../../../../../components/Input';
import PaymentMethod from '../../../components/PaymentMethod';
import Dropdown from '../../../../Settings/Dropdown/Dropdown';
import Button from '../../../../../components/Button';
import InvoicesSubscriptions from "../ClientInvoicesCreate/InvoicesSubscriptions";


type Props = {
  partnerCompany: PartnerCompany;
  setOnPay: React.Dispatch<React.SetStateAction<boolean>>;
};

type Named = {
  id: number;
  name: string;
};

const DIALOGS_LIMITS = { min: 1, max: 25000 };

const ClientBalanceCreate = ({ partnerCompany, setOnPay }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [requisites, setRequisites] = useState<Named[]>([]);
  const [invoices, setInvoices] = useState<CompanyPayment>();
  const getInvoice = useInvoice();

  useEffect( () => {
    let isFetchData = true;

    const fetchData = async () => {
      if (partnerCompany.id) {
        let requisitesList: Named[];

        if (partnerCompany.paymentsLocked) {
          requisitesList = await getPartnerRequisites({ company_id: currentCompany.id, only_approved: true });
        } else {
          requisitesList = await getPartnerCompanyRequisites({
            company_id: currentCompany.id, partner_company_id: partnerCompany.id, only_approved: true });
        }

        if (isFetchData) {
          if (requisitesList.length > 0) {
            setRequisites(requisitesList.map((item) => ({ id: item.id, name: item.name })));
          }
        }
      } else {
        setRequisites([]);
      }
    };

    fetchData();

    return () => {
      isFetchData = false;
    };

  }, []);

  const schema = yup.object().shape({
    dialogs: yup.number().transform((current, original) => (original === '' ? null : current)).nullable()
      .min(DIALOGS_LIMITS.min, t('error.form.min_value', { limit: DIALOGS_LIMITS.min }))
      .max(DIALOGS_LIMITS.max, t('error.form.max_value', { limit: DIALOGS_LIMITS.max })),
    payment_method: yup.string(),
    company_requisite_id: yup.number().when('payment_method', {
      is: 'BANK_TRANSFER',
      then: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      otherwise: yup.number().transform((current, original) =>  original === '' ? null : current).nullable(),
    }),
    partner_email: yup.string().when('payment_method', {
      is: (value: string) => value === 'CARD_RU' && partnerCompany.paymentsLocked,
      then: yup.string().email(t('error.form.email_format')).required(t('error.form.empty_field')),
      otherwise: yup.string().nullable()
    }),
  });

  const methods = useForm<PartnerInvoiceSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { payment_method: 'CARD_RU' },
  });

  const paymentMethod = useWatch({ control: methods.control, name: 'payment_method' });
  const dialogsInput = useWatch({ control: methods.control, name: 'dialogs' });
  const [dialogs] = useDebounce(dialogsInput, 400);

  useEffect(() => {
    if (methods.formState.errors.dialogs === undefined) {
      getInvoice(methods.getValues(), partnerCompany.id, true).then((res) => {
        if (res) setInvoices(res);
      });
    }
  }, [dialogs]);

  const onSubmit = async (data: PartnerInvoiceSchema) => {
    const invoices = await getInvoice(data, partnerCompany.id, false);
    if (invoices?.previewUrl) {
      dispatch(addAlertWithCustomText({
        message: t('partner_cabinet.payments.create.submit_fulfilled'), type: 'info' }));
      setOnPay(false);
      window.open(invoices.previewUrl, '_blank');
    }
  }

  return (
    <div className="clientBalanceCreate">
      <h1>{t('subscriptions.pay')}</h1>
      <FormProvider {...methods}>
        <div className="clientBalanceCreate__countDialogs">
          <h3>{t('subscriptions.invoice.dialogs.caption')}</h3>
          <Input name='dialogs' placeholder={t('partner_cabinet.payments.create.dialogs.placeholder')}
            inputType='number' min={0} step={100} />
        </div>
        {invoices && invoices.receipt.items.length > 0 && <InvoicesSubscriptions invoices={invoices} />}
        <PaymentMethod label={t('partner_cabinet.clients.create.methods_payment')}
          name="payment_method" clientPaymentsLocked={partnerCompany.paymentsLocked} />
        {paymentMethod === 'BANK_TRANSFER' &&
          <Dropdown label={t('partner_cabinet.payments.create.requisites')} name='company_requisite_id'
            items={requisites} labelKey='name' valueKey='id' placeholder={t('select')} />
        }
        {paymentMethod === 'CARD_RU' && (
          <Input name="partner_email" label={t('partner_cabinet.clients.create.email')}
            placeholder={t('partner_cabinet.payments.create.email.placeholder')} autoComplete='on' />
        )}
        <Button color='orange' textType='regular' text={t('next')} onClick={methods.handleSubmit(onSubmit)} />
      </FormProvider>
    </div>
  );
};

export default ClientBalanceCreate;
