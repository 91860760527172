import './InteractiveMessageButtons.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { InteractiveTemplate } from '../../../api';
import ButtonAction from '../ButtonAction';
import Button from '../../../../../../components/Button';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


const MAX_BUTTONS = 3;

const InteractiveMessageButtons = () => {
  const { t } = useTranslation();
  const methods = useFormContext<InteractiveTemplate>();
  const fieldArrayMethods = useFieldArray<InteractiveTemplate>({
    name: 'action.buttons',
    control: methods.control,
  });

  const onAddButtonAction = () => {
    if (fieldArrayMethods.fields.length < MAX_BUTTONS) {
      fieldArrayMethods.append({ type: 'reply', reply: { title: '' } });
    }
  };

  return (
    <div className="interactiveMessageButtons">
      {fieldArrayMethods.fields.map((button, idx) => (
        <ButtonAction id={idx} {...methods} key={button.id} {...fieldArrayMethods} />
      ))}
      <ErrorMessage errors={methods.formState.errors} name={'action.buttons'}
          render={({ message }) => <p className="smallText error">{message}</p>} />
      {fieldArrayMethods.fields.length < MAX_BUTTONS && (
        <Button type='button' color='white' textType='small' image={<PlusIcon />}
          text={t('templates.add_interactive_templates.template_actions.buttons.add_button')}
          onClick={onAddButtonAction}
        />
      )}
    </div>
  );
};

export default InteractiveMessageButtons;
