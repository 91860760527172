import './PartnerCabinetList.scss';
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { partnersTabs } from '../tabs';


const PartnerCabinetList = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const params = useRouteMatch<{ tab: string; }>('/companies/:companyId/partners/:tab');

  return (
    <div className="partnerCabinetList">
      {partnersTabs.map((tab) => (
        <Link key={tab.id} to={`${url}/${tab.id}`}>
          <div key={tab.id}
            className={`partnerCabinetList__item ${params?.params.tab === tab.id ? 'active' : ''}`}>
            {tab.icon}
            {t(`partner_cabinet.partner_list.${tab.id}`)}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PartnerCabinetList;
